import React from 'react'
import AuthGuard from '../authGuards'
import TopNav from '../topnavComponent'
import StatSearch from './statsSearch'

const Statics = () =>
{
    return (
        <>
            <AuthGuard />
            <TopNav />
            <StatSearch />
        </>
    )
}

export default Statics