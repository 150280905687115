import React from "react";
import styled from "styled-components";
import {message, Button , Input, Form } from "antd";
import { useState } from "react";
import AuthGuard from "../../authGuards";
import adminService from "../../../../common/adminService";



const CreateKeywordWrapper = styled.div`
padding : 20px;`;

const CreateKeywordHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
`;

const CreateKeywordButton = styled(Button)`
  float: right;
`;
function CreateKeywords(props){
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) =>{
      e.preventDefault();
      props.form.validateFields(async( err, values) => {
        if(!err){
          // const cityData = ["bmbc", "bmbc2"];
          setLoading(true);
          const createKeyword = await adminService.addKeyword(values);
          setLoading(false);
          if (createKeyword.data.success === true) {
            const logData = {body:  `Keyword ${values.name} created`, url: 'keyword/add' }
            const createLog = await adminService.addLog(logData)
            if (createLog.data.success === true)
            {
              message.success("Keyword Successfully Created");
            };
         
            props.form.resetFields();
            props.history.push("/admin/newKeywords");
          } else {
            message.error("Error in creating keyword");
          }
        }
      });
    };
    const { getFieldDecorator } = props.form;
    return (
        <>
        <AuthGuard />
        <CreateKeywordWrapper>
          <CreateKeywordHeader>
          <h2>Create Keyword</h2>
            <CreateKeywordButton onClick={() => props.history.push("/admin/newKeywords")}>
              Cancel
            </CreateKeywordButton>
          </CreateKeywordHeader>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item label="Name">{
              getFieldDecorator("name",{ 
                rules : [{ required:true, message :"Please Enter"}],
              })(<Input placeholder="please Enter" />)}
            </Form.Item>
            <Form.Item>
              <Button size="large"
              type="primary"
              htmlType="submit"
              loading={loading}>
                Create
              </Button>
            </Form.Item>
          </Form>
        </CreateKeywordWrapper>
        </>
    );
}

export  default Form.create()(CreateKeywords);