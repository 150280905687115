import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/searchForm.css";
import "../assets/css/batch.css";
import SeriesSearch from "../SearchPages/TestSeries";
import BatchSearch from "../SearchPages/BatchSearch";
import HindiBatch from "../SearchPages/HindiBatch";
import InstituteSearch from "../SearchPages/InstituteSearch";
import { BatchContext } from "../../redux/batchContext";
import AffordaleBatch from "../SearchPages/AffordaleBatch";
import TrendingBatches from "../SearchPages/TrendingBatches";
import PromoDetails from "../PromoDetails";
import { PromoContext } from "../../redux/promoContext";

const SearchFilter = () =>
{
    const history = useHistory()
    const { examName } = useContext(BatchContext);
    const { promoDetails, setPromoDetails } = useContext(PromoContext)
    const handlePromoData = (data) =>
    {
        setPromoDetails(data);
    };
    return (
        <>

            <div className="container">
                <button type="button" className="go_back_btn" onClick={() => history.goBack()}>Go Back</button>
            </div>
          

            <BatchSearch examName={examName} />

            {promoDetails.length > 0 && (
                <PromoDetails onPromo={handlePromoData} promoData={promoDetails.slice(0, 5)} />
            )}

            <TrendingBatches examName={examName} />


            <HindiBatch examName={examName} />

            {(promoDetails.length >= 5 || promoDetails.length <= 10) && (
                <PromoDetails onPromo={handlePromoData} promoData={promoDetails.slice(5, 10)} />
            )}


            <AffordaleBatch examName={examName} />


            <SeriesSearch examName={examName} />

            {promoDetails.length >= 15 && (
                <PromoDetails onPromo={handlePromoData} promoData={promoDetails.slice(10, 15)} />
            )}

            <InstituteSearch examName={examName} />





        </>
    )

}

export default SearchFilter