import React, { useState } from 'react';
import Chat from './Chat';
import { BiSupport } from "react-icons/bi";
const ChatForm = () =>
{
    const [chatVisible, setChatVisible] = useState(false);

    const handleCutButtonClick = () =>
    {
        setChatVisible(false);
    };

    const handleChatSupportButtonClick = () =>
    {
        setChatVisible(true);
    };

    return (
        <>
            {chatVisible && (
                <div className='chat_wrapper'>
                    <div className='chat_box'>
                        <button style={{ 'float': 'right', color: 'black' }} onClick={handleCutButtonClick}><b>Close Chat</b></button>
                        <Chat />
                    </div>
                </div>
            )}
            {
                !chatVisible && (
                    <button className='chat_support_button' onClick={handleChatSupportButtonClick}>
                        <BiSupport /> Support
                    </button>
                )
            }
        </>

    )
}

export default ChatForm