import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";
// import DownloadSheet from "../download";
// import axios from "axios";

const BatchManagementWrapper = styled.div`
  padding: 20px;
`;

const BatchManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BatchManagementCreateButton = styled(Button)`
  float: right;
  margin-left:10px;
`;

const BatchManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const BatchManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const BatchManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
  display:flex;
  margin-bottom:2rem;
  justify-content:space-between;
`;

function BatchManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allBatches, setAllBatches] = useState([])
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  const [rawData, setRawData] = useState(null);

  useEffect(() =>
  {
    const type = sessionStorage.getItem("adminType");
    setAdminType(type);
  }, [])
  useEffect(() =>
  {
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) =>
  {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = () =>
  {
    setLoading(true);
    adminService.getAllBatches().then((apiResponse) =>
    {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      const data = apiResponse.data.data
      let batchList = data.map((obj) =>
      {

        const endDate = new Date(obj.endDate);
        const startDate = new Date(obj.startDate);
        const momentEndDate = moment(endDate);
        const momentStartDate = moment(startDate);
        const momentNowDate = moment();
        let status;
        if (momentNowDate.isAfter(momentEndDate, 'day'))
        {
          status = 'Ended';
        } else if (!momentNowDate.isAfter(momentStartDate))
        {
          status = 'Upcoming';
        } else
        {
          status = 'Active';
        }

        return {
          ...obj,
          status,
        };
      }).filter(obj => obj.hide === "yes");
      setAllBatches(batchList)
      const ongoingData = batchList.filter(obj => obj.status === 'Active');

      setData(ongoingData);
      setPagination(paginationObj);
    });
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [batchIdToDelete, setBatchIdToDelete] = useState(null);

  const showDeleteModal = (batchId) =>
  {
    setRawData(data)
    setBatchIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteBatch = async (id) =>
  {
    try
    {
      const deleteBatchResponse = await adminService.deleteBatch(
        batchIdToDelete
      );
      if (deleteBatchResponse.data.success === true)
      {
        const logData = { body: `Batch ${rawData.batchName} deleted`, url: 'batch/delete' }
        const createLog = await adminService.addLog(logData);
        if (createLog.data.success === true)
        {
          message.success(deleteBatchResponse.data.data);
        }
      } else
      {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error)
    {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () =>
  {
    setIsDeleteModalOpen(false);
  };

  
  const columns = [
    {
      title: "Batch Name",
      dataIndex: "batchName",
      width: "6%"
    },
    {
      title: "Image",
      dataIndex: "instituteId.image",
      key: "image",
      width: "10%",
      render: (image) => (
        <img style={{ width: "80px" }} src={image} alt={"batchImage"} />
      ),
    },
    {
      title: "Institute",
      dataIndex: "instituteId.name",
      width: "12%"
    },
    {
      title: "Exam",
      dataIndex: "examId.name",
      width: "6%"
    },
    {
      title: "City",
      dataIndex: "city",
      width: "6%"
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
      render: (value) => (value ? value : "Online / Offline"),
    },
    {
      title: "Timing",
      key: "startdate",
      width: "6%",
      dataIndex: "timings",
      render: (timings) =>
      {
        if (timings)
        {
          return timings;
        } else
        {
          return "No Timing Available";
        }
      },
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      width: "8%",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) =>
      {
        if (value === "Ended")
        {
          return <p className="bg-danger text-white text-center p-1">{value}</p>
        } else if (value === "Upcoming")
        {
          return <p className="bg-secondary text-white text-center p-1">{value}</p>
        } else
        {
          return <p className="bg-success text-white text-center p-1">Ongoing</p>
        }
      }
    },
    {
      title: "BranchLink",
      dataIndex: "branchLink",
      width: "15%",
      render: (ele) =>
      {
        return <Link to="/admin/batch">{ele?.slice(0, 200)}</Link>
      }
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editBatch/${record}`}>Edit</Link>
              </Button>
              {/* {data.copied === "yes" ?
                <Button type="primary" disabled>
                  Copied
                </Button>
                :
                <Button className="bg-success text-white" type="light" style={{ marginRight: 2 }}>
                  <Link to={`/admin/copyBatch/${record}`}>Copy Batch</Link>
                </Button>
              } */}
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
            </>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editBatch/${record}`}>Edit</Link>
              </Button>
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
                {data.copied === 0 ?
                  <Button className="bg-success text-white">
                    <Link to={`/admin/copyBatch/${record}`}>Copy</Link>
                  </Button>
                  :
                  <Button disabled type="light" style={{ marginRight: 2 }}>
                    Copy ({data.copied})
                  </Button>
                }
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleVisibility = async (data) =>
  {
    const batchStatus = data.hide === "yes" ? "Visible" : "Hidden"
    const obj = {
      id: data._id,
      hide: data.hide === "yes" ? "no" : "yes",
    };

    const updateInstitue = await adminService.handleBatchVisibility(obj);
    if (updateInstitue.status === 200)
    {
      const logData = { body: `Batch ${data.batchName} status changed to ${batchStatus}`, url: 'batch/status' }
      const createLog = await adminService.addLog(logData)
      if (createLog.data.success === true)
      {
        message.success("Visiblity Updated");
      }

      fetch();
    } else
    {
      message.error("An Error Occured");
    };
  }



  const handleSearch = (searchTerm) =>
  {

    const filteredData = allBatches.filter(
      (item) =>
        item.batchName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.instituteId?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.examId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.timings?.[0]?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length === 0)
    {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };
  


  return (
    <>
      <AuthGuard />
      <TopNav />
      <BatchManagementWrapper>
        <BatchManagementHeader>
          <h2>Hidden Batches</h2>
          {(adminType === "delete" || adminType === "edit") && (
                        <BatchManagementCreateButton type="light">
                            <Link to="/admin/batch">Cancel</Link>
                        </BatchManagementCreateButton>
                    )}
          
        </BatchManagementHeader>
        
            <SearchWrapper>
            <SearchComponent data={data} onSearch={handleSearch} />
            </SearchWrapper>
        <BatchManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Delete Batch"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this batch?</p>
        </Modal>
      </BatchManagementWrapper>
    </>
  );
}
export default BatchManagement;
