import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";
// import DownloadSheet from "../download";
import axios from "axios";
import ConfirmPopup from "./confirmPopup";

const BatchManagementWrapper = styled.div`
  padding: 20px;
`;

const BatchManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const BatchButton = styled.div`
display:flex;
align-items:center;
`

const BatchManagementCreateButton = styled(Button)`
  float: right;
  margin-left:10px;
`;

const BatchManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const BatchManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const BatchManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
  display:flex;
  margin-bottom:2rem;
  justify-content:space-between;
`;

function BatchManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allBatches, setAllBatches] = useState([])
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  const [rawData, setRawData] = useState(null);

  useEffect(() =>
  {
    const type = sessionStorage.getItem("adminType");
    setAdminType(type);
  }, [])
  useEffect(() =>
  {
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) =>
  {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = () =>
  {
    setLoading(true);
    adminService.getAllBatches().then((apiResponse) =>
    {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      const data = apiResponse.data.data
      let batchList = data.map((obj) =>
      {

        const endDate = new Date(obj.endDate);
        const startDate = new Date(obj.startDate);
        const momentEndDate = moment(endDate);
        const momentStartDate = moment(startDate);
        const momentNowDate = moment();
        let status;
        if (momentNowDate.isAfter(momentEndDate, 'day'))
        {
          status = 'Ended';
        } else if (!momentNowDate.isAfter(momentStartDate))
        {
          status = 'Upcoming';
        } else
        {
          status = 'Active';
        }

        return {
          ...obj,
          status,
        };
      })
      setAllBatches(batchList)
      setData(batchList);
      setPagination(paginationObj);
    });
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [batchIdToDelete, setBatchIdToDelete] = useState(null);

  const showDeleteModal = (batchId) =>
  {
    setRawData(data)
    setBatchIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteBatch = async (id) =>
  {
    try
    {
      const deleteBatchResponse = await adminService.deleteBatch(
        batchIdToDelete
      );
      if (deleteBatchResponse.data.success === true)
      {
        const logData = { body: `Batch ${rawData.batchName} deleted`, url: 'batch/delete' }
        const createLog = await adminService.addLog(logData);
        if (createLog.data.success === true)
        {
          message.success(deleteBatchResponse.data.data);
        }
      } else
      {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error)
    {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () =>
  {
    setIsDeleteModalOpen(false);
  };

  // const examFilters = examList.map((exam) => ({
  //   text: exam,
  //   value: exam,
  // }));

  const columns = [
    {
      title: "Batch Name",
      dataIndex: "batchName",
      width: "6%"
    },
    {
      title: "Image",
      dataIndex: "instituteId.image",
      key: "image",
      width: "10%",
      render: (image) => (
        <img style={{ width: "80px" }} src={image} alt={"batchImage"} />
      ),
    },
    {
      title: "Institute",
      dataIndex: "instituteId.name",
      width: "12%"
    },
    {
      title: "Exam",
      dataIndex: "examId.name",
      width: "6%"
    },
    {
      title: "City",
      dataIndex: "city",
      width: "6%"
    },
    {
      title: "Language",
      dataIndex: "languages.name",
      width: "6%"
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
      render: (value) => (value ? value : "Online / Offline"),
    },
    {
      title: "Timing",
      key: "startdate",
      width: "6%",
      dataIndex: "timings",
      render: (timings) =>
      {
        if (timings)
        {
          return timings;
        } else
        {
          return "No Timing Available";
        }
      },
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      width: "8%",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) =>
      {
        if (value === "Ended")
        {
          return <p className="bg-danger text-white text-center p-1">{value}</p>
        } else if (value === "Upcoming")
        {
          return <p className="bg-secondary text-white text-center p-1">{value}</p>
        } else
        {
          return <p className="bg-success text-white text-center p-1">Ongoing</p>
        }
      }
    },
    {
      title: "BranchLink",
      dataIndex: "branchLink",
      width: "15%",
      render: (ele) =>
      {
        return <Link to="/admin/batch">{ele?.slice(0, 200)}</Link>
      }
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editBatch/${record}`}>Edit</Link>
              </Button>
              {data.copied === 0 ?
                <Button className="bg-success text-white">
                  <Link to={`/admin/copyBatch/${record}`}>Copy</Link>
                </Button>
                :
                <Button disabled type="light" style={{ marginRight: 2 }}>
                  Copy ({data.copied})
                </Button>
              }
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
            </>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editBatch/${record}`}>Edit</Link>
              </Button>
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
              {data.copied === 0 ?
                  <Button className="bg-success text-white">
                    <Link to={`/admin/copyBatch/${record}`}>Copy</Link>
                </Button>
                :
                <Button  disabled type="light" style={{ marginRight: 2 }}>
                  Copy ({data.copied})
                </Button>
              }
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleVisibility = async (data) =>
  {
    const batchStatus = data.hide === "yes" ? "Visible" : "Hidden"
    const obj = {
      id: data._id,
      hide: data.hide === "yes" ? "no" : "yes",
    };

    const updateInstitue = await adminService.handleBatchVisibility(obj);
    if (updateInstitue.status === 200)
    {
      const logData = { body: `Batch ${data.batchName} status changed to ${batchStatus}`, url: 'batch/status' }
      const createLog = await adminService.addLog(logData)
      if (createLog.data.success === true)
      {
        message.success("Visiblity Updated");
      }

      fetch();
    } else
    {
      message.error("An Error Occured");
    };
  }


  const handleSearch = (searchTerm) =>
  {
    const filteredData = allBatches.filter((item) =>
    {
      return (
        item.batchName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.instituteId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.examId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    if (filteredData.length === 0)
    {
      message.info('No Results Found');
    }

    setFilteredData(filteredData);
  };


  // const testURL = process.env.REACT_APP_API_URL_TEST
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);



  const handleConfirmDownload = async () =>
  {
    const url = `http://18.188.147.208:5000/batch/download/1`;
    setLoading(true)
    try
    {
      const res = await axios.get(url);
      if (res)
      {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        message.success("Sheet downloaded successfully");
        document.body.removeChild(link);
        setLoading(false)
      }
    } catch (error)
    {
      console.log(error);
    }

  }

  // const handleUpload = async (fileList, setFileList) =>
  // {
  //   setLoading(true);
  //   const url = `http://18.188.147.208:5000/batch/upload`;
  //   const file = fileList[0];
  //   if (file)
  //   {
  //     try
  //     {
  //       const formData = new FormData();
  //       formData.append('file', file.originFileObj);
  //       message.success("Please wait, it takes few minutes");
  //       const res = await axios.post(url, formData);
  //       if (res.data.success === true)
  //       {
  //         message.success("Sheet uploaded successfully");
  //         setFileList(null);
  //         setLoading(false);
  //       } else
  //       {
  //         message.error("Sheet upload failed");
  //       }
  //     } catch (error)
  //     {
  //       console.error(error);
  //       message.error("An error occurred while uploading the sheet");
  //       setLoading(false);
  //     }
  //   }

  // }


  return (
    <>
      <AuthGuard />
      <TopNav />
      <BatchManagementWrapper>
        <BatchManagementHeader>
          <h2>Batch Management</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <BatchManagementCreateButton type="primary">
              <Link to="/admin/createBatch">Create</Link>
            </BatchManagementCreateButton>
          )}
        </BatchManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
          <BatchButton>
            {/* <DownloadSheet onDownload={handleDownload} onUpload={handleUpload} loading={loading} /> */}
            <BatchManagementCreateButton className="bg-success" type="light">
              <Link to="/admin/batch" style={{ color: 'white' }}>All Batches</Link>
            </BatchManagementCreateButton>
            <BatchManagementCreateButton type="light">
              <Link to="/admin/ongoingBatch" style={{ color: 'primary' }}>Ongoing Batches</Link>
            </BatchManagementCreateButton>
            <BatchManagementCreateButton type="secondary">
              <Link to="/admin/upcomingBatch" style={{ color: 'dark' }}>Upcoming Batches</Link>
            </BatchManagementCreateButton>
            <BatchManagementCreateButton type="danger">
              <Link to="/admin/endedBatch">Ended Batches</Link>
            </BatchManagementCreateButton>
            <BatchManagementCreateButton type="secondary">
              <Link to="/admin/hiddenBatch" style={{ color: 'grey' }}>Hidden Batches</Link>
            </BatchManagementCreateButton>
          </BatchButton>

        </SearchWrapper>

        <BatchManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Delete Batch"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this batch?</p>
        </Modal>


        <ConfirmPopup
          visible={downloadModalVisible}
          loading={loading}
          onConfirm={handleConfirmDownload}
          onCancel={() => setDownloadModalVisible(false)}
          title="Confirm Download"
          content="Are you sure you want to download?"
        />
        {/* <ConfirmPopup
          visible={downloadModalVisible}
          loading={loading}
          onCancel={() => setDownloadModalVisible(false)}
          title="Confirm Upload"
          content="Are you sure you want to upload?"
        /> */}

      </BatchManagementWrapper>
    </>
  );


}
export default BatchManagement;
