import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import userService from "../../common/userService";
import toast, { Toaster } from 'react-hot-toast';
import { message } from "antd";
import axios from "axios";
import GoogleLogin from 'react-google-login';
import { gapi } from "gapi-script";
export default function Register({ text, onRegisterClose })
{
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const testURL = process.env.REACT_APP_API_URL_LIVE
  const history = useHistory();
  useEffect(() =>
  {
    gapi.load("client:auth2", () =>
    {
      gapi.auth2.init({ clientId: '1083827701907-o7vmna2tpg4ieuq2ls9het5jivfq9scv.apps.googleusercontent.com' })
    })
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
  });
  const [errors, setErrors] = useState("");
  const handleInputChange = async (event) =>
  {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === 'password')
    {
      const error = validatePassword(value);
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }
  };
  const validatePassword = (password) =>
  {
    if (password.length < 6)
    {
      return 'Password must be at least 6 characters long.';
    }
    return '';
  };

  const handleSubmit = async (event) =>
  {
    event.preventDefault();
    // onModalClose();
    const allFieldsFilled = Object.values(formValues).every(
      (val) => val !== ""
    );
    if (!allFieldsFilled)
    {
      toast.error("Please fill in all the fields.");
      return;
    }
    if (formValues.password.length < 6)
    {
      setFormErrors("Password must be at least 6 characters long.")
    } else
    {
      try
      {
        const register = await axios.post(testURL + "user/add", formValues)
        if (register.data.success === true)
        {
          toast.success('Registered Successfully!');
          const login = await userService.login(formValues)
          if (login.data.success === true)
          {
            localStorage.setItem('token', login.data.data.token)
            localStorage.setItem('id', login.data.data._id);
            localStorage.setItem('userName', login.data.data.name)
            const redirectUrl = sessionStorage.getItem("redirectUrl")
            toast.success('Register successfully!');
            if (redirectUrl)
            {
              history.push(redirectUrl);
              sessionStorage.removeItem("redirectUrl");
              onRegisterClose();
            } else
            {
              history.push("/");
            }
          }
        } else
        {
          if (register.data.message === "User already exists with this email!")
          {
            message.error(register.data.message);
          } else
          {
            setErrors('An error occurred. Please try again.');
          }
        }
      } catch (error)
      {
        message.error("User already exists with this email!");
      };
    }

  }


  const handleGoogleResponse = async (res) =>
  {
    if (res)
    {
      try
      {
        const name = res?.profileObj?.name;
        const email = res?.profileObj?.email;
        const password = res?.profileObj?.familyName;
        const image = res.profileObj?.imageUrl;
        const phone = "";
        const qualification = "";
        const address = "";
        const city = "";
        const result = await axios.post(testURL + "user/add", {
          name, email, password, image, qualification, address, phone, city
        });
        if (result.data.success === true)
        {
          localStorage.setItem('token', result.data.data.token)
          localStorage.setItem('id', result.data.data._id);
          localStorage.setItem('userName', result.data.data.name)
          const redirectUrl = sessionStorage.getItem("redirectUrl")
          toast.success('Register successfully!');
          if (redirectUrl)
          {
            history.push(redirectUrl);
            sessionStorage.removeItem("redirectUrl");
            onRegisterClose();
          } else
          {
            history.push("/");
          }
        } else
        {
          message.error("Some error occured try again !")
        }
      } catch (error)
      {
        try
        {
          const email = res?.profileObj?.email;
          const googleId = res?.googleId;
          const tokenId = res?.tokenId
          const result = await axios.post(testURL + "user/login", {
            email, googleId, tokenId
          });
          if (result.data.success === true)
          {
            localStorage.setItem('token', result.data.data.token)
            localStorage.setItem('id', result.data.data._id);
            localStorage.setItem('userName', result.data.data.name);
            const redirectUrl = sessionStorage.getItem("redirectUrl")
            toast.success('Logged in successfully!');
            if (redirectUrl)
            {
              history.push(redirectUrl);
              sessionStorage.removeItem("redirectUrl");
              onRegisterClose();
            } else
            {
              history.push("/");
            }
          } else
          {
            message.error("Some error occured try again !")
          }
        } catch (error)
        {
          console.log("Error");
        }
      }
    }
  }

  const handleGoogleFailure = async () =>
  {
    message.error("Google authentication failed")
  }

  return (
    <div className="ltn__login-area login_form_new">
      <div className="account-login-inner form_template_wrapper">
        <h2>{text}</h2>
        <form
          onSubmit={handleSubmit}
        >
          <input
            value={formValues.name}
            onChange={handleInputChange}
            className="form-control"
            type="text"
            name="name"
            placeholder="Name*"
          />
          <input
            value={formValues.email}
            onChange={handleInputChange}
            className="form-control"
            type="email"
            name="email"
            placeholder="Email*"
          />

          <input
            value={formValues.password}
            onChange={handleInputChange}
            className="form-control"
            type="password"
            name="password"
            placeholder="Password*"
          />
          {formErrors.password && <p className="error_message_alert mb-3">{formErrors.password}</p>}
          {errors && <p className="error_message_alert mb-3">{errors}</p>}
          <label className="checkbox-inline">
            <input type="checkbox" defaultValue required /> &nbsp; By clicking
            "create account", I consent to the <Link to="/term-condition">Privacy Policy</Link> and <Link to="/privacy-policy">T&Cs</Link>
          </label>

          <div className="forget_links">
            <p>Already Have Account?<Link to="/login">Sign in</Link></p>
          </div>
          <div className="sign_up_btn_wrap">
            <button type="submit">
              Register
            </button>

            <p>OR</p>
            <GoogleLogin
              clientId={clientID}
              buttonText="Continue with Google"
              onSuccess={handleGoogleResponse}
              onFailure={handleGoogleFailure}
              cookiePolicy={'single_host_origin'}
            />

          </div>
        </form>

      </div>
      <Toaster />
    </div>
  );
}
