import apiService from "./apiService";
// const apiURL = process.env.REACT_APP_API_URL;
const testURL = process.env.REACT_APP_API_URL_LIVE
class UserService {

  async register(data) {
    return apiService.post(testURL + "user/add", {
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      qualification: data.qualification,
      address: data.address,
      city: data.city
    });
  }

  async login(data) {
    return apiService.post(testURL + "user/login", {
      email: data.email,
      password: data.password,
    });
  }
  // async getAllNotification()
  // {
  //   return apiService.get(testURL + "location/all");
  // }
  async getAllLocations() {
    return apiService.get(testURL + "location/all");
  }

  async getAllInstitutes() {
    return apiService.get(testURL + "institute/list");
  }

  async getInstitueById(id) {
    return apiService.get(testURL + `institute/id/${id}`);
  }

  async getAllCategories() {
    return apiService.get(testURL + "category/list");
  }

  async getAllExams() {
    return apiService.get(testURL + "exam/list");
  }

  async getAllBatches({ filter, sort }) {
    if (filter === 'all') {
      return apiService.get(testURL + "batch/list");
    } else if (sort !== undefined) {
      return apiService.get(testURL + `batch/list?sort=${sort}`);
    } else {
      return apiService.get(testURL + `batch/list?status=${filter}`);
    }
  };



  async getAllBatchesByFilter(exam, city, mode) {
    let url = testURL + `batch/list`;

    if (exam !== 'all' && city === 'all' && mode === 'all') {
      url += `?examId=${exam}`;
    }
    else if (city !== 'all' && exam === 'all' && mode === 'all') {
      url += `?city=${city}`;
    }
    else if (mode !== 'all' && exam === 'all' && city === 'all') {
      url += `?status=${mode}`;
    }
    else if (exam !== 'all' && city !== 'all' && mode === 'all') {
      url += `?examId=${exam}&city=${city}`;
    }
    else if (exam !== 'all' && mode !== 'all' && city === 'all') {
      url += `?examId=${exam}&status=${mode}`;
    }
    else if (city !== 'all' && mode !== 'all' && exam === 'all') {
      url += `?city=${city}&status=${mode}`;
    }
    else if (exam !== 'all' && city !== 'all' && mode !== 'all') {
      url += `?examId=${exam}&city=${city}&status=${mode}`;
    } else if (exam === 'all' && city === 'all' && mode === 'all') {
      url = testURL + `batch/list`;
    }

    return apiService.get(url);
  }


  async getBatchById(id) {
    return apiService.get(testURL + `batch/id/${id}`);
  }

  async requestHelp(userId, data) {
    return apiService.post(testURL + `help/add`, {
      userId: userId,
      subject: data.subject,
      contact: data.contact,
      message: data.message
    });
  }



  async addInstitueReview(intId, obj) {
    return apiService.post(testURL + `instituteReview/add`, {
      instituteId: intId,
      message: obj.comment,
      rating: obj.rating,
      userId: obj.userActiveId
    });
  }


  async getUserDetails(id) {
    return apiService.get(testURL + `user/id/${id}`);
  }

  // async getPaymentByUserid(userId)
  // {
  //   return apiService.get(testURL + `transaction/list/${userId}`);
  // }
  async getPaymentByUserid(userId) {
    return apiService.get(testURL + `user/id/${userId}`);
  }

  async sendInvoice(transactionIdNew) {
    return apiService.get(testURL + `transaction/invoice-send?transactionId=${transactionIdNew}`)
  }



}

export default new UserService();

