import React, { useState } from 'react'
import Layout from './global-components/Layout'
import ViewAllBatches from './section-components/viewAllBatches'
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom"
const InstituteAllBatches = () =>
{
  const {instituteId,examId} = useParams()
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [info, setInfo] = useState("");
  const [image, setImage] = useState("");
  const handleData = (nameID, descriptionName, cityName, logoImg) =>
  {
    setName(nameID);
    setCity(cityName);
    setInfo(descriptionName);
    setImage(logoImg);
  };
  
  return (
    <>
     <Helmet>
                <title>{name?.slice(0, 40)}</title>
                <meta name="description" content={info?.slice(0, 70)} />
                <meta name="keywords" content={city} />
                <meta property="og:title" content={name?.slice(0, 40)} />
                <meta property="og:description" content={info?.slice(0, 70)} />
                <meta property="og:image" content={image} />
                <link rel="canonical" href={`https://bookmybestclass.com/institute/${instituteId}/test-series/${examId}`} />
                <meta property="og:url" content={`https://bookmybestclass.com/institute/${instituteId}/test-series/${examId}`} />
                <meta property="og:site_name" content="bookmybestclass" />
      </Helmet>
      <Layout>
        <ViewAllBatches onData={handleData} />
      </Layout>
    </>

  )
}

export default InstituteAllBatches