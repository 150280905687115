import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { User } from "react-feather";
import "antd/dist/antd.css";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const { SubMenu } = Menu;

const TopNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001529;
  height: 64px;
  padding: 0 20px;
`;

const UserMenu = styled(Dropdown)`
  display: flex;
  align-items: center;
`;

const UserIcon = styled(User)`
  display: block;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

function TopNav()
{
  const [adminType, setAdminType] = useState("");
  const [accessRights, setAccessRights] = useState([]);

  useEffect(() =>
  {
    const type = sessionStorage.getItem('adminRole')
    setAdminType(type)
    const rights = sessionStorage.getItem('adminAccessRights')
    if (rights === 'all')
    {
      setAccessRights(['dashboard', 'user', 'subscribedUsers', 'institute', 'category', 'plan', 'batch', 'exam', 'location', 'branch', 'offer', 'blog', 'helpCenter', 'instituteRating', 'facilities', 'language', 'news','keywords', 'instituteAds',"limit", 'batchKeywords', 'instituteKeywords', 'notificationPopup','instituteThumbnail'])
    } else
    {
      setAccessRights(rights)
    }
  }, []);

  const history = useHistory()
  const handleUser = () =>
  {
    sessionStorage.clear()

    history.push("/admin/signin")
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleUser}>Logout</Menu.Item>
    </Menu>
  );
  return (
    <TopNavWrapper>
      <Menu mode="horizontal" theme="dark">
        {accessRights.includes("dashboard") && (<Menu.Item key="home"><Link to="/admin/dashboard">Home</Link></Menu.Item>)}
        {accessRights.includes("user" || "subscribedUsers") && (
          <SubMenu key="um" title="User Management">
            {accessRights.includes("user") && (<Menu.Item key="users"><Link to="/admin/user">Users</Link></Menu.Item>)}
            {accessRights.includes("subscribedUsers") && (<Menu.Item key="subscribeUser"><Link to="/admin/subscribeUser">Subscribe Users</Link></Menu.Item>)}
          </SubMenu>
        )}
        {accessRights.includes("institute", "category", "plan", "batch", "exam", "location", "branch", "offer", "limit","keywords","batchKeywords", "instituteKeywords") && (
          <SubMenu key="gm" title="General Management">
            {accessRights.includes("institute") && (<Menu.Item key="institute"><Link to="/admin/institute">Institute</Link></Menu.Item>)}
            {accessRights.includes("category") && (<Menu.Item key="category"><Link to="/admin/category">Category</Link></Menu.Item>)}
            {accessRights.includes("plan") && (<Menu.Item key="plan"><Link to="/admin/plan">Plan</Link></Menu.Item>)}
            {accessRights.includes("batch") && (<Menu.Item key="batch"><Link to="/admin/batch">Batch</Link></Menu.Item>)}
            {accessRights.includes("limit") && (<Menu.Item key="limit"><Link to="/admin/limit">Affordable Limit</Link></Menu.Item>)}
            {accessRights.includes("facilities") && (<Menu.Item key="facilities"><Link to="/admin/facilities">Batch Facilities</Link></Menu.Item>)}
            {accessRights.includes("language") && (<Menu.Item key="language"><Link to="/admin/language">Language</Link></Menu.Item>)}
            {accessRights.includes("exam") && (<Menu.Item key="exam"><Link to="/admin/exam">Exam</Link></Menu.Item>)}
            {accessRights.includes("location") && (<Menu.Item key="location"><Link to="/admin/location">Location</Link></Menu.Item>)}
            {accessRights.includes("branch") && (<Menu.Item key="branch"><Link to="/admin/branch">Branch</Link></Menu.Item>)}
            {accessRights.includes("offer") && (<Menu.Item key="offer"><Link to="/admin/offer">Offer</Link></Menu.Item>)}
            {accessRights.includes("notificationPopup") && (<Menu.Item key="notificationPopup"><Link to="/admin/notificationPopup">Notification Popup</Link></Menu.Item>)}
            {accessRights.includes("instituteThumbnail") && (<Menu.Item key="instituteThumbnail"><Link to="/admin/instituteThumbnail">Institute Thumbnail</Link></Menu.Item>)}
          </SubMenu>
        )}
        {accessRights.includes("blog") && (<Menu.Item key="thumbnail"><Link to="/admin/thumbnail">ThumbNail</Link></Menu.Item>)}
        {accessRights.includes("blog") && (<Menu.Item key="blog"><Link to="/admin/blog">Blog</Link></Menu.Item>)}
        {accessRights.includes("helpCenter") && (<Menu.Item key="helpCenter"><Link to="/admin/helpCenter">Help Center</Link></Menu.Item>)}
        {accessRights.includes("news") && (<Menu.Item key="news"><Link to="/admin/news">NewsFlash</Link></Menu.Item>)}

        {accessRights.includes("keywords" || "batchKeywords" || "instituteKeywords" || "instituteAds") && (
          <SubMenu key="pm" title="Promo Management">

            {accessRights.includes("keywords"|| "batchKeywords" || "instituteKeywords") && 
            <SubMenu key="km" title = "Keyword Management"> 
              {accessRights.includes("keywords") && (<Menu.Item key="kw"><Link to="/admin/newKeywords">Keywords Creation</Link></Menu.Item>)}
              {accessRights.includes("batchKeywords") && (<Menu.Item key="bk"><Link to="/admin/batchKeywords">Batch Keywords</Link></Menu.Item>)}
              {accessRights.includes("instituteKeywords") && (<Menu.Item key="ik"><Link to="/admin/instituteKeywords">Institute Keywords</Link></Menu.Item>)}

            </SubMenu>} 
            
            {accessRights.includes("instituteAds") && (<Menu.Item key="promo"><Link to="/admin/promo">Ads Management</Link></Menu.Item>)}
          </SubMenu>
        )}
        {accessRights.includes("instituteRating") && (<Menu.Item key="institueRating"><Link to="/admin/instituteRating">Institute Rating</Link></Menu.Item>)}
        {adminType === "superAdmin" && <Menu.Item key="adminUsers"><Link to="/admin/adminUsers">Admin Users</Link></Menu.Item>}
        {adminType === "superAdmin" && <Menu.Item key="logs"><Link to="/admin/logs">Logs</Link></Menu.Item>}
      </Menu>
      <UserMenu overlay={userMenu} placement="bottomRight">
        <UserIcon style={{ color: "white" }} />
      </UserMenu>
    </TopNavWrapper>
  );
}

export default TopNav;


// {accessRights.includes("home") && ( )}
