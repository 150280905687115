import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import bmbcLogo from "../media/banner/logo.png"
import { BatchContext } from '../../redux/batchContext';
import { BsArrowRightShort } from "react-icons/bs"
// import { useRef } from 'react';
const Sidebar = () => {
	// const apiURL = process.env.REACT_APP_API_URL;
	const apiURL = process.env.REACT_APP_API_URL_LIVE
	const [data, setData] = useState([])
	const [more, setMore] = useState(false)
	// const [loading, setLoading] = useState(false);

	const fetchBlog = async () => {
		const res = await axios.get(apiURL + `blog/list`)
		try {
			setData(res.data.data);

		} catch (error) {
			console.log(error);
		}
	}
	const { setBlogId } = useContext(BatchContext)
	const handleSendId = (id) => {
		setBlogId(id)
	}
	useEffect(() => {
		fetchBlog();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<aside className={`prev_blog_section ${more ? 'scrollable' : ''}`}>
				<h2 className='lt_blg_hd'>Popular Blogs</h2>
				{
					!more ? data &&
						data.slice(0, 8).map((index, id) => {
							const uniqueId = id;
							const encodedHeading = encodeURIComponent(index.heading);
							const cleanHeading = encodedHeading.toLowerCase().replace(/%20/g, '-');
							const createdAt = data[id]?.createdAt;
							const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
							const localDate = new Date(createdAt);
							const paymentDate = new Date(localDate);
							const payment_date_year = paymentDate.getFullYear();
							const payment_date_month = months[paymentDate.getMonth()];
							const payment_date_date = paymentDate.getDate();
							const payment_date_dateNew = payment_date_date < 10 ? '0' + payment_date_date : payment_date_date;
							const fulldate_paymentDate = payment_date_dateNew + '-' + payment_date_month + '-' + payment_date_year;
							return (
								<React.Fragment key={uniqueId}>
									<div className='prev_blog_wrap'>
										{
											index.image ? (
												<Link onClick={() => handleSendId(index._id)} to={`/blog/${cleanHeading}`}>
													<img src={index.image} alt="blogImage" />
												</Link>
											) : (
												<img src={bmbcLogo} alt={bmbcLogo} />
											)
										}

										<div className='prev_blog_cnt'>
											<h2>
												{index?.heading.slice(0, 26) + "..."}
											</h2>
											<div className='blg_prev_ft'>
												<p><i className="far fa-calendar-alt" />{fulldate_paymentDate}</p>
												<Link onClick={() => handleSendId(index._id)} to={`/blog/${cleanHeading}`}>
													<BsArrowRightShort />Read more
												</Link>
											</div>
										</div>
									</div>
								</React.Fragment>
							);
						})
						:
						data.map((index, id) => {
							const uniqueId = id;
							const encodedHeading = encodeURIComponent(index.heading);
							const cleanHeading = encodedHeading.toLowerCase().replace(/%20/g, '-');
							const createdAt = data[id]?.createdAt;
							const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
							const localDate = new Date(createdAt);
							const paymentDate = new Date(localDate);
							const payment_date_year = paymentDate.getFullYear();
							const payment_date_month = months[paymentDate.getMonth()];
							const payment_date_date = paymentDate.getDate();
							const payment_date_dateNew = payment_date_date < 10 ? '0' + payment_date_date : payment_date_date;
							const fulldate_paymentDate = payment_date_dateNew + '-' + payment_date_month + '-' + payment_date_year;

							return (
								<React.Fragment key={uniqueId}>
									<div className='prev_blog_wrap'>
										{
											index.image ? (
												<Link onClick={() => handleSendId(index._id)} to={`/blog/${cleanHeading}`}>
													<img src={index.image} alt="blogImage" />
												</Link>
											) : (
												<img src={bmbcLogo} alt={bmbcLogo} />
											)
										}

										<div className='prev_blog_cnt'>
											<h2>
												{index?.heading?.slice(0, 26) + "..."}
											</h2>
											<div className='blg_prev_ft'>
												<p>	<i className="far fa-calendar-alt" />{fulldate_paymentDate}</p>
												<Link onClick={() => handleSendId(index._id)} to={`/blog/${cleanHeading}`} ><BsArrowRightShort />Read more</Link>
											</div>
										</div>
									</div>
								</React.Fragment>
							)
						})

				}
			</aside>
			<p className='see_more_blog' onClick={() => setMore(!more)}>
				{more ? 'Show Less' : 'Load More..'}
			</p>


		</>
	)
}

export default Sidebar;
