import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#fff; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
`;

const SpinnerContainer = styled.div`
    text-align: center;
`;

const BmbcLogoLoader = ({loading}) =>
{
    return (
        loading && (
            <Overlay>
                <SpinnerContainer>
                    <Spin size="large" />
                </SpinnerContainer>
            </Overlay>
        ) 
    );
};

export default BmbcLogoLoader;
