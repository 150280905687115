import React from 'react';
// import PageHeader from './global-components/page-header';
import Faq from './section-components/faq-v1';
// import faqBan from "./media/banner/faqBan.png"
import Layout from './global-components/Layout';
const FaqV1 = () =>
{
    return <div>
        <Layout>
            {/* <PageHeader image={faqBan} /> */}
            <Faq />
        </Layout>
     
    </div>
}

export default FaqV1

