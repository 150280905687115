import React, { createContext, useState } from 'react';

export const PromoContext = createContext();

export const PromoProvider = ({ children }) => {
    const [promoDetails, setPromoDetails] = useState([]);

    return (
        <PromoContext.Provider value={{ promoDetails, setPromoDetails }}  >
            {children}
        </PromoContext.Provider>
    );

}