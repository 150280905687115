import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import "../assets/css/searchForm.css";
import "../assets/css/batch.css";
import Slider from "react-slick";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { Spin } from "antd";
import ErrorPage from "../404";
import axios from "axios";
import "../assets/css/newSearch.css";
import "../assets/css/compareCard.css";
const BatchCard = lazy(() => import('../reusable/BatchCard'));

const SeriesSearch = ({ examName }) =>
{
    const [activeTestFilter, setActiveTestFilter] = useState("all");
    const [showMoreTest, setShowMoreTest] = useState(false)
    const [loading, setLoading] = useState(false);
    const [testSeries, setTestSeries] = useState([]);
    const [testSeriesOriginal, setTestSeriesOriginal] = useState([]);
    const [ableSeriesButton, setAbleSeriesButton] = useState([]);
    const [cityName, setCityName] = useState("");
    // const [examName, setExamName] = useState("");
    const [modeName, setModeName] = useState("");
    const [examLocalId, setExamLocalId] = useState("");
    const testURL = process.env.REACT_APP_API_URL_LIVE
    const history = useHistory();
    useEffect(() =>
    {
        let isMounted = true; 

        const cityFromLocalStorage = localStorage.getItem('city');
        const examFromLocalStorage = localStorage.getItem('exam');
        const modeFromLocalStorage = localStorage.getItem('mode');

        setLoading(true);
        setCityName(cityFromLocalStorage);
        setModeName(modeFromLocalStorage);
        setExamLocalId(examFromLocalStorage);
        setLoading(false);
        console.log(isMounted);
   
        return () =>
        {
            isMounted = false;
        };
    }, []);

    useEffect(() =>
    {
        let isMounted = true; 

        if (modeName !== null && examLocalId !== null)
        {
            getTestSeries();
        }
        console.log(isMounted);
   
        return () =>
        {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, []);


    const getTestSeries = async () =>
    {
        setLoading(true);
        const cityValue = localStorage.getItem('city');
        const examValue = localStorage.getItem('exam');
        const modeValue = localStorage.getItem('mode');
        const branchValue = localStorage.getItem('branchName');
        let url = `${testURL}batch/list`;
        if (modeValue === "Online")
        {
            let modeSearch = "Online Test Series"
            url += `?status=${modeSearch}`;
        }
        if (modeValue === "Offline")
        {
            let modeSearch = "Offline Test Series"
            url += `?status=${modeSearch}`;
        }

        if (examValue)
        {
            url += `&examId=${examValue}`;
        }
        if (examValue === "6461de19cd10f83e9358827b" && branchValue)
        {
            url += `&branch=${branchValue}`;
        }
        if (modeValue === "Offline")
        {
            if (cityValue !== "all" && cityValue !== null)
            {
                url += `&city=${cityValue}`;
            }
        }

        const rawData = await axios.get(url);
        const data = rawData.data.data;

        let testSeries = data.map((obj) =>
        {
            const startDate = new Date(obj.startDate);
            const endDate = new Date(obj.endDate);
            const momentStartDate = moment(startDate);
            const momentEndDate = moment(endDate);
            const momentNowDate = moment();
            let status;

            if (momentNowDate.isAfter(momentEndDate))
            {
                status = 'Ended';
            } else if (!momentNowDate.isAfter(momentStartDate))
            {
                status = 'Upcoming';
            } else
            {
                status = 'Ongoing';
            }
            return {
                ...obj,
                status,
            };
        }).filter(obj => obj.status !== 'Ended');


        const sortedTestSeries = testSeries.sort((a, b) =>
            b.instituteId?.rating - a.instituteId?.rating
        );
        testSeries = [];
        testSeries.push(...sortedTestSeries)
        let testSeriesCityFilter = testSeries.filter(obj => obj.category === "Online Test Series" || obj.category === "Offline Test Series");
        const groupedBatchList = {};
        testSeriesCityFilter.forEach(obj =>
        {
            const instituteId = obj.instituteId?._id;
            const examId = obj.examId?._id;
            const key = `${instituteId}-${examId}`;
            if (!groupedBatchList[key])
            {
                groupedBatchList[key] = {
                    ...obj,
                    count: 1
                };
            } else
            {
                groupedBatchList[key].count++;
            }

        });
        const testSeriesCityData = Object.values(groupedBatchList)
        setTestSeries(testSeriesCityData);
        setTestSeriesOriginal(testSeriesCityData)
        const upComingAble = testSeriesCityData.filter(obj => obj.status === "Upcoming")
        setAbleSeriesButton(upComingAble)
        setLoading(false);
    }

    // handleClickSeries
    const handleClickSeries = (filterString) =>
    {
        let filteredBatches;
        if (filterString === "all")
        {
            filteredBatches = testSeriesOriginal;
        } else if (filterString === "upcoming")
        {
            filteredBatches = testSeriesOriginal.filter((batch) => batch.status === "Upcoming");
        } else if (filterString === "ongoing")
        {
            filteredBatches = testSeriesOriginal.filter((batch) => batch.status === "Ongoing");
        } else if (filterString === "amount")
        {
            filteredBatches = testSeriesOriginal.slice().sort((a, b) => a.amount - b.amount);
        } else
        {
            filteredBatches = testSeriesOriginal;
        }
        setTestSeries(filteredBatches);
        setActiveTestFilter(filterString)
    };

    // SLIDER SETTING FUNCTION

    const CustomPrevArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsLeft />
            </div>
        );
    };

    const CustomNextArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsRight />
            </div>
        );
    };

    const TestListSliderAll = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: testSeries.length > 4 ? 5 : testSeries.length,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1091,
                settings: {
                    slidesToShow: testSeries.length > 3 ? 4 : testSeries.length,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: testSeries.length < 2 ? 1 : 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: testSeries.length < 2 ? 1 : 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />

    }
    let TestSliderWidthDesktop = "100%";
    let TestSliderWidthMobile = "100%";

    if (testSeries.length < 4)
    {
        TestSliderWidthDesktop = `${25 * testSeries.length}%`;
    }
    if (testSeries.length < 2 && window.innerWidth < 768)
    {
        TestSliderWidthMobile = `${50 * testSeries.length}%`;
    }

    const handleViewAllSeries = (instituteId, examIdInfo) =>
    {
        history.push(`/institute/${instituteId}/test-series/${examIdInfo}`)
    }
    const permissionIds = [
        "6461de19cd10f83e9358827b",
        "64be659d4dcfa238cc4b2b14",
        "64e473a04f25d7076635288a",
        "64e487ff4f25d707663528b9",
        "64be65bb4dcfa238cc4b2b16",
        "6463245acd10f83e9358828e"
    ]

    return (

        testSeries.length > 0 &&
        <div className="ltn__product-slider-area batch_ins_list ltn__product-gutter" >
            <div className="container batch_reviw">
                <div className="section-title-area mb-3 ltn__section-title-2--- text-center">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h1 className="inst_title mb-0 mt-0">

                            Best {modeName && modeName} Test Series {examName && <span style={{ color: 'red' }}> {examName}</span>} {
                                modeName === "Offline" && <>
                                    {examName && cityName && '-'} <span style={{ color: 'red' }}>{cityName}</span></>
                            }

                        </h1>
                        {
                            !showMoreTest ?
                                <button className="btn pe-0  me-0 show_more_btn" onClick={() => setShowMoreTest(true)}>See All ({testSeries.length})</button>
                                :
                                <button className="btn pe-0  me-0 show_more_btn" onClick={() => setShowMoreTest(false)}>See Less</button>
                        }

                    </div>
                    {
                        !showMoreTest ?
                            <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMoreTest(true)}>See All ({testSeries.length})<i className="fa-solid fa-arrow-right"></i></button>
                            :
                            <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMoreTest(false)}>See Less<i className="fa-solid fa-arrow-right"></i></button>
                    }
                    <h6 className="section-subtitle batch_sec_title">
                        <div className="stat_btn"
                        >
                            <button
                                style={{ backgroundColor: "transparent" }}
                                className={
                                    activeTestFilter === "all" ? "filter-active" : ""
                                }
                                onClick={(e) => handleClickSeries("all")}
                            >
                                All ({testSeries.length})
                            </button>
                            {
                                ableSeriesButton.length > 0 &&
                                <button
                                    style={{ backgroundColor: "transparent" }}
                                    className={
                                        activeTestFilter === "upcoming" ? "filter-active" : ""
                                    }
                                    onClick={(e) => handleClickSeries("upcoming")}
                                >
                                    Upcoming
                                </button>
                            }

                            <button
                                style={{ backgroundColor: "transparent" }}
                                className={
                                    activeTestFilter === "ongoing" ? "filter-active" : ""
                                }
                                onClick={() => handleClickSeries("ongoing")}
                            >
                                Ongoing
                            </button>
                            <button
                                style={{ backgroundColor: "transparent" }}
                                className={
                                    activeTestFilter === "amount" ? "filter-active" : ""
                                }
                                onClick={() => handleClickSeries("amount")}
                            >
                                Price
                            </button>
                            {/* {
                                !showMoreTest ?
                                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMoreTest(true)}>See All ({testSeries.length})</button>
                                    :
                                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMoreTest(false)}>See Less</button>
                            } */}
                        </div>
                    </h6>
                </div>
                <div className="tab-content">
                    <div className="tab-pane fade active" id="liton_product_list">
                        <div className="ltn__product-tab-content-inner tabs_content_btch ltn__product-list-view">
                            <div className="g-2 row">
                                {
                                    loading ?
                                        <div className="d-flex justify-content-center">
                                            <Spin />
                                        </div>
                                        :
                                        (
                                            testSeries.length > 0 ?
                                                (showMoreTest ?
                                                    <Suspense fallback={'Loading ...'}>
                                                        <BatchCard
                                                            batchList={testSeries}
                                                            seriesName={"Series"}
                                                            showMore={showMoreTest}
                                                            onView={handleViewAllSeries}
                                                        />
                                                    </Suspense>
                                                    :
                                                    <div className="slider-container" style={{ width: window.innerWidth >= 768 ? TestSliderWidthDesktop : TestSliderWidthMobile }}>
                                                        <Slider {...TestListSliderAll}>
                                                            {
                                                                testSeries.map((ele) => (
                                                                    <div className="px-2" key={ele._id}>

                                                                        <div className="batch_card_details">
                                                                            <div className="left_box">
                                                                                {
                                                                                    ele.batchKeyword?.keyword && (
                                                                                        <h5 className="keyword_tags">
                                                                                            <span>{ele.batchKeyword?.keyword?.name}</span>
                                                                                            <p className="badge_btn">
                                                                                                {ele.status}
                                                                                            </p>
                                                                                        </h5>
                                                                                    )
                                                                                }
                                                                                <img loading="lazy" className="img-fluid img-responsive rounded product-image" src={ele?.instituteId?.image} alt={ele.image} />
                                                                                {
                                                                                    ele.planName?.title && (
                                                                                        <h5 className="marquee_tags">
                                                                                            {/* <p>Batch Type</p> */}
                                                                                            <span>{ele.planName?.title}</span>
                                                                                        </h5>

                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className="center_box">
                                                                                <div className="center_details">
                                                                                    <h5 className="my-0">
                                                                                        <span>
                                                                                            {ele.examId?.name}
                                                                                        </span>
                                                                                    </h5>
                                                                                    <h4>
                                                                                        <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span><FaStar />
                                                                                    </h4>
                                                                                    <span className="mob_category">{ele?.category}</span>
                                                                                </div>
                                                                                <h5 className="desk_inst">
                                                                                    <span>Institute :</span>
                                                                                    <span>{ele.instituteId?.name?.slice(0, 15) + ".."}</span>
                                                                                </h5>
                                                                                <h5 className="mob_inst">
                                                                                    <span>{ele.instituteId?.name?.slice(0, 15) + ".."}</span>
                                                                                </h5>
                                                                                <h5 className="mob_batch">
                                                                                    <span>Series No:</span>
                                                                                    <span>{ele?.batchName}</span>
                                                                                </h5>
                                                                                {ele.category !== 'Online' &&
                                                                                    <h5 className="mob_batch">
                                                                                        {ele.category !== 'Online' && <span>City :</span>}
                                                                                        {ele.category !== 'Online' && <span>{ele.city}</span>}
                                                                                    </h5>
                                                                                }
                                                                                <h3 className="mob_price">
                                                                                    <span>Price :</span>
                                                                                    <span style={{ color: "#2292ca" }}>
                                                                                        {ele.amount.toLocaleString("en-IN", {
                                                                                            style: "currency",
                                                                                            currency: "INR",
                                                                                        }).split('.')[0]}
                                                                                        <span className="incl_gst">incl. GST</span>
                                                                                    </span>
                                                                                </h3>
                                                                                <h5 className="desk_btch_number">
                                                                                    <span>Batch No :</span>
                                                                                    <span>
                                                                                        {ele?.batchName}
                                                                                    </span>

                                                                                </h5>
                                                                                {/* <h5 className="desk_strt_dt">
                                                                                    <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                                                                                </h5> */}
                                                                            </div>
                                                                            <div className="right_box right_box_mob">
                                                                                <h5>
                                                                                    <span>Price :</span>
                                                                                    <span style={{ color: "#2292ca" }}>
                                                                                        {ele.amount.toLocaleString("en-IN", {
                                                                                            style: "currency",
                                                                                            currency: "INR",
                                                                                        }).split('.')[0]}
                                                                                        <span className="incl_gst">incl. GST</span>
                                                                                    </span>
                                                                                </h5>
                                                                                {permissionIds.includes(ele.examId._id) && <h5>
                                                                                    <span>Branch :</span>
                                                                                    <Link to={`/batch-detail/${ele._id}`}>
                                                                                        {ele.branchName?.length > 0 && (
                                                                                            <>
                                                                                                {ele.branchName.slice(0, 3).map((branch, index) =>
                                                                                                {
                                                                                                    const truncatedName = branch.name;
                                                                                                    return (
                                                                                                        <React.Fragment key={index}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                            >
                                                                                                                {truncatedName}
                                                                                                            </span>
                                                                                                            {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                                                                            
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                })}
                                                                                                {ele?.branchName.length > 4 &&
                                                                                                    <span
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                    >
                                                                                                        ...
                                                                                                    </span>
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </Link>
                                                                                </h5>
                                                                                }
                                                                            </div>
                                                                            <div className="right_box right_box_desk">
                                                                                <h5>
                                                                                    <span>Price :</span>
                                                                                    <span>
                                                                                        {ele.amount.toLocaleString("en-IN", {
                                                                                            style: "currency",
                                                                                            currency: "INR",
                                                                                        }).split('.')[0]}
                                                                                        <span className="incl_gst">incl. GST</span>
                                                                                    </span>
                                                                                </h5>
                                                                                {permissionIds.includes(ele.examId._id) && <h5>
                                                                                    <span>Branch :</span>
                                                                                    <Link to={`/batch-detail/${ele._id}`}>
                                                                                        {ele.branchName?.length > 0 && (
                                                                                            <>
                                                                                                {ele.branchName.slice(0, 3).map((branch, index) =>
                                                                                                {
                                                                                                    const truncatedName = branch.name;
                                                                                                    return (
                                                                                                        <React.Fragment key={index}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                            >
                                                                                                                {truncatedName}
                                                                                                            </span>
                                                                                                            {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                                                                           
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                })}
                                                                                                {ele?.branchName.length > 4 &&
                                                                                                    <span
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                    >
                                                                                                        ...
                                                                                                    </span>
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </Link>
                                                                                </h5>
                                                                                }
                                                                                <h5>
                                                                                    <span>Language :</span>
                                                                                    <span style={{ color: "#2292ca" }}>
                                                                                        {ele?.languages?.name ? ele?.languages?.name : "English"}
                                                                                    </span>
                                                                                </h5>
                                                                                <h5>
                                                                                    <span className='w-auto'>{ele?.category}</span>
                                                                                    {
                                                                                        ele?.category !== "Online Test Series" && <p className="city">{ele?.city}</p>
                                                                                    }
                                                                                </h5>

                                                                            </div>
                                                                            <div className="see_btn_wrap">
                                                                                <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary view_al_btch w-100" type="button">View Details</Link>
                                                                                <button className="btn view_more_batch_btn" onClick={() => handleViewAllSeries(ele.instituteId._id, ele.examId._id, ele?.city)}>All Test Series ({ele.count})</button>
                                                                            </div>
                                                                            {
                                                                                !ele.batchKeyword?.keyword && (
                                                                                    <p className="badge_btn2">
                                                                                        {ele.status}
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                ))
                                                            }
                                                        </Slider>
                                                    </div>
                                                )
                                                :
                                                <ErrorPage value="There are no batches for current filter. New Batches will be updated soon, please check regularly." />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SeriesSearch