import React, { useEffect, useState } from "react";
import "../components/assets/css/alert.css";
import { Link, useHistory } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import OTPInput from "otp-input-react";
// import userService from "../common/userService";
import "./assets/css/profile.css"
import Layout from "./global-components/Layout";
const ForgetPassword = () => {
    const testURL = process.env.REACT_APP_API_URL_LIVE
    const [email, setEmail] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [resendOtp, setResendOtp] = useState(false)
    const [otp, setOtp] = useState("");
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [isOTPExpired, setIsOTPExpired] = useState(false);
    const [otpTimer, setOTPTimer] = useState(60);
    const [otpVerify, setOtpVerify] = useState(false)
    const [alertMsg, setAlertMsg] = useState("");
    const [oTPalertMsg, setOTPalertMsg] = useState("");
    const [alertErrorMsg, setAlertErrorMsg] = useState("");
    const [otpErrorAlertMsg, setotpErrorAlertMsg] = useState("");
    // Function to start the OTP timer
    const startOTPTimer = () => {
        setIsOTPExpired(false);
        setOTPTimer(60);

        const timer = setInterval(() => {
            setOTPTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
            setIsOTPExpired(true);
        }, 60000);
    };


    const history = useHistory();

    const handleForget = async (e) => {
        e.preventDefault();

        if (email === "") {
            setAlertErrorMsg("Please enter register email")
            setTimeout(() => {
                setAlertErrorMsg("");
            }, 2000);
        } else {
            try {
                const res = await axios.get(testURL + `user/reset-password/${email}`);

                if (res.data.success === true) {
                    setAlertMsg("OTP send to your register Email");
                    setResendOtp(true);
                    setIsOTPSent(true);
                    startOTPTimer();
                    setTimeout(() => {
                        setAlertMsg("");
                    }, 2000);
                } else {
                    setAlertErrorMsg(res.data.message);
                }
            } catch (error) {
                if (error.response) {
                    setAlertErrorMsg(error.response.data.message);
                    setTimeout(() => {

                        setAlertErrorMsg("");
                    }, 2000);
                } else {
                    setAlertErrorMsg("An error occurred. Please try again.");
                    setTimeout(() => {

                        setAlertErrorMsg("");
                    }, 2000)
                }
            }
        }


    };

    const handleVerify = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.put(testURL + "user/verify-otp", {
                otp, email
            })
            if (res.data.success === true) {
                setOTPalertMsg(res.data.message)
                setResendOtp(false);
                setOtpVerify(true)
                setTimeout(() => {
                    setAlertMsg("");
                }, 2000);
            }
            else {
                setotpErrorAlertMsg(res.data.message)
            }
        } catch (error) {
            if (error.response) {
                setotpErrorAlertMsg(error.response.data.message);
                setTimeout(() => {

                    setotpErrorAlertMsg("");
                }, 2000)
            } else {
                setotpErrorAlertMsg("An error occurred. Please try again.");
                setTimeout(() => {

                    setotpErrorAlertMsg("");
                }, 2000)
            }
        }

    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const res = await axios.put(testURL + "user/update-password", {
            newPassword, email
        })
        if (res.data.success === true) {
            message.success("Password Change Successfully")
            history.push("/")

        }
        else {
            message.error("Something went wrong");
        }
    }

    useEffect(() => {
        if (otpTimer < 0) {
            setOTPTimer(0);
        }
    }, [otpTimer]);

    return (
        <>
            <Layout>
                <div className="ltn__login-area login_form_new">
                    <div className="account-login-inner form_template_wrapper">
                        {!resendOtp && !otpVerify && <h2>Forget your password</h2>}
                        {resendOtp && <h2>Enter OTP send to email-id</h2>}
                        <div className="forget_form">
                            {
                                !resendOtp && !otpVerify &&
                                <form onSubmit={handleForget}>
                                    <input type="email" value={email.email} name='email' onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder='Enter your register email' />
                                    <Link to="/login"><i className="fa fa-arrow-left" aria-hidden="true"></i> Go To Login</Link>

                                    <div className="sign_up_btn_wrap">
                                        <div>
                                            {alertMsg && <p className="alert_msg_otp">{alertMsg}</p>}
                                            {alertErrorMsg && <p className="alert_msg_otp2 mb-2">{alertErrorMsg}</p>}
                                            <button type="submit">
                                                Send OTP
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            }
                            {
                                resendOtp &&
                                <form onSubmit={handleVerify}>
                                    <OTPInput
                                        value={otp}
                                        onChange={setOtp}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        className="otp_verify_input"
                                        disabled={false}
                                        placeholder="X" />
                                    {/* <input type="password" value={email.email} name='newPassword' onChange={(e) => setNewPassword(e.target.value)} className="form-control" placeholder='Enter new password' /> */}
                                    {/* <ResendOTP handleForget /> */}
                                    <div className="sign_up_btn_wrap">
                                        {isOTPSent ? (

                                            <div>
                                                {alertMsg && <p className="alert_msg_otp">{alertMsg}</p>}
                                                {alertErrorMsg && <p className="alert_msg_otp2">{alertErrorMsg}</p>}
                                                {isOTPExpired ? (
                                                    <button type="button" className="resen_otp_btn mb-0" onClick={handleForget}>
                                                        Resend OTP
                                                    </button>
                                                ) : (
                                                    <button type="button" className="resen_otp_btn2 mb-0" disabled>
                                                        Resend OTP ({otpTimer}s)
                                                    </button>
                                                )}
                                            </div>

                                        ) : null}
                                        <div>
                                            {
                                                oTPalertMsg && <p className="alert_msg_otp">{oTPalertMsg}</p>
                                            }
                                            {
                                                otpErrorAlertMsg && <p className="alert_msg_otp2 mb-2">{oTPalertMsg}</p>
                                            }
                                            <button type="submit">
                                                Verify OTP
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            }
                            {
                                otpVerify &&
                                <form onSubmit={handleChangePassword}>
                                    <input type="password" value={email.email} required name='newPassword' onChange={(e) => setNewPassword(e.target.value)} className="form-control" placeholder='Enter new password' />
                                    {/* <ResendOTP handleForget /> */}

                                    <div className="sign_up_btn_wrap">
                                        <button type="submit">
                                            Change Password
                                        </button>
                                    </div>
                                </form>
                            }

                        </div>
                    </div>
                </div>
            </Layout>

        </>
    )
}

export default ForgetPassword