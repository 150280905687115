import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png";
import SearchFilter from './section-components/SearchFilter';
import Layout from './global-components/Layout';
import { useEffect } from 'react';
import { BatchContext } from '../redux/batchContext';
const SearchResult = () =>
{
    const [categoryList, setCategoryList] = useState("");
    const {
        examName,
        cityName
    } = useContext(BatchContext);
    useEffect(() =>
    {
        const modeName = localStorage.getItem("mode");
        setCategoryList(modeName);
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    Find The Best {categoryList} Coaching Classes for {examName}
                    {categoryList === "Offline" && cityName ? ` in ${cityName}` : ""}
                    | Visit BookMyBestClass
                </title>

                <meta
                    name="description"
                    content={`Get the best ${categoryList} coaching classes for ${examName}${categoryList === "Offline" ? ` in ${cityName ? cityName : ""}` : ""}.
                    Visit Book My Best Class to find a wide range of ${categoryList} Coaching Centre for ${examName}${categoryList === "Offline" ? ` in ${cityName ? cityName : ""}` : ""}`}
                />
                <meta
                    property="og:title"
                    content={`Find The Best ${categoryList} Coaching Classes for ${examName}${categoryList === "Offline" ? ` in ${cityName ? cityName : ""}` : ""} | Visit BookMyBestClass`}
                />
                <meta
                    property="og:description"
                    content={`Get the best ${categoryList} coaching classes for ${examName}${categoryList === "Offline" ? ` in ${cityName ? cityName : ""}` : ""}.
                    Visit Book My Best Class to find a wide range of ${categoryList} Coaching Centre for ${examName}${categoryList === "Offline" ? ` in ${cityName ? cityName : ""}` : ""}`}
                />

                <meta property="og:image" content={logoBmbc} />
                <meta property="og:url" content="https://bookmybestclass.com"></meta>
                <meta property="og:site_name" content="bookmybestclass"></meta>
                <meta name="keywords" content={`${examName}`} />
                <link rel="canonical" href="https://bookmybestclass.com" />
            </Helmet>
            <Layout>
                <SearchFilter />
            </Layout>

        </>
    )
}

export default SearchResult