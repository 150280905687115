import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
// import axios from 'axios';

const NewsManagementWrapper = styled.div`
  padding: 20px;
`;

const NewsManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const NewsManagementCreateButton = styled(Button)`
  float: right;
`;

const NewsManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const NewsManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const NewsManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function NewsManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.getAllNews().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const columns = [
    {
      title: "News",
      dataIndex: "news",
    },

    {
      title: "URL",
      dataIndex: "redirect",
      width: "50%",
      textAlign: "justify",
      render: (redirect) => (
        <div dangerouslySetInnerHTML={{ __html: redirect }}></div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editNews/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                {"Delete"}
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editNews/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
          {/* <Button type="info" style={{ marginRight: 2 }}>
                        <Link to={`/admin/news/${record}`}>View</Link>
          </Button> */}
        </>
      ),
    },
  ];

  const handleSearch = (searchTerm) => {
    console.log("searchTerm", searchTerm);
    console.log("data", data);
    const filteredData = data.filter((item) =>
      item.news?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log("Filtered Data:", filteredData);
    if (filteredData.length === 0) {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newsIdToDelete, setNewsIdToDelete] = useState(null);

  const showDeleteModal = (batchId) => {
    setNewsIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteNews = async () => {
    try {
      const deleteBatchResponse = await adminService.deleteNews(newsIdToDelete);
      if (deleteBatchResponse.data.success === true) {
        message.success(deleteBatchResponse.data.data);
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <NewsManagementWrapper>
        <NewsManagementHeader>
          <h2>News Management</h2>
          <NewsManagementCreateButton type="primary">
            <Link to="/admin/createNews">Create</Link>
          </NewsManagementCreateButton>
        </NewsManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <NewsManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />
        <Modal
          title="Delete News"
          visible={isDeleteModalOpen}
          onOk={handleDeleteNews}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this news?</p>
        </Modal>
      </NewsManagementWrapper>
    </>
  );
}

export default NewsManagement;
