import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Row, Col } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";
import AuthGuard from "../authGuards";

const { TextArea } = Input;

const EditRatingWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }
`;

const EditRatingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditRatingButton = styled(Button)`
  float: right;
`;

function EditRating(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchRating();
    // eslint-disable-next-line
  }, []);

  const fetchRating = async () => {
    const response = await adminService.getInstituteReviewById(id);
    const data = response.data.data;
    data.institueName = data.instituteId.name
    data.userName = data.userId.name
    setData(data)
    props.form.setFieldsValue({ ...data });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const valObj = {
          instituteId: data.instituteId._id,
          rating: props.form.getFieldValue('rating'),
          message: props.form.getFieldValue('message'),
          userId: data.userId._id,
        }
        const updateRating = await adminService.updateInstitueReview(id, valObj);
        setLoading(false);
        if (updateRating.data.success === true) {
        const logData = {body:  `Institute Rating updated for ${values.institueName}`, url: 'instituteRating/update', oldData: updateRating.data.dataUpdate.oldData, newData: updateRating.data.dataUpdate.newData }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("Rating Successfully Updated");
          }
        
          props.history.push("/admin/instituteRating");
        } else {
          message.error("Error in updating rating");
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <>
      <AuthGuard />
      <EditRatingWrapper>
        <EditRatingHeader>
          <h2>Edit Rating</h2>
          <EditRatingButton onClick={() => props.history.push("/admin/instituteRating")}>
            Cancel
          </EditRatingButton>
        </EditRatingHeader>
        <Form onSubmit={handleSubmit} className="login-form">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Institute Name">
                {getFieldDecorator("institueName", {
                  rules: [{ required: true, message: "Please enter institute name" }],
                })(<Input disabled={true} placeholder="Please enter institute name" />)}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="User Name">
                {getFieldDecorator("userName", {
                  rules: [{ required: true, message: "Please enter" }],
                })(<Input disabled={true} placeholder="Please enter" />)}
              </Form.Item>
            </Col>

           
          </Row>

          <Row gutter={16}>
          <Col span={12}>
              <Form.Item label="Rating">
                {getFieldDecorator("rating", {
                  rules: [{ required: true, message: "Please enter rating" }],
                })(<Input placeholder="Please enter rating" />)}
              </Form.Item>
            </Col>

          <Col span={12}>
              <Form.Item label="Review">
                {getFieldDecorator("message", {
                  rules: [{ required: true, message: "Please enter review" }],
                })(<TextArea rows={4} placeholder="Please enter review" />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </EditRatingWrapper>
    </>
  );
}

export default Form.create()(EditRating);
