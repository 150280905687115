import React from 'react'
import "../assets/css/Spinner.css"
import { Spin } from 'antd'
const Spinner = () =>
{
  return (

    <div className='spinner_loader'>
      <Spin />
    </div>
  )
}

export default Spinner