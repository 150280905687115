import React from "react";

const FaqV1 = () =>
{
  
    return (
      <div className="ltn__faq-area contact_text_head padding_area">
        <div className="container">
          <h1 className="section-title text-center mt-2 pt-2">FAQ's</h1>
          <p className="contact_text">
            Welcome to Book My Best Classes' FAQ section, which addresses your
            most common queries and concerns. While exploring a new session,
            service, or concept, seeking clarification and detailed information
            is natural. That’s why we’ve compiled this comprehensive resource to
            assist you in finding quick and accurate answers.
          </p>
          <p className="contact_text">
            Team BMBC has carefully curated a wide range of questions and
            provided detailed responses to ensure that you have access to the
            information you need. Whether you’re a potential customer, an
            existing user, or simply curious about our offerings, we’ve got you
            covered.
          </p>
          <p className="contact_text">
            This FAQ section provides valuable insights about BMBC, its
            services, policies, and procedures. From troubleshooting common
            issues to understanding pricing structures, Book My Best classes aim
            to provide clarity and transparency at every step.
          </p>
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-1"
                      aria-expanded="true"
                    >
                      What is BMBC?
                    </h6>
                    <div
                      id="faq-item-2-1"
                      className="collapse show"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          BMBC to find the perfect coaching institute / Test Series  for your competitive exam preparation. We offer a comprehensive database of institutes across India,
                          with detailed information on institutes, batches, fees, ratings, reviews, test series and location.
                          Our user-friendly platform makes navigation easy, and our services are completely free. Let us help you achieve your goals by connecting you with the best coaching institutes available.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-2"
                      aria-expanded="false"
                    >
                      How to use BMBC?
                    </h6>
                    <div
                      id="faq-item-2-2"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Kindly follow these steps:
                        </p>
                        <p>
                          Choose Exam - Choose Category - Choose City

                        </p>
                        <p>
                          and Click on find now. Based on Search Criteria we provides you list of best
                          coaching institutes/test series for your competitive exam preparation with all
                          Details, then you can join whichever institute you want by clicking on View details.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-3"
                      aria-expanded="false"
                    >
                      Is there any fee for using the BMBC website?
                    </h6>
                    <div
                      id="faq-item-2-3"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          No, there is no fee for using the BMBC website. The website is free to use for all students who are looking for coaching centers for competitive exams.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                    >
                      What are the benefits of  BMBC?
                    </h6>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          1. BMBC never takes convenience fees from students, our services to students are always free, and we are committed to student growth.
                          2. Extensive List of Coaching Institutes
                          3. Providing great offers, competitive fares, exclusive discounts, and a seamless online booking experience to students.
                          4. All the listed institutes information  are Verified and Reliable
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-5"
                      aria-expanded="false"
                    >
                      What kinds of competitive exams are covered by the
                      coaching centers listed on BMBC?
                    </h6>
                    <div
                      id="faq-item-2-5"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          The coaching centers listed on BMBC cover various
                          competitive exams, including UPSC, GATE, CAT, IIT JEE,
                          NEET, PSU Jobs, SSC, banking, railways, and many more.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-6"
                      aria-expanded="false"
                    >
                      What is the mode of Payment to get admission?
                    </h6>
                    <div
                      id="faq-item-2-6"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Online Admission through Credit card, Debit card, Net Banking, UPI, Wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-7"
                      aria-expanded="false"
                    >
                      I took admission, but then I never attended a single class, I want to get my amount refunded?
                    </h6>
                    <div
                      id="faq-item-2-7"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Students must be serious before taking admission and must remain committed in the course preparation
                          of the target exam. After Fee payment withdrawal from the course is not permitted because we have already made payment to the institute
                          hence no refund of fee shall be applicable under any circumstances. The student hereby
                          agrees without any pressure with the above mentioned conditions of fee payment, refund & withdrawals before taking the admission.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-8"
                      aria-expanded="false"
                    >
                      I took admission a couple of months back, but I got a job - Can I get my fee refunded or can I replace a friend in my seat?
                    </h6>
                    <div
                      id="faq-item-2-8"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Students must be serious before taking admission and must remain committed in the course preparation of the target exam. After Fee payment withdrawal from the course is not permitted because we have already made payment to the institute hence no
                          refund of fee shall be applicable under any circumstances. The student hereby agrees without any pressure with the above mentioned conditions of fee payment, refund & withdrawals before taking the admission.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-9"
                      aria-expanded="false"
                    >
                      Can I leave a review for a coaching center on the BMBC website?
                    </h6>
                    <div
                      id="faq-item-2-9"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          You can leave a review for any coaching center listed on the BMBC website if you know about that institute. Your review will help other students decide whether to choose a coaching center.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      style={{color:'#f0151f'}}
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-10"
                      aria-expanded="false"
                    >
                      Can I search for coaching centers based on my location?
                    </h6>
                    <div
                      id="faq-item-2-10"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Yes, you can search for coaching centers based on your location. Simply enter your location in the search bar on the website, and the website will provide you with a list of coaching centers in your area.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default FaqV1;
