import React, { useState, useEffect } from "react";
import { Button, Table, message } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const BranchManagementWrapper = styled.div`
  padding: 20px;
`;

const BranchManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BranchManagementCreateButton = styled(Button)`
  float: right;
`;

const BranchManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const BranchManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const BranchManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function BranchManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() =>
  {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) =>
  {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) =>
  {
    setLoading(true);
    adminService.getAllBranch().then((apiResponse) =>
    {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const deleteBranch = async (id, data) =>
  {
    const deleteBranch = await adminService.deleteBranch(id);
    if (deleteBranch.data.success === true)
    {
      const logData = { body: `Branch ${data.name} deleted`, url: 'branch/delete' }
      const createLog = await adminService.addLog(logData)
      if (createLog.data.success === true)
      {
        message.success(deleteBranch.data.data);
      };

    } else
    {
      message.warn("An error occured please try again");
    }
    fetch();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <>
              
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editBranch/${record}`}>Edit</Link>
            </Button>
             <Button onClick={() => handleBranchVisiblity(data)} type="success" style={{ marginRight: 2 }}>
                {data.hide === "yes" ? "Unhide" : "Hide"}
              </Button>
              </>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => deleteBranch(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button onClick={() => handleBranchVisiblity(data)} type="success" style={{ marginRight: 2 }}>
                {data.hide === "yes" ? "Unhide" : "Hide"}
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editBranch/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleBranchVisiblity = async (data) =>
  {
    console.log(data);
    const planStatus = data.hide === "yes" ? "Visible" : "Hidden"
    const obj = {
      id: data._id,
      name: data.name,
      hide: data.hide === "yes" ? "no" : "yes",
    };

    const updateInstitue = await adminService.hideBranch(obj);
    if (updateInstitue.status === 200)
    {
      const logData = { body: `Branch ${data.name} status changed to ${planStatus}`, url: 'institute/status' }
      const createLog = await adminService.addLog(logData);
      if (createLog.data.success === true)
      {
        message.success("Log created")
      }
      message.success("Visiblity Updated");
      fetch();
    } else
    {
      message.error("An Error Occured");
    }
  }

  const handleSearch = (searchTerm) =>
  {
    const filteredData = data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length === 0) { message.warn('No Data Found') }
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <BranchManagementWrapper>
        <BranchManagementHeader>
          <h2>Branch Management</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <BranchManagementCreateButton type="primary">
              <Link to="/admin/createBranch">Create</Link>
            </BranchManagementCreateButton>
          )}
        </BranchManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <BranchManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />
      </BranchManagementWrapper>
    </>
  );
}

export default BranchManagement;
