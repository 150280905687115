import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Card, Statistic, Select } from "antd";
import TopnavComponent from "./topnavComponent";
import AuthGuard from "./authGuards";
import adminService from "../../common/adminService";
const { Content } = Layout;
const { Option } = Select;

function Dashboard()
{
  const [data, setData] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [selectedCity, setSelectedCity] = useState("Select City");
  const [selectedBatch, setSelectedBatch] = useState("Select Batch");
  const [instituteData, setInstituteData] = useState(null);
  const [batchData, setBatchData] = useState(null);
  const [batchCount, setBatchCount] = useState("loading..");
  useEffect(() =>
  {
    fetch();
    getCityList();
    fetchBatches();
  }, []);

  const fetch = async () =>
  {
    const rawData = await adminService.getDashboardData();
    const data = rawData.data.data;
    setData(data);
  };

  const fetchBatches = async () =>
  {
    const rawData = await adminService.getAllBatches();
    const data = rawData.data.data;
    const batchData = data.filter((idx) => idx.hide === "yes");
    setBatchCount(batchData.length);


  };

  const getCityList = async () =>
  {
    const rawData = await adminService.getAllLocations();
    const data = rawData.data.data;
    setCityList(data);
  };

  const handleChangeCity = async (val) =>
  {
    setSelectedBatch("Select Batch")
    setBatchData(null)
    const rawData = await adminService.getAllInstitutes(val);
    const data = rawData.data.data;
    setInstituteData(data);
  };

  const handleChangeBatch = async (val) =>
  {
    setSelectedBatch(val)
    const rawData = await adminService.getAllBatches(val);
    const data = rawData.data.data;
    setBatchData(data);
  };

  return (
    <>
      <AuthGuard />
      {data && (
        <Layout>
          <TopnavComponent />
          <Content style={{ padding: "20px" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Registered Users" value={data.UserCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Subscribed Users" value={data.SubscribedCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Institutes" value={data.InstituteCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="All Batches" value={data.BatchCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Branch" value={data.BranchCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Exams" value={data.ExamCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Category" value={data.CategoryCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Plan" value={data.PlanCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Locations" value={data.LocationCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Help Requests" value={data.HelpCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Blogs" value={data.BlogCount} />
                </Card>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Card>
                  <Statistic title="Hidden Batches" value={batchCount} />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12}>
                <Card title="Institute / Batch Statistic">
                  <Select
                    placeholder="Select City"
                    onChange={handleChangeCity}
                    style={{
                      width: "50%",
                    }}
                  >
                    {cityList &&
                      cityList.map((d) => (
                        <Option key={d._id} value={d.city}>
                          {d.city}
                        </Option>
                      ))}
                  </Select>
                  {instituteData && (
                    <div style={{ marginTop: "10px" }}>
                      This City Has {instituteData.length} Institutes
                    </div>
                  )}

                  {instituteData && instituteData.length > 0 && (
                    <>
                      <div style={{ marginTop: "20px" }}>
                        <Select
                          placeholder="Select Batch"
                          onChange={handleChangeBatch}
                          style={{
                            width: "50%",
                          }}
                          value={selectedBatch}
                          allowClear
                        >
                          {instituteData &&
                            instituteData.map((d) => (
                              <Option key={d._id} value={d._id}>
                                {d.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                      <div>
                        {batchData && (
                          <div style={{ marginTop: "10px" }}>
                            This Institute Has {batchData.length} Batches
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}

    </>
  );
}

export default Dashboard;
