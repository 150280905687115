import React from 'react';
// import PageHeader from './global-components/page-header';
// import myProf from "./media/banner/brdcrumb.jpg"
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png"
import Layout from './global-components/Layout';
import LoginForm from './reusable/loginForm';
const LoginV1 = () =>
{
    const textValue = "Sign In"
    return <div>
        <Helmet>
            <title>
                Join Our Educational Community for Exclusive Benefits and Learning Opportunities
            </title>
            <meta
                name="description"
                content="Register now to become a part of our educational community and unlock exclusive benefits 
                and learning opportunities. Create your account to gain access to personalized guidance, expert resources, 
                and a supportive network of fellow learners. Sign up today and embark on your journey towards academic success"
            />
            <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
            <meta property="og:title" content="Join Our Educational Community for Exclusive Benefits and Learning Opportunities" />
            <meta property="og:description" content="Register now to become a part of our educational community and unlock exclusive benefits 
                and learning opportunities. Create your account to gain access to personalized guidance, expert resources, 
                and a supportive network of fellow learners. Sign up today and embark on your journey towards academic success" />
            <meta property="og:image" content={logoBmbc} />
            <link rel="canonical" href="https://bookmybestclass.com/login" />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            {/* <PageHeader image={myProf} /> */}
            <LoginForm text={textValue} />
        </Layout>
    
    </div>
}

export default LoginV1

