import React from 'react';
// import PageHeader from './global-components/page-header';
import Blog from './blog-components/blog';
// import blogBanner from "./media/banner/blogBanner.png"
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png"
import Layout from './global-components/Layout';
const BlogPage = () =>
{
    return (
        <>
            <Helmet>
                <title>Empowering Students: Insights, Tips, and Strategies for Academic Success</title>
                <meta name="description" content="Discover our blog page dedicated to empowering students with valuable insights, practical tips, and effective strategies for achieving academic success. 
                Gain inspiration, guidance, and expert advice on various topics to enhance your learning journey. Join our community of motivated learners and unlock your full potential today" />
                <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
                <meta property="og:title" content="Empowering Students: Insights, Tips, and Strategies for Academic Success" />
                <meta property="og:description" content="Discover our blog page dedicated to empowering students with valuable insights, practical tips, and effective strategies for achieving academic success. 
                Gain inspiration, guidance, and expert advice on various topics to enhance your learning journey. Join our community of motivated learners and unlock your full potential today" />
                <meta property="og:image" content={logoBmbc} />
                <link rel="canonical" href="https://bookmybestclass.com/blog" />
                <meta property="og:url" content="https://bookmybestclass.com"></meta>
                <meta property="og:site_name" content="bookmybestclass"></meta>
            </Helmet>
            <Layout>
                {/* <PageHeader image={blogBanner} /> */}
                <Blog />
            </Layout>
          
        </>
    )
}

export default BlogPage