import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import userService from "../../common/userService";
import toast, { Toaster } from 'react-hot-toast';
import GoogleLogin from 'react-google-login';
import axios from "axios";
import { message } from "antd";
import { gapi } from "gapi-script"

export const LoginForm = ({ text, onRegisterClose }) => {
    const history = useHistory();
    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        gapi.load("client:auth2", () => {
            gapi.auth2.init({ clientId: '1083827701907-o7vmna2tpg4ieuq2ls9het5jivfq9scv.apps.googleusercontent.com' })
        })
    }, []);

    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const allFieldsFilled = Object.values(formValues).every(
            (val) => val !== ""
        );
        if (!allFieldsFilled) {
            toast.error("Please fill in all the fields.");
            return;
        }
        try {
            const login = await userService.login(formValues)

            if (login.data.success === true) {
                localStorage.setItem('token', login.data.data.token)
                localStorage.setItem('id', login.data.data._id)
                // GETTING URL FROM LOCALSTORAGE 
                const redirectUrl = localStorage.getItem("redirectUrl")
                toast.success('Logged in successfully!');
                setErrorMessage('')
                if (redirectUrl) {
                    history.push(redirectUrl);
                    localStorage.removeItem("redirectUrl");

                } else {
                    history.push("/");
                }
            } else {
                setErrorMessage('Credentials do not match. Please try again.');
            }
        } catch (error) {
            message.error("Already taken");
        }

    };

    // const apiURL = process.env.REACT_APP_API_URL;
    const apiURL = process.env.REACT_APP_API_URL_LIVE;
    const responseGoogle = async (res) => {
        if (res) {
            try {
                const email = res?.profileObj?.email;
                const googleId = res?.googleId;
                const tokenId = res?.tokenId
                const result = await axios.post(apiURL + "user/login", {
                    email, googleId, tokenId
                });
                if (result.data.success === true) {
                    localStorage.setItem('token', result.data.data.token)
                    localStorage.setItem('id', result.data.data._id);
                    localStorage.setItem('userName', result.data.data.name);
                    const redirectUrl = sessionStorage.getItem("redirectUrl")
                    toast.success('Logged in successfully!');
                    setErrorMessage('')
                    if (redirectUrl) {
                        history.push(redirectUrl);
                        sessionStorage.removeItem("redirectUrl");
                        onRegisterClose();
                    } else {
                        history.push("/");
                    }
                } else {
                    message.error("Some error occured try again !")
                }
            } catch (error) {
                console.log("Error");
            }
        }

    }
    return (
        <div>
            <div className="ltn__login-area login_form_new">
                <div className="account-login-inner form_template_wrapper">
                    <h2>{text}</h2>
                    <form>
                        <input value={formValues.email}
                            onChange={handleInputChange} className="form-control" type="text" name="email" placeholder="Email*" />
                        <input
                            value={formValues.password}
                            onChange={handleInputChange}
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="Password*"
                        />
                        {errorMessage && <p className="error_message_alert">{errorMessage}</p>}
                        <div className="forget_links">
                            <Link to="/forget">
                                Forget Password
                            </Link>
                            <p>Do not have an account?<Link to="/register">Create Account</Link></p>
                        </div>
                        <div className="sign_up_btn_wrap">
                            <button type="submit" onClick={handleSubmit}>
                                SIGN IN
                            </button>
                            <p>OR</p>
                            <GoogleLogin
                                clientId='1083827701907-o7vmna2tpg4ieuq2ls9het5jivfq9scv.apps.googleusercontent.com'
                                buttonText="Continue with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </form>
                </div>
            </div>

            <Toaster />
        </div>
    );
};

export default LoginForm;
