import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Row, Col, Select, DatePicker } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuthGuard from "../authGuards";
// import axios from 'axios';
// const apiURL = process.env.REACT_APP_API_URL;

const Option = Select.Option;

const EditBatchWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const EditBatchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditBatchButton = styled(Button)`
  float: right;
`;

function CopyBatch(props)
{
    const [loading, setLoading] = useState(false);
    const [instituteList, setInstituteList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const [image, setImage] = useState(null);
    const [institueData, setInstitueData] = useState([]);
    const [listCategory, setListCategory] = useState(null);
    const { id } = useParams();

    useEffect(() =>
    {
        // listExams();
        listInstitute();
        listPlans();
        listBranch();
        fetchBatch();
        getCategories();
        getLanguages();
        getFacilities();
        // eslint-disable-next-line
    }, []);

    const getLanguages = async () =>
    {
        const rawData = await adminService.getLanguageList();
        setLanguageList(rawData.data.data);
    }

    const getFacilities = async () =>
    {
        const rawData = await adminService.getFacilityList();
        setFacilityList(rawData.data.data);
    }

    const fetchBatch = async () =>
    {
        const response = await adminService.getBatchById(id);
        const data = response.data.data;
        data.startDate = moment(data.startDate)
        data.endDate = moment(data.endDate)
        data.instituteId = data?.instituteId?._id
        data.exam = data?.exam
        data.languages = data?.languages._id
        data.examId = data?.examId?._id
        data.planName = data?.planName?._id
        const examIdArray = data.facilities?.map((item) => item._id);
        const branchDistract = data.branchName?.map((item) => item._id);
        props.form.setFieldsValue({ ...data, facilities: examIdArray, branchName: branchDistract });
        // setImage(data.image)
        getInstituteDetail(data.instituteId, data.city, data.exam)
    };

    const getCategories = async () =>
    {
        const rawData = await adminService.getAllCategories();
        setListCategory(rawData.data.data);
    };

    const listBranch = async () =>
    {
        const rawData = await adminService.getAllBranch();
        setBranchList(rawData.data.data);
    };

    const listInstitute = async () =>
    {
        const rawData = await adminService.getAllInstitutes();
        setInstituteList(rawData.data.data);
    };

    const listPlans = async () =>
    {
        const rawData = await adminService.getAllPlans();
        setPlanList(rawData.data.data);
    };


    const handleSubmit = (e) =>
    {
        e.preventDefault();
        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {

                setLoading(true);
                props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
              
                if (!values.branchName)
                {
                    values.branchName = null;
                }
                const copyBatch = await adminService.copyBatch(id , values)
        
                if (copyBatch.data.success === true)
                {
                    const logData = { body: `Batch ${values.batchNum} created`, url: 'batch/add' }
                    const createLog = await adminService.addLog(logData);
                    if (createLog.data.success === true)
                    {
                        message.success("Log created")
                    }
                    setLoading(false);
                    message.success("Batch Successfully Created");
                    props.history.push("admin/batch");
                } else
                {
                    setLoading(false);
                    message.error("Error in creating batch");
                }
            }
        });
    };

    // const handleUpload = (info) =>
    // {
    //     if (info.file.status === "done")
    //     {
    //         setImage(info.file.originFileObj);
    //     }
    // };

    // const handleRemove = () =>
    // {
    //     setImage(null);
    // };
    // const dummyRequest = ({ file, onSuccess }) =>
    // {
    //     setTimeout(() =>
    //     {
    //         onSuccess("ok");
    //     }, 0);
    // };
    const getInstituteDetail = async (id, city, exam) =>
    {
        const data = await adminService.getInstituteById(id)
        setInstitueData(data.data.data);
        if (city)
        {
            props.form.setFieldsValue({ city: city })
        }
        if (exam)
        {
            props.form.setFieldsValue({ exam: exam })
        }
    }

    const { getFieldDecorator } = props.form;

    return (
        <>
            <AuthGuard />
            <EditBatchWrapper>
                <EditBatchHeader>
                    <h2>Create New Copy Batch</h2>
                    <EditBatchButton onClick={() => props.history.push("/admin/batch")}>
                        Cancel
                    </EditBatchButton>
                </EditBatchHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Batch Name">
                                {getFieldDecorator("batchNum", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Category">
                                {getFieldDecorator("category", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {listCategory &&
                                            listCategory.map((d) => (
                                                <Option key={d._id} value={d.title}>{d.title}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Institute">
                                {getFieldDecorator("instituteId", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        onChange={(e) => getInstituteDetail(e)}
                                        showSearch
                                        optionFilterProp="children"
                                        className="input-select"
                                        placeholder="Please Select"
                                        filterOption={(input, option) =>
                                            (option.props.children ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteList &&
                                            instituteList.map((d) => (
                                                <Option key={d._id} value={d._id}>{d.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Exam">
                                {getFieldDecorator("examId", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {institueData?.examId?.length > 0 &&
                                            institueData.examId.map((exam) => (
                                                <Option key={exam._id} value={exam._id}>{exam.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={12} style={{
                            display: [
                                "6461de19cd10f83e9358827b",
                                "64be659d4dcfa238cc4b2b14",
                                "64e473a04f25d7076635288a",
                                "64e487ff4f25d707663528b9",
                                "64be65bb4dcfa238cc4b2b16",
                                "6463245acd10f83e9358828e"].includes(props.form.getFieldValue("examId"))
                                ? "block"
                                : "none"
                        }}>
                            <Form.Item label="Branch Name">
                                {getFieldDecorator("branchName", {
                                    rules: [{required:false, message: "please enter" }],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {branchList &&
                                            branchList.map((d) => (
                                                <Option key={d.name} value={d._id}>{d.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Plan">
                                {getFieldDecorator("planName", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        className="input-select"
                                        placeholder="Please Select"
                                        filterOption={(input, option) =>
                                            (option.props.children ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {planList &&
                                            planList.map((d) => (
                                                <Option key={d._id} value={d._id}>{d.title}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="BMBC Rating">
                                {getFieldDecorator("bmbcRating", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input type="number" placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Languages">
                                {getFieldDecorator("languages", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Select
                                    className="input-select"
                                    placeholder="Please Select"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    {languageList?.length > 0 &&
                                        languageList.map((lang) => (
                                            <Option key={lang._id} value={lang._id}>{lang.name}</Option>
                                        ))}
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Facilities">
                                {getFieldDecorator("facilities", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Select
                                    mode="multiple"
                                    className="input-select"
                                    placeholder="Please Select"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    {facilityList?.length > 0 &&
                                        facilityList.map((facility) => (
                                            <Option key={facility._id} value={facility._id}>{facility.name}</Option>
                                        ))}
                                </Select>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Faculties">
                                {getFieldDecorator("faculties", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Amount">
                                {getFieldDecorator("amount", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Start Date">
                                {getFieldDecorator("stDate", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setStartDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="End Date">
                                {getFieldDecorator("enDate", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setEndDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Timings">
                                {getFieldDecorator("timings", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Tag Line">
                                {getFieldDecorator("tagLine", {
                                    rules: [{ message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}
                        //     style={{
                        //     display:
                        //         props.form.getFieldValue("category") === "Online" ||
                        //             props.form.getFieldValue("category") === "Online Test Series"
                        //             ? "none"
                        //             : "block"
                        // }}
                        >
                            <Form.Item label="City">
                                {getFieldDecorator("city", {
                                    rules: [
                                        {
                                            required: props.form.getFieldValue("category") !== "Online" && props.form.getFieldValue("category") !== "Test Series",
                                            message: "Please select a city",
                                        },
                                    ],
                                })(
                                    <Select
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {institueData?.city?.length > 0 &&
                                            institueData.city.map((d) => (
                                                <Option key={d} value={d}>{d}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>


                    </Row>
                    {/* <Row style={{ marginBottom: "2rem" }}>
                        <Col span={12}>
                            <Upload
                                onRemove={handleRemove}
                                customRequest={dummyRequest}
                                listType="picture"
                                maxCount={1}
                                onChange={handleUpload}
                            >
                                <Button>Upload (Max: 1)</Button>
                            </Upload>
                        </Col>
                    </Row> */}
                    <Row>
                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </EditBatchWrapper>
        </>
    );
}

export default Form.create()(CopyBatch);
