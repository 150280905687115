import React from 'react';
// import PageHeader from './global-components/page-header';
import MyAccount from './shop-components/my-account';
// import myProf from "./media/banner/brdcrumb.jpg"
import Layout from './global-components/Layout';
const MyaccountV1 = () => {
    return <div>
        <Layout>
            {/* <PageHeader subheader="My Account" image={myProf} /> */}
            <MyAccount />
        </Layout>
     
    </div>
}

export default MyaccountV1

