import React, { useState, useEffect } from "react";
import userService from "../../common/userService";
// import { message } from "antd";
import axios from "axios";
import "../assets/css/profileinput.css";
import "../assets/css/profile.css";
export const ChnagePassword = () => {
    const testURL = process.env.REACT_APP_API_URL_LIVE
    const userId = localStorage.getItem("id");
    const [userInfo, setUserInfo] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('');

    const getUserDetail = async (userId) => {
        const rawData = await userService.getUserDetails(userId);
        const userData = rawData.data.data;
        setUserInfo(userData);

    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        const email = userInfo.email;


        // Check if any of the password fields are empty
        if (!password || !newPassword || !confirmPassword) {
            setErrorMessage('Please fill in all password fields');
            return;
        }

        // Check if the new password and confirm password match
        if (newPassword !== confirmPassword) {
            setErrorMessage('Confirm password does not match');
            return;
        }


        // Check if the old password and new password are the same
        if (password === newPassword) {
            setErrorMessage('Old and new passwords cannot be the same');
            return;
        }

        try {
            const res = await axios.post(testURL + "user/reset-password", {

                email,
                password,
                newPassword
            });

            if (res.data.success === true) {
                setMessage('Password updated successfully!');
                setPassword('');
                setConfirmPassword('');
                setNewPassword('');
                setErrorMessage('');
            }
        } catch (error) {
            setErrorMessage('Something went wrong, try again');
        }
    };

    useEffect(() => {
        getUserDetail(userId);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {userInfo &&
                // <div className="ltn__login-area login_form_new">
                //     <div className="account-login-inner form_template_wrapper">
                <div className="profile_update_section">
                    <div className="profile">
                        <form
                            onSubmit={handleUpdate}
                        >
                            <h5 className="mb-30">Change Password</h5>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Current Password*"
                            />
                            <input
                                type="password"
                                name="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New Password*"
                            />
                            <input
                                type="password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm New Password*"
                            />
                            {message && <p className="mt-1" style={{ color: "green" }}>{message}</p>}
                            {errorMessage && <p className="mt-1 error_message_alert d-flex justify-content-start mb-3">{errorMessage}</p>}
                            <div className="btn-wrapper profile_up_btn mt-0">
                                <button
                                    className="btn profile_btn"
                                    type="submit"
                                >
                                    Update Password
                                </button>

                            </div>
                        </form>

                    </div>
                </div>
                //     </div>
                // </div>

            }

        </>

    );
};

export default ChnagePassword;