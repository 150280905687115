import React from 'react';
import  InstituteDetails  from './shop-components/InstituteDetails';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom"
import Layout from './global-components/Layout';
const Institute_Detail = () =>
{
    const [instituteName, setInstituteName] = useState("");
    const [instituteDescription, setInstituteDescription] = useState("");
    const [categoryList, setCategoryList] = useState("");
    const [city, setCity] = useState("");
    const [logoImg, setLogoImg] = useState("")

    // Function to receive the data from the child component
    const handleData = (name, description, city, exam, logo) =>
    {
        setInstituteName(name);
        setInstituteDescription(description);
        setCategoryList(exam);
        setCity(city);
        setLogoImg(logo)
    };
    const id = useParams();
    return <div>
        <Helmet>
            <title>
                {`${instituteDescription.slice(0.20) + "."}, ${instituteName}`}
            </title>
            <meta
                name="description"
                content={instituteDescription.slice(0.60) + "."}
            />
            <meta name="keywords" content={`${categoryList}, ${city}`} />
            <meta property="og:title" content={`${instituteDescription.slice(0.20) + "."}, ${instituteName}`} />
            <meta property="og:description" content={instituteDescription.slice(0.60) + "."} />
            <meta property="og:image" content={logoImg} />
            <link rel="canonical" href={`https://bookmybestclass.com/institute-detail/${id}`} />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            <InstituteDetails onData={handleData} />
        </Layout>
       
    </div>
}

export default Institute_Detail

