import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";
const PromoManagementWrapper = styled.div`
  padding: 20px;
`;

const PromoManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const PromoManagementCreateButton = styled(Button)`
  float: right;
`;

const PromoManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export default function PromoManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");
  // const [adminType, setAdminType] = useState("");

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };
  const fetch = (params = {}) =>
  {
    setLoading(true);
    adminService.getAllPromo().then((apiResponse) =>
    {
      const result = apiResponse.data.data
      let promoList = result.map((obj) =>
      {
        const endDate = new Date(obj.endDate);
        const momentEndDate = moment(endDate);
        const momentNowDate = moment();
        let statusFront;
        if (momentNowDate.isAfter(momentEndDate, 'day'))
        {
          statusFront = 'Ended';
        } else
        {
          statusFront = 'Active';
        }

        return {
          ...obj,
          statusFront,
        };
      });
      const paginationObj = { ...pagination };
      paginationObj.total = promoList.length;
      setLoading(false);

      setData(promoList);
      setPagination(paginationObj);
    });
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [promoIdToDelete, setPromoIdToDelete] = useState(null);

  const showDeleteModal = (promoId) => {
    // setRawData(data)
    setPromoIdToDelete(promoId);
    setIsDeleteModalOpen(true);
  };

  const handleDeletePromo = async () => {
    try {
      const deletePromoResponse = await adminService.deletePromo(
        promoIdToDelete
      );
      if (deletePromoResponse.data.success === true) {
        message.success(deletePromoResponse.data.data);
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  // please  add institute table and exam table in get promo api

  const columns = [
    {
      title: "Promo name",
      dataIndex: "name",
    },
    {
      title: "Image",
      dataIndex: "instituteId.image",
      key: "image",
      width: "10%",
      render: (image) => (
        <img style={{ width: "90px" }} src={image} alt={"instituteImage"} />
      ),
    },
    {
      title: "Institute Name",
      dataIndex: "instituteId.name",
    },
    {
      title: "Exam",
      dataIndex: "examId.name",
    },
    {
      title: "Discount",
      dataIndex: "discountPercent",
      render: (data) => {
        const formattedAmount = `₹${data}`;
        return formattedAmount;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      // render: (idx) => {
      //   return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      // },
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Promo Type",
      dataIndex: "type",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "statusFront",
      render: (value) => {
        if (value === "Ended") {
          return (
            <p className="bg-danger text-white text-center p-1">{value}</p>
          );
        } else {
          return (
            <p className="bg-success text-white text-center p-1">{value}</p>
          );
        }
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editpromo/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editpromo/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
          {/* <Button
                        onClick={() => handleVisibility(data)}
                        type="success"
                        style={{ marginRight: 2 }}
                    >
                        {data.instituteId?.hide === "no" ? "Hide" : "Unhide"}
                    </Button> */}
        </>
      ),
    },
  ];
  // const handleVisibility = async (data) =>
  // {
  //     const obj = {
  //         id: data._id,
  //         hide: data.hide === "yes" ? "no" : "yes",
  //     };

  //     const updatePromo = await adminService.handlePromoVisibility(obj);
  //     if (updatePromo.status === 200)
  //     {
  //         message.success("Visiblity Updated");
  //         fetch();
  //     } else
  //     {
  //         message.error("An Error Occured");
  //     };
  // }

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.instituteId?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.examId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.timings?.[0]?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length === 0) {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };
  return (
    <>
      <AuthGuard />
      <TopNav />
      <PromoManagementWrapper>
        <PromoManagementHeader>
          <h2>Promotion Management</h2>

          <PromoManagementCreateButton type="primary">
            <Link to="/admin/createPromo">Create</Link>
          </PromoManagementCreateButton>
        </PromoManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <PromoManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Delete Promo"
          visible={isDeleteModalOpen}
          onOk={handleDeletePromo}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this Promo?</p>
        </Modal>
      </PromoManagementWrapper>
    </>
  );
}
