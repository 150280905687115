import React , { useState, useEffect} from 'react';
import styled from 'styled-components';
import AuthGuard from "../../authGuards";
import adminService from "../../../../common/adminService";
import
{
  Select,
    message,
  Button,
  Form,
  DatePicker,
} from "antd";
import moment from "moment";

const CreateKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
   border: none;
   height: 100%;
   width: 100%;
   align-items: center;
   display: grid;
  }

  .ant-select-arrow {
    display: none
  }
`;

const CreateKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateKeywordButton = styled(Button)`
  float: right;
`;





function CreateInsKeywords(props){
    // const [keywords, setKeywords ] =useState("");
    const [loading, setLoading] = useState(false);
    const [instituteList, setInstituteList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  const [keywordList, setKeywordList] = useState([]);
    const Option = Select.Option;

    useEffect(() =>
    {
        listKeyword();
        listInstitute();
        // eslint-disable-next-line
    }, []);
   
    const listInstitute = async () =>
    {
      const rawData = await adminService.getAllInstitutes();
      setInstituteList(rawData.data.data);
    };
    const listKeyword = async () =>{
      const rawData = await adminService.getAllKeywords();
      setKeywordList(rawData.data.data);

    }
    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
          // console.log(values);
          if (!err) {
            setLoading(true);
            props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
            const statusEndDate = moment(values.endDate);
            const statusNowDate = moment();
            let statusDate;
            if (statusNowDate.isAfter(statusEndDate))
            {
                statusDate = 'Ended';
            } else
            {
                statusDate = 'Active';
            }

            setLoading(true);
            const createKeyword = await adminService.addInstituteKeyword(values, statusDate);
            const instituteName = instituteList.filter(item => values.instituteId.includes(item._id))
            const instituteValues = instituteName?.map(item => item.name);
            setLoading(false);
            // console.log(values);
            if (createKeyword.data.success === true) {
              const logData = { body: `${instituteValues && (instituteValues?.join(', '))} Keyword created`, url: 'institutekeyword/add' }
              const createLog = await adminService.addLog(logData)
              if (createLog.data.success === true)
              {
                message.success("Keyword Successfully Created");
              };
           
              props.form.resetFields();
              props.history.push("/admin/instituteKeywords");
            } else {
              message.error("Error in creating keyword");
            }
          }
        });
    };
    const { getFieldDecorator } = props.form;
  return (
    <>
    <AuthGuard />
    <CreateKeywordWrapper>
      <CreateKeywordHeader>
        <h2>Create Institute Keyword</h2>
        <CreateKeywordButton onClick={() => props.history.push("/admin/instituteKeywords")}>
          Cancel
        </CreateKeywordButton>
      </CreateKeywordHeader>
      <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item label="Keyword">
                {getFieldDecorator("keyword", {
                  rules: [{ required: true, message: "please enter" }],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="input-select"
                    placeholder="Please Select"
                    filterOption={(input, option) =>
                      (option.props.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                    }}
                  >
                    {keywordList &&
                      keywordList.map((d) => (
                        
                        <Option key={d._id} value={d._id}>{d.name}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Institute">
                {getFieldDecorator("instituteId", {
                  rules: [{ required: true, message: "please Select" }],
                })(
                  <Select
                  
                  mode="multiple"
                  className="input-select"
                  placeholder="Please Select"
                  filterOption={(input, option) =>
                    (option.props.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                  }}
                  >
                    {instituteList &&
                      instituteList.map((d) => (
                        <Option value={d._id}>{d.name}</Option>
                      ))}
                  </Select>
                )}
        </Form.Item>
        <Form.Item label="Start Date">
            {getFieldDecorator("startDate", {
                rules: [{ required: true, message: "please enter start date" }],
              })(
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(date, dateString) => setStartDate(dateString)}
                  style={{ width: "100%" }}
                   />
                  )}
        </Form.Item>
                       
        <Form.Item label="End Date">
            {getFieldDecorator("endDate", {
                    rules: [{ required: true, message: "please enter end date" }],
                    })(
                    <DatePicker
                    format={"DD-MM-YYYY"}
                     onChange={(date, dateString) => setEndDate(dateString)}
                    style={{ width: "100%" }}
                  />
                )}
        </Form.Item>  
                   
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </CreateKeywordWrapper>
    </>
  );
}

export default Form.create()(CreateInsKeywords);

