import React, { useEffect, useState, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import blogban1 from "../media/banner/1.png";
import axios from 'axios';
import { Spin, message } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BatchContext } from '../../redux/batchContext';
import userService from '../../common/userService';
import { BsEyeFill } from "react-icons/bs"
const BlogDetails = ({ onData }) => {
	const { blogId, setBlogId } = useContext(BatchContext);
	const token = localStorage.getItem("token");
	// const apiURL = process.env.REACT_APP_API_URL;
	const apiURL = process.env.REACT_APP_API_URL_LIVE
	const [name, setName] = useState("")
	const [data, setData] = useState([]);
	const [comment, setComment] = useState([])
	const [isActive, setActive] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory()
	const [blogMessage, setBlogMessage] = useState({
		comment: "",
	});
	const toggleClass = () => {
		setActive(!isActive);
	};
	var gmtDate = data?.createdAt;
	var localDate = new Date(gmtDate);
	const paymentDate = new Date(localDate);
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const payment_date_year = paymentDate.getFullYear();
	const payment_date_month = months[paymentDate.getMonth()];
	const payment_date_date = paymentDate.getDate();
	const fulldate_paymentDate = payment_date_date + '-' + payment_date_month + '-' + payment_date_year;

	const fetchUser = async () => {
		const id = localStorage.getItem("id")
		if (id) {
			const rawData = await userService.getUserDetails(id);
			const data = rawData.data.data.name;
			setName(data);
		}
	}

	const fetchBlog = async () => {
		setLoading(true)
		const res = await axios.get(apiURL + `blog/id/${blogId}`)
		try {
			const newArr = res.data.data
			setData(newArr);
			setComment(newArr.comments);
			const name = newArr.heading;
			const logo = newArr.image;
			const tags = newArr?.tags
			onData(name, logo, tags);
			setLoading(false)
		} catch (error) {
			message.error("Blog not fetched")
		}

	}

	const handlePostComment = async (blogId) => {
		const comment = blogMessage.comment
		const userName = name
		const id = localStorage.getItem("id")
		if (id) {
			if (token) {
				const postComment = await axios.put(apiURL + `blog/add-comment`, { blogId, comment, userName });
				if (postComment.data.success === true) {
					message.success("Comment posted Successfully ");
					setActive(!isActive);
					fetchBlog();
					// blogMessage.comment("")
				} else {
					message.error("Error in posting comment");
				}
			} else {
				alert("Please Login")
				history.push("/login")
			}
		} else {
			localStorage.setItem("redirectUrl", window.location.pathname)
			history.push('/login')
			message.error('Login for leave a comment');
		}

	}

	const handleInput = (e) => {
		const { name, value } = e.target;
		setBlogMessage({
			...blogMessage,
			[name]: value,
		});
	}

	useEffect(() => {
		const storedBlogId = localStorage.getItem('blogId');
		// console.log(storedBlogId);
		if (storedBlogId) {
			setBlogId(JSON.parse(storedBlogId));
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (blogId) {
			fetchBlog();
		}
		fetchUser()
		// eslint-disable-next-line
	}, [blogId]);

	return (
		
		<div className="padding_area blog_padding">
			<div className="container">
				<div className='blog_section_wrap'>
					<div className='blog_list_wrap'>
						{
							!loading ?
								<>
									<h1>{data?.heading}</h1>
									<div className="blog_image">
										{
											data?.image && <img src={data?.image} className='blog_ban_img' alt="blogImage" />

										}
									</div>
									<div className="blog_details_body">
										<ul className="blog_link_col">
											<li>
												<i className="far fa-calendar-alt" />{fulldate_paymentDate}
											</li>
											<li>
												<p><BsEyeFill />Views{data?.views}</p>
											</li>
											<li>
												<p onClick={toggleClass} className='comment_all' ><i className="far fa-comments" /> {data.comments?.length} Comments</p>
											</li>

											<div className={isActive ? 'comnt_box_form' : 'comnt_box_form_hide'}>
												<div className='all_cmnt_see'>
													{
														comment.map((value, id) => {
															const createdAt = value?.date;
															const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
															const localDate = new Date(createdAt);
															const paymentDate = new Date(localDate);
															const payment_date_year = paymentDate.getFullYear();
															const payment_date_month = months[paymentDate.getMonth()];
															const payment_date_date = paymentDate.getDate();
															const payment_date_dateNew = payment_date_date < 10 ? '0' + payment_date_date : payment_date_date;
															const fulldate_paymentDate = payment_date_dateNew + '-' + payment_date_month + '-' + payment_date_year;
															return (
																<div className="card_blog_cmt col-md-4" key={id}>
																	<h2>
																		{value.userName}
																	</h2>
																	<h6>{fulldate_paymentDate}</h6>
																	<p>
																		{value.comment}
																	</p>
																</div>
															)
														})
													}
												</div>

												<form>
													<textarea value={blogMessage.comment} onChange={handleInput} placeholder='Enter your comment' name="comment" className='form-control' />
													<button className='theme-btn-1 btn-sm  py-2 mt-1' type='button' onClick={() => handlePostComment(data._id)} >
														Post
													</button>
												</form>

											</div>
										</ul>
										<p className='tags_msg'>
											{data?.tags && (
												<>
													{data.tags.split(',').map((tag, index) => (
														<span key={index}>
															{index > 0 && ', '}
															#{tag.trim()}
														</span>
													))}
												</>
											)}
										</p>
										<div className="blg_msg" dangerouslySetInnerHTML={{ __html: data?.message }}></div>

									</div>
								</>
								:
								<div className="d-flex justify-content-center">
									<Spin />
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}


export default BlogDetails;
