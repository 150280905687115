import React, { useEffect, useState } from 'react'
import adminService from '../../../common/adminService';
import { Button, Modal, Table, message } from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom"
import styled from 'styled-components';
import SearchComponent from '../search';
import TopNav from '../topnavComponent';
import AuthGuard from '../authGuards';
const BatchManagementWrapper = styled.div`
  padding: 20px;
`;

const BatchManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;


const BatchManagementCreateButton = styled(Button)`
  float: right;
  margin-left:10px;
`;
const BatchManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const BatchManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const BatchManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
  display:flex;
  justify-content:space-between;
`;
const UpcomingBatch = () =>
{
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [adminType, setAdminType] = useState("");
    // const [adminRole, setAdminRole] = useState("");
    const [rawData, setRawData] = useState(null);
    // const [filteredInfo, setFilteredInfo] = useState(null);
    // const [sortedInfo, setSortedInfo] = useState({});
    // const [examList, setExamList] = useState([]);

    useEffect(() =>
    {
        // const role = sessionStorage.getItem("adminRole");
        const type = sessionStorage.getItem("adminType");
        // setAdminRole(role);
        setAdminType(type);
    },[])
    useEffect(() =>
    {
        fetch();
        // eslint-disable-next-line
    }, []);

    const handleTableChange = async (pagination, filters, sorter) =>
    {
        const pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        // setFilteredInfo(filters);
        // setSortedInfo(sorter);
        fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const fetch = (params = {}) =>
    {
        setLoading(true);
        adminService.getAllBatches().then((apiResponse) =>
        {
            const paginationObj = { ...pagination };
            paginationObj.total = apiResponse.data.data.length;
            setLoading(false);
            const data = apiResponse.data.data
            let batchList = data.map((obj) =>
            {

                const endDate = new Date(obj.endDate);
                const startDate = new Date(obj.startDate);
                const momentEndDate = moment(endDate);
                const momentStartDate = moment(startDate);
                const momentNowDate = moment();
                const daysUntilStart = momentStartDate.diff(momentNowDate, 'days');
                const daysUntilEnd = momentEndDate.diff(momentNowDate, 'days');
                let status;
                if (momentNowDate.isAfter(momentEndDate, 'day'))
                {
                    status = 'Ended';
                } else if (!momentNowDate.isAfter(momentStartDate))
                {
                    status = 'Upcoming';
                } else
                {
                    status = 'Active';
                }

                return {
                    ...obj,
                    status,
                    daysUntilStart,
                    daysUntilEnd,
                };
            }).filter(obj => obj.status === 'Upcoming')

            const sortedBatchList = batchList.sort((a, b) =>
                a.daysUntilStart - b.daysUntilStart
            );
            batchList = [];
            batchList.push(...sortedBatchList);
            setData(batchList);
            setPagination(paginationObj);
        });
    };
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [batchIdToDelete, setBatchIdToDelete] = useState(null);

    const showDeleteModal = (batchId) =>
    {
        setRawData(data)
        setBatchIdToDelete(batchId);
        setIsDeleteModalOpen(true);
    };
    const handleDeleteBatch = async (id) =>
    {
        try
        {
            const deleteBatchResponse = await adminService.deleteBatch(
                batchIdToDelete
            );
            if (deleteBatchResponse.data.success === true)
            {
                const logData = { body: `Batch ${rawData.batchName} deleted`, url: 'batch/delete' }
                const createLog = await adminService.addLog(logData);
                if (createLog.data.success === true)
                {
                    message.success("Log created")
                }
                message.success(deleteBatchResponse.data.data);
            } else
            {
                message.warn("An error occurred. Please try again");
            }
            setIsDeleteModalOpen(false);
            fetch();
        } catch (error)
        {
            message.warn("An error occurred. Please try again");
        }
    };

    const handleCancelDelete = () =>
    {
        setIsDeleteModalOpen(false);
    };

    const columns = [
        {
            title: "Batch Name",
            dataIndex: "batchName",
        },
        {
            title: "Image",
            dataIndex: "instituteId.image",
            key: "image",
            width: "10%",
            render: (image) => (
                <img style={{ width: "80px" }} src={image} alt={"batchImage"} />
            ),
        },
        {
            title: "Institute",
            dataIndex: "instituteId.name",
        },
        {
            title: "Exam",
            dataIndex: "examId.name",
        },
        {
            title: "City",
            dataIndex: "city",
        },
        {
            title: "Category",
            dataIndex: "category"
        },
        {
            title: "Timing",
            key: "startdate",
            dataIndex: "timings",
            render: (timings) =>
            {
                if (timings)
                {
                    return timings;
                } else
                {
                    return "No Timing Available";
                }
            },
        },

        {
            title: "Start Date",
            dataIndex: "startDate",
            render: (idx) =>
            {
                return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
            },
        },
        {
            title: "Status",
            dataIndex: "daysUntilStart",
            render: (value) =>
            {
                return <p className="bg-warning text-danger text-center p-1">{`Ongoing in ${value} Days`}</p>

            }
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (record, data) => (
                <>
                    {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
                        <>
                            {data.copied === 0 ?
                                <Button className="bg-success text-white">
                                    <Link to={`/admin/copyBatch/${record}`}>Copy</Link>
                                </Button>
                                :
                                <Button disabled type="light" style={{ marginRight: 2 }}>
                                    Copy ({data.copied})
                                </Button>
                            }
                            <Button type="primary" style={{ marginRight: 2 }}>
                                <Link to={`/admin/editBatch/${record}`}>Edit</Link>
                            </Button>
                            <Button
                                onClick={() => handleVisibility(data)}
                                type="success"
                                style={{ marginRight: 2 }}
                            >
                                {data.hide === "no" ? "Hide" : "Unhide"}
                            </Button>
                        </>
                    ) : adminType === "delete" ? (
                        <>
                            <Button
                                onClick={() => showDeleteModal(record, data)}
                                type="danger"
                                style={{ marginRight: 2 }}
                            >
                                Delete
                            </Button>
                                {data.copied === 0 ?
                                    <Button className="bg-success text-white">
                                        <Link to={`/admin/copyBatch/${record}`}>Copy</Link>
                                    </Button>
                                    :
                                    <Button disabled type="light" style={{ marginRight: 2 }}>
                                        Copy ({data.copied})
                                    </Button>
                                }
                                <Button type="primary" style={{ marginRight: 2 }}>
                                    <Link to={`/admin/editBatch/${record}`}>Edit</Link>
                                </Button>
                            <Button
                                onClick={() => handleVisibility(data)}
                                type="success"
                                style={{ marginRight: 2 }}
                            >
                                {data.hide === "no" ? "Hide" : "Unhide"}
                            </Button>
                        </>
                    ) : null}
                </>
            ),
        },
    ];

    const handleVisibility = async (data) =>
    {
        const batchStatus = data.hide === "yes" ? "Visible" : "Hidden"
        const obj = {
            id: data._id,
            hide: data.hide === "yes" ? "no" : "yes",
        };

        const updateInstitue = await adminService.handleBatchVisibility(obj);
        if (updateInstitue.status === 200)
        {
            const logData = { body: `Batch ${data.batchName} status changed to ${batchStatus}`, url: 'batch/status' }
            const createLog = await adminService.addLog(logData)
            if (createLog.data.success === true)
            {
                message.success("Visiblity Updated");
            }
       
            fetch();
        } else
        {
            message.error("An Error Occured");
        };
    }
    
    const handleSearch = (searchTerm) =>
    {
        const filteredData = data.filter(
            (item) =>
                item.batchName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.instituteId?.name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                item.examId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.timings?.[0]?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (filteredData.length === 0)
        {
            message.info("No Results Found");
        }
        setFilteredData(filteredData);
    };

    return (
        <>
            <AuthGuard />
            <TopNav />
            <BatchManagementWrapper>
                <BatchManagementHeader>
                    <h2>Upcoming Batch Management</h2>
                    {(adminType === "delete" || adminType === "edit") && (
                        <BatchManagementCreateButton type="light">
                            <Link to="/admin/batch">Cancel</Link>
                        </BatchManagementCreateButton>
                    )}
                </BatchManagementHeader>
                <SearchWrapper>
                    <SearchComponent data={data} onSearch={handleSearch} />
                </SearchWrapper>
                <BatchManagementTable
                    columns={columns}
                    rowKey={(record) => record._id}
                    dataSource={filteredData.length > 0 ? filteredData : data}
                    pagination={
                        filteredData.length > 0 ? filteredData.length : data.length
                    }
                    loading={loading}
                    onChange={handleTableChange}
                />
                <Modal
                    title="Delete Batch"
                    visible={isDeleteModalOpen}
                    onOk={handleDeleteBatch}
                    onCancel={handleCancelDelete}
                    okText="Delete"
                    okType="danger"
                    cancelText="Cancel"
                    centered
                    maskClosable={false}
                    width={400}
                >
                    <p>Are you sure you want to delete this batch?</p>
                </Modal>
            </BatchManagementWrapper>
        </>
    )
}

export default UpcomingBatch