import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./components/assets/css/responsive.css";
import "./components/assets/css/slider.css"
import Routes from './Routes';
import "antd/dist/antd.css";
import { BatchProvider } from './redux/batchContext';
import "./components/assets/css/templates.css";
import { PromoProvider } from './redux/promoContext';

class Root extends Component
{
    render()
    {
        return (
            <PromoProvider>
                <BatchProvider>
                    <Routes />
                </BatchProvider>
            </PromoProvider>
          
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
