import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Col, Row } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";
import AuthGuard from "../authGuards";

const EditInstituteWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const EditInstituteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditInstituteButton = styled(Button)`
  float: right;
`;

function UpdateLanguage(props)
{
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() =>
    {
        // getCategories();
        const fetchFacility = async () =>
        {
            const response = await adminService.getLanguageById(id);
            const data = response.data.data;
            console.log(response.data);
            props.form.setFieldsValue({ ...data });
        };

        fetchFacility();
        // eslint-disable-next-line
    }, []);



    const handleSubmit = (e) =>
    {

        e.preventDefault();
        props.form.validateFields(async (err, values) =>
        {
            console.log(values);
            if (!err)
            {
                setLoading(true);
                const updateInstitute = await adminService.updateLanguage(id, values);
                setLoading(false);
                if (updateInstitute.data.success === true)
                {
                    const logData = {body:  `Language ${values.name} updated`, url: 'language/update' }
                    const createLog = await adminService.addLog(logData);
                    if (createLog.data.success === true)
                    {
                        message.success("Language Successfully Updated");
                    }
                
                    props.history.push("/admin/language");
                } else
                {
                    message.error("Error in updating language");
                }
            }
        });
    };


    const { getFieldDecorator } = props.form;

    return (
        <>
            <AuthGuard />
            <EditInstituteWrapper>
                <EditInstituteHeader>
                    <h2>Edit Language</h2>
                    <EditInstituteButton
                        onClick={() => props.history.push("/admin/language")}
                    >
                        Cancel
                    </EditInstituteButton>
                </EditInstituteHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Name">
                                {getFieldDecorator("name", {
                                    rules: [{ required: true, message: "please enter name" }],
                                })(<Input placeholder="please enter name" />)}
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12}>
              <Form.Item label="Info">
                {getFieldDecorator("info", {
                  rules: [{ required: true, message: "please enter info" }],
                })(<Input placeholder="please enter info" />)}
              </Form.Item>
            </Col> */}
                    </Row>
                    <Row>
                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </EditInstituteWrapper>
        </>
    );
}

export default Form.create()(UpdateLanguage);
