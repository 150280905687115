import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const InstituteManagementWrapper = styled.div`
  padding: 20px;
`;

const InstituteManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const InstituteManagementCreateButton = styled(Button)`
  float: right;
`;

const InstituteManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function InstituteManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = () => {
    setLoading(true);
    adminService.getFacilityList().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [facilityIdToDelete, setFacilityIdToDelete] = useState(null);

  const showDeleteModal = (FacilityId) => {
    setFacilityIdToDelete(FacilityId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteBatch = async () => {
    try {
      const deleteBatchResponse = await adminService.deleteFacility(
        facilityIdToDelete
      );
      if (deleteBatchResponse.data.success === true) {
        message.success(deleteBatchResponse.data.data);
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editFacility/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                {"Delete"}
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editFacility/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.city &&
          item.city[0].toLowerCase().includes(searchTerm.toLowerCase()))
    );
    if (filteredData.length === 0) {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <InstituteManagementWrapper>
        <InstituteManagementHeader>
          <h2>Batch Facility</h2>
          <InstituteManagementCreateButton type="primary">
            <Link to="/admin/createFacility">Create</Link>
          </InstituteManagementCreateButton>
        </InstituteManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <InstituteManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Delete Facility"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this Facility?</p>
        </Modal>
      </InstituteManagementWrapper>
    </>
  );
}

export default InstituteManagement;
