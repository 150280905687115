import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Col, Row, Upload } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import AuthGuard from "../authGuards";
import TopNav from "../topnavComponent";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import adminService from "../../../common/adminService";
import { WithContext as ReactTags } from 'react-tag-input';
// const { TextArea } = Input;
const EditInstituteWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
    padding-right: 40px;
  }
`;

const EditInstituteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditInstituteButton = styled(Button)`
  float: right;
`;

function EditBlog(props)
{
  const apiURL = process.env.REACT_APP_API_URL_LIVE;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [metaImage, setMetaImage] = useState(null)
  const [tags, setTags] = useState([]);
  const { id } = useParams();

  useEffect(() =>
  {
    const fetchBlog = async () =>
    {
      const response = await axios.get(apiURL + `blog/id/${id}`);
      const data = response.data.data;
      // setBlog(data);
      props.form.setFieldsValue({ ...data });
    };
    fetchBlog();
    // eslint-disable-next-line
  }, []);



  // Tags function 
  const handleTagDelete = (i) =>
  {
    const newTags = tags?.slice();
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleTagAddition = (tag) =>
  {
    const newTags = [...tags, tag];
    setTags(newTags);
  };

  const handleSubmit = (e) =>
  {
    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      if (!err)
      {
        setLoading(true);
        const formattedTags = tags.map((tag) => tag.text);
        const tagsString = formattedTags.join(' ');
        const updateBlog = await adminService.updateBlog(id, values, image, tagsString, metaImage)
        setLoading(false);
        if (updateBlog.data.success === true)
        {
          const logData = { body: `Blog ${values.heading} updated`, url: 'blog/update', oldData: updateBlog.data.dataUpdate.oldData, newData: updateBlog.data.dataUpdate.newData }
          const createLog = await adminService.addLog(logData);
          if (createLog.data.success === true)
          {
            message.success("Views Successfully Updated");
          }
      
          props.history.push("/admin/blog");
        } else
        {
          message.error("Error in updating views");
        }
      }
    });
  };

  const handleUpload = (info) =>
  {
    if (info.file.status === "done")
    {
      setImage(info.file.originFileObj);
    }
  };

  const dummyRequest = ({ file, onSuccess }) =>
  {
    setTimeout(() =>
    {
      onSuccess("ok");
    }, 0);
  };

  const handleUploadMetaImage = (info) =>
  {
    if (info.file.status === "done")
    {
      setMetaImage(info.file.originFileObj);
    }
  };

  const dummyRequestMetaImage = ({ file, onSuccess }) =>
  {
    setTimeout(() =>
    {
      onSuccess("ok");
    }, 0);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'align',
    'list', 'bullet',
    'link', 'image',
  ];

  const { getFieldDecorator } = props.form;

  return (
    <>
      <AuthGuard />
      <TopNav />

      <EditInstituteWrapper>
        <EditInstituteHeader>
          <h2>Edit Blog</h2>
          <EditInstituteButton
            onClick={() => props.history.push("/admin/blog")}
          >
            Cancel
          </EditInstituteButton>
        </EditInstituteHeader>
        <Form onSubmit={handleSubmit} className="login-form">
          <Row gutter={16}>
            <Col>
              <Form.Item label="Heading">
                {getFieldDecorator("heading", {
                  rules: [{ required: true, message: "please enter heading" }],
                })(
                  <Input placeholder="Enter blog heading*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Short Description">
                {getFieldDecorator("description", {
                  rules: [{ required: true, message: "please enter short description" }],
                })(
                  <Input placeholder="Enter short description*" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col>
              <Form.Item label="Message">
                {getFieldDecorator("message", {
                  rules: [{ required: true, message: "Please enter message " }],
                })(<ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col span={12}>
              <Form.Item label="Blog Image">
                <Upload
                  customRequest={dummyRequest}
                  listType="picture"
                  maxCount={1}
                  className="form-control"
                  onChange={handleUpload}
                >
                  <Button>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Blog Tags">
                <ReactTags
                  tags={tags}
                  required
                  className="form-control"
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAddition}
                  placeholder="Type tags prefixed with #"
                  delimiters={[32]} // Space character
                  allowDragDrop={false}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Blog Slug">
                {getFieldDecorator("metaSlug", {
                  rules: [{ required: true, message: "please enter meta slug" }],
                })(
                  <Input placeholder="Enter meta slug*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Blog Category">
                {getFieldDecorator("blogCategory", {
                  rules: [{ required: true, message: "please enter blog category" }],
                })(
                  <Input placeholder="Enter blog category*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta title">
                {getFieldDecorator("metaTitle", {
                  rules: [{ required: true, message: "please enter meta title" }],
                })(
                  <Input placeholder="Enter meta title*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta tags">
                {getFieldDecorator("metaTags", {
                  rules: [{ required: true, message: "please enter meta tags" }],
                })(
                  <Input placeholder="Enter meta keywords*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta Description">
                {getFieldDecorator("metaDescription", {
                  rules: [{ required: true, message: "please enter meta description" }],
                })(
                  <Input placeholder="Enter meta description*" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta Image">
                <Upload
                  customRequest={dummyRequestMetaImage}
                  listType="picture"
                  maxCount={1}
                  className="form-control"
                  onChange={handleUploadMetaImage}
                >
                  <Button>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col span={12}>
              <Form.Item label="Views">
                {getFieldDecorator("views", {
                  rules: [{ required: true, message: "Enter Custom Views" }],
                })(<Input placeholder="Views" />)}
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </EditInstituteWrapper>
    </>
  );
}

export default Form.create()(EditBlog);
