import React from 'react'
import whstapp from "../media/banner/whatsappicon2.png";
import Navbar from './navbar';
import Footer from "./footer";
// import { BiChat } from "react-icons/bi";
// import { height } from 'pdfkit/js/page';
// import { useEffect, useState } from 'react';
// import Spinner from "../global-components/Spinner"
const Layout = ({ children }) =>
{
    const bookmybestclass = "https://api.whatsapp.com/send?phone";
    const countryCode = "91";
    const whatsappNumber = "8879045526";
    const whatsappLink = `${bookmybestclass}=${countryCode}${whatsappNumber}`;


    return (
        <section className='layout_wrapper p-0'>
            <Navbar />
            {children}
            <a href={whatsappLink} className='whatsapp_icon' target="_blank" rel="noopener noreferrer" title="bookmybestclass.whatsapp">
                <img src={whstapp} alt='image_whatsapp'
                    // style={{ width: "40px", height: "40px" }}
                    style={{ minHeight: "32px" }}
                />
            </a>
            <Footer />
        </section>



    );
}

export default Layout