import React, { useState, useEffect } from "react";
import { Button, Table, message } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const CategoryManagementWrapper = styled.div`
  padding: 20px;
`;

const CategoryManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CategoryManagementCreateButton = styled(Button)`
  float: right;
`;

const CategoryManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const CategoryManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const CategoryManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function LimitManager()
{
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [adminType, setAdminType] = useState("");
    // const [adminRole, setAdminRole] = useState("");

    useEffect(() =>
    {
        // const role = sessionStorage.getItem("adminRole");
        const type = sessionStorage.getItem("adminType");
        // setAdminRole(role);
        setAdminType(type);
        fetch();
        // eslint-disable-next-line
    }, []);

    const handleTableChange = async (pagination, filters, sorter) =>
    {
        const pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const fetch = (params = {}) =>
    {
        setLoading(true);
        adminService.getAllAffordableBatches().then((apiResponse) =>
        {
            const paginationObj = { ...pagination };
            paginationObj.total = apiResponse.data.data.length;
            setLoading(false);
            setData(apiResponse.data.data);
            setPagination(paginationObj);
        });
    };

    const deleteLimit = async (id, data) =>
    {
        const deleteCat = await adminService.deleteAffordableBatch(id);
        if (deleteCat.data.success === true)
        {
            const logData = { body: `Limit ${data.title} deleted`, url: 'limit/delete' }
            const createLog = await adminService.addLog(logData);
            if (createLog.data.success === true)
            {
                message.success("Limit Successfully Deleted");
            };
        } else
        {
            message.warn("An error occured please try again");
        }
        fetch();
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "examId.name",
        },
        {
            title: "Category",
            dataIndex: "category",
        },
        {
            title: "Limit",
            dataIndex: "limit",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            render: (idx) =>
            {
                return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
            },
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (record, data) => (
                <>
                    {adminType === "view" ? null : adminType === "edit" ? (
                        <>

                            <Button type="primary" style={{ marginRight: 2 }}>
                                <Link to={`/admin/editLimit/${record}`}>Edit</Link>
                            </Button>
                            <Button
                                onClick={() => deleteLimit(record, data)}
                                type="danger"
                                style={{ marginRight: 2 }}
                            >
                                Delete
                            </Button>
                        </>
                    ) : adminType === "delete" ? (
                        <>
                            <Button
                                onClick={() => deleteLimit(record, data)}
                                type="danger"
                                style={{ marginRight: 2 }}
                            >
                                Delete
                            </Button>
                            <Button type="primary" style={{ marginRight: 2 }}>
                                <Link to={`/admin/editLimit/${record}`}>Edit</Link>
                            </Button>
                        </>
                    ) : null}
                </>
            ),
        },
    ];

    const handleSearch = (searchTerm) =>
    {
        const filteredData = data.filter(
            (item) =>
                item.title &&
                item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (filteredData.length === 0) { message.warn('No Data Found') }
        setFilteredData(filteredData);
    };

    return (
        <>
            <AuthGuard />
            <TopNav />
            <CategoryManagementWrapper>
                <CategoryManagementHeader>
                    <h2>Affordable Batch Limit</h2>
                    {(adminType === "delete" || adminType === "edit") && (
                        <CategoryManagementCreateButton type="primary">
                            <Link to="/admin/createLimit">Create</Link>
                        </CategoryManagementCreateButton>
                    )}
                </CategoryManagementHeader>
                <SearchWrapper>
                    <SearchComponent data={data} onSearch={handleSearch} />
                </SearchWrapper>
                <CategoryManagementTable
                    columns={columns}
                    rowKey={(record) => record._id}
                    dataSource={filteredData.length > 0 ? filteredData : data}
                    pagination={
                        filteredData.length > 0 ? filteredData.length : data.length
                    }
                    loading={loading}
                    onChange={() => handleTableChange}
                />
            </CategoryManagementWrapper>
        </>
    );
}

export default LimitManager;
