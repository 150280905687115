import { Button, Upload } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BiCloudUpload } from 'react-icons/bi';
const DownloadWrapper = styled.div`
display:flex;
justify-content:flex-end;

`
const DownloadSheet = ({ onDownload, onUpload, loading }) =>
{
    const [fileList, setFileList] = useState([]);

    const handleDownLoad = () =>
    {
        onDownload();
    }
    const handleUpload = (info) =>
    {
        setFileList(info.fileList);
        onUpload(fileList, setFileList);
    }

    const handleRemove = () =>
    {
        setFileList(null);
    };

    const dummyRequest = ({ file, onSuccess }) =>
    {
        setTimeout(() =>
        {
            onSuccess("ok");
        }, 0);
    };




    return (
        <DownloadWrapper>
            <div style={{ display: "flex" }}>
                <Button
                    style={{ margin: "0.12rem" }}
                    type="primary"
                    onClick={handleDownLoad}
                >
                    Download Sheet
                </Button>

                <Upload
                    fileList={fileList}
                    onChange={handleUpload}
                    onRemove={handleRemove}
                    loading={loading}
                    customRequest={dummyRequest}
                    accept=".xlsx, .csv" 
                >
                    <Button><BiCloudUpload />Upload Sheet</Button>
                </Upload>

            </div>
        </DownloadWrapper>
    )
}

export default DownloadSheet