import React from 'react';
import
{
	FaFacebook,
	FaInstagram,
	FaTwitter,
	FaLinkedin,
	FaYoutube
} from 'react-icons/fa';
const Social = () =>
{
	const handleLinkClick = (event, url) =>
	{
		event.preventDefault();
		window.open(url, '_blank', 'noopener noreferrer');
	}
	return <div className="ltn__social-media">
		<ul>
			<li>
				<a href="https://www.facebook.com/Bookmybestclass?mibextid=ZbWKwL" title="Facebook" onClick={(event) => handleLinkClick(event, "https://www.facebook.com/Bookmybestclass?mibextid=ZbWKwL")}>
					<FaFacebook style={{ color: "#ffff" }} />
				</a>
			</li>
			<li>
				<a href="https://twitter.com/bmbc23" title="Twitter" onClick={(event) => handleLinkClick(event, "https://twitter.com/bmbc23")}>
					<FaTwitter style={{ color: "#ffff" }} />
				</a>
			</li>
			<li>
				<a href="https://www.instagram.com/bmbc667/?igshid=MzRlODBiNWFlZA%3D%3D" title="Instagram" onClick={(event) => handleLinkClick(event, "https://www.instagram.com/bmbc667/?igshid=MzRlODBiNWFlZA%3D%3D")}>
					<FaInstagram style={{ color: "#ffff" }} />
				</a>
			</li>
			<li>
				<a href="https://www.linkedin.com/company/bookyourclasss/" title="Linkedin" onClick={(event) => handleLinkClick(event, "https://www.linkedin.com/company/bookyourclasss/")}>
					<FaLinkedin style={{ color: "#ffff" }} />
				</a>
			</li>
			<li>
				<a href="https://www.youtube.com/channel/UCPU9Xm4hMVEy-WsbZimQbyQ" title="Youtube" onClick={(event) => handleLinkClick(event, "https://www.youtube.com/channel/UCPU9Xm4hMVEy-WsbZimQbyQ")}>
					<FaYoutube style={{ color: "#ffff" }} />
				</a>
			</li>
		</ul>
	</div>

}
export default Social