import React, { useState, useEffect } from 'react';
import { Button, Table, message, Modal, Tag } from 'antd';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import TopNav from "../../topnavComponent";
import AuthGuard from "../../authGuards";
import SearchComponent from "../../search";
import adminService from "../../../../common/adminService";
import moment from "moment";

const InstKeywordManagementWrapper = styled.div`
  padding: 20px;
`;

const InstKeywordManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const InstKeywordManagementCreateButton = styled(Button)`
  float: right;
`;

const InstKeywordManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;
const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
function InstKeywordManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  useEffect(() =>
  {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) =>
  {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };
  const fetch = (params = {}) =>
  {
    setLoading(true);
    adminService.getAllInstituteKeywords().then((apiResponse) =>
    {
      const result = apiResponse.data.data
      let instituteKeywordList = result.map((obj) =>
      {
        const endDate = new Date(obj.endDate);
        const momentEndDate = moment(endDate);
        const momentNowDate = moment();
        let statusFront;
        if (momentNowDate.isAfter(momentEndDate, 'day'))
        {
          statusFront = 'Ended';
        } else
        {
          statusFront = 'Active';
        }

        return {
          ...obj,
          statusFront,
        };
      });
      const paginationObj = { ...pagination };
      paginationObj.total = instituteKeywordList.length;
      setLoading(false);
      console.log(instituteKeywordList);
      setData(instituteKeywordList);
      setPagination(paginationObj);
    });

  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [instituteIdToDelete, setInstituteIdToDelete] = useState(null);
  const [rawData, setRawData] = useState(null);

  const showDeleteModal = (instituteId, data) =>
  {
    setRawData(data)
    setInstituteIdToDelete(instituteId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteInstitute = async () =>
  {
    try
    {
      const deleteInstituteResponse = await adminService.deleteInstitute(
        instituteIdToDelete
      );
      if (deleteInstituteResponse.data.success === true)
      {
        const logData = { body: `Institute ${rawData.name} deleted`, url: 'institute/delete' }
        const createLog = await adminService.addLog(logData);
        if (createLog.data.success === true)
        {
          message.success(deleteInstituteResponse.data.data);
        }

      } else
      {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      setRawData(null)
      fetch();
    } catch (error)
    {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () =>
  {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword.name",
    },
    {
      title: "Institute",
      dataIndex: "instituteId",
      render: (instituteId) => (
        <>
          {instituteId.map((ele) => (
            <Tag key={ele._id} color='darkblue'>{ele.name} </Tag>
          ))}
        </>
      ),

      width: "30%"

    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) =>
      {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "statusFront",
      render: (value) =>
      {
        if (value === "Ended")
        {
          return (
            <p className="bg-danger text-white text-center p-1">{value}</p>
          );
        } else
        {
          return (
            <p className="bg-success text-white text-center p-1">{value}</p>
          );
        }
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editInsKeywords/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editInsKeywords/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleSearch = (searchTerm) =>
  {
    const filteredData = data.filter(
      (item) =>
      (
        item.keyword.name &&
        item.keyword.name.toLowerCase().includes(searchTerm.toLowerCase())
      )

    );
    if (filteredData.length === 0) { message.warn('No Data Found') }
    console.log(filteredData);
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <InstKeywordManagementWrapper>
        <InstKeywordManagementHeader>
          <h2>Keyword for Institutes</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <InstKeywordManagementCreateButton type="primary">
              <Link to="/admin/createInsKeywords">Create</Link>
            </InstKeywordManagementCreateButton>
          )}
        </InstKeywordManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <InstKeywordManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />
        <Modal
          title="Delete Institute Keyword"
          visible={isDeleteModalOpen}
          onOk={handleDeleteInstitute}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this keyword?</p>
        </Modal>
      </InstKeywordManagementWrapper>
    </>
  );


}
export default InstKeywordManagement;
