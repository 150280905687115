import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, message, Spin, Select } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";
import AuthGuard from "../authGuards";

const EditAdminUserWrapper = styled.div`
  padding: 20px;
`;

const EditAdminUserHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditAdminUserButton = styled(Button)`
  float: right;
`;

const Option = Select.Option;

function EditAdminUser(props) {
  const [loading, setLoading] = useState(false);
  // const [adminUser, setAdminUser] = useState({});
  const { id } = useParams();

  const adminTypeList = [
    { title: "View", key: "view" },
    { title: "Edit", key: "edit" },
    { title: "Delete", key: "delete" },
  ];

  const accessRightsList = [
    { title: "Dashboard", key: "dashboard" },
    { title: "Users", key: "user" },
    { title: "Subscribed Users", key: "subscribedUsers" },
    { title: "Institute", key: "institute" },
    { title: "Category", key: "category" },
    { title: "Plan", key: "plan" },
    { title: "Batch", key: "batch" },
    { title: "Exam", key: "exam" },
    { title: "Location", key: "location" },
    { title: "Branch", key: "branch" },
    { title: "Offer", key: "offer" },
    { title: "Blog", key: "blog" },
    { title: "Help Center", key: "helpCenter" },
    { title: "Institute Rating", key: "instituteRating" },
    { title: "Language", key: "language" },
    { title: "Facilities", key: "facilities" },
    { title: "News", key: "news" },
    { title : "Keywords Creation", key:"keywords"},
    { title: "Batch Keywords", key: "batchKeywords" },
    { title: "Institute Keywords", key: "instituteKeywords" },
    { title: "Ads Management", key: "instituteAds" },
    { title: "Notification Management", key: "notificationPopup" } 


  ];

  useEffect(() => {
    const fetchAdminUser = async () => {
      setLoading(true);
      const response = await adminService.getAllAdmins();
      const admin = response.data.data.find((item) => item._id === id);
      admin.password = null;
      const data = admin;
      // setAdminUser(data);
      props.form.setFieldsValue({ ...data });
      setLoading(false);
    };
    fetchAdminUser();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const updateAdminUser = await adminService.updateAdminUser(id, values);
        setLoading(false);
        if (updateAdminUser.data.success === true) {
          message.success("Admin User Successfully Updated");
          props.history.push("/admin/adminUsers");
        } else {
          message.error("Error in updating admin user");
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <>
      <AuthGuard />
      <EditAdminUserWrapper>
        <EditAdminUserHeader>
          <h2>Edit Admin User</h2>
          <EditAdminUserButton
            onClick={() => props.history.push("/admin/adminUsers")}
          >
            Cancel
          </EditAdminUserButton>
        </EditAdminUserHeader>
        <Spin spinning={loading}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "Please enter name" }],
                  })(<Input placeholder="Please enter name" disabled />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [{ required: true, message: "Please enter email" }],
                  })(<Input placeholder="Please enter email" disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Password">
                  {getFieldDecorator("password", {
                    rules: [
                      { required: false, message: "Please enter password" },
                    ],
                  })(<Input.Password placeholder="Please enter password" />)}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Admin Type">
                  {getFieldDecorator("adminType", {
                    rules: [
                      { required: true, message: "Please enter admin type" },
                    ],
                  })(
                    <Select
                      className="input-select"
                      placeholder="please select admin Type"
                      style={{
                        width: "100%",
                      }}
                    >
                      {adminTypeList &&
                        adminTypeList.map((d) => (
                          <Option value={d.key}>{d.title}</Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Access Rights">
                  {getFieldDecorator("accessRights", {
                    rules: [
                      { required: true, message: "Please enter access right" },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      className="input-select"
                      placeholder="please select admin Type"
                      style={{
                        width: "100%",
                      }}
                    >
                      {accessRightsList &&
                        accessRightsList.map((d) => (
                          <Option value={d.key}>{d.title}</Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </EditAdminUserWrapper>
    </>
  );
}

export default Form.create()(EditAdminUser);
