import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import DownloadSheet from "../download";
import axios from "axios";
import ConfirmPopup from "./confirmPopup";

const NotificationManagementWrapper = styled.div`
  padding: 20px;
`;

const NotificationManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const NotificationButton = styled.div`
display:flex;
align-items:center;
`
const NotificationManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
  display:flex;
  margin-bottom:2rem;
  justify-content:space-between;
`;

function NotificationManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [adminType, setAdminType] = useState("");
  const apiURL = process.env.REACT_APP_API_URL_LIVE;
//   const [rawData, setRawData] = useState(null);

  useEffect(() =>
  {
    const type = sessionStorage.getItem("adminType");
    setAdminType(type);
     // eslint-disable-next-line
  }, [])
  useEffect(() =>
  {
    fetch();
    // eslint-disable-next-line
  }, []);

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.getAllNotificationsData().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };
  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
      {
        return(
          item.course.toLowerCase().includes(searchTerm.toLowerCase())
  
        )

      }
     
        
        
    );
    if(filteredData.length === 0){message.warn('No Data Found')}
    setFilteredData(filteredData);
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  // const testURL = process.env.REACT_APP_API_URL_TEST
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const handleDownload = async () =>
  {
    setDownloadModalVisible(true);
  }

  const handleConfirmDownload = async () =>
  {
    const url = apiURL + `popup/download/1`;
    setLoading(true)
    try
    {
      const res = await axios.get(url);
      if (res)
      {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        message.success("Sheet downloaded successfully");
        document.body.removeChild(link);
        setLoading(false)
      }
    } catch (error)
    {
      console.log(error);
    }

  }

  const handleUpload = async (fileList, setFileList) =>
  {
    setLoading(true);
    const url = apiURL + `popup/upload`;
    const file = fileList[0];
    if (file)
    {
      try
      {
        const formData = new FormData();
        formData.append('file', file.originFileObj);
        message.success("Please wait, it takes few minutes");
        const res = await axios.post(url, formData);
        if (res.data.success === true)
        {
          message.success("Sheet uploaded successfully");
          setFileList(null);
          setLoading(false);
        } else
        {
          message.error("Sheet upload failed");
        }
      } catch (error)
      {
        console.error(error);
        message.error("An error occurred while uploading the sheet");
        setLoading(false);
      }
    }

  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "City", 
      dataIndex : "location"
    },
    {
      title:"Courses",
      dataIndex:"course"

    },
    {
      title:"Time",
      dataIndex:"time"

    }
  ]


  return (
    <>
      <AuthGuard />
      <TopNav />
      <NotificationManagementWrapper>
        <NotificationManagementHeader>
          <h2>Notification Management</h2>
          
        </NotificationManagementHeader>
        <SearchWrapper>
          <NotificationButton>

            <DownloadSheet onDownload={handleDownload} onUpload={handleUpload} loading={loading} />
            <SearchComponent data={data} onSearch={handleSearch} />

          </NotificationButton>

        </SearchWrapper>
        <NotificationManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />


        <ConfirmPopup
          visible={downloadModalVisible}
          loading={loading}
          onConfirm={handleConfirmDownload}
          onCancel={() => setDownloadModalVisible(false)}
          title="Confirm Download"
          content="Are you sure you want to download?"
        />

      </NotificationManagementWrapper>
    </>
  );


}
export default NotificationManagement;
