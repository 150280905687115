import React, { useState, useEffect } from "react";
import { Button, Table, message } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const LocationManagementWrapper = styled.div`
  padding: 20px;
`;

const LocationManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const LocationManagementCreateButton = styled(Button)`
  float: right;
`;

const LocationManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const LocationManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const LocationManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function LocationManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.getAllLocations().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const deleteLocation = async (id, data) => {
    const deleteLoc = await adminService.deleteLocation(id);
    if (deleteLoc.data.success === true) {
      const logData = {body:  `Location ${data.city} deleted`, url: 'location/delete' }
      const createLog = await adminService.addLog(logData)
      if (createLog.data.success === true)
      {
        message.success(deleteLoc.data.data);
      }

    } else {
      message.warn("An error occured please try again");
    }
    fetch();
  };

  const columns = [
    {
      title: "Head Office",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editLocation/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => deleteLocation(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editLocation/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        (item.city &&
          item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.state &&
          item.state.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    if(filteredData.length === 0){message.warn('No Data Found')}
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <LocationManagementWrapper>
        <LocationManagementHeader>
          <h2>Location Management</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <LocationManagementCreateButton type="primary">
              <Link to="/admin/createLocation">Create</Link>
            </LocationManagementCreateButton>
          )}
        </LocationManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <LocationManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />
      </LocationManagementWrapper>
    </>
  );
}

export default LocationManagement;
