import React, { useState, useEffect } from "react";
import { Table,  Button, Modal, Row, Col ,message} from "antd";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";
import { diff } from 'deep-object-diff';

const LogListWrapper = styled.div`
  padding: 20px;
`;

const LogListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

// const LogListCreateButton = styled(Button)`
//   float: right;
// `;

const LogListTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function LogListComponent() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showDataModal, setShowDataModal] = useState(false);
  // const [rawData, setRawData] = useState(null);
  const [difference, setDifference] = useState(null);
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };
  const handleSearch = (searchTerm) => {
    console.log("searchTerm", searchTerm);
    console.log("data", data);
    const filteredData = data.filter((item) =>
      item.body?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log("Filtered Data:", filteredData);
    if (filteredData.length === 0) {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    adminService.getAllLogs().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const getDifferences = (data) => {
    const oldData = data?.oldData || {};
    const newData = data?.newData || {};

    const differences = diff(oldData, newData);

    return differences;
  };

  const showUpdateModal = (entity) => {
    const diff = getDifferences(entity)
    delete diff.updatedAt;
    if(diff.startDate){
      const date = moment(diff.startDate).format("MMMM Do, YYYY")
      diff.startDate = date
    }
    if(diff.endDate){
      const date = moment(diff.endDate).format("MMMM Do, YYYY")
      diff.endDate = date
    }
    setDifference(diff)
    // setRawData(entity);
    setShowDataModal(true);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Accessed URL",
      dataIndex: "url",
    },
    {
      title: "Message",
      dataIndex: "body",
    },
    {
      title: "Data Change",
      dataIndex: "oldData",
      render: (oldData, data) => {
        return data.url.includes("update") ? (
          <Button onClick={() => showUpdateModal(data)} type="primary">
            {" "}
            View{" "}
          </Button>
        ) : (
          "No Update"
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt, data) => {
        return <span>{moment(createdAt).format("MMMM Do, YYYY h:mm A")}</span>;
      },
    },
  ];

  return (
    <>
      <AuthGuard />
      <TopNav />
      <LogListWrapper>
        <LogListHeader>
          <h2>Logs List</h2>
          <Button onClick={() => fetchData()} type="primary">
            Refresh
          </Button>
        </LogListHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <LogListTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Data Update"
          visible={showDataModal}
          onCancel={() => setShowDataModal(false)}
          footer={null}
          width={800}
        >
          <Row gutter={[16, 16]}>
            {/* <Col span={12}>
              <h4>Old Data</h4>
              <pre>{JSON.stringify(rawData?.oldData, null, 2)}</pre>
            </Col>

            <Col span={12}>
              <h4>New Data</h4>
              <pre>{JSON.stringify(rawData?.newData, null, 2)}</pre>
            </Col> */}
            <Col span={12}>
              <h4>Data Difference</h4>
              <pre>{JSON.stringify(difference, null, 2)}</pre>
            </Col>
          </Row>
        </Modal>
      </LogListWrapper>
    </>
  );
}

export default LogListComponent;
