import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import axios from 'axios';
import "../assets/css/instituteBatchDetails.css";
import "../assets/css/badge.css"
import { FaStar } from 'react-icons/fa';
import { Link } from "react-router-dom";
import moment from 'moment';
const InstituteBatch = ({ instituteId }) =>
{
    const [instituteBatchDetail, setInstituteBatchDetail] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const testURL = process.env.REACT_APP_API_URL_LIVE

    const showModal = () =>
    {
        setIsModalOpen(true);
    };

    const handleCancel = () =>
    {
        setIsModalOpen(false);
    };

    const getInstituteDetail = async () =>
    {
        const rawData = await axios.get(testURL + `batch/list?instituteId=${instituteId}`)
        const data = rawData.data.data;

        let instituteBatchDetail = data.map((obj) =>
        {
            const startDate = new Date(obj.startDate);
            const endDate = new Date(obj.endDate);
            const momentStartDate = moment(startDate);
            const momentEndDate = moment(endDate);
            const momentNowDate = moment();
            let status;

            if (momentNowDate.isAfter(momentEndDate))
            {
                status = 'Ended';
            } else if (!momentNowDate.isAfter(momentStartDate))
            {
                status = 'Upcoming';
            } else
            {
                status = 'Ongoing';
            }

            return {
                ...obj,
                status,
            };
        }).filter(obj => obj.status !== 'Ended');
        setInstituteBatchDetail(instituteBatchDetail);


    };

    useEffect(() =>
    {
        getInstituteDetail();
        // eslint-disable-next-line
    }, [])


    return (
        <>

            <button type="primary" className="btn pay_now_btn" onClick={showModal}>
                View Batches
            </button>
            <Modal
                title="They Provide classes for these exam"
                visible={isModalOpen}
                onCancel={handleCancel}
                // width={{ lg: 600, sm: '100%' }}
                footer={[

                ]}
                className='inst_btch_details_modal'
            >
                <div className="container institute_batch_detail">
                    <div className='row'>
                        {
                            instituteBatchDetail?.length > 0 ?
                                (
                                    instituteBatchDetail.map((ele) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={ele._id}>
                                            <div className="batch_card_details batch_card_details_inst_pop">
                                                <div className="left_box">
                                                    {ele?.batchKeyword &&
                                                        ele?.keywordStatus !== "Ended" && (
                                                            <h5 className="keyword_tags">
                                                                <p className="badge_btn">
                                                                    {ele.status}
                                                                </p>
                                                                <span>{ele.batchKeyword?.keyword?.name}</span>

                                                            </h5>
                                                        )
                                                    }
                                                    <img className="img-fluid img-responsive rounded product-image" loading="lazy" src={ele?.instituteId?.image} alt={ele.instituteId.image} />
                                                    {
                                                        ele.planName?.title && (
                                                            <h5 className="marquee_tags">
                                                                {/* <p>Batch Type</p> */}
                                                                <span>{ele.planName?.title}</span>
                                                            </h5>
                                                        )
                                                    }
                                                </div>
                                                <div className="center_box">
                                                    <div className="center_details">
                                                        <h5 className="my-0">
                                                            <span>
                                                                {ele.examId?.name}
                                                            </span>
                                                        </h5>
                                                        <h4>
                                                            <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span><FaStar />
                                                        </h4>
                                                        <span className="mob_category">{ele?.category}</span>
                                                    </div>
                                                    <h5 className="desk_inst">
                                                        <span>Institute :</span>
                                                        <span>{ele.instituteId?.name?.slice(0, 20) + ".."}</span>
                                                    </h5>
                                                    <h5 className="mob_inst">
                                                        <span>{ele.instituteId?.name?.slice(0, 20) + ".."}</span>
                                                    </h5>
                                                    <h5 className="mob_batch">
                                                        <span>Batch No:</span>
                                                        <span>{ele?.batchName}</span>
                                                    </h5>
                                                    {ele?.examId.name === "GATE" &&
                                                        <h5 className="mob_batch">
                                                            <span>Branch :</span>
                                                            <Link to={`/batch-detail/${ele._id}`}>
                                                                {ele.branchName?.length > 0 && (
                                                                    <>
                                                                        {ele.branchName.slice(0, 4).map((branch, index) =>
                                                                        {
                                                                            const truncatedName = branch.name;

                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <span
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                    >
                                                                                        {truncatedName}
                                                                                    </span>
                                                                                    {index < ele.branchName.length - 1 && <span>, </span>}
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}
                                                            </Link>
                                                        </h5>
                                                    }
                                                    <h5 className="mob_batch">
                                                        <span>Language :</span>
                                                        <span style={{ color: "#2292ca" }}>
                                                            {ele?.languages?.name}
                                                        </span>
                                                    </h5>
                                                    {(ele.category === "Offline" || ele.category === "Offline Test Series") &&
                                                        <h5 className="mob_batch">
                                                            {(ele.category === "Offline" || ele.category === "Offline Test Series") && <span>City :</span>}
                                                            {(ele.category === "Offline" || ele.category === "Offline Test Series") && <span>{ele.city}</span>}
                                                        </h5>
                                                    }

                                                    <h3 className="mob_price">
                                                        <span>Price :</span>
                                                        <span style={{ color: "#2292ca" }}>
                                                            {ele.amount.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            }).split('.')[0]}
                                                            <span className="incl_gst">incl. GST</span>
                                                        </span>
                                                    </h3>
                                                    <h5 className="desk_btch_number">
                                                        <span>Batch No :</span>
                                                        <span>
                                                            {ele.batchName}
                                                        </span>
                                                    </h5>
                                                    {/* <h5 className="desk_strt_dt">
                                                        <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                                                    </h5> */}
                                                </div>
                                                <div className="right_box">
                                                    <h5>
                                                        <span>Price :</span>
                                                        <span style={{ color: "#2292ca" }}>
                                                            {ele.amount.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            }).split('.')[0]}
                                                            <span className="incl_gst">incl. GST</span>
                                                        </span>
                                                    </h5>
                                                    {ele?.examId.name === "GATE" && <h5>
                                                        <span>Branch :</span>
                                                        <Link to={`/batch-detail/${ele._id}`}>
                                                            {ele.branchName?.length > 0 && (
                                                                <>
                                                                    {ele.branchName.slice(0, 4).map((branch, index) =>
                                                                    {
                                                                        const truncatedName = branch.name;
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    {truncatedName}
                                                                                </span>
                                                                                {index < ele.branchName.length - 1 && <span>, </span>}
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                        </Link>
                                                    </h5>

                                                    }
                                                    <h5>
                                                        <span>Language :</span>
                                                        <span style={{ color: "#2292ca" }}>
                                                            {ele?.languages?.name}
                                                        </span>
                                                    </h5>
                                                    <h5>
                                                        <span>{ele?.category}</span>
                                                        {
                                                            (ele.category === "Offline" || ele.category === "Offline Test Series") && <p className="city">{ele?.city}</p>
                                                        }
                                                    </h5>
                                                </div>
                                                <div className="see_btn_wrap_batch">
                                                    <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary view_al_btch w-100" type="button">View Details</Link>
                                                </div>
                                                {

                                                    !ele?.batchKeyword && ele?.keywordStatus !== "Active" &&
                                                    (
                                                        <p className="badge_btn2">
                                                            {ele.status}
                                                        </p>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    )))
                                :
                                "No batch persent for this institute"
                        }
                    </div>
                </div>
            </Modal>


        </>

    )
}

export default InstituteBatch