import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import TopNav from "../topnavComponent";
import AuthGuard from "../../authGuards";
// import SearchComponent from "../search";
import adminService from "../../../../common/adminService";
import
{
  Select,
  message,
  Button,
  Form,
  DatePicker,
  Row,
  Col
} from "antd";
import moment from "moment";


const CreateKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
   border: none;
   height: 100%;
   width: 100%;
   align-items: center;
   display: grid;
  }

  .ant-select-arrow {
    display: none
  }
`;

const CreateKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateKeywordButton = styled(Button)`
  float: right;
`;

function CreateBatchKeywords(props)
{
  const [loading, setLoading] = useState(false);
  const [batchList, setBatchList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywordList, setKeywordList] = useState([]);
  const [batchName, setBatchName] = useState(null)
  const Option = Select.Option;
  useEffect(() =>
  {
    listBatch();
    listKeyword();
    // eslint-disable-next-line
  }, []);
  const listBatch = async () =>
  {
    const rawData = await adminService.getAllBatches();
    setBatchList(rawData.data.data);
    setBatchName(rawData.data.data);
    // console.log(rawData.data.data);
  };

  const listKeyword = async () =>
  {
    const rawData = await adminService.getAllKeywords();
    setKeywordList(rawData.data.data);
  }
  const handleSubmit = (e) =>
  {
    e.preventDefault();

    props.form.validateFields(async (err, values) =>
    {
      // console.log(values);
      if (!err)
      {
        setLoading(true);
        props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
        const statusEndDate = moment(values.endDate);
        const statusNowDate = moment();
        let statusDate;
        if (statusNowDate.isAfter(statusEndDate))
        {
          statusDate = 'Ended';
        } else
        {
          statusDate = 'Active';
        }

        // console.log(values.batchId?.join(",") ?? "");
        // console.log(typeof(Object.values(values.batchId)));
        setLoading(true);
        const createKeyword = await adminService.addBatchKeyword(values, statusDate);
        const batchByName = batchName?.filter(item => values.batchId.includes(item._id));
        const batchValues = batchByName?.map(item => item.batchName);
        setLoading(false);
        // console.log(values);
        if (createKeyword.data.success === true)
        {
          const logData = { body: `Batch ${batchValues && (batchValues?.join(', '))} Keyword created`, url: 'batchkeyword/add' }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("Keyword Successfully Created");
          };

          props.form.resetFields();
          props.history.push("/admin/batchKeywords");
        } else
        {
          message.error("Error in creating keyword");
        }
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <>
      <AuthGuard />
      <CreateKeywordWrapper>
        <CreateKeywordHeader>
          <h2>Create Batch Keyword</h2>
          <CreateKeywordButton onClick={() => props.history.push("/admin/batchKeywords")}>
            Cancel
          </CreateKeywordButton>
        </CreateKeywordHeader>
        <Form onSubmit={handleSubmit} className="login-form">

          <Form.Item label="Keyword">
            {getFieldDecorator("keyword", {
              rules: [{ required: true, message: "please enter" }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                className="input-select"
                placeholder="Please Select"
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
              >
                {keywordList &&
                  keywordList.map((d) => (
                    <Option key={d._id} value={d._id}>{d.name}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Batch">
            {getFieldDecorator("batchId", {
              rules: [{ required: true, message: "please enter" }],
            })(
              <Select
                mode="multiple"
                className="input-select"
                placeholder="Please Select"
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
              >
                {batchList &&
                  batchList.map((d) => (
                    <Option key={d._id} value={d._id}>{d.batchName}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Row gutter={16}>

              <Col span={12}>
                <Form.Item label="Start Date">
                  {getFieldDecorator("startDate", {
                    rules: [{ required: true, message: "please enter start date" }],
                  })(
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      onChange={(date, dateString) => setStartDate(dateString)}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="End Date">
                  {getFieldDecorator("endDate", {
                    rules: [{ required: true, message: "please enter end date" }],
                  })(
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      onChange={(date, dateString) => setEndDate(dateString)}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </CreateKeywordWrapper>
    </>
  );
}

export default Form.create()(CreateBatchKeywords);

