import React, { useState } from "react";
import styled from "styled-components";
import { Col, Input, Button } from "antd";

// const { Search } = Input;

const Wrapper = styled.div`
  .input {
    height: 36px !important;
    min-width:200px;
  }
`;

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <Wrapper>
      <Col span={6}>
        <div style={{ display: "flex" }}>
          <Input
            className="input mb-0"
            placeholder="Enter a search term"
            value={searchTerm}
            onChange={handleInputChange}
          />
          <Button
            style={{ margin: "0.12rem" }}
            type="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{ margin: "0.12rem" }}
            type="danger"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </Col>
    </Wrapper>
  );
};

export default SearchComponent;
