import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  message
} from "antd";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";

const FaciltiyCreateWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const FaciltiyCreateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FaciltiyCreateButton = styled(Button)`
  float: right;
`;

function CreateFacility(props) {



  const handleSubmit = (e) => {
    e.preventDefault();
      props.form.validateFields(async (err, values) => {
        if (!err) {
          const createInstitute = await adminService.addFacility(values);
          if (createInstitute.data.success === true) {
            const logData = {body:  `Facility ${values.name} created`, url: 'facility/create' }
            const createLog = await adminService.addLog(logData)
            if (createLog.data.success === true)
            {
              message.success("Facility Successfully Created");
            };
          
            props.form.resetFields();
            props.history.push("/admin/facilities");
          }
        }
      });
  };

  const { getFieldDecorator } = props.form;
  return (
    <>
    <AuthGuard />
    <FaciltiyCreateWrapper>
      <FaciltiyCreateHeader>
        <h2>Create Facility</h2>
        <FaciltiyCreateButton
          onClick={() => props.history.push("/admin/facilities")}
        >
          Cancel
        </FaciltiyCreateButton>
      </FaciltiyCreateHeader>
        <Form onSubmit={handleSubmit} className="login-form">
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Name">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "please enter name" }],
                })(<Input placeholder="please enter name" />)}
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12}>
                <Form.Item label="Info">
                  {getFieldDecorator("info", {
                    rules: [{ required: true, message: "please enter info" }],
                  })(<Input placeholder="please enter info" />)}
                </Form.Item>
            </Col> */}
            </Row>
            
          {/* <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Info">
                {getFieldDecorator("info", {
                  rules: [{ required: true, message: "please enter info" }],
                })(<Input placeholder="please enter info" />)}
              </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Exam">
                  {getFieldDecorator("exam", {
                    rules: [{ required: true, message: "please enter exam" }],
                  })(
                    <Select
                      mode="multiple"
                      className="input-select"
                      placeholder="please enter exam"
                      style={{
                        width: "100%",
                      }}
                    >
                      {examList &&
                        examList.map((d) => (
                          <Option value={d._id}>
                            {d.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col span={12}>
              <Upload
                customRequest={dummyRequest}
                listType="picture"
                maxCount={1}
                onChange={handleUpload}
              >
                <Button icon={<UploadOutline />}>Upload (Max: 1)</Button>
              </Upload>
            </Col>
          </Row> */}

          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
    </FaciltiyCreateWrapper>
    </>
  );
}

export default withRouter(Form.create()(CreateFacility));
