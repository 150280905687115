import React, { useState } from 'react';
import { Upload, Button, List, message ,  Modal} from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import { PlusOutlined } from '@ant-design/icons';

// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
const InstituteThumbnailManagement = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleUpload = (options) => {
    const { file, onSuccess, onError } = options;
    
    // Simulate an asynchronous upload operation (you would replace this with your backend logic).
    setTimeout(() => {
      // Upload failed
      if (Math.random() < 0.2) {
        onError('Upload failed');
      } else {
        // Upload succeeded
        onSuccess();
        setFileList([...fileList, { uid: file.uid, name: file.name }]);
        message.success('Image uploaded successfully.');
      }
    }, 1000);
  };

  const onRemove = (file) => {
    const newFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(newFileList);
  };

  // const handleCancel = () => setPreviewOpen(false);
  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };
  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  // const uploadButton = (
  //   <div>
  //     {/* <PlusOutlined /> */}
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );
  return (
    <>
    <h1>HII</h1>
      {/* <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal> */}
      <div>
      <Upload
        customRequest={handleUpload}
        showUploadList={false}
      >
        <Button >Upload Thumbnail</Button>
      </Upload>
      <List
        dataSource={fileList}
        renderItem={item => (
          <List.Item
            actions={[<Button danger onClick={() => onRemove(item)}>Remove</Button>]}
          >
            {item.name}
          </List.Item>
        )}
      />
    </div>
    </>
  );
};
export default InstituteThumbnailManagement;
