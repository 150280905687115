import React, { useState, useEffect } from "react";
import
{
    Button,
    Form,
    Input,
    message,
    Row,
    Col,
    DatePicker,
    Select,
} from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";
import { useHistory } from "react-router-dom";
import moment from "moment";

const CreatePromoWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const CreatePromoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreatePromoButton = styled(Button)`
  float: right;
`;

function CreatePromo(props)
{
    const [loading, setLoading] = useState(false);
    const [instituteList, setInstituteList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [instituteData, setInstituteData] = useState([]);
    const history = useHistory();

    const Option = Select.Option;
    useEffect(() =>
    {
        listInstitute();
        // eslint-disable-next-line
    }, []);


    const listInstitute = async () =>
    {
        const rawData = await adminService.getAllInstitutes();
        setInstituteList(rawData.data.data);
    };

    const getInstituteDetail = async (id) =>
    {
        const data = await adminService.getInstituteById(id)
        setInstituteData(data.data.data)
    }



    const handleSubmit = (e) =>
    {
        e.preventDefault();

        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {
                setLoading(true);
                props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
                const statusEndDate = moment(values.endDate);
                const statusNowDate = moment();
                let statusDate;
                if (statusNowDate.isAfter(statusEndDate))
                {
                    statusDate = 'Ended';
                } else
                {
                    statusDate = 'Active';
                }


                const createPromo = await adminService.addPromo(values, statusDate);
                if (createPromo.data.success === true)
                {
                    const logData = { body: `Promo ${values.name} created`, url: 'promo/add' }
                    const createLog = await adminService.addLog(logData);
                    if (createLog.data.success === true)
                    {
                        message.success("Promo Successfully Created");
                    };
                
                    props.form.resetFields();
                    setLoading(false);
                    history.push("/admin/promo");
                } else
                {
                    message.error("Error in creating batch");
                    setLoading(false);
                }
            }
        });

    };

    const { getFieldDecorator } = props.form;
    return (
        <>
            <AuthGuard />
            <CreatePromoWrapper>
                <CreatePromoHeader>
                    <h2>Create Promo</h2>
                    <CreatePromoButton onClick={() => props.history.push("/admin/promo")}>
                        Cancel
                    </CreatePromoButton>
                </CreatePromoHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Promo Name">
                                {getFieldDecorator("name", {
                                    rules: [{ required: true, message: "please enter name" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Discount Percent">
                                {getFieldDecorator("discountPercent", {
                                    rules: [{ required: true, message: "please enter discount" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item label="Start Date">
                                {getFieldDecorator("startDate", {
                                    rules: [{ required: true, message: "please enter start date" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setStartDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="End Date">
                                {getFieldDecorator("endDate", {
                                    rules: [{ required: true, message: "please enter end date" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setEndDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Description">
                                {getFieldDecorator("description", {
                                    rules: [{ required: true, message: "please enter Description" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Promo type">
                                {getFieldDecorator("type", {
                                    rules: [{ required: true, message: "please enter promo type" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Institute">
                                {getFieldDecorator("instituteId", {
                                    rules: [{ required: true, message: "please enter insitute name" }],
                                })(
                                    <Select
                                        onChange={(e) => getInstituteDetail(e)}
                                        showSearch
                                        optionFilterProp="children"
                                        className="input-select"
                                        placeholder="Please Select"
                                        filterOption={(input, option) =>
                                            (option.props.children ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteList &&
                                            instituteList.map((d) => (
                                                <Option key={d._id} value={d._id}>{d.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="City">
                                {getFieldDecorator("city", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteData?.city?.length > 0 &&
                                            instituteData.city.map((d) => (
                                                <Option key={d} value={d}>{d}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>              
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="Exam">
                                {getFieldDecorator("examId", {
                                    rules: [{ required: true, message: "please enter exam name" }],
                                })(
                                    <Select

                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteData?.examId?.length > 0 &&
                                            instituteData.examId.map((exam) => (
                                                <Option key={exam._id} value={exam._id}>{exam.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                     
                    </Row>
                    {/* <Row>
                    <Col span={12}>
                            <Form.Item label ="Keyword">
                                {getFieldDecorator("keywordId", {
                                    rules:[{ required: false, message : "please assign Keyword" }],
                                })
                                (
                                    <Select
                                    className="input-select"
                                    placeholder="Please Select"
                                    style={{'width' : '100%'}}
                                    >
                                     <Option key={keyword._id} value={keyword._id}>{keyword.name}</Option> 

                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                       
                        <Col span={12} >
                            <Form.Item label = "Batch(Not Mandatory)">
                                <Select
                                mode="multiple"
                                className="input-select"
                                placeholder="Please Select"
                                filterOption={(input, option) =>
                                    (option.props.children ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                style={{'width':'100%'}}
                                >
                                    {instituteData?.examId?.batchId?.length > 0 &&
                                            instituteData.examId.batchId.map((batch) => (
                                                <Option key={batch._id} value={batch._id}>{batch.name}</Option>
                                            ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Row>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Create
                        </Button>
                    </Row>

                </Form>
            </CreatePromoWrapper>
        </>
    );
}

export default Form.create()(CreatePromo);
