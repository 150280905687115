import React, { useState } from "react";
import AuthGuard from "../authGuards";
import { Link } from "react-router-dom";
import TopNav from "../topnavComponent";
import
{
  Button,
  Col,
  Row,
  message,
  Upload,
  Form,
  Input,
} from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { WithContext as ReactTags } from 'react-tag-input';
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";


const BlogPost = (props) =>
{
  const [image, setImage] = useState(null);
  const [metaImage, setMetaImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState('');
  const { id } = useParams();
  // Tags function 
  const handleTagDelete = (i) =>
  {
    const newTags = tags?.slice();
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleTagAddition = (tag) =>
  {
    const newTags = [...tags, tag];
    setTags(newTags);
  };
  const handlePostBlog = async (e) =>
  {
    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      if (!err)
      {
        const formattedTags = tags.map((tag) => tag.text);
        const tagsString = formattedTags.join(' ');
        const createBlog = await adminService.createBlog(id, values, image, metaImage, tagsString)
        if (createBlog.data.success === true)
        {
          const logData = { body: `Blog ${values.heading} posted`, url: 'blog/create' }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("Blog posted Successfully ");
          };
         
          props.history.push("/admin/blog");
        } else
        {
          message.error("Error in posting blog");
        }
      }
      else
      {
        setError("Please enter all fields")
      }
    })
  }

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setBlogMessage({
  //     ...blogMessage,
  //     [name]: value,
  //   });
  // };

  const handleUpload = (info) =>
  {
    if (info.file.status === "done")
    {
      setImage(info.file.originFileObj);
      console.log(info.file.originFileObj);
    }
  };

  const dummyRequest = ({ file, onSuccess }) =>
  {
    setTimeout(() =>
    {
      onSuccess("ok");
    }, 0);
  };

  const handleUploadMetaImage = (info) =>
  {
    if (info.file.status === "done")
    {
      setMetaImage(info.file.originFileObj);
    }
  };

  const dummyRequestMetaImage = ({ file, onSuccess }) =>
  {
    setTimeout(() =>
    {
      onSuccess("ok");
    }, 0);
  };


  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'align',
    'list', 'bullet',
    'link', 'image',
  ];

  const { getFieldDecorator } = props.form;


  return (
    <>
      <AuthGuard />
      <TopNav />
      <section className="blog_wrapper">
        <div className="heading">
          <h2>Post Blog</h2>
          <Link to="/admin/blog" className="btn">
            Cancel
          </Link>
        </div>

        <Form className="blog_post_form" onSubmit={handlePostBlog}>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Heading">
                {getFieldDecorator("heading", {
                  rules: [{ required: true, message: "please enter heading" }],
                })(
                  <Input placeholder="Enter blog heading*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Short Description">
                {getFieldDecorator("description", {
                  rules: [{ required: true, message: "please enter short description" }],
                })(
                  <Input placeholder="Enter short description*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Message">
                {getFieldDecorator("message", {
                  rules: [{ required: true, message: "Please enter message " }],
                })(<ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col span={12}>
              <Form.Item label="Blog Image">
                <Upload
                  customRequest={dummyRequest}
                  listType="picture"
                  maxCount={1}
                  className="form-control"
                  onChange={handleUpload}
                >
                  <Button>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Blog Tags">
                <ReactTags
                  tags={tags}
                  required
                  className="form-control"
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAddition}
                  placeholder="Type tags prefixed with #"
                  delimiters={[32]} // Space character
                  allowDragDrop={false}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Blog Slug / Blog Page URL">
                {getFieldDecorator("metaSlug", {
                  rules: [{ required: true, message: "please enter meta slug" }],
                })(
                  <Input placeholder="Enter meta slug with not including character like ? , ! , : only contain alphabatic charcater" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Blog Category">
                {getFieldDecorator("blogCategory", {
                  rules: [{ required: true, message: "please enter blog category" }],
                })(
                  <Input placeholder="Enter blog category*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta title">
                {getFieldDecorator("metaTitle", {
                  rules: [{ required: true, message: "please enter meta title" }],
                })(
                  <Input placeholder="Enter meta title*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta tags">
                {getFieldDecorator("metaTags", {
                  rules: [{ required: true, message: "please enter meta tags" }],
                })(
                  <Input placeholder="Enter meta keywords*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta Description">
                {getFieldDecorator("metaDescription", {
                  rules: [{ required: true, message: "please enter meta description" }],
                })(
                  <Input placeholder="Enter meta description*" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            <Col>
              <Form.Item label="Meta Image">
                <Upload
                  customRequest={dummyRequestMetaImage}
                  listType="picture"
                  maxCount={1}
                  className="form-control"
                  onChange={handleUploadMetaImage}
                >
                  <Button>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {error && <p className="my-2 error_message_alert d-flex justify-content-start">{error}</p>}
          <button
            className="ant-btn sc-kPTPQs XDFxh ant-btn-primary mt-3"
            type="submit"
          >
            Post
          </button>
        </Form>
      </section>
    </>
  );
};
export default Form.create()(BlogPost)
