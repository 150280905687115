import React from 'react';
import WhyBmbc from './section-components/WhyBmbc';
import TestinomialNew from './section-components/TestinomialNew';
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png";
import Layout from './global-components/Layout';
import { Suspense } from 'react';
import BmbcLogoLoader from './reusable/BmbcLogoLoader';
import { useContext } from 'react';
import { BatchContext } from '../redux/batchContext';
// import News from './news';

const News = React.lazy(() => import('./news'));
const Banner = React.lazy(() => import('./section-components/BannerComp'));
const SearchForm = React.lazy(() => import('./section-components/search-form'));

const Home = () =>
{
    const { loading } = useContext(BatchContext)
    return <div>
        <Helmet>
            <title>Discover the Best Online and Offline Coaching Classes | Find the Nearest Coaching Centre</title>
            <meta name="description" content="Explore our platform to discover the best coaching centers offering comprehensive courses. Find expert guidance from top educators. We help you connect with the best coaching centers near you." />
            <meta name="keywords" content="online coaching classes, best coaching centers, best coaching centre near me, nearest coaching centre" />
            <meta property="og:title" content="Discover the Best Online & Offline Coaching Classes | Find the Nearest Coaching Centre" />
            <meta property="og:description" content="Explore our platform to discover the best coaching centers offering comprehensive courses. Find expert guidance from top educators. We help you connect with the best coaching centers near you." />
            <meta property="og:image" content={logoBmbc} />
            <link rel="canonical" href="https://bookmybestclass.com" />
            <meta property="og:url" content="https://bookmybestclass.com" />
            <meta property="og:site_name" content="bookmybestclass" />
        </Helmet>
        <Suspense fallback={<BmbcLogoLoader loading={loading} />}>
            <Layout>
                <News />
                <Banner />

                <SearchForm />

                <TestinomialNew />
                <WhyBmbc />
            </Layout>
        </Suspense>



    </div>
}

export default Home

