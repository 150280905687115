import React, { useState } from 'react';
import userService from "../../common/userService";
import "../assets/css/review.css";
import { message } from "antd";
import { useHistory } from "react-router-dom";
const ContactUsForm = () => {
    // const apiURL = process.env.REACT_APP_API_URL;
    const [successMessage, setSuccessMessage] = useState('');
    const [alertMsg, setAlertMsg] = useState("")
    const history = useHistory();
    const [formValues, setFormValues] = useState({
        contact: '',
        message: '',
        subject: 'BMBC HELP'
    });
    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (event) =>
    {
        const userActiveId = localStorage.getItem("id");
        if (userActiveId)
        {
            const target = event.target;
            const value = target.value;
            const name = target.name;

            if (name === 'contact' && isNaN(value))
            {
                return;
            }

            setFormValues({
                ...formValues,
                [name]: value,
            });

            setValidationErrors({
                ...validationErrors,
                [name]: '',
            });
        } else
        {
            localStorage.setItem("redirectUrl", window.location.pathname)
            setAlertMsg("Please Login First");
            setTimeout(() =>
            {
                setAlertMsg("")
                history.push("/login");
            }, 1000);
        }
    };

    const requestHelp = async (e) =>
    {
        e.preventDefault();
        const id = localStorage.getItem('id');
        const token = localStorage.getItem('token');


        const errors = {};
        if (formValues.contact.trim() === '')
        {
            errors.contact = 'Please enter phone number.';
        } else if (isNaN(formValues.contact) || formValues.contact.length !== 10)
        {
            errors.contact = 'Please enter a valid 10-digit phone number.';
        }
        if (formValues.message.trim() === '')
        {
            errors.message = 'Please enter a message.';
        }
        if (Object.keys(errors).length > 0)
        {
            setValidationErrors(errors);
            return;
        }
        if (id == null || token == null)
        {
            localStorage.setItem("redirectUrl", window.location.pathname)
            message.error("Please Login First");
            setTimeout(() =>
            {
                history.push("/login");
            }, 1000);
        } else
        {
            const requestHelp = await userService.requestHelp(id, formValues)
            if (requestHelp.data.success === true)
            {
                setFormValues({
                    contact: "",
                    subject: "",
                    message: "",
                });
                setValidationErrors({
                    ...validationErrors,
                    message: "",
                });
                setSuccessMessage('Request Raised Successfully');
                setTimeout(() =>
                {
                    setSuccessMessage("")
                }, 3000);
            } else
            {
                setSuccessMessage('An error occured');

            }

        }

    };


    return (
        <>
            <div className="ltn__contact-message-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__form-box contact-form-box box-shadow white-bg">
                                <form id="contact-form">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-item input-item-email mb-4">
                                                <input
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    value={formValues.contact}
                                                    name="contact"
                                                    className="mb-1"
                                                    placeholder="Enter phone"
                                                />
                                                {validationErrors.contact && <p style={{ color: "red" }} className="validation-message">{validationErrors.contact}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-item input-item-textarea ltn__custom-icon mb-4">
                                        <textarea
                                            onChange={handleInputChange}
                                            name="message"
                                            value={formValues.message}
                                            placeholder="Enter message"
                                            className="mb-1"
                                            defaultValue={""}
                                        />
                                        {validationErrors.message && <p style={{ color: "red" }} className="validation-message">{validationErrors.message}</p>}
                                    </div>
                                    <div className="btn-wrapper mt-0">
                                        {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>}
                                        {
                                            alertMsg && <p className="alert_msg_otp2 mb-0 text-start">{alertMsg}</p>
                                        }
                                        <button
                                            className="btn theme-btn-1 mt-4 btn-effect-1 text-uppercase"
                                            onClick={(e) => requestHelp(e)}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsForm