import { Modal } from 'antd'
import React from 'react'

const ConfirmPopup = ({ visible, onConfirm, onCancel, title, content }) => {
  return (
      <>
          <Modal
              visible={visible}
              title={title}
              onOk={onConfirm}
              onCancel={onCancel}
              okText="Yes"
              cancelText="No"
          >
              {content}
          </Modal>
      </>
  )
}

export default ConfirmPopup