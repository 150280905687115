import React from 'react'
import About from './components/about';
import Faq from './components/faq';
import InstituteDetail from './components/institute-detail';
import Contact from './components/contact';
import MyAccount from './components/my-account';
import Login from './components/login';
import Register from './components/register';
import HelpCenter from './components/help-center';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/home';
import PrivacyPolicy from './components/PrivacyPolicy';
import Termcondition from './components/TermCondition';
import './index.css';
import BatchDetail from './components/BatchDetail';
import BlogPage from './components/BlogPage';
import BlogContent from './components/blog-components/BlogContent';
import "./components/assets/css/alert.css";
import ForgetPassword from './components/ForgetPassword';
import Refund from './components/Refund';
import SearchResult from './components/SearchResult';
import InstituteAllBatches from './components/InstituteAllBatches';
import ViewAllTestSeries from './components/InstituteTestDetails';
import AdminSignin from './components/admin/signin';
import Dashboard from './components/admin/dashboard';
import UserManagement from './components/admin/users/user';
import CreateUser from './components/admin/users/createUser';
import InstituteManagement from './components/admin/institute/institute';
import CreateInstitute from './components/admin/institute/createInstitute';
import EditInstitute from './components/admin/institute/editInstitute';
import CategoryManagement from './components/admin/category/category';
import CreateCategory from './components/admin/category/createCategory';
import EditCategory from './components/admin/category/editCategory';
import PlanManagement from './components/admin/plan/plan';
import CreatePlan from './components/admin/plan/createPlan';
import EditPlan from './components/admin/plan/editPlan';
import BatchManagement from './components/admin/batch/batch';
import CreateBatch from './components/admin/batch/createBatch';
import EditBatch from './components/admin/batch/editBatch';
import ExamManagement from './components/admin/exam/exam';
import CreateExam from './components/admin/exam/createExam';
import EditExam from './components/admin/exam/editExam';
import LocationManagement from './components/admin/location/location';
import CreateLocation from './components/admin/location/createLocation';
import EditLocation from './components/admin/location/editLocation';
import BranchManagement from './components/admin/branch/branch';
import CreateBranch from './components/admin/branch/createBranch';
import EditBranch from './components/admin/branch/editBranch';
import OfferManagement from './components/admin/offer/offer';
import CreateOffer from './components/admin/offer/createOffer';
import EditOffer from './components/admin/offer/editOffer';
import BatchFacility from './components/admin/Facilities/Facility';
import CreateFacility from './components/admin/Facilities/createFacility';
import EditFacility from './components/admin/Facilities/editFacility';
import HelpCenterList from './components/admin/helpCenter/helpCenter';
import InstituteRating from './components/admin/institueRating/institueRating';
import EditInstituteRating from './components/admin/institueRating/editInstituteRating';
import Blog from './components/admin/BlogComponents/Blog';
import BlogPost from './components/admin/BlogComponents/BlogPost';
import EditBlog from './components/admin/BlogComponents/EditBlog';
import BlogDetail from './components/admin/BlogComponents/BlogDetail';
import BlogAllComment from './components/admin/BlogComponents/BlogAllComment';
import SubscribeUser from './components/admin/subscribeUser/subscribeUserList';
import LanguageList from './components/admin/language/languageList';
import CreateLanguage from './components/admin/language/create';
import updateLanguage from './components/admin/language/updateLanguage';
import NewsManagement from './components/admin/news/news';
import createNews from './components/admin/news/createNews';
import editNews from './components/admin/news/editNews';
import PromoManagement from './components/admin/promo/promo';
import createPromo from './components/admin/promo/createPromo';
import editPromo from './components/admin/promo/editPromo';
import UserListComponent from './components/admin/adminUsers/userList';
import CreateAdminUser from './components/admin/adminUsers/createUser'
import EditAdminUser from './components/admin/adminUsers/editUser'
import LogsList from './components/admin/logs/logs';
import EndedBatches from './components/admin/batch/endedBatches';
import CopyBatch from './components/admin/batch/copyBatch';
import UpcomingBatch from './components/admin/batch/upcomingBatch';
import CreateLimit from './components/admin/affordableLimit/createLimit';
import EditLimit from './components/admin/affordableLimit/editLimit';
import LimitManager from './components/admin/affordableLimit/limit';
import Statics from './components/admin/stats/statics';
import BatchKeywordManagement from './components/admin/keyWords/batchKeywords/batchKeywords';
import createBatchKeywords from './components/admin/keyWords/batchKeywords/createBatchKeywords';
import editBatchKeywords from './components/admin/keyWords/batchKeywords/editBatchKeywords';
import InstKeywordManagement from './components/admin/keyWords/instituteKeywords/instituteKeywords';
import createInsKeywords from './components/admin/keyWords/instituteKeywords/createInsKeywords';
import editInsKeywords from './components/admin/keyWords/instituteKeywords/editInsKeywords';
import createKeywords from './components/admin/keyWords/newKeywords/createKeywords';
import editKeywords from './components/admin/keyWords/newKeywords/editKeywords';
import NewKeywordManagement from './components/admin/keyWords/newKeywords/newKeywords';
import HiddenBatches from './components/admin/batch/hiddenBatch';
import OngoingBatch from './components/admin/batch/ongoingBatch';
import BatchThumb from './components/admin/thumbnail/batchThumb';
import AddThumbnail from './components/admin/thumbnail/addThumbNail';
import NotificationManagement from './components/admin/notificationPopup/notificationPopup';
import InstituteThumbnailManagement from './components/admin/instituteThumbnail/instituteThumbnail';

// ADMIN ROUTES END

const Routes = () =>
{
    return (
        <>
            <Router basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/blogs" component={BlogPage} />
                        <Route path="/refund-policy" component={Refund} />
                        <Route path="/search/results/:searchParams?" component={SearchResult} />
                        <Route path="/institute/:instituteId/batches/:searchParams?" component={InstituteAllBatches} />
                        <Route path="/institute/:instituteId/series/:searchParams?" component={ViewAllTestSeries} />
                        <Route path="/search/results/:searchParams?" component={SearchResult} />
                        <Route path="/institute/:instituteId/batches/:searchParams?" component={InstituteAllBatches} />
                        <Route path="/institute/:instituteId/series/:searchParams?" component={ViewAllTestSeries} />
                        <Route path="/institute/:instituteId/batch/:examIdInfo" component={InstituteAllBatches} />
                        <Route path="/institute/:instituteId/test-series/:examIdInfo" component={ViewAllTestSeries} />
                        <Route path="/blog/:cleanHeading" component={BlogContent} />
                        <Route path="/institute-detail/:id" component={InstituteDetail} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/helpCenter" component={HelpCenter} />
                        <Route path="/my-account/profile" component={MyAccount} />
                        <Route path="/login" component={Login} />
                        <Route path="/forget" component={ForgetPassword} />
                        <Route path="/register" component={Register} />
                        <Route path="/term-condition" component={Termcondition} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/batch-detail/:id" component={BatchDetail} />
                        <Route path="/admin/signin" component={AdminSignin} />
                        <Route path="/admin/dashboard" component={Dashboard} />
                        <Route path="/admin/user" component={UserManagement} />
                        <Route path="/admin/createUser" component={CreateUser} />
                        <Route path="/admin/subscribeUser" component={SubscribeUser} />
                        <Route path="/admin/institute" component={InstituteManagement} />
                        <Route path="/admin/createInstitute" component={CreateInstitute} />
                        <Route path="/admin/editInstitute/:id" component={EditInstitute} />
                        <Route path="/admin/category" component={CategoryManagement} />
                        <Route path="/admin/createCategory" component={CreateCategory} />
                        <Route path="/admin/editCategory/:id" component={EditCategory} />
                        <Route path="/admin/blog" component={Blog} />
                        <Route path="/admin/addBlog" component={BlogPost} />
                        <Route path="/admin/editblog/:id" component={EditBlog} />
                        <Route path="/admin/blogdetail/:id" component={BlogDetail} />
                        <Route path="/admin/blogcomment/:id" component={BlogAllComment} />
                        <Route path="/admin/plan" component={PlanManagement} />
                        <Route path="/admin/createPlan" component={CreatePlan} />
                        <Route path="/admin/editPlan/:id" component={EditPlan} />
                        <Route path="/admin/batch" component={BatchManagement} />
                        <Route path="/admin/createBatch" component={CreateBatch} />
                        <Route path="/admin/endedBatch" component={EndedBatches} />
                        <Route path="/admin/upcomingBatch" component={UpcomingBatch} />
                        <Route path="/admin/ongoingBatch" component={OngoingBatch} />
                        <Route path="/admin/hiddenBatch" component={HiddenBatches}/>
                        <Route path="/admin/copyBatch/:id" component={CopyBatch} />
                        <Route path="/admin/editBatch/:id" component={EditBatch} />
                        <Route path="/admin/createLimit" component={CreateLimit} />
                        <Route path="/admin/editLimit/:id" component={EditLimit} />
                        <Route path="/admin/limit" component={LimitManager} />
                        <Route path="/admin/exam" component={ExamManagement} />
                        <Route path="/admin/createExam" component={CreateExam} />
                        <Route path="/admin/editExam/:id" component={EditExam} />
                        <Route path="/admin/location" component={LocationManagement} />
                        <Route path="/admin/createLocation" component={CreateLocation} />
                        <Route path="/admin/editLocation/:id" component={EditLocation} />
                        <Route path="/admin/branch" component={BranchManagement} />
                        <Route path="/admin/createBranch" component={CreateBranch} />
                        <Route path="/admin/editBranch/:id" component={EditBranch} />
                        <Route path="/admin/offer" component={OfferManagement} />
                        <Route path="/admin/createOffer" component={CreateOffer} />
                        <Route path="/admin/editOffer/:id" component={EditOffer} />
                        <Route path="/admin/helpCenter" component={HelpCenterList} />
                        <Route path="/admin/instituteRating" component={InstituteRating} />
                        <Route path="/admin/editInstituteRating/:id" component={EditInstituteRating} />
                        <Route path="admin/instituteThumbnail" component={InstituteThumbnailManagement}/>
                        {/* <Route path="/admin/instituteThumbnail" component={InstituteThumbnail}/> */}
                        <Route path="/admin/facilities" component={BatchFacility} />
                        <Route path="/admin/createFacility" component={CreateFacility} />
                        <Route path="/admin/editFacility/:id" component={EditFacility} />
                        <Route path="/admin/language" component={LanguageList} />
                        <Route path="/admin/createLanguage" component={CreateLanguage} />
                        <Route path="/admin/editLanguage/:id" component={updateLanguage} />

                        <Route path="/admin/notificationPopup" component={NotificationManagement} />


                        <Route path="/admin/news" component={NewsManagement} />
                        <Route path="/admin/createnews" component={createNews} />
                        <Route path="/admin/editnews/:id" component={editNews} />

                        <Route path="/admin/promo" component={PromoManagement} />
                        <Route path="/admin/createpromo" component={createPromo} />
                        <Route path="/admin/editpromo/:id" component={editPromo} />

                        <Route path="/admin/offer" component={OfferManagement} />
                        <Route path="/admin/createOffer" component={CreateOffer} />
                        <Route path="/admin/editOffer/:id" component={EditOffer} />

                        <Route path="/admin/newKeywords" component={NewKeywordManagement} />
                        <Route path="/admin/createKeywords" component={createKeywords} />
                        <Route path="/admin/editKeywords/:id" component={editKeywords} />

                        <Route path="/admin/batchKeywords" component={BatchKeywordManagement} />
                        <Route path="/admin/createBatchKeywords" component={createBatchKeywords} />
                        <Route path="/admin/editBatchKeywords/:id" component={editBatchKeywords} />


                        <Route path="/admin/instituteKeywords" component={InstKeywordManagement} />
                        <Route path="/admin/createInsKeywords" component={createInsKeywords} />
                        <Route path="/admin/editInsKeywords/:id" component={editInsKeywords} />

                        <Route path="/admin/helpCenter" component={HelpCenterList} />

                        <Route path="/admin/instituteRating" component={InstituteRating} />
                        <Route path="/admin/editInstituteRating/:id" component={EditInstituteRating} />

                        <Route path="/admin/adminUsers" component={UserListComponent} />
                        <Route path="/admin/createAdminUser" component={CreateAdminUser} />
                        <Route path="/admin/editAdminUser/:id" component={EditAdminUser} />

                        <Route path="/admin/createThumbnail" component={AddThumbnail} />
                        <Route path="/admin/thumbnail" component={BatchThumb} />

                        <Route path="/admin/status" component={Statics} />

                        <Route path="/admin/logs" component={LogsList} />

                    </Switch>
                </div>
            </Router>
        </>
    )
}

export default Routes