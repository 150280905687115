import React, { useState, useEffect } from "react";
import { Button,  Table, message } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const UserManagementWrapper = styled.div`
  padding: 20px;
`;

const UserManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const UserManagementCreateButton = styled(Button)`
  float: right;
`;

const UserManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const UserManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const UserManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function UserManagement()
{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() =>
  {
    const type = sessionStorage.getItem('adminType')
    // const role = sessionStorage.getItem('adminRole')
    setAdminType(type)
    // setAdminRole(role)
    fetch();
    // eslint-disable-next-line 
  }, []);

  const handleTableChange = async (pagination, filters, sorter) =>
  {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) =>
  {
    setLoading(true);
    adminService.getAllUsers().then((apiResponse) =>
    {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      const subcribeUserData = apiResponse.data.data.filter(obj => obj.subscribe !== "yes")
      setData(subcribeUserData);
      setPagination(paginationObj);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Joined On",
      dataIndex: "createdAt",
      render: (createdAt, data) => {
        return <span>{moment(createdAt).format("MMMM Do, YYYY h:mm A")}</span>;
      },
    },
  ];

  const handleSearch = (searchTerm) =>
  {
    const filteredData = data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if(filteredData.length === 0){message.warn('No Data Found')}
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <UserManagementWrapper>
        <UserManagementHeader>
          <h2>Users</h2>
          {adminType === "delete" &&  
          <UserManagementCreateButton type="primary">
            <Link to="/admin/createUser">Create</Link>
          </UserManagementCreateButton>}
        </UserManagementHeader>
            <SearchWrapper>
              <SearchComponent data={data} onSearch={handleSearch} />
            </SearchWrapper>
            <UserManagementTable
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={filteredData.length > 0 ? filteredData : data}
              pagination={filteredData.length > 0 ? filteredData.length : data.length}
              loading={loading}
              onChange={handleTableChange}
            />
          

      </UserManagementWrapper>
    </>
  );
}

export default UserManagement;
