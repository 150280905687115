import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import axios from "axios";
import moment from "moment";
const UserManagementWrapper = styled.div`
  padding: 20px;
`;

const UserManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

// const UserManagementCreateButton = styled(Button)`
//   float: right;
// `;

const UserManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function SubscribeUser()
{
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() =>
    {
        fetch();
        // eslint-disable-next-line 
    }, []);

    const handleTableChange = async (pagination, filters, sorter) =>
    {
        const pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const fetch = async (params = {}) =>
    {
        setLoading(true);

        const res = await axios.get("https://bookmybestclass.com/api/transaction/list")
        try
        {
            setData(res.data.data);
            const paginationObj = { ...pagination };
            paginationObj.total = res.data.data.length;
            setPagination(paginationObj);
            setLoading(false)

        } catch (error)
        {
            console.log(error);
        }

    };

    const columns = [
        {
            title: "Name",
            dataIndex: "userId.name",
        },
        {
            title: " Transaction ID",
            dataIndex: "_id"
        },
        {
            title: "Date&Time",
            dataIndex: "createdAt",
            render: (createdAt) => moment(createdAt).format('YYYY-MM-DD (hh:mm:ss A)'),
        },
        {
            title: "Institute Name",
            dataIndex: "batchId.instituteId.name",
        },
        {
            title: "Batch Id",
            dataIndex: "batchId.batchName",
        },
        {
            title: "Exam",
            dataIndex: "batchId.examId.name",
        },

        {
            title: "Email",
            dataIndex: "userId.email",
        },
        {
            title: "Total Amount",
            dataIndex: "amount",
        },
        {
            title:"Discount Amount",
            dataIndex: "discountValue"
        },{
            title:"Paid Amount",
            dataIndex:"priceAfterDiscount"
        },
        {
            title: "Phone",
            dataIndex: "userId.phone",
        },
        {
            title: "City",
            dataIndex: "userId.city",
        },

    ];

    const handleSearch = (searchTerm) =>
    {
        const filteredData = data.filter(
            (item) => (item && item.userId.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item && item.batchId?.instituteId.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item && item.batchId?.batchName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item && item.batchId?.examId.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item && item.userId.email.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        if (filteredData.length === 0) { message.warn('No Data Found') }
        setFilteredData(filteredData);
    };

    return (
        <>
            <AuthGuard />
            <TopNav />
            <UserManagementWrapper>
                <UserManagementHeader>
                    <h2>Subscribe User</h2>
                    {/* <UserManagementCreateButton type="primary">
                        <Link to="/admin/createUser">Create</Link>
                    </UserManagementCreateButton> */}
                </UserManagementHeader>
                <SearchWrapper>
                    <SearchComponent data={data} onSearch={handleSearch} />
                </SearchWrapper>
                <UserManagementTable
                    columns={columns}
                    rowKey={(record) => record._id}
                    dataSource={filteredData.length > 0 ? filteredData : data}
                    pagination={filteredData.length > 0 ? filteredData.length : data.length}
                    loading={loading}
                    onChange={handleTableChange}
                />


            </UserManagementWrapper>
        </>
    );
}

export default SubscribeUser;
