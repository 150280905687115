import React from 'react';
import testimonial1 from "../media/banner/testimonial1.jpeg";
import testimonial2 from "../media/banner/testimonial2.jpeg";
import testimonial3 from "../media/banner/testimonial3.jpeg";
import testimonial4 from "../media/banner/testimonial4.jpeg";
import testimonial5 from "../media/banner/testimonial5.jpeg";
import "../assets/css/carousel.css";
import Slider from "react-slick";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import { BsFillChatQuoteFill } from "react-icons/bs";
const TestinomialNew = () =>
{
    const testimonialArray = [
        {
            id: 1,
            image: testimonial1,
            name: "Sujita Chauhan",
            dscrtn: "BMBC simplifies learning choices. Found my ideal coaching institute hassle-free."
        },
        {
            id: 2,
            image: testimonial2,
            name: "Pardeep Sukhla",
            dscrtn: "BMBC's curated options ensure excellence. Proud to be part of this journey."
        },
        {
            id: 3,
            image: testimonial3,
            name: "Rajesh Punia",
            dscrtn: "The platform where comprehensive listings, detailed reviews, and user-friendly interface."
        },
        {
            id: 4,
            image: testimonial4,
            name: "Dhruv Batra",
            dscrtn: "BMBC = Success catalyst. It led me to a coaching that empowers my growth and kept one step closer to Dream."
        },
        {
            id: 5,
            image: testimonial5,
            name: "Mithali Raj",
            dscrtn: "A remarkable compass, directing me to an outstanding coaching choice. My success story started here!"
        }
    ]

    const CustomPrevArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsLeft />
            </div>
        );
    };

    const CustomNextArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsRight />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1191,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };


    return (

        <>
            <div className="testimonial_section batch_ins_list mb-0 pb-0">
                <div className='container'>
                    <h2 className="testimonial_sub_heading text-center">Testimonials</h2>
                    <section className='testi_slider'>
                        <Slider {...settings}>
                            {
                                testimonialArray.map((ele) => (
                                    <div className='px-2' key={ele.id}>
                                        <div className="card">
                                            <div className='card_header'>
                                                <BsFillChatQuoteFill />
                                            </div>
                                            <p>{ele.dscrtn}</p>
                                            <div className='footer'>
                                                <div className='img_wrap'>
                                                    <img src={ele.image} alt='user_image' />
                                                </div>
                                                <h2>{ele.name}</h2>

                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </Slider>
                    </section>
                </div>
            </div>

        </>


    )
}

export default TestinomialNew

