import React from 'react'
import "../assets/css/thanksPage.css";
import image from "../media/banner/thnksImage.png";
import { Link } from "react-router-dom";
import { BiArrowBack, BiArrowToRight } from "react-icons/bi";
const ThanksPage = () =>
{
    return (
        <div className="thankyouCard">
            <div className='d-flex justify-content-center'>
                <img src={image} alt={image} />
            </div>

            <h2>Congratulations! Thank you for choosing to embark on this learning journey through BMBC. </h2>
            <h5>We've sent the invoice to your registered email inbox.</h5>
            <h5>To access course quickly, You can What'sapp at 8879045526 Or, mail at info.bmbc2023@gmail.com . </h5>
            <div className='d-flex justify-content-center'>
                <Link to="/my-account/profile"><BiArrowToRight /> Go To My Profile</Link>
            </div>
            <div className='d-flex justify-content-center'>
                <Link to="/"><BiArrowBack /> Back To Home</Link>
            </div>
            
        </div>
    )
}

export default ThanksPage