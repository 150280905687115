
import React, { useState } from "react";
import
{
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Spin
} from "antd";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";
import axios from "axios";
import adminService from "../../../common/adminService"

const UserCreateWrapper = styled.div`
  padding: 20px;
`;

const UserCreateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const UserCreateButton = styled(Button)`
  float: right;
`;
const apiURL = process.env.REACT_APP_API_URL;
function CreateUser(props)
{
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) =>
  {
    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      try
      {
        if (!err)
        {
          setLoading(true);
          console.log(values);
          const createUser = await axios.post(apiURL + "user/add", values)
          setLoading(false);
          if (createUser.data.message === "Created success!")
          {
            const logData = {body: `User ${values.name} created`, url: '/user/add' }
            const createLog = await adminService.addLog(logData);
            if (createLog.data.success === true)
            {
              message.success("User Successfully Created");
            }
          
            props.history.push("/admin/user");
          }
        }
      } catch (error)
      {
        if (error.response && error.response.status === 403)
        {
          message.error("Email already exist !");
          props.history.push("/admin/user");
        } else
        {
          message.error("An error occurred. Please try again later.");
        }
      }
    });



    setLoading(false);
  };


  const { getFieldDecorator } = props.form;
  return (
    <>
      <AuthGuard />
      <UserCreateWrapper>
        <UserCreateHeader>
          <h2>Create User</h2>
          <UserCreateButton onClick={() => props.history.push("/admin/user")}>
            Cancel
          </UserCreateButton>
        </UserCreateHeader>
        <Spin spinning={loading}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "please enter name" }],
                  })(<Input placeholder="please enter name" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [{ required: true, message: "please enter email" }],
                  })(<Input placeholder="please enter name" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Password">
                  {getFieldDecorator("password", {
                    rules: [{ required: true, message: "please enter password" }],
                  })(<Input.Password placeholder="please enter password" />)}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Qualification">
                  {getFieldDecorator("qualification", {
                    rules: [
                      { required: true, message: "please enter qualification" },
                    ],
                  })(<Input placeholder="please enter qualification" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Phone">
                  {getFieldDecorator("phone", {
                    rules: [{ required: true, message: "please enter phone" }],
                  })(
                    <Input
                      style={{ width: "100%" }}
                      placeholder="please enter phone"
                    />
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="City">
                  {getFieldDecorator("city", {
                    rules: [{ required: true, message: "please enter city" }],
                  })(<Input placeholder="please enter city" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item label="Address">
                  {getFieldDecorator("address", {
                    rules: [{ required: true, message: "please enter address" }],
                  })(<Input placeholder="please enter address" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Create
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </UserCreateWrapper>
    </>
  );
}

export default withRouter(Form.create()(CreateUser));
