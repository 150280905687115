import React from 'react'
// import PageHeader from "./global-components/page-header";
// import privacyBanner from "./media/banner/priBan.png"
import Layout from './global-components/Layout';
const Refund = () =>
{
    return (
        <div>
            <Layout>
                {/* <PageHeader
                    image={privacyBanner}
                /> */}
                <div className="ltn__faq-area contact_text_head padding_area">
                    <div className="container">
                        <h1 className="section-title text-center mt-2 pt-2">
                            Refund Policy
                        </h1>
                        <p className="contact_text">
                            Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans,
                            as once you have subscribed, you cannot change or cancel your subscription plan.
                            Once you subscribe and make the required payment for any online subscriptions, it shall be
                            final and there cannot be any changes or modifications to the same and neither will there be any refund.
                        </p>
                    </div>
                </div>
            </Layout>
  
        </div>
    )
}

export default Refund