import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import adminService from "../../../common/adminService";
const UserManagementWrapper = styled.div`
  padding: 20px;
`;

const UserManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const BlogList = () => {
  const apiURL = process.env.REACT_APP_API_URL_LIVE;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");
  const [rawData, setRawData] = useState(null);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  // FETCH BLOG LIST
  const fetchBlog = async () => {
    setLoading(true);
    const res = await axios.get(apiURL + `blog/list`);
    try {
      const paginationObj = { ...pagination };
      paginationObj.total = res.data.data.length;
      setData(res.data.data);
      setLoading(false);
      setPagination(paginationObj);
    } catch (error) {}
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [batchIdToDelete, setBatchIdToDelete] = useState(null);

  const showDeleteModal = (batchId, data) => {
    setRawData(data)
    setBatchIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteBatch = async () => {
    try {
      const deleteBatchResponse = await adminService.deleteBlog(
        batchIdToDelete
      );
      if (deleteBatchResponse.data.success === true) {
        const logData = {body:  `Blog ${rawData.heading} deleted`, url: 'blog/delete' }
        const createLog = await adminService.addLog(logData)
        if (createLog.data.success === true)
        {
          message.success(deleteBatchResponse.data.data)
        };
        
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetchBlog();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "Views",
      dataIndex: "views",
    },
    {
      title: "Heading",
      dataIndex: "heading",
      width: "10%",
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "40%",
      textAlign: "justify",
      render: (txt) => (
        <div className="blg_msg" dangerouslySetInnerHTML={{ __html:(txt) }}></div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "10%",
    },
    {
      title: "Meta Slug",
      dataIndex: "metaSlug",
      width: "10%",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "20%",
      render: (image) => (
        <>
          {image !== "" ? (
            <img
              style={{ width: "120px" }}
              src={image}
              alt={"instituteImage"}
            />
          ) : (
            "No Image Added"
          )}

        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      width: "200px",
      render: (record, data) => (
        <>
          {adminType === "view" ? (
            <Button type="info" style={{ marginRight: 2 }}>
              <Link to={`/admin/blogdetail/${record}`}>View</Link>
            </Button>
          ) : adminType === "edit" ? (
            <>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editblog/${record}`}>Edit</Link>
              </Button>
              <Button type="info" style={{ marginRight: 2 }}>
                <Link to={`/admin/blogdetail/${record}`}>View</Link>
              </Button>
            </>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editblog/${record}`}>Edit</Link>
              </Button>
              <Button type="info" style={{ marginRight: 2 }}>
                <Link to={`/admin/blogdetail/${record}`}>View</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];
 
  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetchBlog();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <UserManagementWrapper>
        <UserManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={() => handleTableChange}
        />
        <Modal
          title="Delete Blog"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this blog?</p>
        </Modal>
      </UserManagementWrapper>
    </>
  );
};

export default BlogList;
