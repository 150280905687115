import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CreateCategoryWrapper = styled.div`
  padding: 20px;
`;

const CreateCategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateCategoryButton = styled(Button)`
  float: right;
`;
const Option = Select.Option;
const EditLimit = (props) =>
{
  const [loading, setLoading] = useState(false);
  const [examList, setExamList] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const { id } = useParams()
  useEffect(() =>
  {
    listExams();
    getCategories();
    limitFetch();
    // eslint-disable-next-line 
  }, [])

  const limitFetch = async () =>
  {
    const response = await adminService.getAffordableBatcheById(id);
    const data = response.data.data;
    data.examId = data?.examId?._id
    props.form.setFieldsValue({ ...data });

  }
  const getCategories = async () =>
  {
    const rawData = await adminService.getAllCategories();
    setListCategory(rawData.data.data);
  };
  const listExams = async () =>
  {
    const rawData = await adminService.getAllExam();
    setExamList(rawData.data.data);
  };
  const handleSubmit = (e) =>
  {
    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      if (!err)
      {
        setLoading(true);
        const createCategory = await adminService.editAffordableBatch(values,id);
        setLoading(false);
        if (createCategory.data.success === true)
        {
          const logData = { body: `Category ${values.category + values.limit} created`, url: 'affordableBatch/update' }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("Limit Successfully Updated");
          }
          props.form.resetFields();
          props.history.push("/admin/limit");
        } else
        {
          message.error("Error in creating category");
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <>
      <AuthGuard />
      <CreateCategoryWrapper>
        <CreateCategoryHeader>
          <h2>Edit Limit</h2>
          <CreateCategoryButton
            onClick={() => props.history.push("/admin/limit")}
          >
            Cancel
          </CreateCategoryButton>
        </CreateCategoryHeader>
        <Form onSubmit={handleSubmit} className="login-form">
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Price Limit">
                {getFieldDecorator("limit", {
                  rules: [{ required: true, message: "please enter price limit" }],
                })(<Input placeholder="please enter price limit" />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Exam">
                {getFieldDecorator("examId", {
                  rules: [{ required: true, message: "please enter exam" }],
                })(
                  <Select
                    mode="multiple"
                    className="input-select"
                    placeholder="please enter exam"
                    filterOption={(input, option) =>
                      (option.props.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                    }}
                  >
                    {examList &&
                      examList.map((d) => (
                        <Option key={d._id} value={d._id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Category">
                {getFieldDecorator("category", {
                  rules: [{ required: true, message: "please enter" }],
                })(
                  <Select
                    className="input-select"
                    placeholder="Please Select"
                    style={{
                      width: "100%",
                    }}
                  >
                    {listCategory &&
                      listCategory.map((d) => (
                        <Option key={d._id} value={d.title}>{d.title}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CreateCategoryWrapper>
    </>
  );
}
export default Form.create()(EditLimit);