// import { message } from "antd";
import React, { useState } from "react";
import userService from "../../common/userService";
import "../assets/css/review.css";
import { message } from "antd";
import { useHistory } from "react-router-dom";
// import parse from 'html-react-parser';

export const HelpCenterForm = () =>
{
  const [successMessage, setSuccessMessage] = useState('');
  const [alertMsg, setAlertMsg] = useState("")
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    contact: '',
    message: '',
    subject: 'BMBC HELP'
  });
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (event) =>
  {
    const userActiveId = localStorage.getItem("id");
    if (userActiveId)
    {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      if (name === 'contact' && isNaN(value))
      {
        return;
      }

      setFormValues({
        ...formValues,
        [name]: value,
      });

      setValidationErrors({
        ...validationErrors,
        [name]: '',
      });
    } else
    {
      localStorage.setItem("redirectUrl", window.location.pathname)
      setAlertMsg("Please Login First");
      setTimeout(() =>
      {
        setAlertMsg("")
        history.push("/login");
      }, 1000);
    }
  };

  const requestHelp = async (e) =>
  {
    e.preventDefault();
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');


    const errors = {};
    if (formValues.contact.trim() === '')
    {
      errors.contact = 'Please enter phone number.';
    } else if (isNaN(formValues.contact) || formValues.contact.length !== 10)
    {
      errors.contact = 'Please enter a valid 10-digit phone number.';
    }
    if (formValues.message.trim() === '')
    {
      errors.message = 'Please enter a message.';
    }
    if (Object.keys(errors).length > 0)
    {
      setValidationErrors(errors);
      return;
    }
    if (id == null || token == null)
    {
      localStorage.setItem("redirectUrl", window.location.pathname)
      message.error("Please Login First");
      setTimeout(() =>
      {
        history.push("/login");
      }, 1000);
    } else
    {
      const requestHelp = await userService.requestHelp(id, formValues)
      if (requestHelp.data.success === true)
      {
        setFormValues({
          contact: "",
          subject: "",
          message: "",
        });
        setValidationErrors({
          ...validationErrors,
          message: "",
        });
        setSuccessMessage('Request Raised Successfully');
        setTimeout(() =>
        {
          setSuccessMessage("")
        }, 3000);
      } else
      {
        setSuccessMessage('An error occured');

      }

    }

  };

  return (
    <div className="ltn__contact-message-area padding_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h4 className="title-2">Get Help</h4>
            <div className="hlp_center_wrapper">
              <p>
                We understand that, as a student, you may face various challenges and issues during your academic journey. And we provide you with this platform to voice your concerns and get them addressed properly.
              </p>
              <p>
                BMBC offers an easy and accessible way for students to raise grievances or complaints about their academic experience. The relevant authorities will receive and address your complaints, ensuring timely action is taken to resolve your issues.
              </p>
              <p>
                Our team is well-equipped to address a wide range of problems that you may encounter during your academic journey. These include academic issues, such as grading, evaluations, exams,
                and assessments. We can also help you with problems related to facilities, such as infrastructure, equipment, or services offered by the institute.
              </p>
              <p className="mb-0">
                In case of any complaints regarding any kind of harassment, discrimination, or bias the student faces, we have a team specifically trained to handle such cases. In addition
                to these issues, you can approach us with any other grievances or concerns that you may have related to your academic experience.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <h4 className="title-2 get_hlp_title">Login To Get Help</h4>
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <form id="contact-form">
                <div className="row">
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name mb-4">
                      <input
                        onChange={handleInputChange}
                        type="text"
                        className="mb-1"
                        name="subject"
                        placeholder="Enter your subject"
                      />
                      {validationErrors.subject && <p style={{ color: "red" }} className="validation-message">{validationErrors.subject}</p>}
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="input-item input-item-email mb-4">
                      <input
                        onChange={handleInputChange}
                        type="number"
                        value={formValues.contact}
                        name="contact"
                        className="mb-1"
                        placeholder="Enter phone"
                      />
                      {validationErrors.contact && <p style={{ color: "red" }} className="validation-message">{validationErrors.contact}</p>}
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea mb-4">
                  <textarea
                    onChange={handleInputChange}
                    name="message"
                    value={formValues.message}
                    placeholder="Enter message"
                    className="mb-1"
                    defaultValue={""}
                  />
                  {validationErrors.message && <p style={{ color: "red" }} className="validation-message">{validationErrors.message}</p>}
                </div>
                <div className="btn-wrapper mt-0">
                  {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>}
                  {
                    alertMsg && <p className="alert_msg_otp2 mb-0 text-start">{alertMsg}</p>
                  }
                  <button
                    className="btn theme-btn-1 mt-4 btn-effect-1 text-uppercase"
                    onClick={(e) => requestHelp(e)}
                  >
                    Request Help
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterForm;
