import React, { useState, useContext, Suspense, lazy } from "react";
import "../assets/css/searchForm.css";
import "../assets/css/batch.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import { Modal, Spin, message } from "antd";
import ErrorPage from "../404";
import { BatchContext } from "../../redux/batchContext";
import "../assets/css/newSearch.css";
import "../assets/css/compareCard.css";
import axios from "axios";
import TableComp from "../reusable/TableComp";
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from "moment";


const BatchCard = lazy(() => import('../reusable/BatchCard'));

export const BatchSearch = ({ examName }) =>
{
  // const apiURL = "http://13.59.237.22:5000/";
  const testURL = process.env.REACT_APP_API_URL_LIVE
  const [activeBatchFilter, setActiveBatchFilter] = useState("all");
  const [showMore, setShowMore] = useState(false);
  const {
    originalBatchList,
    // examName,
    modeName,
    cityName,
    loading,
    batchList,
    // examLocalId,
    setBatchList,
    setMaxAmount,
    setGroupedBatch,
    setMinAmount,
    setFilterValue,
  } = useContext(BatchContext);

  const history = useHistory();

  // useEffect(() =>
  // {
  //   if (modeName !== null && examLocalId !== null)
  //   {
  //     handleClick("all");
  //   }
  // }, [modeName, examLocalId, examName, cityName]);

  // SLIDER SETTING FUNCTION
  const CustomPrevArrow = (props) =>
  {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
        <BiChevronsLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) =>
  {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
        <BiChevronsRight />
      </div>
    );
  };

  const BatchSliderAll = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: batchList.length > 4 ? 5 : batchList.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1091,
        settings: {
          slidesToShow: batchList.length > 3 ? 4 : batchList.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: batchList.length > 2 ? 3 : batchList.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: batchList.length < 2 ? 1 : 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  }

  let BatchSliderWidth = "100%";
  let BatchSliderWidthMobile = "100%";

  if (batchList.length < 4)
  {
    BatchSliderWidth = `${25 * batchList.length}%`;
  }
  if (batchList.length < 2 && window.innerWidth < 768)
  {
    BatchSliderWidthMobile = `${50 * batchList.length}%`;
  }
  // FILTER -- BATCHES BY SORT BY FUNCTION
  const handleFilterSelect = (filters) =>
  {
    sessionStorage.removeItem("checkIds");
    setCheckedID([])
    if (filters.startsWith("Rs"))
    {
      const match = filters.match(/Rs (\d+) - Rs (\d+)/);
      if (match && match.length >= 3)
      {
        const minValue = parseInt(match[1], 10);
        const maxValue = parseInt(match[2], 10);
        setMaxAmount(maxValue);
        setMinAmount(minValue);
      }
    } else
    {
      setMaxAmount("");
      setMinAmount("");
      setFilterValue(filters);
    }

  }

  // FILTER ALL BATCHES ACCORDING TO STARUS
  const handleClick = (e) =>
  {
    const filterString = e.target.value
    console.log(filterString);
    let filteredBatches;
    if (filterString === "all")
    {
      filteredBatches = originalBatchList;
    } else if (filterString === "upcoming")
    {
      filteredBatches = originalBatchList.filter((batch) => batch.status === "Upcoming");
    } else if (filterString === "ongoing")
    {
      filteredBatches = originalBatchList.filter((batch) => batch.status === "Ongoing");
    } else
    {
      filteredBatches = originalBatchList;
    }
    setBatchList(filteredBatches);
    setActiveBatchFilter(filterString);
  };

  // REDIRECT URL FOR INSTITUTE ALL BATCHES 
  const hanleViewAllBatches = (instituteId, examIdInfo) =>
  {
    history.push(`/institute/${instituteId}/batch/${examIdInfo}`)
  }
  // =======>>>>>> COMPARISION ==>>  FUNCTION  ==>>   STARTS 
  const [hidden, setHidden] = useState(false);
  const [selectResultModal, setSelectResultModal] = useState(false);
  const [compareData, setCompareData] = useState({});
  const [alertText, setAlertText] = useState("");
  const [sessionCheckedId, setSessionCheckedId] = useState(null)
  const [checkedId, setCheckedID] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () =>
  {
    setIsModalOpen(false);
  };
  // const updateCheckedIds = (newCheckedIds) =>
  // {
  //   setCheckedID(newCheckedIds);
  // };
  
  const compareNow = () =>
  {
    setAlertText("You can select maximum 3 batches");
    setHidden(true);
    setShowMore(true)
  }
 

  const handleCompareInput = async (ele) =>
  {
    const existingCheckedIds = JSON.parse(sessionStorage.getItem('checkIds')) || [];
    const isChecked = existingCheckedIds.includes(ele._id);
    const existingCheckedIdsLength = existingCheckedIds.length;
    const updatedCheckedIds = isChecked
      ? existingCheckedIds.filter((checkedId) => checkedId !== ele._id)
      : [...existingCheckedIds, ele._id];
    sessionStorage.setItem('checkIds', JSON.stringify(updatedCheckedIds));
    setCheckedID(updatedCheckedIds);
    const sessionChecked = sessionStorage.getItem("checkIds");
    setSessionCheckedId(sessionChecked);
    if (existingCheckedIdsLength >= 3)
    {
      setAlertText("You can select a maximum of 3 batches");
    } else if (updatedCheckedIds.length === 2)
    {
     
      setSessionCheckedId(sessionChecked =>
      {
        setIsModalOpen(true);
      });
      console.log(isModalOpen);
    } else if (updatedCheckedIds.length === 3)
    {
      setSessionCheckedId(sessionChecked =>
      {
        handleCompare(); 
        return JSON.stringify(updatedCheckedIds);
      });
    }
  };

  const userId = localStorage.getItem("id");

  const handleCompare = async () =>
  {
    if (userId)
    {
      const batchIds = sessionStorage.getItem("checkIds");
      const result = await axios.get(testURL + `batch/compare-batch?batchIds=${batchIds}`);
      setCompareData(result.data.data);
      setSelectResultModal(true);
    } else
    {
      sessionStorage.setItem("redirectUrl", window.location.pathname);
      setTimeout(() =>
      {
        message.error("Please Login First")
      }, 2000);
      history.push("/login");
    }
  }

  // const handleSelectedValueChange = (value) =>
  // {
  //   setSelectModalOpen(value);
  // };

  const handleMoreBatch = () =>
  {
    setGroupedBatch(true);
    setShowMore(true);
  }

  const handleLessBatch = () =>
  {
    setGroupedBatch(false);
    setShowMore(false);
  }


  const handleCancelResult = () =>
  {
    setSelectResultModal(false)
    setIsModalOpen(false)
  }


  const permissionIds = [
    "6461de19cd10f83e9358827b",
    "64be659d4dcfa238cc4b2b14",
    "64e473a04f25d7076635288a",
    "64e487ff4f25d707663528b9",
    "64be65bb4dcfa238cc4b2b16",
    "6463245acd10f83e9358828e"
  ]
  
  return (
    <>
      <div className="ltn__product-slider-area batch_ins_list ltn__product-gutter">
        <div className="container batch_reviw">
          <div className="section-title-area mb-3 ltn__section-title-2--- text-center">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h1 className="inst_title mb-0 mt-0">
                Best {modeName && modeName} Classes {examName && <span style={{ color: 'red' }}> {examName}</span>} {
                  modeName === "Offline" && <>
                    {examName && cityName && '-'} <span style={{ color: 'red' }}>{cityName}</span></>
                }
              </h1>
              {
                !showMore ?
                  <button className="btn pe-0  me-0 show_more_btn" onClick={() => handleMoreBatch()}>View All Batches</button>
                  :
                  <button className="btn pe-0  me-0 show_more_btn" onClick={() => handleLessBatch()}>View Less</button>
              }
            </div>
            <h6 className="section-subtitle batch_sec_title">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                  // justifyContent: "center",
                }}
              >
                {/* <button style={{ backgroundColor: "transparent" }} className={activeBatchFilter === "all" ? "filter-active" : ""} onClick={(e) => handleClick("all")} >
                  {
                    !showMore ? `All Institute (${batchList.length})` : `All Batches (${batchList.length})`
                  }

                </button> */}
                {/* {
                  ableButton.length > 0 && <button style={{ backgroundColor: "transparent" }} className={activeBatchFilter === "upcoming" ? "filter-active" : ""} onClick={() => handleClick("upcoming")}>
                    Upcoming
                  </button>
                } */}

                {/* <button
                  style={{ backgroundColor: "transparent" }}
                  className={
                    activeBatchFilter === "ongoing" ? "filter-active" : ""
                  }
                  onClick={() => handleClick("ongoing")}
                >
                  Ongoing
                </button> */}
                <div className="sort_column sort_column_mob">
                  <label>Sort By:</label>
                  <select className={
                    activeBatchFilter === "ongoing" ? "filter-active form-control" : "form-control"
                  }
                    onChange={handleClick}>
                    <option> {
                      !showMore ? `All Institute (${batchList.length})` : `All Batches (${batchList.length})`
                    }</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="upcoming">Upcoming</option>
                  </select>
                </div>
                {
                  !showMore ?
                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => handleMoreBatch()}>View All Batches </button>
                    :
                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => handleLessBatch()}>View Less</button>
                }
                <div className="sort_column">
                  <label>Filter :</label>
                  <select className="form-control" onChange={(e) => handleFilterSelect(e.target.value)}>
                    <option value="all">Recommended</option>
                    <option value="amount">Price Low to High</option>
                    <option value="hightolow">Price High to Low</option>
                    <option value="Rs 4000 - Rs 10000">Rs 4000 - Rs 10000</option>
                    <option value="Rs 10000 - Rs 50000">Rs 10000 - Rs 50000</option>
                    <option value="Rs 50000 - Rs 100000">Rs 50000 - Rs 100000</option>
                    <option value="Rs 100000 - Rs 1000000">Above Rs 100000</option>
                  </select>
                </div>
                <div className="sort_column">
                  <label>Sort By:</label>
                  <select className={
                    activeBatchFilter === "ongoing" ? "filter-active form-control" : "form-control"
                  }
                    onChange={handleClick}>
                    <option> {
                      !showMore ? `All Institutes (${batchList.length})` : `All Batches (${batchList.length})`
                    }</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="upcoming">Upcoming</option>
                  </select>
                </div>
                {
                  checkedId.length < 1 && (
                    <button className="theme_btn_main theme_btn_desk" onClick={compareNow}>
                      Compare
                    </button>
                  )
                }
              </div>
            </h6>
            <div className="mob_sort">
              <div className="sort_column  sort_column_mob">
                <label>Filter Apply :</label>
                <select className="form-control" onChange={(e) => handleFilterSelect(e.target.value)}>
                  <option value="null">Recommended</option>
                  <option value="amount">Price Low to High</option>
                  <option value="hightolow">Price High to Low</option>
                  <option value="Rs 4000 - Rs 10000">Rs 4000 - Rs 10000</option>
                  <option value="Rs 10000 - Rs 50000">Rs 10000 - Rs 50000</option>
                  <option value="Rs 50000 - Rs 100000">Rs 50000 - Rs 100000</option>
                  <option value="Rs 100000 - Rs 1000000">Above Rs 100000</option>
                </select>
              </div>

              {
                checkedId.length < 1 && (
                  <button className="theme_btn_main main_theme_btn-2" onClick={compareNow}>
                    Compare
                  </button>
                )
              }
              {/* {
                checkedId.length === 1 && (
                  <button className="theme_btn_main main_theme_btn-2" disabled onClick={compareNow}>
                    Compare
                  </button>
                )
              } */}
              {/* {
                checkedId.length >= 2 && checkedId.length <= 3 && (
                  <button className="theme_btn_main main_theme_btn-2" onClick={() => handleCompare()} >
                    Compare Now
                  </button>
                )
              } */}
              {/* {
                checkedId.length > 3 && (
                  <button className="theme_btn_main main_theme_btn-2" onClick={() => handleCompare()} disabled>
                    Compare (Max 3)
                  </button>
                )
              } */}
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade active" id="liton_product_list">
              <div className="ltn__product-tab-content-inner tabs_content_btch ltn__product-list-view">
                <div className="row g-2">
                  {
                    showMore && alertText && <p className={` ${sessionCheckedId > 3 ? "compare_alert" : "compare_success"}`} style={{ textAlign: "center" }}>{alertText}</p>
                  }
                  {
                    loading ?
                      <div className="d-flex justify-content-center">
                        <Spin />
                      </div>
                      :
                      (
                        batchList.length > 0 ? (showMore ?
                          <Suspense fallback={'Loading ...'}>
                            <BatchCard
                              batchList={batchList}
                              hidden={hidden}
                              showMore={showMore}
                              onView={hanleViewAllBatches}
                              checkedId={checkedId}
                              onCompare={handleCompareInput}
                            />
                          </Suspense>

                          :
                          <div className="slider-container" style={{ width: window.innerWidth >= 768 ? BatchSliderWidth : BatchSliderWidthMobile }}>
                            <Slider {...BatchSliderAll}>
                              {
                                batchList.map((ele) =>
                                {
                                  return (
                                    <Suspense key={ele._id} fallback={'Loading ...'}>
                                      <div className="px-2" key={ele._id}>
                                        <div className="batch_card_details">
                                          <div className="left_box">
                                            {ele?.batchKeyword &&
                                              ele?.keywordStatus !== "Ended" && (
                                                <h5 className="keyword_tags">
                                                  <p className="badge_btn">
                                                    {ele.status}
                                                  </p>
                                                  <span>{ele.batchKeyword?.keyword?.name}</span>

                                                </h5>
                                              )
                                            }
                                            <img className="img-fluid img-responsive rounded product-image" loading="lazy" src={ele?.instituteId?.image} alt={ele.instituteId.image} />
                                            {
                                              ele.planName?.title && (
                                                <h5 className="marquee_tags">
                                                  {/* <p>Batch Type</p> */}
                                                  <span>{ele.planName?.title}</span>
                                                </h5>
                                              )
                                            }
                                          </div>
                                          <div className="center_box">
                                            <div className="center_details">
                                              <h5 className="my-0">
                                                <span>
                                                  {ele.examId?.name}
                                                </span>
                                              </h5>
                                              <h4>
                                                <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span><FaStar />
                                              </h4>
                                              <span className="mob_category">{ele?.category}</span>
                                            </div>
                                            <h5 className="desk_inst">
                                              <span>Institute :</span>
                                              <span>{ele.instituteId?.name?.slice(0, 13) + ".."}</span>
                                            </h5>
                                            <h5 className="mob_inst">
                                              <span>{ele.instituteId?.name?.slice(0, 13) + ".."}</span>
                                            </h5>
                                            <h5 className="mob_batch">
                                              <span>Batch No:</span>
                                              <span>{ele?.batchName}</span>
                                            </h5>
                                            {permissionIds.includes(ele.examId._id) &&
                                              <h5 className="mob_batch">
                                                <span>Branch :</span>
                                                <Link to={`/batch-detail/${ele._id}`}>
                                                  {ele.branchName?.length > 0 && (
                                                    <>
                                                      {ele.branchName.slice(0, 3).map((branch, index) =>
                                                      {
                                                        const truncatedName = branch.name;

                                                        return (
                                                          <React.Fragment key={index}>
                                                            <span
                                                              style={{
                                                                cursor: "pointer",
                                                              }}
                                                            >
                                                              {truncatedName}
                                                            </span>
                                                            {index < ele.branchName.slice(0, 4).length - 1 && <span>, </span>}

                                                          </React.Fragment>
                                                        );
                                                      })}
                                                      {ele?.branchName.length > 4 &&
                                                        <span
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          ...
                                                        </span>
                                                      }
                                                    </>
                                                  )}
                                                </Link>
                                              </h5>
                                            }
                                            <h5 className="mob_batch">
                                              <span>Language :</span>
                                              <span style={{ color: "#2292ca" }}>
                                                {ele?.languages?.name ? ele?.languages?.name : "English"}
                                              </span>
                                            </h5>
                                            {ele.category !== 'Online' &&
                                              <h5 className="mob_batch">
                                                {ele.category !== 'Online' && <span>City :</span>}
                                                {ele.category !== 'Online' && <span>{ele.city}</span>}
                                              </h5>
                                            }

                                            <h3 className="mob_price">
                                              <span>Price :</span>
                                              <span style={{ color: "#2292ca" }}>
                                                {ele.amount.toLocaleString("en-IN", {
                                                  style: "currency",
                                                  currency: "INR",
                                                }).split('.')[0]}
                                                <span className="incl_gst">incl. GST</span>
                                              </span>
                                            </h3>
                                            <h5 className="desk_btch_number">
                                              <span>Batch No :</span>
                                              <span>
                                                {ele.batchName}
                                              </span>
                                            </h5>
                                            {/* <h5 className="desk_strt_dt">
                                              <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                                            </h5> */}
                                          </div>
                                          <div className="right_box">
                                            <h5>
                                              <span>Price :</span>
                                              <span style={{ color: "#2292ca" }}>
                                                {ele.amount.toLocaleString("en-IN", {
                                                  style: "currency",
                                                  currency: "INR",
                                                }).split('.')[0]}
                                                <span className="incl_gst">incl. GST</span>
                                              </span>
                                            </h5>
                                            {permissionIds.includes(ele.examId._id) && <h5>
                                              <span>Branch :</span>
                                              <Link to={`/batch-detail/${ele._id}`}>
                                                {ele.branchName?.length > 0 && (
                                                  <>
                                                    {ele.branchName.slice(0, 4).map((branch, index) =>
                                                    {
                                                      const truncatedName = branch.name;
                                                      return (
                                                        <React.Fragment key={index}>
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {truncatedName}
                                                          </span>
                                                          {index < ele.branchName.slice(0, 4).length - 1 && <span>, </span>}

                                                        </React.Fragment>
                                                      );
                                                    })}
                                                    {ele?.branchName.length > 4 &&
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        ...
                                                      </span>
                                                    }
                                                  </>
                                                )}
                                              </Link>
                                            </h5>

                                            }
                                            <h5>
                                              <span>Language :</span>
                                              <span style={{ color: "#2292ca" }}>
                                                {ele?.languages?.name ? ele?.languages?.name : "English"}
                                              </span>
                                            </h5>
                                            <h5>
                                              <span>{ele?.category}</span>
                                              {
                                                ele?.category === "Offline" && <p className="city">{ele?.city}</p>
                                              }
                                            </h5>
                                          </div>
                                          <div className="see_btn_wrap_batch">
                                            <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary view_al_btch w-100" type="button">View Details</Link>
                                            {
                                              ele.count >= 1 &&
                                              <button className="btn view_more_batch_btn" onClick={() => hanleViewAllBatches(ele.instituteId._id, ele.examId._id, ele?.city)}>All Batches {ele.count && `(${ele.count})`}</button>
                                            }
                                          </div>
                                          {
                                            ((ele?.batchKeyword && ele?.keywordStatus === "Ended") ||
                                              (!ele?.batchKeyword && ele?.keywordStatus === "Active")) &&
                                            (
                                              <p className="badge_btn2">
                                                {ele.status}
                                              </p>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </Suspense>
                                  )
                                })
                              }
                            </Slider>
                          </div>
                        )
                          :
                          <ErrorPage value="There are no batches for current filter. New Batches will be updated soon, please check regularly." />
                      )
                  }
                </div>
                <Modal
                  visible={isModalOpen}
                  style={{ borderBottom: 'none', paddingLeft: '0px' }}
                  okText="Continue"
                  width={300}
                  header={null}
                  closable={false}
                  onOk={() => handleCompare()}
                  onCancel={handleCancel}
                  bodyStyle={{padding:'10px 5px'}}
                  footerStyle={{ padding: '5px' }}
                >
                  <p>You can select max 3 batches if  you want to compare only 2 batches then click on continue.</p>
                </Modal>
                <Modal
                  title="Result"
                  style={{ top: 10 }}
                  visible={selectResultModal}
                  onCancel={handleCancelResult}
                  footer={null}
                  className='inst_btch_details_modal'
                >
                  <TableComp compareData={compareData} />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default BatchSearch;









