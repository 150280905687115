import axios from 'axios';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import apiService from '../common/apiService';
import moment from 'moment';
import adminService from '../common/adminService';

export const BatchContext = createContext();

export const BatchProvider = ({ children }) =>
{

    const testURL = process.env.REACT_APP_API_URL_LIVE
    const [originalBatchList, setOriginalBatchList] = useState([]);
    const [instituteList, setInstituteList] = useState([]);
    const [examIdData, setExamIdData] = useState();
    const [instituteHeading, setInstituteHeading] = useState(null);
    const [originalInstituteList, setOriginalInstituteList] = useState([]);
    const [examName, setExamName] = useState("");
    const [blogId, setBlogId] = useState();
    const [loading, setLoading] = useState(false);
    const [ableButton, setAbleButton] = useState([]);
    const [cityName, setCityName] = useState("");
    const [modeName, setModeName] = useState("");
    const [branchId, setBranchId] = useState("")
    const [examLocalId, setExamLocalId] = useState("");
    const [batchList, setBatchList] = useState([]);
    const [minAmount, setMinAmount] = useState(null);
    const [maxAmount, setMaxAmount] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [groupBatch, setGroupedBatch] = useState(false);

    // HINID BATCHES FILTERATION STATES
    const [newBatchList, setNewBatchList] = useState([]);
    const [newFilterBatchList, setNewFilterBatchList] = useState([]);

    // TRENDING BATCHES
    const [trendingList, setTrendingList] = useState([]);
    const [trendingFilterList, setTrendingFilterList] = useState([]);

    // AFFORDABLE BATCHES
    // const [data, setData] = useState([]);
    const [allBatch, setAllBatch] = useState([])
    const [affordableList, setAffordableList] = useState([]);
    const [afforableFilterList, setAfforableFilterList] = useState([]);

    const fetchExamById = useMemo(() => async () =>
    {
        setLoading(true);
        try
        {
            const res = await axios.get(`${testURL}exam/id/${examLocalId}`);
            setExamName(res.data.data.name);
            setLoading(false);
        } catch (error)
        {
            console.error(error);
        }
    }, [examLocalId, testURL]);

    const getAllBatches = async () =>
    {
        setLoading(true)
        const cityValue = localStorage.getItem('city');
        const examValue = localStorage.getItem('exam');
        const modeValue = localStorage.getItem('mode');
        const branchValue = localStorage.getItem('branchName');
        let url = `${testURL}batch/list`;
        if (modeValue)
        {
            url += `?status=${modeValue}`;
        }
        if (examValue === "6461de19cd10f83e9358827b" && branchValue)
        {
            url += `&branch=${branchValue}`;
        }
        if (minAmount)
        {
            url += `&minPrice=${minAmount}`;
        }
        if (maxAmount)
        {
            url += `&maxPrice=${maxAmount}`;
        }
        if (filterValue && filterValue !== "all")
        {
            url += `&sort=${filterValue}`;
        }
        if (examValue)
        {
            url += `&examId=${examValue}`;
        }
        if (modeValue === "Offline")
        {
            if (cityValue !== "all" && cityValue !== null)
            {
                url += `&city=${cityValue}`;
            }
        }
        const rawData = await apiService.get(url);
        const data = rawData.data.data;
        let batchList = data.map((obj) =>
        {
            const startDate = new Date(obj.startDate);
            const endDate = new Date(obj.endDate);
            const momentStartDate = moment(startDate);
            const momentEndDate = moment(endDate);
            const momentNowDate = moment();
            let status;

            if (momentNowDate.isAfter(momentEndDate, 'day'))
            {
                status = 'Ended';
            } else if (!momentNowDate.isAfter(momentStartDate))
            {
                status = 'Upcoming';
            } else
            {
                status = 'Ongoing';
            }

            return {
                ...obj,
                status,
            };
        }).filter(obj => obj.status !== 'Ended');
        let BatchKeywordList = batchList.map((obj) =>
        {
            const endDate = new Date(obj?.batchKeyword?.endDate);
            const momentEndDate = moment(endDate);
            const momentNowDate = moment();
            let keywordStatus;

            if (momentNowDate.isAfter(momentEndDate, 'day'))
            {
                keywordStatus = 'Ended';
            }

            else
            {
                keywordStatus = 'Active';
            }

            return {
                ...obj,
                keywordStatus,
            };

        })
        if (!(examLocalId && modeName))
        {
            const sortedBatchKeywordList = BatchKeywordList.sort((a, b) =>
                b.instituteId?.rating - a.instituteId?.rating
            );
            BatchKeywordList = [];
            BatchKeywordList.push(...sortedBatchKeywordList);
        }
        const filterBatch = BatchKeywordList.filter(
            obj => obj.category === "Online" || obj.category === "Offline"
        );
        setAllBatch(filterBatch)
        handleClickHindiBatches(filterBatch);
        handleClickTrendingBatches(filterBatch);
        if (minAmount === null && (filterValue === "all" || filterValue === null) && !groupBatch)
        {
            const groupedBatchListCount = {};
            filterBatch.forEach(obj =>
            {
                const instituteId = obj.instituteId?._id;
                const examId = obj.examId?._id;
                const key = `${instituteId}-${examId}`;
                if (!groupedBatchListCount[key])
                {
                    groupedBatchListCount[key] = {
                        ...obj,
                        count: 1,
                        batches: [obj],
                        batchesId: [obj._id]
                    };
                } else
                {
                    groupedBatchListCount[key].count++;
                    groupedBatchListCount[key].batches.push(obj);
                    groupedBatchListCount[key].batchesId.push(obj);
                }

            });
            const mergedArray = Object.values(groupedBatchListCount);
            mergedArray.forEach((ele) =>
            {
                ele.batchIds = []
                ele.batches.map((btc) => ele.batchIds.push(btc._id))

            })
            setBatchList(mergedArray);
            setOriginalBatchList(mergedArray);
            const upComingAble = mergedArray.filter(obj => obj.status === "Upcoming");
            setAbleButton(upComingAble);
            setLoading(false);
        } else if (filterValue === "all")
        {
            const groupedBatchListCount = {};
            filterBatch.forEach(obj =>
            {
                const instituteId = obj.instituteId?._id;
                const examId = obj.examId?._id;
                const key = `${instituteId}-${examId}`;
                if (!groupedBatchListCount[key])
                {
                    groupedBatchListCount[key] = {
                        ...obj,
                        count: 1,
                        batches: [obj],
                        batchesId: [obj._id]
                    };
                } else
                {
                    groupedBatchListCount[key].count++;
                    groupedBatchListCount[key].batches.push(obj);
                    groupedBatchListCount[key].batchesId.push(obj);
                }

            });
            const mergedArray = Object.values(groupedBatchListCount);
            mergedArray.forEach((ele) =>
            {
                ele.batchIds = []
                ele.batches.map((btc) => ele.batchIds.push(btc._id))

            })
            setBatchList(mergedArray);
            setOriginalBatchList(mergedArray);
            const upComingAble = mergedArray.filter(obj => obj.status === "Upcoming");
            setAbleButton(upComingAble);
            setLoading(false);
        }
        else
        {
            setBatchList(filterBatch);
            setOriginalBatchList(filterBatch);
            const upComingAble = filterBatch.filter(obj => obj.status === "Upcoming");
            setAbleButton(upComingAble);
            setLoading(false);
        }
        // eslint-disable-next-line
    };

    const updateBlogId = (id) =>
    {
        setBlogId(id);
        localStorage.setItem('blogId', JSON.stringify(id));
    };

    const handleClickHindiBatches = useMemo(() => (filterBatch) =>
    {
        const hindiBatch = filterBatch.filter(batch =>
        {
            if (batch.languages && batch.languages.name === "Hindi Medium")
            {
                return true;
            }
            return false;
        });
        setNewBatchList(hindiBatch);
        setNewFilterBatchList(hindiBatch);
    }, []);


    const handleClickTrendingBatches = useMemo(() => (filterBatch) =>
    {
        const affordable = filterBatch.filter(batch => batch.status === "Upcoming");
        setTrendingList(affordable);
        setTrendingFilterList(affordable);
    }, [])


    const handleClickAffordableBatch = useMemo(() => async () =>
    {
        try
        {

            if (examLocalId && modeName)
            {
                const rawData = await adminService.getAllAffordableBatches();
                const responseData = rawData.data.data;
                const matchingObjects = responseData.filter(item =>
                    item.category === modeName && item.examId._id === examLocalId
                );
                const affordable = allBatch.filter(batch => batch.amount <= matchingObjects[0]?.limit);

                setAffordableList(affordable);
                setAfforableFilterList(affordable);
            }


        } catch (error)
        {
            console.error(error);
        }
        // eslint-disable-next-line
    }, [examLocalId, allBatch])


    const branchValue = localStorage.getItem('branchName');
    useEffect(() =>
    {
        setBranchId(branchValue);
        // eslint-disable-next-line
    }, [branchValue]);


    useEffect(() =>
    {
        const cityFromLocalStorage = localStorage.getItem('city');
        const examFromLocalStorage = localStorage.getItem('exam');
        const modeFromLocalStorage = localStorage.getItem('mode');
        setLoading(true);
        setBranchId(branchValue)
        setCityName(cityFromLocalStorage);
        setModeName(modeFromLocalStorage);
        setExamLocalId(examFromLocalStorage);
        setLoading(false);
        const storedBlogId = localStorage.getItem('blogId');
        if (storedBlogId)
        {
            setBlogId(JSON.parse(storedBlogId));
        }
        if (modeName && examLocalId && examName)
        {
            getAllBatches();
        }

        // eslint-disable-next-line
    }, [groupBatch, filterValue, cityName, minAmount, examLocalId, modeName, examName, branchId]);


    useEffect(() =>
    {
        setLoading(true);
        if (examLocalId)
        {
            fetchExamById();
            handleClickAffordableBatch();
        }
        setLoading(false);
    }, [fetchExamById, examLocalId, handleClickAffordableBatch]);


    return (
        <BatchContext.Provider value={{
            originalBatchList,
            setOriginalBatchList,
            instituteList,
            setInstituteList,
            examIdData,
            setExamIdData,
            instituteHeading,
            setInstituteHeading,
            blogId,
            setBlogId: updateBlogId,
            originalInstituteList,
            setOriginalInstituteList,
            examName,
            setExamLocalId,
            setCityName,
            setModeName,
            modeName,
            batchList,
            setGroupedBatch,
            setBatchList,
            examLocalId,
            cityName,
            loading,
            setMaxAmount,
            setMinAmount,
            maxAmount, setFilterValue, filterValue, minAmount,
            ableButton,
            newFilterBatchList,
            setAffordableList,
            newBatchList,
            afforableFilterList,
            affordableList,
            trendingList,
            trendingFilterList,
            setTrendingList,
            setNewBatchList,
            getAllBatches,
            fetchExamById
        }}>
            {children}
        </BatchContext.Provider>
    );



};
