import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import "../assets/css/allblog.css";
import axios from 'axios';
import { Spin } from 'antd';
import blogImg from "../media/banner/newbanner1.webp";
// import { Pagination } from 'react-bootstrap';
import { BatchContext } from '../../redux/batchContext';
import { BsArrowRightShort } from "react-icons/bs"
const Blog = () =>
{
	// const apiURL = process.env.REACT_APP_API_URL;
	const apiURL = process.env.REACT_APP_API_URL_LIVE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchBlog = async () =>
	{
		setLoading(true);
		const res = await axios.get(apiURL + `blog/list`)
		try
		{
			setData(res.data.data);
			setLoading(false);
		} catch (error)
		{
			console.log(error);
		}
	}
	const { setBlogId } = useContext(BatchContext)
	const handleSendId = (id) =>
	{
		setBlogId(id);
	}
	const [objectsPerPage] = useState(4);
	const currentObjects = data.slice(0, 4)
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(data.length / objectsPerPage); i++)
	{
		pageNumbers.push(i);
	}
	useEffect(() =>
	{
		fetchBlog()
		// eslint-disable-next-line
	}, []);


	return (
		<div className="padding_area blog_padding">
			<div className="container">
				<div className='blog_section_wrap'>
					<div className="row">
						<div className="col-lg-8">
							<div className='blog_list_wrap'>
								<h2 className='lt_blg_hd'>Latest Blogs</h2>
								<div className={!loading ? `blog_grid_col` : 'blog_grid_col_2'} >
									{
										!loading ?
											currentObjects.map((index, id) =>
											{
												const uniqueId = id;
												const shortenedHeading = index.heading.slice(0, -2);
												const encodedHeading = encodeURIComponent(shortenedHeading);
												const cleanHeading = encodedHeading
													.toLowerCase()
													.replace(/%20/g, '-')
													.replace(/%3f/g, ' ');
												const createdAt = data[id]?.createdAt;
												const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
												const localDate = new Date(createdAt);
												const paymentDate = new Date(localDate);
												const payment_date_year = paymentDate.getFullYear();
												const payment_date_month = months[paymentDate.getMonth()];
												const payment_date_date = paymentDate.getDate();
												const payment_date_dateNew = payment_date_date < 10 ? '0' + payment_date_date : payment_date_date;
												const fulldate_paymentDate = payment_date_dateNew + '-' + payment_date_month + '-' + payment_date_year;
												return (
													<div className='card_blog' key={uniqueId}>
														<div>
															{index.image ? (
																<img src={index?.image} alt={index.image} className='img-fluid' />
															) : (
																<img src={blogImg} alt={blogImg} />
															)}

															<h1 className='blg_heading_main'>
																{index.heading?.slice(0, 20) + "..."}
															</h1>
															<div className='blg_card_footer'>
																<span className='date_tag'>
																	<i className="far fa-calendar-alt" /> {fulldate_paymentDate}
																</span>
																<p>By: Admin</p>
															</div>
															<Link
																className="read_more_details_blog"
																onClick={() => handleSendId(index._id)}
																to={`/blog/${cleanHeading}`}
															>
																<BsArrowRightShort />Read more
															</Link>
														</div>
													</div>
												);
											})
											:
											<div className="d-flex justify-content-center w-100">
												<Spin />
											</div>
									}

								</div>
								{/* <Pagination>
									<Pagination.Prev
										onClick={() => handlePageChange(currentPage - 1)}
										disabled={currentPage === 1}
									/>

									{pageNumbers.map((number) => (
										<React.Fragment key={number}>
											{number === currentPage ? (
												<Pagination.Item active>{number}</Pagination.Item>
											) : (
												<Pagination.Item onClick={() => handlePageChange(number)}>
													{number}
												</Pagination.Item>
											)}
										</React.Fragment>
									))}

									<Pagination.Next
										onClick={() => handlePageChange(currentPage + 1)}
										disabled={indexOfLastObject >= data.length}
									/>
								</Pagination> */}
							</div>
						</div>
						<div className='col-lg-4'>
							<Sidebar />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Blog;
