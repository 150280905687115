import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import
{
    Select,
    message,
    Button,
    Form,
    Upload
} from "antd";
import AuthGuard from '../authGuards';
import { BiPlusCircle } from 'react-icons/bi';
import adminService from '../../../common/adminService';


const CreateKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
   border: none;
   height: 100%;
   width: 100%;
   align-items: center;
   display: grid;
  }

  .ant-select-arrow {
    display: none
  }
`;

const CreateKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateKeywordButton = styled(Button)`
  float: right;
`;

function AddThumbnail(props)
{
    const Option = Select.Option;
    const [instituteList, setInstituteList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [newlySelectedFiles, setNewlySelectedFiles] = useState([]);

    useEffect(() =>
    {
        getInstitutes();
    }, [])


    const getInstitutes = async () =>
    {
        const response = await adminService.getAllInstitutes();
        if (response.data)
        {
            setInstituteList(response.data.data)
        }
    }

    const handleUpload = (info) =>
    {
        if (info.file.status === "done")
        {
            const uploadedFiles = info.fileList.map((file) => file.originFileObj);
            setNewlySelectedFiles(uploadedFiles);
            setFileList([...fileList, ...newlySelectedFiles]);
        }
    };

    const dummyRequest = ({ file, onSuccess }) =>
    {
        setTimeout(() =>
        {
            onSuccess("ok");
        }, 0);
    };

    const uploadButton = (
        <div>
            <BiPlusCircle />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        setLoading(true);
        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {
                const imageUrls = fileList.map((file) => file.name);
                console.log(imageUrls);
                const data = {
                    instituteId: values.instituteId,
                    files: imageUrls?.join(",") ?? "",
                };
                const res = await adminService.addThumbNail(data);
                if (res.data.success === true)
                {
                    const logData = { body: `Thumbnail created`, url: 'institute/thumbnail/add' }
                    const createLog = await adminService.addLog(logData)
                    if (createLog.data.success === true)
                    {
                        message.success("Thumbnail Successfully Created");
                        setLoading(false)
                    };
                    props.form.resetFields();
                    props.history.push("/admin/thumbnail");
                } else
                {
                    message.error("Error in creating thumbnail");
                    setLoading(false)
                }
            }
        }
        )


    }
    const { getFieldDecorator } = props.form;

    return (
        <>
            <AuthGuard />
            <CreateKeywordWrapper>
                <CreateKeywordHeader>
                    <h2>Create Institute ThumbNail</h2>
                    <CreateKeywordButton onClick={() => props.history.push("/admin/thumbnail")}>
                        Cancel
                    </CreateKeywordButton>
                </CreateKeywordHeader>
                <Form onSubmit={handleSubmit} className="login-form">

                    <Form.Item label="Institute">
                        {getFieldDecorator("instituteId", {
                            rules: [{ required: true, message: "please enter" }],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                className="input-select"
                                placeholder="Please Select"
                                filterOption={(input, option) =>
                                    (option.props.children ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                style={{
                                    width: "100%",
                                }}
                            >
                                {instituteList &&
                                    instituteList.map((d) => (
                                        <Option key={d._id} value={d._id}>{d.name}</Option>
                                    ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="ThumbNail Images">
                        <Upload
                            customRequest={dummyRequest}
                            listType="picture-card"
                            maxCount={1}
                            onChange={handleUpload}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>

                    </Form.Item>

                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </CreateKeywordWrapper>
        </>
    );
}

export default Form.create()(AddThumbnail);

