import React , { Component} from 'react'
// import Review from './DetailsReview';
import ChatBot from 'react-simple-chatbot';
// import { message } from 'antd';
import { ThemeProvider } from 'styled-components';

const  theme = {
    background: '#f5f8fb',
    fontFamily: 'Arial, Helvetica, sans-serif',
    headerBgColor: '#372db1',
    headerFontColor: '#FFFFFF',
    headerFontSize: '15px',
    botBubbleColor: '#372db1',
    botFontColor: '#FFFFFF',
    userBubbleColor: '#edb207',
    userFontColor: '#000000',
    headerTitle: 'BMBC Bot',
};
class CustomLinkStep extends Component {
    handleLinkClick = () => {

     window.open(this.props.link, '_blank'); // Open link in a new tab
        // window.location.href = this.props.link;
        this.props.triggerNextStep();
    };
  
    render() {
      return (
        <div>
          <p>Click the button to open a link:</p>
          <button onClick={this.handleLinkClick}>Open Link</button>
        </div>
      );
    }
}


const Chat = () => {
  
  // const [userInformation, setUserInformation] = useState([]);
  // useEffect(() => {
  //   const storedUserInformation = JSON.parse(localStorage.getItem('userInformation'));
  //   if (storedUserInformation) {
  //     setUserInformation(storedUserInformation);
  //   }
    
  // }, []);


  
   
  
  const steps =
    [
      {
          id: '1',
          message: "Greetings from BMBC...",
          trigger: '2'
      }, 
      {
          id: '2',
          message: "I'd be happy to assist you in finding the perfect coaching institute..",
          trigger: '3'
      },
      {
        id : '3',
        message : ' Could be please provide me with some details so that i can better assist you',
        trigger : '4'
      },
      {
        id : '4',
        message : 'Please enter your name',
        trigger: '5'
      },
      {
        id : '5',
        user : true,
        validator: (value) => {
          if (!value || value.trim() === '') {
            return 'Please provide a valid name.';
          }
          return true;
        },
        trigger: '6'
      },
      {
        id : '6',
        message : 'Please enter your valid email id',
        trigger: '7'
      },
      {
        id : '7',
        user : true,
        trigger: '8',
        validator: (value) => {
          // Perform email validation here
          return true;
        }

      },
      {
        id : '8',
        message : 'Thanks, Please enter your valid 10 digit contact number',
        trigger: '9'
      },
      {
        id : '9',
        user : true,
        trigger: '10',
        validator: (value) => {
          // Perform contact number validation here
          return true;
        },
      },
      {
        id : '10',
        message : 'Great!',
        trigger: '11'
      },
      {
          id: '11',
          message: "See the FAQs section , Most probably your doubts will be resolved.",
          trigger: '12'
      },

      {
          id:'12',
          options: [
              { value: '/faq', label: 'To go faqs, CLICK HERE',trigger : "13"
              }
          ],
      },
      {
          id:'13',
          component: <CustomLinkStep link='/faq' />,
            
          trigger:'14'
      },
      {
          id: '14',
          message: "If you still have any doubts, Please visit the Help Center",
          trigger: '15'

      },
      {
          id:'15',
          options: [
              { value: '/helpCenter', label: 'To go help center, CLICK HERE ',trigger:"16"}
               
          ],

      },
      {
          id:'16',
          component: <CustomLinkStep link="/helpCenter" />,
          trigger: '17'
      },
      {
        id : '17',
        message : 'Is there anything else I can assist you with? ',
        trigger : '18'
      },
      {
        id : '18',
        options:[
                  {value: 'yes', label: 'Yes,please', trigger: 'ok'},
                  {value : 'no', label : 'No, I am done', trigger : 'no'}
                  
        ],
      },
      {
        id : 'ok',
        message:"We have received your information, Our team will contact you very soon..",
        trigger:'no'
      },
      
      
      {
          id: 'no',
          message: "Thank you for choosing Book my Best Class !!",
          end : true
      }

  ]
  // const handleEnd = ({ steps }) => {
  //   const name = steps[5].value;
  //   const email = steps[7].value;
  //   const contact = steps[9].value;

  //   const userInfo = {
  //     name,
  //     email,
  //     contact
  //   };

  //   // Save user information to local storage
  //   localStorage.setItem('userInformation', JSON.stringify(userInfo));
    
  //   setUserInformation(userInfo);
  // };




  return (
    <div>
        <ThemeProvider theme={theme}>
        
        <ChatBot className="chatArea" headerTitle = 'BMBC Bot' headerFontColor = '#FFFFFF'
          steps={steps} // Pass conversation steps to the Chatbot
          // handleEnd={handleEnd} // Triggered when chat ends
        />
        </ThemeProvider>
    </div>
  );
  
}

export default Chat;
