import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
// import SearchComponent from "../search";
import moment from "moment";

const UserListWrapper = styled.div`
  padding: 20px;
`;

const UserListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const UserListCreateButton = styled(Button)`
  float: right;
`;

const UserListTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const SearchWrapper = styled.div`
//   position: relative;
//   z-index: 1;
// `;

function UserListComponent() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    adminService.getAllAdmins().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data.reverse());
      setPagination(paginationObj);
    });
  };

  const handleStatus = async (id, status) => {
    let stat
    if(status === 'enabled'){
      stat = 'disabled'
    } else {
      stat = 'enabled'
    }
    const updateStatus = await adminService.updateAdminStatus(id, stat);
    console.log(updateStatus);
    fetchData()
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Admin Type",
      dataIndex: "adminType",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          <Button type="primary" style={{ marginRight: 2 }}>
            <Link to={`/admin/editAdminUser/${record}`}>Edit</Link>
          </Button>
          <Button onClick={() => handleStatus(data._id, data.status)} type="success" style={{ marginRight: 2 }}>
            {data.status === "enabled" ? "Disable" : "Enable"}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <AuthGuard />
      <TopNav />
      <UserListWrapper>
        <UserListHeader>
          <h2>Admin Panel Users</h2>
          <UserListCreateButton type="primary">
            <Link to="/admin/createAdminUser">Create</Link>
          </UserListCreateButton>
        </UserListHeader>
        {/* <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper> */}
        <UserListTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </UserListWrapper>
    </>
  );
}

export default UserListComponent;
