import React from 'react';
import BlogDetails from './blog-details';
import "../assets/css/blog.css";
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useParams } from "react-router-dom"
import Layout from '../global-components/Layout';
const BlogContent = () =>
{
    const [heading, setHeading] = useState("");
    const [logoImg, setLogoImg] = useState("");
    const handleData = (name, logo) =>
    {
        setHeading(name);
        setLogoImg(logo)
    };
    const { cleanHeading } = useParams();
    return (
        <>
            <Helmet>
                <title>{heading?.slice(0, 30)}</title>
                <meta name="description" content={heading} />
                <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
                <meta property="og:title" content={heading?.slice(0, 30)} />
                <meta property="og:description" content={heading} />
                <meta property="og:image" content={logoImg} />
                <link rel="canonical" href={`https://bookmybestclass.com/blog/${cleanHeading}`} />
                <meta property="og:url" content="https://bookmybestclass.com"></meta>
                <meta property="og:site_name" content="bookmybestclass"></meta>
            </Helmet>
            <Layout>
                <BlogDetails onData={handleData} />
            </Layout>


        </>
    )
}

export default BlogContent