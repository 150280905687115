import React from 'react';
// import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
// import whyBmBc from "./media/banner/whybmbcban.png"
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png"
import Layout from './global-components/Layout';
const About_v1 = () =>
{
    return <div>
        <Helmet>
            <title>Find Online/Offline Coaching Classes: Get Personalized Guidance</title>
            <meta name="description" content="Get coaching classes tailored to your specific needs and goals. Discover our platform to find a wide selection of coaching centers offering personalized courses and expert guidance. Choose the best coaching classes to enhance your academic journey." />
            <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
            <meta property="og:title" content="Find Online/Offline Coaching Classes: Get Personalized Guidance" />
            <meta property="og:description" content="Get coaching classes tailored to your specific needs and goals. Discover our platform to find a wide selection of coaching centers offering personalized courses and expert guidance. Choose the best coaching classes to enhance your academic journey." />
            <meta property="og:image" content={logoBmbc} />
            <link rel="canonical" href="https://bookmybestclass.com/about" />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            {/* <PageHeader image={whyBmBc} /> */}
            <AboutV4 />
        </Layout>
       
    </div>
}

export default About_v1

