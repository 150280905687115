import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function AuthGuard() {
  const history = useHistory();

  useEffect(() => {
    const adminToken = sessionStorage.getItem("adminToken");
    const adminRole = sessionStorage.getItem("adminRole")
    const adminType = sessionStorage.getItem("adminType")
    // const adminAccessRights = sessionStorage.getItem("adminAccessRights")
    if (!adminToken || !adminType || !adminRole ) {
      history.push("/admin/signin");
      // sessionStorage.clear()
    }
  }, [history]);

  return null;
}

export default AuthGuard;
