import React, { useState, useEffect } from "react";
import { Button, Form, message, Input } from "antd";
import styled from "styled-components";
import adminService from "../../../../common/adminService";
import { useParams } from "react-router-dom";

import AuthGuard from "../../authGuards";
const EditKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const EditKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditKeywordButton = styled(Button)`
  float: right;
`;

function EditKeywords(props) {
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState({});
  const { id } = useParams();
  useEffect(() =>
  { 
    const fetchKeyword = async () =>
    {
      const response = await adminService.getKeywordById(id);
      const data = response.data.data;
      setKeyword(data);
      props.form.setFieldsValue({ ...data });
    };

    fetchKeyword();
    // eslint-disable-next-line
  }, []); 
  const handleSubmit = (e) =>
  {

    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      console.log(values.name.trim().length === 0);
      if (values.name.trim().length === 0)
      {
       message.error("please enter")
      } else
      {
        if (!err)
        {
          setLoading(true);
          const updateKeyword = await adminService.updateKeyword(id, values);
          setLoading(false);
          if (updateKeyword.data.success === true)
          {
            const logData = { body: `Keyword ${values.name} Updated`, url: 'keyword/update', oldData: updateKeyword.data.dataUpdate.oldData, newData: updateKeyword.data.dataUpdate.newData }
            const createLog = await adminService.addLog(logData)
            if (createLog.data.success === true)
            {
              message.success("Keyword Successfully Updated");
            };


            props.history.push("/admin/newKeywords");
          } else
          {
            message.error("Error in updating Keyword");
          }
        }
      }
     
    });
  };


  const { getFieldDecorator } = props.form;

  return (
    <>
    <AuthGuard />
    <EditKeywordWrapper>
      <EditKeywordHeader>
        <h2>Edit Keyword</h2>
        <EditKeywordButton
          onClick={() => props.history.push("/admin/newKeywords")}
        >
          Cancel
        </EditKeywordButton>
      </EditKeywordHeader>
      <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item label="Keyword">
          {getFieldDecorator("name",
           {initialValue: keyword.name,
            rules: [{ required: true, message: "Please enter" }],
          })(<Input placeholder="Please enter" />)}
        </Form.Item>
       
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </EditKeywordWrapper>
    </>
  );
}

export default Form.create()(EditKeywords);
