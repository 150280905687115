import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userService from "../../common/userService";
import { useParams } from "react-router-dom";
import "../assets/css/review.css";
import "../assets/css/instituteDetails.css";
import "../assets/css/batchDetail.css";
import { Modal, message } from "antd";
import axios from "axios";
import { FaStar, FaRegStar } from 'react-icons/fa';
import logoUser from "../media/banner/avtarbmbc.jpg";
import InstituteBatch from "./InstituteBatch";
import Benifits from "../global-components/Benifits";
const getLocalData = () =>
{

  let userActive = localStorage.getItem("id");

  if (userActive)
  {
    return localStorage.getItem("id")
  } else
  {
    return [];
  }
}
export const InstituteDetails = ({ onData }) =>
{
  const [instituteDetail, setInstituteDetail] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [ratingComment, setRatingComment] = useState('');
  const [ratingCount, setRatingCount] = useState('');
  const [whyBmbcModal, setWhyBmbcModal] = useState(false)
  const testURL = process.env.REACT_APP_API_URL_LIVE
  const { id } = useParams();

  const [userData] = useState(getLocalData());

  useEffect(() =>
  {
    getInstituteDetail(id);
    // handleReviews(id);
    getLocalData();
    // eslint-disable-next-line
  }, []);

  const handleStarClick = (rating) =>
  {
    setRatingCount(rating.toString());
  };

  // const handleInputChange = (e) =>
  // {
  //   const inputValue = e.target.value;
  //   const isValidInput = /^[1-5]$/.test(inputValue);

  //   if (isValidInput || inputValue === '')
  //   {
  //     setRatingCount(inputValue);
  //   }
  // };
  const getInstituteDetail = async (id) =>
  {
    const rawData = await userService.getInstitueById(id);
    const data = rawData.data.data;
    setInstituteDetail(data);
    const name = data.name;
    const description = data.info;
    const city = Array.isArray(data.city) ? data.city.join(', ') : data.city;
    const exam = Array.isArray(data.exam) ? data.exam.join(', ') : data.exam;
    const logo = data.image;
    // Call the parent component function with the data
    onData(name, description, city, exam, logo);
  };

  const history = useHistory();

  const handlePostReview = async (e) =>
  {
    e.preventDefault();
    const intId = instituteDetail._id
    const userId = localStorage.getItem('id');

    if (!userId)
    {
      localStorage.setItem("redirectUrl", window.location.pathname)

      message.error('Login to give a review');
      history.push('/login')
      setTimeout(() =>
      {
        message.error('')
      }, 2000);
    } else if (ratingComment === '' || ratingCount === '')
    {
      message.warn('Please fill in all fields');
    } else
    {
      const obj = { rating: ratingCount, comment: ratingComment, userActiveId: userData };
      const add = await userService.addInstitueReview(intId, obj);

      if (add.data.success === true)
      {
        message.success('Rating Added');
        handleReviews();
        setRatingComment("");
        setRatingCount("");
      } else
      {
        message.error('An error occurred. Please try again later');
      }
    }
  };

  const handleReviews = async () =>
  {

    try
    {
      const res = await axios.get(testURL + `instituteReview/instituteId/${id}`);
      const reviewData = res.data.data;
      setReviews(reviewData);
    } catch (error)
    {
      console.log(error);
    }
  }

  const bmbcModalFunction = () =>
  {
    setWhyBmbcModal(true);
  }

  const handleCancelBMBCwHy = () =>
  {
    setWhyBmbcModal(false)
  }


  return (
    <>
      <div className="ltn__shop-details-area padding_area_inst">
        <div className="container">
          {
            instituteDetail && (
              <>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="batch_detail_box institute_detail_box">
                      <h2 className="batch_header">Institute Details</h2>
                      <div className="batch_detail_box_head">
                        <div className="row gx-1">
                          <div className="col-lg-10 col-md-9 col-12">
                            <div className="inst_col_view">
                              <div>
                                <h1>
                                  Institute Name : <p>{instituteDetail.name}
                                    <span className="ps-1">({instituteDetail?.instituteKeyword?.keyword?.name})</span>
                                  </p>
                                </h1>
                                <div className="exam_names">
                                  <h1 className="mb-0">
                                    Exam Name :
                                  </h1>
                                  <span className="d-flex">
                                    {instituteDetail?.examId?.map((examName, index) => (
                                      <React.Fragment key={index}>
                                        <p>{examName.name}</p>
                                        {index < instituteDetail.examId.length - 1 && <span className="mx-2"> , </span>}
                                      </React.Fragment>
                                    ))}
                                  </span>
                                  {/* // <p key={index} className="pe-2">{examName.name + "  "}</p> */}
                                </div>
                              </div>
                              <div className="img_tag_mob_view">
                                <img src={instituteDetail?.image} alt="logoBanner" />
                              </div>
                            </div>
                            <h1 className="mb-2">City : </h1>
                            <div className="batch_facilities inst_city_facilities">
                              <ol>
                                {instituteDetail.city?.map((facility, index) => (
                                  <li key={index}><p className="m-0">{facility}</p></li>
                                ))}
                              </ol>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 d-none d-md-block">
                            <div className="img_tag">
                              <img src={instituteDetail?.image} alt="logoBanner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>Institute Description :</h2>
                      <p className="mb-0 inst_info_txt">{instituteDetail?.info}</p>
                      {/* <div className="batch_timings desk_v">
                        <h4 className="title_head mb-0">Class Timings</h4>
                        <ul>
                          <li>{instituteDetail.timings}</li>
                        </ul>
                      </div> */}
                      <div className="book_btn_text_mob">
                        <InstituteBatch instituteId={id} />
                        <button type="primary" className="pay_now_btn blue_btn ms-1" onClick={bmbcModalFunction}>
                          Why BMBC
                        </button>
                      </div>
                      <div className="comment_lv_fm cmnt_desk_view">
                        <form action="#">
                          <h4>Add a Review</h4>
                          <div className="mb-15">
                            <div className="add-a-review">
                              <h6>Your Rating:</h6>
                              <div className="product-ratting">
                                <ul>
                                  {[1, 2, 3, 4, 5].map((rating) => (
                                    <li key={rating}>
                                      <button
                                        type="button"
                                        className={`star-icon${rating <= parseInt(ratingCount) ? ' active' : ''}`}
                                        onClick={() => handleStarClick(rating)}
                                      >
                                        <FaStar />
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="leave_cmnt_input">
                            <input type="text" className="form-control" value={ratingComment} onChange={(e) => setRatingComment(e.target.value)} placeholder="Type your comments...." />
                            <button
                              className="sign_up"
                              type="submit"
                              onClick={(e) => handlePostReview(e)}
                            >
                              Post
                            </button>
                          </div>
                        </form>

                        <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 mt-30 ltn__product-details-review-inner mobile_sidedesk">
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <h4 className="title_head mb-0">
                                Student Ratings
                              </h4>

                              <div className="product-ratting mt-0">
                                <ul className="ps-3">
                                  {Array.from({ length: 5 }).map((_, index) => (
                                    <li key={index} className="me-2">
                                      <span>
                                        {index < instituteDetail?.rating ? <FaStar /> : <FaRegStar />}
                                      </span>
                                    </li>
                                  ))}
                                  <li><p>({instituteDetail?.rating})</p></li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="btn btn-light btn-outline-secondary" onClick={handleReviews}>See Reviews ({instituteDetail?.ratingCount - 1})</button>
                          </div>

                          <hr className="d-block" />
                          <div className="row gy-4 mt-2 mb-4 reviews_col_row">
                            {
                              reviews.length > 0 &&
                              reviews?.map((index) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={id}>
                                  <div className="review_card">
                                    <div className="heading_rev">
                                      <img src={index.userId?.photo ? index.userId?.photo : logoUser} alt="hjghdhds" />
                                      <div className="name">
                                        <span className="rating_icon">
                                          <h4>
                                            {index?.userId?.name}

                                          </h4>
                                          <h5>
                                            {
                                              index?.userId?.city
                                            }
                                          </h5>
                                        </span>
                                      </div>
                                    </div>
                                    <p className="cmt">
                                      {
                                        index?.message
                                      }
                                    </p>
                                    <span className="rating_star_number">
                                      ({index?.rating}) <FaStar />
                                    </span>
                                  </div>
                                </div>
                              ))
                            }
                            {/* <p>{batchReview.length > 0 ? "" : "No reviews yet"}</p> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="batch_right_column_wrap">
                      <div className="book_btn_text2">
                        <InstituteBatch instituteId={id} />
                        {/* WHY BMBC */}
                        <button className="pay_now_btn bg_blue ms-1" onClick={bmbcModalFunction}>
                          Why BMBC
                        </button>
                        <Modal
                          className="thankYouPage"
                          visible={whyBmbcModal}
                          onCancel={handleCancelBMBCwHy}
                          footer={false}
                        >
                          <Benifits />
                        </Modal>
                        {/* WHY BMBC ENDS */}
                      </div>
                      <div className="comment_lv_fm cmnt_mobile_view">
                        <form action="#">
                          <h4>Add a Review</h4>
                          <div className="mb-15">
                            <div className="add-a-review">
                              <h6>Your Rating:</h6>
                              <div className="product-ratting">
                                <ul>
                                  {[1, 2, 3, 4, 5].map((rating) => (
                                    <li key={rating}>
                                      <button
                                        type="button"
                                        className={`star-icon${rating <= parseInt(ratingCount) ? ' active' : ''}`}
                                        onClick={() => handleStarClick(rating)}
                                      >
                                        <FaStar />
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="leave_cmnt_input">
                            <input type="text" className="form-control" value={ratingComment} onChange={(e) => setRatingComment(e.target.value)} placeholder="Type your comments...." />
                            <button
                              className="sign_up"
                              type="submit"
                              onClick={(e) => handlePostReview(e)}
                            >
                              Post
                            </button>
                          </div>
                        </form>
                        <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 mt-30 ltn__product-details-review-inner mobile_sidedesk">
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <h4 className="title_head mb-0">
                                Student Ratings
                              </h4>
                              <div className="product-ratting mt-0">
                                <ul className="ps-3">
                                  {Array.from({ length: 5 }).map((_, index) => (
                                    <li key={index} className="me-2">
                                      <span>
                                        {index < instituteDetail?.rating ? <FaStar /> : <FaRegStar />}
                                      </span>
                                    </li>
                                  ))}
                                  <li><p>({instituteDetail?.rating})</p></li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="btn btn-light btn-outline-secondary" onClick={handleReviews}>See Reviews ({instituteDetail?.ratingCount - 1})</button>
                          </div>

                          <hr className="d-block" />
                          <div className="row gy-4 mt-2 mb-4 reviews_col_row">
                            {
                              reviews.length > 0 &&
                              reviews.map((review, id) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={id}>
                                  <div className="review_card">
                                    <div className="heading_rev">
                                      <img src={review.userId?.photo ? review.userId?.photo : logoUser} alt="hjghdhds" />
                                      <div className="name">
                                        <span className="rating_icon">
                                          <h4>
                                            {review?.userId?.name}

                                          </h4>
                                          <h5>
                                            {
                                              review?.userId?.city
                                            }
                                          </h5>
                                        </span>
                                      </div>
                                    </div>
                                    <p className="cmt">
                                      {
                                        review?.message
                                      }
                                    </p>
                                    <span className="rating_star_number">
                                      ({review?.rating}) <FaStar />
                                    </span>
                                  </div>
                                </div>
                              ))
                            }
                            {/* <p>{batchReview.length > 0 ? "" : "No reviews yet"}</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  );


};

export default InstituteDetails;
