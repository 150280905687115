import React from 'react';
import { Modal, Spin, Table, Tag } from 'antd';
import { FaStar } from "react-icons/fa";
import { useState } from 'react';
import userService from '../../common/userService';
import DescribeBatch from './DescribeBatch';
const TableComp = ({ compareData }) =>
{
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false)
    const handleBatch = (record) =>
    {
        setVisible(true);
        fetch(record);

    }
    const fetch = async (record) =>
    {
        setLoader(true);
        const resData = await userService.getBatchById(record);
        try
        {
            if (resData.data.success === true)
            {
                setData(resData.data.data);
                setLoader(false)
            }

        } catch (error)
        {
            console.log(error);
        }
    }
    const isMobileView = window.innerWidth <= 767;

    const columns = [
        {
            title: 'Institute Name',
            dataIndex: 'instituteId.name',
            headerClassName: 'vertical-header',
            render: (text) =>
            {
                if (isMobileView)
                {
                    return (
                        <>
                            <span>Institute Name</span>
                            <span>{text}</span>
                        </>
                    );
                } else
                {
                    return text;
                }
            },
        },
        {
            title: 'Rating',
            dataIndex: 'instituteId.rating',
            key: 'instituteId.rating',
            render: (rating) => (
                <>
                    {isMobileView && <span>Rating</span>}
                    <Tag color="#001529" style={{ minWidth: '50px' }} key={rating}>
                        <FaStar /> {rating.toFixed(1) + "0"}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Facilities',
            dataIndex: 'facilities',
            key: 'facilities',
            width: '30%',
            render: (facilities) => (
                <>
                    {isMobileView ? <span>Facility</span> : null}
                    <div className="mb-2 facilty_tag" >
                        {facilities?.map((facility, index) => (


                            <Tag key={index}>
                                {facility.name}
                            </Tag>



                        ))}
                    </div>

                </>
            ),
        },
        {
            title: 'Plan',
            dataIndex: 'planName.title',
            key: 'planName',
            render: (text) =>
            {
                if (isMobileView)
                {
                    return (
                        <>
                            <span>Plan</span>
                            <span>{text}</span>
                        </>
                    );
                } else
                {
                    return text;
                }
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) =>
            {
                if (isMobileView)
                {
                    return (
                        <>
                            <span>Amount</span>
                            <span>{`Rs.${text}`}</span>
                        </>
                    );
                } else
                {
                    return text;
                }
            },
        },
        {
            title: 'Batch Name',
            dataIndex: 'batchName',
            key: 'batchName',
            render: (text) =>
            {
                if (isMobileView)
                {
                    return (
                        <>
                            <span>Batch Number</span>
                            <span>{text}</span>
                        </>
                    );
                } else
                {
                    return text;
                }
            },
        },
        {
            title: 'Action',
            dataIndex: '_id',
            render: (record) => (
                <div className='d-flex w-100 justify-content-center'>
                    <button className="comp_view_btn" onClick={() => handleBatch(record)} style={{ marginRight: 2 }}>
                        View Details
                    </button>
                </div>
            ),
        },
    ];


    // const stripedRowClassName = (record, index) =>
    // {
    //     return index % 2 === 0 ? 'striped-row' : 'table_row';
    // };
    return (
        <>
            {
                loader ?

                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <Spin />
                    </div>
                    :
                    <>
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={compareData}
                            rowKey={"_id"}
                            rowClassName="table_row"
                            className='result_table'
                        />

                        <Modal
                            title="Batch detail"
                            className='cmp_batches'
                            style={{ top: 10 }}
                            visible={visible}
                            width={window.innerWidth > 1191 ? "60%" : window.innerWidth > 1091 ? "70%" : "90%"}
                            onCancel={() => setVisible(false)}
                            footer={[

                            ]}
                        >
                            <DescribeBatch data={data} />
                        </Modal>


                    </>


            }

        </>

    )

}

export default TableComp;