import React from 'react'
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from "moment";
const BatchCard = ({ batchList, hidden, onCompare, onView, showMore, checkedId, seriesName }) =>
{
    const permissionIds = [
        "6461de19cd10f83e9358827b",
        "64be659d4dcfa238cc4b2b14",
        "64e473a04f25d7076635288a",
        "64e487ff4f25d707663528b9",
        "64be65bb4dcfa238cc4b2b16",
        "6463245acd10f83e9358828e"
    ]
    
    return (
        batchList.length > 0 && batchList.map((ele) =>
        {
            return (
                <div className={` col-xl-3 col-md-4 col-sm-6 col-${window.innerWidth < 400 ? 12 : 6}`} key={ele._id}>
                    <div className={hidden ? "batch_card_details active" : "batch_card_details"}>
                        <div className="left_box">
                            {ele?.batchKeyword &&
                                ele?.keywordStatus !== "Ended" && (
                                    <h5 className="keyword_tags">
                                        <p className="badge_btn">
                                            {ele.status}
                                        </p>
                                        <span>{ele.batchKeyword?.keyword?.name}</span>

                                    </h5>
                                )
                            }
                            <img className="img-fluid img-responsive rounded product-image" loading="lazy" src={ele?.instituteId?.image} alt={ele.image} />
                            {
                                ele.planName?.title && (
                                    <h5 className="marquee_tags">
                                        {/* <p>Batch Type</p> */}
                                        <span>{ele.planName?.title}</span>
                                    </h5>
                                )
                            }
                        </div>
                        <div className="center_box">
                            <div className="center_details">
                                <h5 className="my-0">
                                    <span>
                                        {ele.examId?.name}
                                    </span>
                                </h5>
                                <h4>
                                    <FaStar /> <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span>
                                </h4>
                                <span className="mob_category">{ele?.category}</span>
                            </div>
                            <h5 className="desk_inst">
                                <span>Institute :</span>
                                <span>{ele.instituteId?.name?.slice(0, 13) + ".."}</span>
                            </h5>
                            <h5 className="mob_inst">
                                <span>{ele.instituteId?.name?.slice(0, 13) + ".."}</span>
                            </h5>
                            <h5 className="mob_batch">
                                <span>Batch {seriesName ? seriesName : "Number"}:</span>
                                <span>{ele?.batchName}</span>
                            </h5>
                            {permissionIds.includes(ele.examId._id) &&
                                <h5 className="mob_batch">
                                    <span>Branch :</span>
                                    <Link to={`/batch-detail/${ele._id}`}>
                                        {ele.branchName?.length > 0 && (
                                            <>
                                                {ele.branchName.slice(0, 3).map((branch, index) =>
                                                {
                                                    const truncatedName = branch.name;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <span
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {truncatedName}
                                                            </span>
                                                            {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                        
                                                        </React.Fragment>
                                                    );
                                                })}
                                                {ele?.branchName.length > 4 &&
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        ...
                                                    </span>
                                                }
                                            </>
                                        )}

                                    </Link>
                                </h5>
                            }
                            <h5 className="mob_batch">
                                <span>Language :</span>
                                <span style={{ color: "#2292ca" }}>
                                    {ele?.languages?.name ? ele?.languages?.name : "English"}
                                </span>
                            </h5>
                            {ele.category !== 'Online' &&
                                <h5 className="mob_batch">
                                    {ele.category !== 'Online' && <span>City :</span>}
                                    {ele.category !== 'Online' && <span>{ele.city}</span>}
                                </h5>
                            }
                            <h3 className="mob_price">
                                <span>Price :</span>
                                <span className="price_tag" style={{ color: "#2292ca" }}>
                                    {ele.amount.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                    }).split('.')[0]}
                                    <span className="incl_gst">incl. GST</span>
                                </span>
                            </h3>
                            <h5 className="desk_btch_number">
                                <span>Batch No :</span>
                                <span>
                                    {ele.batchName}
                                </span>
                            </h5>
                            {/* <h5 className="desk_strt_dt">
                                <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                            </h5> */}
                        </div>
                        <div className="right_box">
                            <h5>
                                <span>Price :</span>
                                <span style={{ color: "#2292ca" }}>
                                    {ele.amount.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                    }).split('.')[0]}
                                    <span className="incl_gst">incl. GST</span>
                                </span>
                            </h5>
                            {ele?.examId.name === "GATE" && <h5>
                                <span>Branch :</span>
                                <Link to={`/batch-detail/${ele._id}`}>
                                    {ele.branchName?.length > 0 && (
                                        <>
                                            {ele.branchName.slice(0, 3).map((branch, index) =>
                                            {
                                                const truncatedName = branch.name;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <span
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {truncatedName}
                                                        </span>
                                                        {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                       
                                                    </React.Fragment>
                                                );
                                            })}
                                            {ele?.branchName.length > 4 &&
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    ...
                                                </span>
                                            }
                                        </>
                                    )}
                                </Link>
                            </h5>
                            }
                            <h5>
                                <span>Language :</span>
                                <span style={{ color: "#2292ca" }}>
                                    {ele?.languages?.name ? ele?.languages?.name : "English"}
                                </span>
                            </h5>
                            <h5>
                                <span className='w-auto'>{ele?.category}</span>
                                {
                                    ele?.category === "Offline" && <p className="city">{ele?.city}</p>
                                }
                            </h5>
                        </div>
                        <div className="batch_card_btns">
                            <div className="see_btn_wrap_batch">
                                <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary  view_al_btch" type="button">View Details</Link>
                                {
                                    !showMore &&
                                    <button className="btn view_more_batch_btn" onClick={() => onView(ele.instituteId._id, ele.examId._id, ele?.city)}>All Batches {ele.count && `(${ele.count})`}</button>
                                }
                                {showMore && hidden && (
                                    <button className={checkedId.includes(ele._id) ? "compare_checked_active ms-1 mb-0" : "compare_check_button mb-0"} onClick={() => onCompare(ele)}>
                                        {checkedId.includes(ele._id) ? "Selected" : "Select"}
                                    </button>
                                )}

                            </div>
                        </div>
                        {
                            ((ele?.batchKeyword && ele?.keywordStatus === "Ended") ||
                                (!ele?.batchKeyword && ele?.keywordStatus === "Active")) &&
                            (
                                <p className="badge_btn2">
                                    {ele.status}
                                </p>
                            )
                        }
                    </div>
                </div>
            )
        })

    )

}

export default BatchCard