import React, { useState } from "react";
import { Button, Form, Input, message, Row, Col } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";

const CreateNewsWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }
`;

const CreateNewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateNewsButton = styled(Button)`
  float: right;
`;

function CreateNews(props)
{
    const [loading, setLoading] = useState(false);


    const handleSubmit = (e) =>
    {
        e.preventDefault();
        props.form.validateFields(async (err, values) =>
        {



            if (!err)
            {
                setLoading(true);
                // console.log(startDate);
                const createNews = await adminService.addNews(values);
                setLoading(false);
                if (createNews.data.success === true)
                {
                    const logData = {body:  `News ${values.news} created`, url: 'news/create' }
                    const createLog = await adminService.addLog(logData)
                    if (createLog.data.success === true)
                    {
                        message.success("News Successfully Published");
                    };
                  
                    props.form.resetFields();
                    props.history.push("/admin/news");
                } else
                {
                    message.error("Error in Publish News");
                }
            }
        });
    };

    const { getFieldDecorator } = props.form;
    return (
        <>
            <AuthGuard />
            <CreateNewsWrapper>
                <CreateNewsHeader>
                    <h2>Publish News</h2>
                    <CreateNewsButton onClick={() => props.history.push("/admin/news")}>

                        Cancel
                    </CreateNewsButton>
                </CreateNewsHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="News">
                                {getFieldDecorator("news", {
                                    rules: [{ required: true, message: "please enter news headlines" }],
                                })(<Input placeholder="please enter news headlines" />)}
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="URL">
                                {getFieldDecorator("redirect", {
                                    rules: [{ required: true, message: "please enter link URL for see news" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Publish
                        </Button>
                    </Form.Item>
                </Form>
            </CreateNewsWrapper>
        </>
    );
}

export default Form.create()(CreateNews);
