import React, { useEffect, useState } from 'react'
import AuthGuard from '../authGuards'
import TopNav from '../topnavComponent'
// import SearchComponent from '../search'
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Button } from 'antd';
import adminService from '../../../common/adminService';
const ThumbManagementWrapper = styled.div`
  padding: 20px;
`;

const ThumbManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ThumbManagementCreateButton = styled(Button)`
  float: right;
`;


const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const BatchThumb = () =>
{
    const [data, setdata] = useState(null)
    const fetch = async () =>
    {
        try {
            const result = await adminService.getAllInstitutes();
            if (result.data)
            {
                setdata(result.data.data)
            }
        } catch (error) {
            console.log(error);
        }
       
    }
    console.log(data);

    useEffect(() =>
    {
        fetch();
    })


    return (
        <>
            <AuthGuard />
            <TopNav />
            <ThumbManagementWrapper>
                <ThumbManagementHeader>
                    <h2>Thumbnail Management</h2>
                    <ThumbManagementCreateButton type="primary">
                        <Link to="/admin/createThumbnail">
                            Create
                        </Link>
                    </ThumbManagementCreateButton>
                </ThumbManagementHeader>
                <SearchWrapper>
                    {/* <SearchComponent data={data} onSearch={handleSearch} /> */}
                </SearchWrapper>
            </ThumbManagementWrapper>
        </>
    )
}

export default BatchThumb;