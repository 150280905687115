import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Table, Upload, message } from "antd";
import profilePhoto from "../media/banner/avtarbmbc.jpg";
import "../assets/css/profileinput.css";
import userService from "../../common/userService";
import adminService from "../../common/adminService";
// import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import pica from "pica";
import axios from "axios";
import styled from "styled-components";
import ChnagePassword from "../section-components/PasswordUpdate";
import { BiCloudDownload } from "react-icons/bi";
const StyledTableWrapper = styled.div`
  .ant-table {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: scroll;

    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      color: #333;
      font-weight: 600;
      text-align: center;
      padding: 12px;
    }

    .ant-table-tbody > tr > td {
      text-align: center;
      padding:12px;
    }

    .ant-table-row {
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
`;


export const MyAccount = (props) =>
{
    const testURL = process.env.REACT_APP_API_URL_LIVE
    // State variables
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [photo, setPhoto] = useState("");
    const [qualification, setQualification] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    // TBAS STATE
    const [profileTabs, setProfileTabs] = useState(true);
    const [passwordTabs, setPasswordTabs] = useState(false);
    const [transactionTabs, setTransactionTabs] = useState(false)
    const [image, setImage] = useState(null);
    // const 
    // const [crop, setCrop] = useState({});

    useEffect(() =>
    {

        fetchUserData();
        fetch();
        // eslint-disable-next-line
    }, []);

    const fetchUserData = async () =>
    {
        setIsLoading(true);

        try
        {
            const userId = localStorage.getItem("id");
            const rawData = await userService.getUserDetails(userId);
            const userData = rawData.data.data;
            setData(userData);
            setName(userData.name);
            setEmail(userData.email);
            setPhoto(userData.photo);
            setCity(userData.city);
            setQualification(userData.qualification);
            setPhone(userData.phone);
            setAddress(userData.address);
            setIsLoading(false);
        } catch (error)
        {
            console.error(error);
            setIsLoading(false);
        }
    };

    const handleUpdateProfile = async (e) =>
    {
        e.preventDefault();
        const id = data._id;

        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {
                setIsLoading(true);
                const createBatch = await adminService.updateUser(values, image, id);
                setIsLoading(false);
                if (createBatch.data.success === true)
                {
                    message.success("Profile Successfully Updated");
                    setIsLoading(true);
                    window.location.reload(true);
                } else
                {
                    message.error("Error in Update Profile");
                }
            }
        });

    }
    const [profileError, setProfileError] = useState("")
    const allowedFileTypes = ['image/svg+xml', 'image/jpeg', 'image/png'];
    
    const beforeUpload = (file) =>
    {
        const fileType = file.type;
        if (!allowedFileTypes.includes(fileType))
        {
            setProfileError('You can only upload SVG, JPG, or PNG files.');
            setTimeout(() =>
            {
                setProfileError(null);
            }, 2000);
            return Promise.reject('Invalid file type');
        }
        return Promise.resolve();
    };

    const handleUpload = async (info) =>
    {
        const file = info.file.originFileObj;
        try
        {
            if (info.file.status === 'done')
            {
                const image = new Image();
                const imageURL = URL.createObjectURL(file);
                image.src = imageURL;

                image.onload = async () =>
                {
                    const width = 300;
                    const height = 300;

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);

                    const resizedCanvas = document.createElement('canvas');
                    resizedCanvas.width = width;
                    resizedCanvas.height = height;
                    const resizedCtx = resizedCanvas.getContext('2d');

                    const picaInstance = pica();

                    await picaInstance.resize(canvas, resizedCanvas);

                    resizedCtx.drawImage(canvas, 0, 0);

                    const resizedBlob = await new Promise((resolve) =>
                    {
                        resizedCanvas.toBlob((blob) =>
                        {
                            resolve(blob);
                        }, 'image/jpeg', 0.5); // Adjust the quality parameter as needed
                    });

                    // Create a new File object with the resized Blob and the original file name
                    const resizedFile = new File([resizedBlob], file.name, { type: resizedBlob.type });
                    setImage(resizedFile);
                    // Revoke the temporary URL
                    URL.revokeObjectURL(imageURL);
                };
            }
        } catch (error)
        {
            console.error('Error resizing the image:', error);
        }
    };

    const handleRemove = () =>
    {
        setImage(null);
    };

    const dummyRequest = ({ file, onSuccess }) =>
    {
        setTimeout(() =>
        {
            onSuccess('ok');
        }, 0);
    };

    const { getFieldDecorator } = props.form;

    const [userTransaction, setUserTransaction] = useState([]);
    const [pagination, setPagination] = useState({});
    const userId = localStorage.getItem("id");

    const fetch = async () =>
    {
        const rawData = await axios.get(testURL + `transaction/list/?userId=${userId}`)
        if (rawData.data.success === true)
        {
            const paginationObj = { ...pagination };
            paginationObj.total = rawData.data.data.length;
            setUserTransaction(rawData.data.data);
            setPagination(paginationObj);
        }
    };

    const handleTableChange = async (pagination, filters, sorter) =>
    {
        const pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const handleProfileTab = () =>
    {
        setProfileTabs(true);
        setPasswordTabs(false);
        setTransactionTabs(false);
    }

    const handlePasswordTab = () =>
    {
        setProfileTabs(false);
        setPasswordTabs(true);
        setTransactionTabs(false);
    }

    const handleTransactionTab = () =>
    {
        setProfileTabs(false);
        setPasswordTabs(false);
        setTransactionTabs(true);
    }

    const downloadInvoice = async (data) =>
    {
        const transactionId = data._id;
        
        const url = testURL + `transaction/invoice-send?type=download&transactionId=${transactionId}`;
        try
        {
            const res = await axios.get(url);
            if (res)
            {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                message.success("Invoice downloaded successfully");
                document.body.removeChild(link);
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    const columns = [
        {
            title: "Transaction Id",
            dataIndex: "_id",
        },
        {
            title: "Institute Name",
            dataIndex: "batchId.instituteId.name",
        },
        {
            title: "Batch Number",
            dataIndex: "batchId.batchName",
        },

        {
            title: "Price",
            dataIndex: "amount",
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (createdAt) =>
            {
                const date = new Date(createdAt);
                return date.toDateString();
            },
        },
        {
            title: "Action",
            render: (data) => (
                <>
                    <Button
                        onClick={() => downloadInvoice(data)}
                        type="danger"
                        style={{ marginRight: 2 }}
                    >
                        <BiCloudDownload />
                    </Button>
                </>
            ),
        }
    ];

    return (

        <div className="liton__wishlist-area profile_section_area padding_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <div className="tabs_btn_wrap">
                            <div className="image_wrap">
                                <div>
                                    <span>
                                        <img src={photo ? photo : profilePhoto} alt="Profile" />
                                    </span>
                                    <p>{name}</p>
                                </div>
                            </div>
                            <div className="btn_wrap">
                                <button className="btn" type="button" onClick={handleProfileTab}>Profile</button>
                                <button className="btn" type="button" onClick={handlePasswordTab}>Password</button>
                                <button className="btn mb-0" type="button" onClick={handleTransactionTab}>Transaction</button>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8">
                        {
                            passwordTabs &&
                            <div className="change_pssvrd_tab">
                                <ChnagePassword />
                            </div>
                        }
                        {
                            profileTabs &&
                            <div className="profile_tab">
                                <Form onSubmit={handleUpdateProfile} className="login-form pt-0">

                                    <h2>Profile Account Settings </h2>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Name">
                                                {getFieldDecorator("name", {
                                                    initialValue: name,
                                                    rules: [{ required: true, message: "Please enter full name" }],
                                                })(<Input placeholder="Your Full Name" />)}
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item label="Email">
                                                {getFieldDecorator("email", {
                                                    initialValue: email,
                                                    rules: [
                                                        { required: true, message: "Please enter an email" },
                                                        { type: "email", message: "Please enter a valid email" },
                                                    ],
                                                })(<Input placeholder="Your Email" />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Phone">
                                                {getFieldDecorator("phone", {
                                                    initialValue: phone,
                                                    rules: [
                                                        { required: true, message: "Please enter phone number" },
                                                        {
                                                            pattern: /^[0-9]{10}$/,
                                                            message: "Enter a valid 10-digit number",
                                                        },
                                                    ],
                                                })(<Input placeholder="Your Phone*" />)}
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} >
                                            <Form.Item label="Qualification">
                                                {getFieldDecorator("qualification", {
                                                    initialValue: qualification,
                                                    rules: [{ required: true, message: "Please enter your qualification" }],
                                                })(<Input placeholder="Your Qualification" />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label="City">
                                                {getFieldDecorator("city", {
                                                    initialValue: city,
                                                    rules: [{ required: true, message: "Please enter your city" }],
                                                })(<Input placeholder="Your City" />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Address">
                                                {getFieldDecorator("address", {
                                                    initialValue: address,
                                                    rules: [{ required: true, message: "Please enter your address" }],
                                                })(<Input placeholder="Your Address" />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: "2rem" }}>
                                        <Col span={12}>
                                            <Form.Item
                                                help={profileError}
                                                label="Choose Profile Picture"

                                            >
                                                <Upload
                                                    beforeUpload={beforeUpload}
                                                    onRemove={handleRemove}
                                                    customRequest={dummyRequest}
                                                    listType="picture"
                                                    maxCount={1}
                                                    onChange={handleUpload}
                                                >
                                                    <div style={{ border: '1px solid #ccc', padding: '10px' }}>
                                                        Upload (Max: 1)
                                                    </div>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <button
                                                htmlType="submit"
                                                loading={isLoading}
                                                className="btn profile_btn"
                                            >
                                                Update
                                            </button>

                                        </div>

                                    </Row>
                                </Form>
                            </div>
                        }
                        {
                            transactionTabs &&
                            <div className="payment_card">
                                <h2>Transaction Details :</h2>
                                <StyledTableWrapper>
                                    <Table
                                        columns={columns}
                                        dataSource={userTransaction}
                                        rowKey={(record) => record._id}
                                        pagination={userTransaction.length}
                                        onChange={handleTableChange}
                                    />
                                </StyledTableWrapper>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div >
    );

};

export default Form.create()(MyAccount)
