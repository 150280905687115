import React, { useState, useEffect } from "react";
import
{
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Spin,
  Select,
  Upload,
} from "antd";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
// import { UploadOutline } from "@ant-design/icons";
import AuthGuard from "../authGuards";

const InstituteCreateWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const InstituteCreateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const InstituteCreateButton = styled(Button)`
  float: right;
`;

const Option = Select.Option;

function CreateInstitute(props)
{
  const [loading, setLoading] = useState(false);
  const [examList, setExamList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() =>
  {
    listExams();
    getLocations();
    // eslint-disable-next-line
  }, []);

  const getLocations = async () =>
  {
    setLoading(true);
    const rawData = await adminService.getAllLocations();
    setLocationList(rawData.data.data);
    setLoading(false)
  };

  const listExams = async () =>
  {
    const rawData = await adminService.getAllExam();
    setExamList(rawData.data.data);
    // console.log(rawData.data.data);
  };

  const handleSubmit = (e) =>
  {
    e.preventDefault();
    if (image == null)
    {
      message.warn("Please Add Image")
    } else
    {
      props.form.validateFields(async (err, values) =>
      {
        if (!err)
        {
          const createInstitute = await adminService.addInstitute(values, image);
          if (createInstitute.data.success === true)
          {
            const logData = { body: `Institute ${values.name} created`, url: 'institute/add' }
            const createLog = await adminService.addLog(logData)
            if (createLog.data.success === true)
            {
              message.success("Institute Successfully Created");
            };
            
            props.form.resetFields();
            props.history.push("/admin/institute");
          }
        }
      });
    }
  };

  const handleUpload = (info) =>
  {
    if (info.file.status === "done")
    {
      setImage(info.file.originFileObj);
    }
  };

  const dummyRequest = ({ file, onSuccess }) =>
  {
    setTimeout(() =>
    {
      onSuccess("ok");
    }, 0);
  };

  const { getFieldDecorator } = props.form;
  
  return (
    <>
      <AuthGuard />
      <InstituteCreateWrapper>
        <InstituteCreateHeader>
          <h2>Create Institute</h2>
          <InstituteCreateButton
            onClick={() => props.history.push("/admin/institute")}
          >
            Cancel
          </InstituteCreateButton>
        </InstituteCreateHeader>
        <Spin spinning={loading}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "please enter name" }],
                  })(<Input placeholder="please enter name" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Info">
                  {getFieldDecorator("info", {
                    rules: [{ required: true, message: "please enter info" }],
                  })(<Input placeholder="please enter info" />)}
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="City">
                  {getFieldDecorator("city", {
                    rules: [{ required: true, message: "please enter city" }],
                  })(
                    <Select
                      mode="multiple"
                      className="input-select"
                      placeholder="please enter city"
                      style={{
                        width: "100%",
                      }}
                    >
                      {locationList &&
                        locationList.map((d) => (
                          <Option value={d.city}>
                            {d.city} ({d.state})
                          </Option>
                        ))}
                      
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Exam">
                  {getFieldDecorator("exam", {
                    rules: [{ required: true, message: "please enter exam" }],
                  })(
                    <Select
                      mode="multiple"
                      className="input-select"
                      placeholder="please enter exam"
                      style={{
                        width: "100%",
                      }}
                    >
                      {examList &&
                        examList.map((d) => (
                          <Option value={d._id}>
                            {d.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={16} style={{ marginBottom: "2rem" }}>
              <Col xs={24} sm={12}>
                <Form.Item label="Intitute Web Link" className="mt-3">
                  {getFieldDecorator("branchLink", {
                    rules: [{ required: false }],
                  })(<Input placeholder="please enter institute link" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Intitute Category">
                  {getFieldDecorator("category", {
                    rules: [{ required: false }],
                  })(<Select
                    mode="multiple"
                    className="input-select"
                    placeholder="please enter category"
                    style={{
                      width: "100%",
                    }}
                  >
                    <option key={0}>
                      Choose Category
                    </option>
                    <option value="Online" className="option">
                      Online
                    </option>
                    <option value="Offline" className="option">
                      Offline
                    </option>
                  </Select>)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: "2rem" }}>
              <Col xs={24} sm={12}>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture"
                  maxCount={1}
                  onChange={handleUpload}
                >
                  <Button >Upload (Max: 1)</Button>
                  {/* <Button icon={<UploadOutline />}>Upload (Max: 1)</Button> */}
                </Upload>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Create
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </InstituteCreateWrapper>
    </>
  );
}

export default withRouter(Form.create()(CreateInstitute));
