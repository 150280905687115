import React from 'react'
import "../assets/css/thanksPage.css";
import image from "../media/banner/benifits.png";
const Benifits = () =>
{
    return (
        <div className="thankyouCard">
            <div className='d-flex justify-content-center'>
                <img src={image} alt={image} />
            </div>
            <h2>Benefits of  BMBC</h2>
            <ol>
                <li>
                    <p>
                        BMBC never takes convenience fees from students, our services to students are always free, and we are committed to student growth.
                    </p>
                </li>
                <li>
                    <p>
                        Extensive List of Coaching Institutes
                    </p>
                </li>
                <li>
                    <p>
                        Providing great offers, competitive fee, exclusive discounts and a seamless online booking experience to students.
                    </p>
                </li>
                <li>
                    <p>

                        All the listed institutes information  are Verified and Reliable
                    </p>
                </li>
            </ol>

        </div>
    )
}

export default Benifits