import React from 'react'
import { useState, useEffect } from 'react'
import userService from '../../../common/userService';
import "../../assets/admin/stats.css";
import apiService from '../../../common/apiService';
import { Select, Table, message } from 'antd';
import SearchComponent from '../search';
const { Option } = Select;
const StatSearch = () =>
{

    const [exam, setExam] = useState("");
    const [category, setCategory] = useState("");
    const [city, setCity] = useState("");
    const [action, setAction] = useState("");
    const [examList, setExamList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [loader, setLoader] = useState(false)
    const [instituteList, setInstituteList] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [filteredData, setFilteredData] = useState([]);
    const getAllLocations = async () =>
    {
        const rawData = await userService.getAllLocations();
        const data = rawData.data.data;
        setLocationList(data);
    };

    const getAllExmas = async () =>
    {
        const rawData = await userService.getAllExams();
        const data = rawData.data.data;
        setExamList(data);
    };

    const handleExam = (e) =>
    {
        setExam(e.target.value);
    }

    const handleCategory = (e) =>
    {
        setCategory(e.target.value)
    }

    const handleCity = (e) =>
    {
        setCity(e.target.value)
    }

    const handleAction = (e) =>
    {
        setAction(e.target.value)
    }

    const testURL = process.env.REACT_APP_API_URL_LIVE

    const handleBatchSearch = async (e) =>
    {
        setLoader(true)
        e.preventDefault();
        let instUrl = `${testURL}institute/list?type=admin`;
        // let instUrl = `${testURL}institute/list?uniqueCity=yes`;
        if (action)
        {
            instUrl += `&status=${action}`;
        }
        if (exam)
        {
            instUrl += `&examId=${exam}&uniqueCity=yes`;
        }
        if (category)
        {
            instUrl += `&category=${category}`;
        }
        if (category === "Offline")
        {
            if (city !== null)
            {
                instUrl += `&city=${city}`;
            }
        }
        const result = await apiService.get(instUrl);
        const instData = result.data.data;
        // let batchList = instData.map((obj) =>
        // {
        //     const startDate = new Date(obj.startDate);
        //     const endDate = new Date(obj.endDate);
        //     const momentStartDate = moment(startDate);
        //     const momentEndDate = moment(endDate);
        //     const momentNowDate = moment();
        //     let status;

        //     if (momentNowDate.isAfter(momentEndDate, 'day'))
        //     {
        //         status = 'Ended';
        //     } else if (!momentNowDate.isAfter(momentStartDate))
        //     {
        //         status = 'Upcoming';
        //     } else
        //     {
        //         status = 'Ongoing';
        //     }

        //     return {
        //         ...obj,
        //         status,
        //     };
        // })
        // const groupedBatchListCount = batchList.reduce((accumulator, obj) =>
        // {
        //     const { instituteId, examId, city } = obj;
        //     const key = `${instituteId?._id}-${examId?._id}-${city}`;

        //     if (!accumulator[key])
        //     {
        //         accumulator[key] = {
        //             ...obj,
        //             count: 1,
        //         };
        //     } else
        //     {
        //         accumulator[key].count++;
        //     }

        //     return accumulator;
        // }, {});
        // const mergedArray = Object.values(groupedBatchListCount);
        // console.log(mergedArray);
        if (city)
        {
            let batchList = instData.filter(obj => obj.city === city);
            if (action === "upcoming")
            {
                batchList.sort((a, b) => (a.upcoming - b.upcoming));
            } else if (action === "ongoing")
            {
                batchList.sort((a, b) => (a.ongoing - b.ongoing));
            }
            setInstituteList(batchList);

        } else
        {
            if (action === "upcoming")
            {
                instData.sort((a, b) => (a.upcoming - b.upcoming));
            } else if (action === "ongoing")
            {
                instData.sort((a, b) => (a.ongoing - b.ongoing));
            }
            setInstituteList(instData);
        }
        setLoader(false)
    }

    const dynamicColumns = [
        {
            title: 'Institute Name',
            dataIndex: 'name',
            width: "20%",
        },
        {
            title: 'City',
            dataIndex: 'city',
            width: "10%",
            // render: (cities) => cities.join(', '),
        }
    ];
    // if (category === 'Offline')
    // {
    //     dynamicColumns.push(
    //         {

    //             title: 'City',
    //             dataIndex: 'city',
    //             width: "10%",

    //         }

    //     );
    // }

    if (action === 'upcoming')
    {
        dynamicColumns.push(
            {
                title: 'Upcoming',
                dataIndex: 'upcoming',
                width: "15%",
            }

        );
    } else if (action === 'ongoing' || action !== "")
    {
        dynamicColumns.push(
            {
                title: 'Ongoing',
                dataIndex: 'ongoing',
                width: "15%",
            }
        );
    }

    const handlePageSizeChange = (value) =>
    {
        setPageSize(value);
    };

    const handleSearch = (searchTerm) =>
    {
        const filteredData = instituteList.filter(
            (item) =>
                item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.examId?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (filteredData.length === 0)
        {
            message.info("No Results Found");
        }
        setFilteredData(filteredData);
    };

    useEffect(() =>
    {
        getAllExmas();
        getAllLocations()
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <div className='stats_wrapper'>
                    <h2>Status Management</h2>
                    <p>Enter the details to search for stats</p>
                    <form className='stats_form' onSubmit={handleBatchSearch}>
                        <div className="select_tabs">
                            <select
                                value={exam}
                                onChange={(e) => handleExam(e)}
                                className="form-select"
                            >
                                <option key={0}>
                                    Choose Exam
                                </option>
                                {examList.map((ele) => (
                                    <option
                                        value={ele._id}
                                        className="option"

                                        key={ele._id}
                                    >
                                        {ele.name} ({ele.branchName})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select_tabs">
                            <select
                                value={category}
                                onChange={(e) => handleCategory(e)}
                                className="form-select"
                            >
                                <option key={0}>
                                    Choose Category
                                </option>
                                <option value="Online" className="option">
                                    Online
                                </option>
                                <option value="Offline" className="option">
                                    Offline
                                </option>
                                <option value="Online Test Series" className="option">
                                    Online Test Series
                                </option>
                                <option value="Offline Test Series" className="option">
                                    Offline Test Series
                                </option>

                            </select>
                        </div>
                        <div className="select_tabs">
                            <select
                                value={action}
                                onChange={(e) => handleAction(e)}
                                className="form-select"
                            >
                                <option key={0}>
                                    Choose Status
                                </option>
                                <option value="upcoming" className="option">
                                    Upcoming
                                </option>
                                <option value="ongoing" className="option">
                                    Ongoing
                                </option>
                                {/* <option value="Ended" className="option">
                                    Ended
                                </option> */}
                            </select>
                        </div>
                        <div className="select_tabs">
                            <select
                                value={city}
                                onChange={(e) => handleCity(e)}
                                className="form-select"
                            >
                                <option key={0}>
                                    All City
                                </option>
                                {locationList.map((ele) => (
                                    <option
                                        value={ele.city}
                                        className="option"
                                        key={ele._id}
                                    >
                                        {ele.city}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select_tabs me-0">
                            <button
                                type='submit'
                                className="btn search_btn_new"
                            >
                                {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                {
                    instituteList?.length > 0 &&
                    <div className='w-100 d-flex justify-content-between text-end mb-2'>
                        <SearchComponent data={instituteList} onSearch={handleSearch} />
                        <Select
                            defaultValue={pageSize}
                            onChange={handlePageSizeChange}
                        >
                            <Option value={5}>5 rows per page</Option>
                            <Option value={10}>10 rows per page</Option>
                            <Option value={25}>25 rows per page</Option>
                            <Option value={50}>50 rows per page</Option>
                            <Option value={100}>100 rows per page</Option>
                            <Option value={200}>200 rows per page</Option>
                        </Select>
                    </div>
                }
                <Table
                    columns={dynamicColumns}
                    loading={loader}
                    pagination={{
                        pageSize: pageSize,
                    }}
                    dataSource={filteredData.length > 0 ? filteredData : instituteList}
                />
            </div>
        </>
    )
}

export default StatSearch