
import React from 'react'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import '../components/assets/css/promotion.css'

const PromoDetails = ({ promoData}) =>
{
    const CustomPrevArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsLeft />
            </div>
        );
    };

    const CustomNextArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsRight />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };


    return (
        <>
            <div className='container'>
                <section className='promo_slider'>

                    <Slider {...settings}>
                        {
                                promoData.map((ele) => (
                                <div className='promo_cards' key={ele._id}>
                                    <div className='promo_card_wrap'>
                                        <div className='promo_image'>
                                            <img
                                                src={ele?.instituteId?.image}
                                                alt='logo' />
                                        </div>
                                        <div className='promo_txt'>
                                            <div className='promo_txt_wrap'>
                                                <h2><b>{ele.type}</b></h2>
                                                <h6>{ele.description}</h6>
                                                <div className='knw_more_btn'>
                                                    <Link to={`/institute-detail/${ele.instituteId?._id}`}
                                                        className="hovercard-cta">Know more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                    </Slider>
                </section>

            </div >



        </>

    )



}
export default PromoDetails;