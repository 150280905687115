import React, { useState, useEffect } from "react";
import { Button, Form, Select, message, Col, Row, DatePicker } from "antd";
import styled from "styled-components";
import adminService from "../../../../common/adminService";
import { useParams } from "react-router-dom";

import AuthGuard from "../../authGuards";
import moment from "moment";

const { Option } = Select;

const EditKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const EditKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditKeywordButton = styled(Button)`
  float: right;
`;

function EditBatchKeywords(props)
{
  const [loading, setLoading] = useState(false);
  const [batchList, setBatchList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywordList, setKeywordList] = useState([]);
  // const [keyWord, setKeyword] = useState(null);
  const [batchName, setBatchName] = useState(null)


  // const [batchData, setBatchData ] = useState([]);
  const { id } = useParams();
  useEffect(() =>
  {
    const fetch = async () =>
    {
      const response = await adminService.getBatchKeywordById(id);
      const data = response.data.data;
      data.startDate = moment(data.startDate)
      data.endDate = moment(data.endDate)
      data.keyword = data[0]?.keyword?._id

      const batchIdArray = data[0].batchId?.map((item) => item._id);
      props.form.setFieldsValue({ ...data, keyword: data[0].keyword._id, batchId: batchIdArray });

    };

    fetch();
    listBatches();
    listKeyword();
    // eslint-disable-next-line
  }, []);
  const listKeyword = async () =>
  {
    const rawData = await adminService.getAllKeywords();
    setKeywordList(rawData.data.data);
    // setKeyword(rawData.data.data)
  }

  const listBatches = async () =>
  {
    const rawData = await adminService.getAllBatches();
    setBatchList(rawData.data.data);
    setBatchName(rawData.data.data);
    // console.log(rawData.data.data);
  };

  const handleSubmit = (e) =>
  {

    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      // console.log(values);
      if (!err)
      {
        setLoading(true);
        props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });

        const statusEndDate = moment(values.endDate);
        const statusNowDate = moment();
        let statusDate;
        if (statusNowDate.isAfter(statusEndDate))
        {
          statusDate = 'Ended';
        } else
        {
          statusDate = 'Active';
        }

        const updateBatchKeyword = await adminService.updateBatchKeyword(id, values, statusDate);

        const batchByName = batchName?.filter(item => values.batchId.includes(item._id));
        const batchValues = batchByName?.map(item => item.batchName);

        // const keyWordName = keyWord?.filter(item => values.keyword.includes(item._id))
        setLoading(false);
        if (updateBatchKeyword.data.success === true)
        {
          const logData = { body: `BatchKeyword ${batchValues && (batchValues?.join(', '))}  Updated`, url: 'batchkeyword/update', oldData: updateBatchKeyword.data.dataUpdate.oldData, newData: updateBatchKeyword.data.dataUpdate.newData }
          // const logData = {body: `BatchKeyword ${values.batchKeyword} Updated`, url: 'batchkeyword/update', oldData: {}, newData: {} }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("BatchKeyword Successfully Updated");
          };


          props.history.push("/admin/batchKeywords");
        } else
        {
          message.error("Error in updating BatchKeyword");
        }
      }
    });
  };


  const { getFieldDecorator } = props.form;

  return (
    <>
      <AuthGuard />
      <EditKeywordWrapper>
        <EditKeywordHeader>
          <h2>Edit Batch Keyword</h2>
          <EditKeywordButton
            onClick={() => props.history.push("/admin/batchKeywords")}
          >
            Cancel
          </EditKeywordButton>
        </EditKeywordHeader>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item label="Keyword">
            {getFieldDecorator("keyword", {
              rules: [{ required: true, message: "please enter" }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                className="input-select"
                placeholder="Please Select"
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
              >
                {keywordList &&
                  keywordList.map((d) => (

                    <Option key={d._id} value={d._id}>{d.name}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Batch">
            {getFieldDecorator("batchId", {
              rules: [{ required: true, message: "please enter" }],
            })(
              <Select
                mode="multiple"
                className="input-select"
                placeholder="Please Select"
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
              >
                {batchList &&
                  batchList.map((d) => (
                    <Option value={d._id}>
                      {d.batchName}

                    </Option>
                  ))}
              </Select>

            )}
          </Form.Item>

          <Row gutter={16}>

            <Col span={12}>
              <Form.Item label="Start Date">
                {getFieldDecorator("startDate", {
                  rules: [{ required: true, message: "please enter start date" }],
                })(
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    onChange={(date, dateString) => setStartDate(dateString)}
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="End Date">
                {getFieldDecorator("endDate", {
                  rules: [{ required: true, message: "please enter end date" }],
                })(
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    onChange={(date, dateString) => setEndDate(dateString)}
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </EditKeywordWrapper>
    </>
  );
}

export default Form.create()(EditBatchKeywords);
