import React, { useState, useEffect } from "react";
import { Button, Form, Select, message , DatePicker } from "antd";
import styled from "styled-components";
import adminService from "../../../../common/adminService";
import { useParams } from "react-router-dom";

import AuthGuard from "../../authGuards";
import moment from "moment";

const { Option } = Select;

const EditKeywordWrapper = styled.div`
  padding: 20px;

  .input-select {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }

  .ant-select-selection {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    display: grid;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const EditKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditKeywordButton = styled(Button)`
  float: right;
`;

function EditInstituteKeywords(props) {
  const [loading, setLoading] = useState(false);
  const [instituteList,setInstituteList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywordList, setKeywordList] = useState([]);
  const { id } = useParams();
  useEffect(() =>
  { 
    const fetch = async () =>
    {
      const response = await adminService.getInstituteKeywordById(id);
      const data = response.data.data;
      data.startDate = moment(data.startDate)
      data.endDate = moment(data.endDate)
      data.keyword = data[0]?.keyword?._id
      const instituteIdArray = data[0].instituteId?.map((item) => item._id);
      props.form.setFieldsValue({ ...data, keyword : data[0].keyword._id, instituteId: instituteIdArray});
    };

    fetch();
    listInstitutes();
    listKeyword();
    // eslint-disable-next-line
  }, []);
  const listKeyword = async () =>{
    const rawData = await adminService.getAllKeywords();
    setKeywordList(rawData.data.data);
  }

  const listInstitutes = async () =>
  {
    const rawData = await adminService.getAllInstitutes();
    setInstituteList(rawData.data.data);
    // console.log(rawData.data.data);
  };
  
  const handleSubmit = (e) =>
  {

    e.preventDefault();
    props.form.validateFields(async (err, values) =>
    {
      // console.log(values);
      if (!err)
      {
        setLoading(true);
        props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
        
            const statusEndDate = moment(values.endDate);
            const statusNowDate = moment();
            let statusDate;
            if (statusNowDate.isAfter(statusEndDate))
            {
                statusDate = 'Ended';
            } else
            {
                statusDate = 'Active';
            }
            
        const updateInstituteKeyword = await adminService.updateInstituteKeyword(id, values, statusDate);
        // console.log(updateInstituteKeyword.data);
        const instituteName = instituteList.filter(item => values.instituteId.includes(item._id))
        const instituteValues = instituteName?.map(item => item.name);
        setLoading(false);
        if (updateInstituteKeyword.data.success === true)
        {
          const logData = { body: `InstituteKeyword ${instituteValues && (instituteValues?.join(', '))} Updated`, url: 'institutekeyword/update', oldData: updateInstituteKeyword.data.dataUpdate.oldData, newData: updateInstituteKeyword.data.dataUpdate.newData }
          // const logData = {body: `InstituteKeyword ${values.keyword} Updated`, url: 'institutekeyword/update', oldData: {}, newData: {} }
          const createLog = await adminService.addLog(logData)
          if (createLog.data.success === true)
          {
            message.success("InstituteKeyword Successfully Updated");
          };

         
          props.history.push("/admin/instituteKeywords");
        } else
        {
          message.error("Error in updating InstituteKeyword");
        }
      }
    });
  };


  const { getFieldDecorator } = props.form;

  return (
    <>
    <AuthGuard />
    <EditKeywordWrapper>
      <EditKeywordHeader>
        <h2>Edit Institute Keyword</h2>
        <EditKeywordButton
          onClick={() => props.history.push("/admin/instituteKeywords")}
        >
          Cancel
        </EditKeywordButton>
      </EditKeywordHeader>
      <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item label="Keyword">
                {getFieldDecorator("keyword", {
                  rules: [{ required: true, message: "please enter" }],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="input-select"
                    placeholder="Please Select"
                    filterOption={(input, option) =>
                      (option.props.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                    }}
                  >
                    {keywordList &&
                      keywordList.map((d) => (
                        
                        <Option key={d._id} value={d._id}>{d.name}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Institute">
                {getFieldDecorator("instituteId", {
                  rules: [{ required: true, message: "please Select" }],
                })(
                  <Select
                  
                  mode="multiple"
                  className="input-select"
                  placeholder="Please Select"
                  filterOption={(input, option) =>
                    (option.props.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                  }}
                  >
                    {instituteList &&
                      instituteList.map((d) => (
                        <Option value={d._id}>{d.name}</Option>
                      ))}
                  </Select>
                )}
        </Form.Item>
        <Form.Item label="Start Date">
            {getFieldDecorator("startDate", {
                rules: [{ required: true, message: "please enter start date" }],
              })(
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(date, dateString) => setStartDate(dateString)}
                  style={{ width: "100%" }}
                   />
                  )}
        </Form.Item>
                       
        <Form.Item label="End Date">
            {getFieldDecorator("endDate", {
                    rules: [{ required: true, message: "please enter end date" }],
                    })(
                    <DatePicker
                    format={"DD-MM-YYYY"}
                     onChange={(date, dateString) => setEndDate(dateString)}
                    style={{ width: "100%" }}
                  />
                )}
        </Form.Item>  
                                         
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </EditKeywordWrapper>
    </>
  );
}

export default Form.create()(EditInstituteKeywords);
