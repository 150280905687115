import React, { useState, useEffect } from "react";
import
{
    Button,
    Form,
    Input,
    message,
    Row,
    Col,
    DatePicker,
    Select,
} from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import AuthGuard from "../authGuards";
import moment from "moment";
import { useParams } from "react-router-dom";


const EditPromoWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }
`;
const EditPromoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditPromoButton = styled(Button)`
  float: right;
`;

function UpdatePromo(props)
{
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { id } = useParams();
    const [instituteData, setInstituteData] = useState([]);
    const [instituteList, setInstituteList] = useState([]);
    const Option = Select.Option;


    useEffect(() =>
    {
        const fetchEachPromo = async () =>
        {
            const response = await adminService.getPromoById(id);
            const data = response.data.data;
            data.startDate = moment(data.startDate)
            data.endDate = moment(data.endDate)
            data.instituteId = data?.instituteId?._id
            data.examId = data?.examId?._id
            props.form.setFieldsValue({ ...data });
            getInstituteDetail(data.instituteId, data.city, data.exam)
            setEndDate();


        };
        listInstitute();
        fetchEachPromo();

        // eslint-disable-next-line
    }, []);

    const listInstitute = async () =>
    {
        const rawData = await adminService.getAllInstitutes();
        setInstituteList(rawData.data.data);
    };

    const getInstituteDetail = async (id) =>
    {
        const data = await adminService.getInstituteById(id)

        setInstituteData(data.data.data)
    }

    const handleSubmit = (e) =>
    {
        e.preventDefault();
        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {
                setLoading(true);
                const statusEndDate = moment(values.endDate);
                const statusNowDate = moment();
                let statusDate;
                if (statusNowDate.isAfter(statusEndDate))
                {
                    statusDate = 'Ended';
                } else
                {
                    statusDate = 'Active';
                }


                props.form.setFieldsValue({ startDate: moment(startDate), endDate: moment(endDate) });
                const updatePromo = await adminService.updatePromo(id, values, statusDate);
                setLoading(false);
                if (updatePromo.data.success === true)
                {
                    const logData = {body:  `Promo ${values.name} updated`, url: 'promo/update', oldData: updatePromo.data.dataUpdate.oldData, newData: updatePromo.data.dataUpdate.newData }
                    const createLog = await adminService.addLog(logData)
                    if (createLog.data.success === true)
                    {
                        message.success("Promo Successfully Updated");
                    };
                
                    props.history.push("/admin/promo");
                } else
                {
                    message.error("Error in updating promo");
                }
            }
        });
    };


    const { getFieldDecorator } = props.form;
    return (
        <>
            <AuthGuard />
            <EditPromoWrapper>
                <EditPromoHeader>
                    <h2>Edit Promo</h2>
                    <EditPromoButton onClick={() => props.history.push("/admin/promo")}>
                        Cancel
                    </EditPromoButton>
                </EditPromoHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Promo Name">
                                {getFieldDecorator("name", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Discount Percent">
                                {getFieldDecorator("discountPercent", {
                                    rules: [{ required: true, message: "please enter discount" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item label="Start Date">
                                {getFieldDecorator("startDate", {
                                    rules: [{ required: true, message: "please enter start date" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setStartDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="End Date">
                                {getFieldDecorator("endDate", {
                                    rules: [{ required: true, message: "please enter end date" }],
                                })(
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        onChange={(date, dateString) => setEndDate(dateString)}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Description">
                                {getFieldDecorator("description", {
                                    rules: [{ required: true, message: "please enter Description" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Promo type">
                                {getFieldDecorator("type", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Institute">
                                {getFieldDecorator("instituteId", {
                                    rules: [{ required: true, message: "please enter insitute name" }],
                                })(
                                    <Select
                                        onChange={(e) => getInstituteDetail(e)}
                                        showSearch
                                        optionFilterProp="children"
                                        className="input-select"
                                        placeholder="Please Select"
                                        filterOption={(input, option) =>
                                            (option.props.children ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteList &&
                                            instituteList.map((d) => (
                                                <Option key={d._id} value={d._id}>{d.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="City">
                                {getFieldDecorator("city", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(
                                    <Select
                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteData?.city?.length > 0 &&
                                            instituteData.city.map((d) => (
                                                <Option key={d} value={d}>{d}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Exam">
                                {getFieldDecorator("examId", {
                                    rules: [{ required: true, message: "please enter exam name" }],
                                })(
                                    <Select

                                        className="input-select"
                                        placeholder="Please Select"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {instituteData?.examId?.length > 0 &&
                                            instituteData.examId.map((exam) => (
                                                <Option key={exam._id} value={exam._id}>{exam.name}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                     </Row>
             
                    <Row>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </Row>
                </Form>
            </EditPromoWrapper>
        </>
    );
}

export default Form.create()(UpdatePromo);
