import React from 'react';
// import PageHeader from './global-components/page-header';
import HelpCenterForm from './section-components/help-center-form';
// import hlpBan from "./media/banner/getHelp.png";
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png"
import Layout from './global-components/Layout';
const HelpCenter = () =>
{
    return <div>
        <Helmet>
            <title>
                Find Answers to Your Questions and Support for Academic Success
            </title>
            <meta
                name="description"
                content="Visit our Help Center page to find comprehensive answers to frequently 
                asked questions and get the support you need for academic success. Our dedicated team is here
                to assist you with any queries or concerns you may have. Explore our resources and find the guidance
                you need to thrive in your educational journey"
            />
            <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
            <meta property="og:title" content="Find Answers to Your Questions and Support for Academic Success" />
            <meta property="og:description" content="Visit our Help Center page to find comprehensive answers to frequently 
                asked questions and get the support you need for academic success. Our dedicated team is here
                to assist you with any queries or concerns you may have. Explore our resources and find the guidance
                you need to thrive in your educational journey" />
            <meta property="og:image" content={logoBmbc} />
            <link rel="canonical" href="https://bookmybestclass.com/helpCenter" />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            {/* <PageHeader image={hlpBan} /> */}
            <HelpCenterForm />
        </Layout>
       
    </div>
}

export default HelpCenter

