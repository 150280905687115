import { Spin } from 'antd';
import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import "../assets/css/InstituteBatch.css";
import moment from 'moment';
import { FaStar } from "react-icons/fa";
import Slider from 'react-slick';
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
const ViewAllBatches = ({ onData }) =>
{
  const testURL = process.env.REACT_APP_API_URL_LIVE
  const history = useHistory();
  const { instituteId, searchParams, examIdInfo } = useParams();
  const [data, setData] = useState([]);
  const [examIdData, setExamIdData] = useState([]);
  const [instituteDetail, setInstituteDetail] = useState([]);
  const [activeBatchFilter, setActiveBatchFilter] = useState("all");
  const [originalBatchList, setOriginalBatchList] = useState([])
  const [loading, setLoading] = useState(false);
  const [ableButton, setAbleButton] = useState([]);
  const urlParams = new URLSearchParams(searchParams);
  const examId = urlParams.get('examId');
  const handleFetch = async () =>
  {

    // onData(city, status);
    const urlQueryParams = new URLSearchParams();
    let exam = localStorage.getItem("exam");
    let mode = localStorage.getItem("mode");
    let city = localStorage.getItem("city");
    let branchValue = localStorage.getItem("branchName");
    if (exam)
    {
      urlQueryParams.append('examId', exam);
    }
    // "64be659d4dcfa238cc4b2b14",
    if (["6461de19cd10f83e9358827b", "64e473a04f25d7076635288a", "64e487ff4f25d707663528b9", "64be65bb4dcfa238cc4b2b16", "6463245acd10f83e9358828e"].includes(exam))
    {
      if (branchValue)
      {
        urlQueryParams.append('branch', branchValue);
      }
    }
  
    if (mode)
    {
      urlQueryParams.append('status', mode);
    }
    if (mode === "Offline")
    {
      if (city)
      {
        const encodedCity = city.replace(/\s/g, '');
        urlQueryParams.append('city', encodedCity);
      }
    }

    const queryString = urlQueryParams.toString();
    console.log(queryString);
    setLoading(true)
    if (queryString)
    {
      const res = await axios.get(testURL + `batch/list?instituteId=${instituteId}&${queryString}`)
      if (res.data.success === true)
      {
        const result = res.data.data

        let batchList = result.map((obj) =>
        {
          const startDate = new Date(obj.startDate);
          const endDate = new Date(obj.endDate);
          const momentStartDate = moment(startDate);
          const momentEndDate = moment(endDate);
          const momentNowDate = moment();
          let status;

          if (momentNowDate.isAfter(momentEndDate))
          {
            status = 'Ended';
          } else if (!momentNowDate.isAfter(momentStartDate))
          {
            status = 'Upcoming';
          } else
          {
            status = 'Ongoing';
          }

          return {
            ...obj,
            status,
          };
        }).filter(obj => obj.status !== 'Ended');
        batchList.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        let testData = batchList.filter(obj => obj.category === "Online" || obj.category === "Offline")
        setData(testData);
        const upComingAble = testData.filter(obj => obj.status === "Upcoming")
        setAbleButton(upComingAble)
        onData(testData.name, testData.info, testData.city, testData.image);
        setOriginalBatchList(testData)
        setLoading(false);
      }
      else
      {
        console.log("Something went wrong");
      }
    } else
    {
      const res = await axios.get(testURL + `batch/list?instituteId=${instituteId}&examId=${examIdInfo}`)
      if (res.data.success === true)
      {
        const result = res.data.data

        let batchList = result.map((obj) =>
        {
          const startDate = new Date(obj.startDate);
          const endDate = new Date(obj.endDate);
          const momentStartDate = moment(startDate);
          const momentEndDate = moment(endDate);
          const momentNowDate = moment();
          let status;

          if (momentNowDate.isAfter(momentEndDate))
          {
            status = 'Ended';
          } else if (!momentNowDate.isAfter(momentStartDate))
          {
            status = 'Upcoming';
          } else
          {
            status = 'Ongoing';
          }

          return {
            ...obj,
            status,
          };
        }).filter(obj => obj.status !== 'Ended');
        batchList.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        let testData = batchList.filter(obj => obj.category === "Online" || obj.category === "Offline")
        setData(testData);
        const upComingAble = testData.filter(obj => obj.status === "Upcoming")
        setAbleButton(upComingAble)
        onData(testData.name, testData.info, testData.city, testData.image);
        setOriginalBatchList(testData)
        setLoading(false);
      }
      else
      {
        console.log("Something went wrong");
      }
    }

  }

  const getExamById = async () =>
  {
    try
    {
      if (!examId)
      {
        const res = await axios.get(testURL + `exam/id/${examIdInfo}`);
        setExamIdData(res.data.data.name);
      } else
      {
        const res = await axios.get(testURL + `exam/id/${examId}`);
        setExamIdData(res.data.data.name);
      }


    } catch (error)
    {
      console.error(error);
    }
  };

  const getInstituteDetail = async () =>
  {
    const rawData = await axios.get(testURL + `institute/id/${instituteId}`);
    const data = rawData.data.data.name;
    setInstituteDetail(data);
  }

  // SLIDER SETTING FUNCTION


  const handleClick = (filterString) =>
  {
    let filteredBatches;
    if (filterString === "all")
    {
      filteredBatches = originalBatchList;
    } else if (filterString === "upcoming")
    {
      filteredBatches = originalBatchList.filter((batch) => batch.status === "Upcoming");
    } else if (filterString === "ongoing")
    {
      filteredBatches = originalBatchList.filter((batch) => batch.status === "Ongoing");
    } else if (filterString === "amount")
    {
      filteredBatches = originalBatchList.slice().sort((a, b) => a.amount - b.amount);
    } else
    {
      filteredBatches = originalBatchList;
    }
    setData(filteredBatches);
    setActiveBatchFilter(filterString);
  }

  useEffect(() =>
  {
    handleFetch();
    getExamById();
    getInstituteDetail();
    // eslint-disable-next-line
  }, []);





  const CustomPrevArrow = (props) =>
  {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
        <BiChevronsLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) =>
  {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
        <BiChevronsRight />
      </div>
    );
  };

  const BatchSliderAll = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: data.length > 4 ? 5 : data.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1091,
        settings: {
          slidesToShow: data.length > 3 ? 4 : data.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: data.length > 2 ? 3 : data.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: data.length < 2 ? 1 : 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  }

  let BatchSliderWidth = "100%";
  let BatchSliderWidthMobile = "100%";


  if (data.length < 4)
  {
    BatchSliderWidth = `${25 * data.length}%`;
  }

  if (data.length < 2 && window.innerWidth < 768)
  {
    BatchSliderWidthMobile = `${50 * data.length}%`;
  }

  const permissionIds = [
    "6461de19cd10f83e9358827b",
    "64be659d4dcfa238cc4b2b14",
    "64e473a04f25d7076635288a",
    "64e487ff4f25d707663528b9",
    "64be65bb4dcfa238cc4b2b16",
    "6463245acd10f83e9358828e"
  ]

  return (
    <>
      
      <div className="container instituteDetails_comp">
          <button type="button" className="go_back_btn" onClick={() => history.goBack()}>Go Back</button>
          <h4 className='heading'><span>{examIdData} </span> Batches By <span>{instituteDetail}</span></h4>
          <h6 className="section-subtitle batch_institute_sec_title">
            <div className='filter_text'>
              <button
                style={{ backgroundColor: "transparent" }}
                className={
                  activeBatchFilter === "all" ? "filter-active" : ""
                }
                onClick={(e) => handleClick("all")}
              >
                All ({data.length})
              </button>
              {
                ableButton.length > 0 &&
                <button
                  style={{ backgroundColor: "transparent" }}
                  className={
                    activeBatchFilter === "upcoming" ? "filter-active" : ""
                  }
                  onClick={(e) => handleClick("upcoming")}
                >
                  Upcoming
                </button>
              }

              <button
                style={{ backgroundColor: "transparent" }}
                className={
                  activeBatchFilter === "ongoing" ? "filter-active" : ""
                }
                onClick={() => handleClick("ongoing")}
              >
                Ongoing
              </button>
              <button
                style={{ backgroundColor: "transparent" }}
                className={
                  activeBatchFilter === "amount" ? "filter-active" : ""
                }
                onClick={() => handleClick("amount")}
              >
                Price
              </button>
            </div>
          </h6>
          {
            loading ?
              <Spin />
              :

              <div className="slider-container" style={{ width: window.innerWidth >= 768 ? BatchSliderWidth : BatchSliderWidthMobile }}>
                <Slider {...BatchSliderAll}>
                  {
                    data.map((ele) =>
                    {
                      return (
                        <Suspense key={ele._id} fallback={'Loading ...'}>
                          <div className="px-2" key={ele._id}>
                            <div className="batch_card_details">
                              <div className="left_box">
                                {ele?.batchKeyword &&
                                  ele?.keywordStatus !== "Ended" && (
                                    <h5 className="keyword_tags">
                                      <p className="badge_btn">
                                        {ele.status}
                                      </p>
                                      <span>{ele.batchKeyword?.keyword?.name}</span>

                                    </h5>
                                  )
                                }
                                <img className="img-fluid img-responsive rounded product-image" loading="lazy" src={ele?.instituteId?.image} alt={ele.instituteId.image} />
                                {
                                  ele.planName?.title && (
                                    <h5 className="marquee_tags">
                                      {/* <p>Batch Type</p> */}
                                      <span>{ele.planName?.title}</span>
                                    </h5>
                                  )
                                }
                              </div>
                              <div className="center_box">
                                <div className="center_details">
                                  <h5 className="my-0">
                                    <span>
                                      {ele.examId?.name}
                                    </span>
                                  </h5>
                                  <h4>
                                    <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span><FaStar />
                                  </h4>
                                  <span className="mob_category">{ele?.category}</span>
                                </div>
                                <h5 className="desk_inst">
                                  <span>Institute :</span>
                                  <span>{ele.instituteId?.name?.slice(0, 20) + ".."}</span>
                                </h5>
                                <h5 className="mob_inst">
                                  <span>{ele.instituteId?.name?.slice(0, 20) + ".."}</span>
                                </h5>
                                <h5 className="mob_batch">
                                  <span>Batch No:</span>
                                  <span>{ele?.batchName}</span>
                                </h5>
                                {permissionIds.includes(ele.examId._id) &&
                                  <h5 className="mob_batch">
                                    <span>Branch :</span>
                                    <Link to={`/batch-detail/${ele._id}`}>
                                      {ele.branchName?.length > 0 && (
                                        <>
                                          {ele.branchName.slice(0, 4).map((branch, index) =>
                                          {
                                            const truncatedName = branch.name;

                                            return (
                                              <React.Fragment key={index}>
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {truncatedName}
                                                </span>
                                                {index < ele.branchName.length - 1 && <span>, </span>}
                                              </React.Fragment>
                                            );
                                          })}
                                        </>
                                      )}
                                    </Link>
                                  </h5>
                                }
                                <h5 className="mob_batch">
                                  <span>Language :</span>
                                  <span style={{ color: "#2292ca" }}>
                                    {ele?.languages?.name}
                                  </span>
                                </h5>
                                {ele.category !== 'Online' &&
                                  <h5 className="mob_batch">
                                    {ele.category !== 'Online' && <span>City :</span>}
                                    {ele.category !== 'Online' && <span>{ele.city}</span>}
                                  </h5>
                                }

                                <h3 className="mob_price">
                                  <span>Price :</span>
                                  <span style={{ color: "#2292ca" }}>
                                    {ele.amount.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).split('.')[0]}
                                    <span className="incl_gst">incl. GST</span>
                                  </span>
                                </h3>
                                <h5 className="desk_btch_number">
                                  <span>Batch No :</span>
                                  <span>
                                    {ele.batchName}
                                  </span>
                                </h5>
                                {/* <h5 className="desk_strt_dt">
                                  <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                                </h5> */}
                              </div>
                              <div className="right_box">
                                <h5>
                                  <span>Price :</span>
                                  <span style={{ color: "#2292ca" }}>
                                    {ele.amount.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).split('.')[0]}
                                    <span className="incl_gst">incl. GST</span>
                                  </span>
                                </h5>
                                {permissionIds.includes(ele.examId._id) && <h5>
                                  <span>Branch :</span>
                                  <Link to={`/batch-detail/${ele._id}`}>
                                    {ele.branchName?.length > 0 && (
                                      <>
                                        {ele.branchName.slice(0, 4).map((branch, index) =>
                                        {
                                          const truncatedName = branch.name;
                                          return (
                                            <React.Fragment key={index}>
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {truncatedName}
                                              </span>
                                              {index < ele.branchName.length - 1 && <span>, </span>}
                                            </React.Fragment>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Link>
                                </h5>

                                }
                                <h5>
                                  <span>Language :</span>
                                  <span style={{ color: "#2292ca" }}>
                                    {ele?.languages?.name}
                                  </span>
                                </h5>
                                <h5>
                                  <span>{ele?.category}</span>
                                  {
                                    ele?.category === "Offline" && <p className="city">{ele?.city}</p>
                                  }
                                </h5>
                              </div>
                              <div className="see_btn_wrap_batch">
                                <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary view_al_btch w-100" type="button">View Details</Link>
                              </div>
                              {
                                ((ele?.batchKeyword && ele?.keywordStatus === "Ended") ||
                                  (!ele?.batchKeyword && ele?.keywordStatus === "Active")) &&
                                (
                                  <p className="badge_btn2">
                                    {ele.status}
                                  </p>
                                )
                              }
                            </div>
                          </div>
                        </Suspense>

                      )
                    })
                  }
                </Slider>
              </div>
          }
      </div >
      
    </>
  )
}

export default ViewAllBatches
