import React, { useState, useEffect } from "react";
import { Table, Modal, Input, Button, message } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
const { TextArea } = Input;

const HelpCenterWrapper = styled.div`
  padding: 20px;
`;

const HelpCenterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const HelpCenterTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function HelpCenterList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [helpId, setHelpId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.listAllHelpRequests().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (email === "" || subject === "" || text === "") {
      message.warn("Please enter values in all Fields");
    } else {
      const replyHelp = await adminService.getHelpRequestReply({
        email: email,
        message: text,
        subject: subject,
        helpId: helpId,
      });

      if (replyHelp?.data?.success === true) {
        const logData = {body:  `Replied on ${rawData.contact}`, url: 'helpCenter/reply' }
        const createLog = await adminService.addLog(logData)
        if (createLog.data.success === true)
        {
          message.success(replyHelp.data.message);
        };
        
      } else {
        message.warn("An error occurred, please try again");
      }
      setEmail("");
      setSubject("");
      setText("");
      setVisible(false);
      fetch();
    }
  };

  const handleReply = (record, data) => {
    // const replyHelp = await adminService.getHelpRequestReply({
    //   email,
    //   message,
    //   subject,
    //   helpId: record,
    // });

    // if (replyHelp?.data?.success === true) {
    //   message.success(replyHelp.data.message);
    // } else {
    //   message.warn("An error occurred, please try again");
    // }
    // fetch();
    setEmail(data.userId.email)
    setRawData(data)
    setHelpId(record);
    setVisible(true);
  };

  const columns = [
    {
      title: "User",
      dataIndex: "userId.name",
    },
    {
      title: "User Email",
      dataIndex: "userId.email",
    },
    {
      title: "User Contact",
      dataIndex: "contact",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "edit" || adminType === "delete" ? (
            <Button
              type="primary"
              style={{ marginRight: 2 }}
              onClick={() => handleReply(record, data)}
            >
              Reply
            </Button>
          ) : null}
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
  ];

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        item.message && (
          item.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.userId?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.userId?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.status.toLowerCase().includes(searchTerm.toLowerCase())
        )

    );
    if(filteredData.length === 0){message.warn('No Data Found')}
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <HelpCenterWrapper>
        <HelpCenterHeader>
          <h2>Help Center</h2>
        </HelpCenterHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <HelpCenterTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />

        <Modal
          title="Send Email"
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Input
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subject"
          />
          <TextArea
            rows={5}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Message"
          />
          <Button className="mt-2" type="primary" onClick={handleSubmit}>
            Send
          </Button>
        </Modal>
      </HelpCenterWrapper>
    </>
  );
}

export default HelpCenterList;
