import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Row, Col } from "antd";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import { useParams } from "react-router-dom";
// import moment from "moment";
import AuthGuard from "../authGuards";

const EditNewsWrapper = styled.div`
  padding: 20px;

  .input-field {
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--border-color-9);
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
  }
`;

const EditNewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const EditNewsButton = styled(Button)`
  float: right;
`;

function EditNews(props)
{
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() =>
    {
        const fetchNews = async () =>
        {
            const response = await adminService.getNewsById(id);
            const data = response.data.data;
            props.form.setFieldsValue({ ...data });
        };
        fetchNews();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (e) =>
    {

        e.preventDefault();
        props.form.validateFields(async (err, values) =>
        {
            if (!err)
            {
                setLoading(true);
                const updateNews = await adminService.updateNews(id, values);
                setLoading(false);
                if (updateNews.data.success === true)
                {
                    const logData = {body:  `News ${values.news} updated`, url: 'news/update' }
                    const createLog = await adminService.addLog(logData);
                    if (createLog.data.success === true)
                    {
                        message.success("News Successfully Updated");
                    };
                   
                    props.history.push("/admin/news");
                } else
                {
                    message.error("Error in updating News");
                }
            }
        });
    };


    const { getFieldDecorator } = props.form;
    return (
        <>
            <AuthGuard />
            <EditNewsWrapper>
                <EditNewsHeader>
                    <h2>Edit News</h2>
                    <EditNewsButton onClick={() => props.history.push("/admin/news")}>
                        Cancel
                    </EditNewsButton>
                </EditNewsHeader>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="News">
                                {getFieldDecorator("news", {
                                    rules: [{ required: true, message: "please enter" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>




                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="URL">
                                {getFieldDecorator("redirect", {
                                    rules: [{ required: false, message: "please enter pdf link" }],
                                })(<Input placeholder="please enter" />)}
                            </Form.Item>
                        </Col>

                    </Row>

                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </EditNewsWrapper>
        </>
    );
}

export default Form.create()(EditNews);
