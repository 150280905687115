import apiService from "./apiService";
import axios from "axios";

// const testURL = process.env.REACT_APP_API_URL;
const testURL = process.env.REACT_APP_API_URL_LIVE


class AdminService
{

  async adminLogin(email, password)
  {
    return apiService.post(testURL + "admin/login", {
      email: email,
      password: password,
    });
  }

  async getAllUsers()
  {
    return apiService.get(testURL + "user/allusers");
  }
  async addPayment(batchDetails, paymentId, userId, discountValue, amountToSend, discountCode)
  {
    return apiService.post(testURL + "transaction/add",
      {
        userId: userId,
        batchId: batchDetails._id,
        branchId: batchDetails.branch,
        paymentId: paymentId,
        amount: batchDetails.amount,
        gstAmount: batchDetails.gstAmount,
        actualAmount: batchDetails.actualAmount,
        discountValue: discountValue,
        priceAfterDiscount: amountToSend,
        discountCode: discountCode,
        narration: 1
      });
  }

  async addUser(data)
  {
    return apiService.post(testURL + "user/add", {
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      qualification: data.qualification,
      address: data.address,
      city: data.city,
    });
  }

  async getUserById(id)
  {
    return apiService.get(testURL + `user/id/${id}`);
  }

  async updateUser(data, img, id)
  {
    return apiService.put(testURL + `user/update`, {
      id: id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      qualification: data.qualification,
      address: data.address,
      city: data.city,
      image: img ? img : undefined,
    });
  }

  async getAllCategories()
  {
    return apiService.get(testURL + "category/list");
  }

  async addCategory(data)
  {
    return apiService.post(testURL + "category/add", {
      title: data.title,
    });
  }

  async updateCategory(id, data)
  {
    return apiService.put(testURL + `category/update`, {
      id: id,
      title: data.title,
    });
  }

  async getCategoryById(id)
  {
    return apiService.get(testURL + `category/id/${id}`);
  }

  async deleteCategory(id)
  {
    return apiService.delete(testURL + `category/delete/${id}`);
  }

  async getAllExam()
  {
    return apiService.get(testURL + `exam/list`);
  }

  async getExamDetailById(id)
  {
    return apiService.get(testURL + `exam/id/${id}`);
  }

  async addExam(data)
  {
    return apiService.post(testURL + "exam/add", {
      name: data.name,
      branchName: data.branchName,
    });
  }

  async updateExam(id, data)
  {
    return apiService.put(testURL + `exam/update`, {
      id: id,
      name: data.name,
      branchName: data.branchName,
    });
  }

  async deleteExam(id)
  {
    return apiService.delete(testURL + `exam/delete/${id}`);
  }

  async getAllLocations()
  {
    return apiService.get(testURL + `location/all`);
  }

  async addLocation(data)
  {
    return apiService.post(testURL + `location/add`, {
      city: data.city,
      state: data.state,
    });
  }

  async updateLocation(id, data)
  {
    return apiService.put(testURL + `location/update`, {
      id: id,
      city: data.city,
      state: data.state,
    });
  }

  async deleteLocation(id)
  {
    return apiService.delete(testURL + `location/delete/${id}`);
  }

  async getLocationById(id)
  {
    return apiService.get(testURL + `location/id/${id}`);
  }

  async getAllInstitutes(city)
  {
    if (city)
    {
      return apiService.get(testURL + `institute/list?type=admin&city=${city}`);
    } else
    {
      return apiService.get(testURL + `institute/list?type=admin`);
    }
  }

  async addInstitute(data, image)
  {
    return apiService.post(testURL + "institute/add", {
      name: data.name,
      info: data.info,
      city: data.city?.join(",") ?? "",
      examId: data.exam?.join(",") ?? "",
      category: data.category?.join(",") ?? "",
      image: image,
      instituteUrl: data.branchLink
    });
  }

  async updateInstitute(id, data, image)
  {
    return apiService.put(testURL + "institute/update", {
      id: id,
      name: data.name,
      city: data.city?.join(",") ?? "",
      examId: data.examId?.join(",") ?? "",
      category: data.category?.join(",") ?? "",
      info: data.info,
      rating: data.rating,
      image: image,
      instituteUrl: data.branchLink
    });

  }

  async getInstituteById(id)
  {
    return apiService.get(testURL + `institute/id/${id}`);
  }

  async deleteInstitute(id)
  {
    return apiService.delete(testURL + `institute/delete`, {
      id: id,
    });
  }

  async getAllPlans()
  {
    return apiService.get(testURL + "plan/list");
  }

  async addPlan(data)
  {
    return apiService.post(testURL + "plan/add", {
      title: data.title,
    });
  }

  async updatePlan(id, data)
  {
    return apiService.put(testURL + `plan/update`, {
      id: id,
      title: data.title,
    });
  }
  async handlePlanVisibility(obj)
  {
    return apiService.put(testURL + `plan/update`, obj);
  }

  async getPlanById(id)
  {
    return apiService.get(testURL + `plan/id/${id}`);
  }

  async deletePlan(id)
  {
    return apiService.delete(testURL + `plan/delete/${id}`);
  }
  async getAllBatches(instituteId)
  {
    if (instituteId)
    {
      return apiService.get(testURL + `batch/list?type=admin&instituteId=${instituteId}`);
    } else
    {
      return apiService.get(testURL + "batch/list?type=admin");
    }
  }


  async addBatch(data)
  {
    return apiService.post(testURL + "batch/add", {
      batchName: data.batchName,
      examId: data.examId,
      category: data.category,
      instituteId: data.instituteId,
      branchName: data.branchName ? (data.branchName?.join(",") ?? "") : null,
      planName: data.planName,
      bmbcRating: data.bmbcRating,
      facilities: data.facilities?.join(",") ?? "",
      faculties: data.faculties,
      languages: data.languages,
      amount: data.amount,
      startDate: data.startDate,
      endDate: data.endDate,
      timings: data.timings,
      image: undefined,
      city: data.city ? data.city : '',
      tagLine: data.tagLine
    });
  }

  async copyBatch(id, data)
  {
    return apiService.post(testURL + "batch/add", {
      oldBatchId: id,
      batchName: data.batchNum,
      examId: data.examId,
      category: data.category,
      instituteId: data.instituteId,
      branchName: data.branchName?.join(","),
      planName: data.planName,
      bmbcRating: data.bmbcRating,
      facilities: data.facilities?.join(",") ?? "",
      faculties: data.faculties,
      languages: data.languages,
      amount: data.amount,
      startDate: data.stDate,
      endDate: data.enDate,
      timings: data.timings,
      city: data.city ? data.city : '',
      tagLine: data.tagLine,
      image: undefined,
    });
  }

  async updateBatch(id, data)
  {

    return apiService.put(testURL + "batch/update", {
      id: id,
      batchName: data.batchName,
      examId: data.examId,
      category: data.category,
      instituteId: data.instituteId,
      branchName: data.branchName?.join(","),
      planName: data.planName,
      bmbcRating: data.bmbcRating,
      facilities: data.facilities?.join(",") ?? "",
      faculties: data.faculties,
      languages: data.languages,
      amount: data.amount,
      startDate: data.startDate,
      endDate: data.endDate,
      timings: data.timings,
      city: data.city ? data.city : '',
      tagLine: data.tagLine,
      image: undefined,
    });
  }

  async getAllAffordableBatches()
  {
    return apiService.get(testURL + "affordableBatch/list");
  }

  async getAffordableBatcheByExamId(examLocalId)
  {
    return apiService.get(testURL + `affordableBatch/id/${examLocalId}`);
  }

  async getAffordableBatcheById(id)
  {
    return apiService.get(testURL + `affordableBatch/id/${id}`);
  }

  async addAffordableBatch(data)
  {
    return apiService.post(testURL + "affordableBatch/add", {
      examId: data.examId?.join(",") ?? "",
      limit: data.maxPrice,
      category: data.mode
    });
  }
  async deleteAffordableBatch(id)
  {
    return apiService.delete(testURL + "affordableBatch/delete", {
      id: id
    });
  }

  async editAffordableBatch(data, id)
  {
    return apiService.put(testURL + "affordableBatch/update", {
      id: id,
      examId: data.examId,
      limit: data.limit,
      category: data.category
    });
  }

  async getBatchById(id)
  {
    return apiService.get(testURL + `batch/id/${id}`);
  }

  async deleteBatch(id)
  {
    return apiService.delete(testURL + `batch/delete/${id}`);
  }

  async addFacility(values)
  {
    return apiService.post(testURL + "facilities/add", {
      name: values.name,
      info: values.info
    });
  }
  async updateFacility(id, values)
  {
    return apiService.put(testURL + "facilities/update", {
      id: id,
      name: values.name,
      info: values.info
    });
  }
  async getFacilityList()
  {
    return apiService.get(testURL + "facilities/list");
  }
  async getFacilityById(id)
  {
    return apiService.get(testURL + `facilities/id/${id}`);
  }
  async deleteFacility(id)
  {
    return apiService.delete(testURL + `facilities/delete/${id}`);
  }

  // LANGUGAE CRUD FUNCTION START
  async addLanguage(values)
  {
    return apiService.post(testURL + "languages/add", {
      name: values.name,
    });
  }
  async updateLanguage(id, values)
  {
    return apiService.put(testURL + "languages/update", {
      id: id,
      name: values.name
    });
  }
  async getLanguageList()
  {
    return apiService.get(testURL + "languages/list");
  }
  async getLanguageById(id)
  {
    return apiService.get(testURL + `languages/id/${id}`);
  }
  async deleteLanguage(id)
  {
    return apiService.delete(testURL + `languages/delete/${id}`);
  }

  async getAllOffers()
  {
    return apiService.get(testURL + "offer/list");
  }

  async addOffer(data)
  {
    return apiService.post(testURL + "offer/add", {
      offerName: data.offerName,
      code: data.code,
      discountPercent: data.discountPercent,
      startDate: data.startDate,
      endDate: data.endDate,
      batchIds: data.batchIds?.join(",") ?? "",
    });
  }

  async getOfferById(id)
  {
    return apiService.get(testURL + `offer/id/${id}`);
  }

  async updateOffer(id, data)
  {
    return apiService.put(testURL + `offer/update`, {
      id: id,
      offerName: data.offerName,
      code: data.code,
      discountPercent: data.discountPercent,
      startDate: data.startDate,
      endDate: data.endDate,
      batchIds: data.batchIds?.join(",") ?? "",
    });
  }

  async deleteOffer(id)
  {
    return apiService.delete(testURL + `offer/delete/${id}`);
  }

  async getAllBranch()
  {
    return apiService.get(testURL + `branch/list`);
  }

  async addBranch(data)
  {
    return apiService.post(testURL + "branch/add", {
      name: data.name,
    });
  }

  async deleteBranch(id)
  {
    return apiService.delete(testURL + `branch/delete/${id}`);
  }
  async hideBranch(obj)
  {
    return apiService.put(testURL + `branch/update`, obj);
  }

  async getBranchDetailById(id)
  {
    return apiService.get(testURL + `branch/id/${id}`);
  }

  async updateBranch(id, data)
  {
    return apiService.put(testURL + `branch/update`, {
      id: id,
      name: data.name,
    });
  }

  async listAllHelpRequests()
  {
    return apiService.get(testURL + `help/list`);
  }

  async getHelpRequestReply({ email, message, subject, helpId })
  {
    return apiService.post(testURL + `help/reply`, {
      email,
      message,
      subject,
      helpId,
    });
  }
  async listInstitueRating()
  {
    return apiService.get(testURL + `InstituteReview/list`);
  }

  async addblog({ data })
  {
    return apiService.post(testURL + `blog/add`, {
      message: data.message,
      writtenBy: data.adminToken,
    });
  }
  async deleteBlog(id)
  {
    return apiService.delete(testURL + `blog/delete/${id}`);
  }

  async getDashboardData()
  {
    return apiService.get(testURL + `admin/dashboard`);
  }

  async handleBatchVisibility(obj)
  {
    return apiService.put(testURL + `batch/hide`, obj);
  }

  async handleInstituteVisibility(obj)

    {
    return apiService.put(testURL + `Institute/hide`, obj);
  }

  async getInstituteReviewById(id) 
  {
    return apiService.get(testURL + `InstituteReview/id/${id}`);
  }

  async updateInstitueReview(id, obj)
  {
    return apiService.put(testURL + `InstituteReview/update`, {
      id: id,
      instituteId: obj.instituteId,
      rating: obj.rating,
      message: obj.message,
      userId: obj.userId,
    });
  }

  async createBlog(id, values, image, metaImage, tagsString)
  {
    return apiService.post(testURL + `blog/add`, {
      blogId: id,
      heading: values.heading,
      message: values.message,
      description: values?.description,
      metaSlug: values?.metaSlug,
      metaTitle: values?.metaTitle,
      blogCategory: values?.blogCategory,
      metaTags: values?.metaTags,
      metaDescription: values?.metaDescription,
      metaImage: metaImage !== null ? metaImage : null,
      tags: tagsString,
      writtenBy: 'Admin',
      image: image !== null ? image : null
    });
  }

  async updateBlog(id, values, image, tagsString)
  {
    return apiService.put(testURL + `blog/update`, {
      blogId: id,
      topMessage: values.metaSlug,
      heading: values.heading,
      message: values.message,
      tags: tagsString,
      views: values.views,
      writtenBy: 'Admin',
      image: image !== null ? image : null,
      description: values?.description,
      metaSlug: values?.metaSlug,
      metaTitle: values?.metaTitle,
      blogCategory: values?.blogCategory,
      metaTags: values?.metaTags,
      metaDescription: values?.metaDescription,
    });
  }

  async getAllNews()
  {
    console.log(testURL);
    return apiService.get(testURL + "news/list");
  }

  async addNews(data)
  {
    return apiService.post(testURL + "news/add", {
      news: data.news,
      redirect: data.redirect

    });
  }

  async getNewsById(id)
  {
    return apiService.get(testURL + `news/id/${id}`);
  }

  async updateNews(id, data)
  {
    return apiService.put(testURL + `news/update`, {
      id: id,
      news: data.news,
      redirect: data.redirect,
      // createdAt: data.code,
      // updatedAt :
    });
  }

  async deleteNews(id)
  {
    return apiService.delete(testURL + `news/delete/${id}`);
  }

  async getAllPromo()
  {
    return apiService.get(testURL + "promo/list");
  }

  async getPromoById(id)
  {
    return apiService.get(testURL + `promo/id/${id}`);
  }


  async addPromo(data, statusDate)
  {
    return apiService.post(testURL + "promo/add", {
      name: data.name,
      description: data.description,
      discountPercent: data.discountPercent,
      startDate: data.startDate,
      endDate: data.endDate,
      city: data.city,
      type: data.type,
      status: statusDate,
      examId: data.examId,
      instituteId: data.instituteId,

    });
  }

  async updatePromo(id, data, statusDate)
  {
    return apiService.put(testURL + `promo/update`, {
      id: id,
      name: data.name,
      description: data.description,
      discountPercent: data.discountPercent,
      startDate: data.startDate,
      endDate: data.endDate,
      city: data.city,
      type: data.type,
      status: statusDate,
      instituteId: data.instituteId,
      examId: data.examId,

    });
  }
  async getPromoByPromoId(id)
  {
    return apiService.get(testURL + `promo/list?promoId/${id}`);
  }
  async getPromoByInstituteId(id)
  {
    return apiService.get(testURL + `promo/list?InstituteId/${id}`);
  }

  async deletePromo(id)
  {
    return apiService.delete(testURL + `promo/delete/${id}`);
  }

  async addKeyword(data)
  {
    return apiService.post(testURL + `keyword/add`,
      {
        name: data.name,

      }
    );
  }

  async updateKeyword(id, data)
  {
    return apiService.put(testURL + `keyword/update`, {
      id: id,
      name: data.name,

    });

  }

  async getKeywordById(id)
  {
    return apiService.get(testURL + `keyword/id/${id}`);
  }
  async getAllKeywords()
  {

    return apiService.get(testURL + `keyword/list`);

  }

  async deleteKeyword(id)
  {
    return apiService.delete(testURL + `keyword/delete/${id}`);
  }


  async addBatchKeyword(data, statusDate)
  {

    return apiService.post(testURL + `batchkeyword/add`,
      {
        keyword: data.keyword,
        batchId: data.batchId?.join(",") ?? "",
        startDate: data.startDate,
        endDate: data.endDate,
        status: statusDate
      }

    );

  }

  async updateBatchKeyword(id, data, statusDate)
  {
    return apiService.put(testURL + `batchkeyword/update`, {
      id: id,
      keyword: data.keyword,
      batchId: data.batchId?.join(",") ?? "",
      startDate: data.startDate,
      endDate: data.endDate,
      status: statusDate

    });

  }

  async getBatchKeywordById(id)
  {
    return apiService.get(testURL + `batchkeyword/id/${id}`);
  }
  async getAllBatchKeywords()
  {

    return apiService.get(testURL + `batchkeyword/list`);

  }

  async deleteBatchKeyword(id)
  {
    return apiService.delete(testURL + `batchkeyword/delete/${id}`);
  }



  async addInstituteKeyword(data, statusDate)
  {

    return apiService.post(testURL + `institutekeyword/add`,
      {
        keyword: data.keyword,
        instituteId: data.instituteId?.join(",") ?? "",
        startDate: data.startDate,
        endDate: data.endDate,
        status: statusDate
      }

    );

  }

  async updateInstituteKeyword(id, data, statusDate)
  {
    return apiService.put(testURL + `institutekeyword/update`, {
      id: id,
      keyword: data.keyword,
      instituteId: data.instituteId?.join(",") ?? "",
      startDate: data.startDate,
      endDate: data.endDate,
      status: statusDate

    });

  }

  async getInstituteKeywordById(id)
  {
    return apiService.get(testURL + `institutekeyword/id/${id}`);

  }
  
  async getAllInstituteKeywords()
  {

    return apiService.get(testURL + `institutekeyword/list`);

  }

  async deleteInstituteKeyword(id)
  {
    return apiService.delete(testURL + `institutekeyword/delete/${id}`);
  }

  // ------- THUMBNAILS ------
  async addThumbNail(data)
  {

    return apiService.post(testURL + `institute/thumbnail/add`, data);

  }

  async getAllAdmins()
  {
    return apiService.get(testURL + "admin/list");
  }

  async addAdminUser(data)
  {
    return apiService.post(testURL + "admin/create", {
      name: data.name,
      email: data.email,
      password: data.password,
      role: 'admin',
      adminType: data.adminType,
      accessRights: data.accessRights?.join(",") ?? "",
    });
  }

  async updateAdminStatus(id, status)
  {
    return apiService.put(testURL + "admin/update-status", {
      id: id,
      status: status,
    });
  }

  async getAdminUserById(id)
  {

  }

  async updateAdminUser(id, data)
  {
    let payload = {
      id: id,
      name: data.name,
      email: data.email,
      role: 'admin',
      adminType: data.adminType,
      accessRights: data.accessRights?.join(",") ?? "",
    };

    if (data.password && data.password.trim() !== '')
    {
      payload.password = data.password;
    }

    return apiService.put(testURL + `admin/update`, payload);
  }

  async getAllLogs()
  {
    return apiService.get(testURL + "logger/list");
  }

  // async addLog(data){
  //   const email = sessionStorage.getItem('adminEmail')
  //   return apiService.post(testURL + "logger/add", {
  //     email: data.email || email,
  //     body: data.body,
  //     url: `bookmybestclass.com/${data.url}`,
  //     oldData: data.oldData,
  //     newData: data.newData
  //   });
  // }

  async addLog(data)
  {
    const email = sessionStorage.getItem('adminEmail');

    const payload = {
      email: data.email || email,
      body: data.body,
      url: `bookmybestclass.com/${data.url}`,
      oldData: data.oldData,
      newData: data.newData
    };

    return axios.post(testURL + 'logger/add', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async getAllNotificationsData(){
    return apiService.get( testURL +'popup/list');
  }


}

export default new AdminService();
