import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './notify.css';
import { BatchContext } from '../../redux/batchContext';

const NotificationPopup = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [lastNotificationIndex, setLastNotificationIndex] = useState(-1); // Store the last shown notification index
  const { examName } = useContext(BatchContext);

  const getRandomIndex = (max) => {
    let randomIndex = currentNotificationIndex;
    while (randomIndex === currentNotificationIndex) {
      randomIndex = Math.floor(Math.random() * max);
    }
    return randomIndex;
  };

  const showNextRandomNotification = () => {
    if (notifications.length > 0) {
      let randomIndex = getRandomIndex(notifications.length);

      setCurrentNotificationIndex(randomIndex);
      setLastNotificationIndex(randomIndex); // Update the last shown notification index
      setIsNotificationVisible(true);

      setTimeout(() => {
        setIsNotificationVisible(false);
      }, 10000); // Shows notification box  for 10 seconds
    }
  };

  useEffect(() => {
    const notificationInterval = setInterval(showNextRandomNotification, 30000); // Shows notification every 30 seconds

    return () => {
      clearInterval(notificationInterval);
    };
    //eslint-disable-next-line
  }, [notifications, currentNotificationIndex, lastNotificationIndex, examName]);

  const fetchNotifications = async () => {
    const apiURL = process.env.REACT_APP_API_URL_LIVE;

    try {
      const response = await axios.get(apiURL + 'popup/list');
      const data = response.data.data;

      const newNotifications = data.filter((notification) => {
        const isPreferredCourse = examName.includes(notification.course);
        return isPreferredCourse && !notifications.map(n => n._id).includes(notification._id);
      });

      setNotifications([...notifications, ...newNotifications]);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    //eslint-disable-next-line
  }, [examName]);

  return (
    <div className={`notification-popup${isNotificationVisible ? ' show' : ''}`}>
      {isNotificationVisible && notifications.length > 0 && (
        <div className="notification">
          <span style={{ color: '#FFA500' }}>{`${notifications[currentNotificationIndex].name}`}</span> from {`${notifications[currentNotificationIndex].location}`} joined the{' '}
          <span style={{ color: 'yellow' }}><b>{`${notifications[currentNotificationIndex].course}`} Batch.</b></span>
          <p style={{ color: 'white' }}>{` ${notifications[currentNotificationIndex].time}.`}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
