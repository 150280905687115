import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";
// import InstituteSearch from "./InstituteSearch";

const InstituteManagementWrapper = styled.div`
  padding: 20px;
`;

const InstituteManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const InstituteManagementCreateButton = styled(Button)`
  float: right;
`;

const InstituteManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
const BatchButton = styled.div`
display:flex;
`

const BatchManagementCreateButton = styled(Button)`
  float: right;
  margin-left:10px;
`;

function InstituteManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");
  // const [cityList, setCityList] = useState([]);

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.getAllInstitutes().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      // const cities = [...new Set(apiResponse.data.data.map((item) => item.city))];
      setData(apiResponse.data.data);
      // setCityList(cities)
      setPagination(paginationObj);
    });
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [batchIdToDelete, setBatchIdToDelete] = useState(null);
  const [rawData, setRawData] = useState(null);

  const showDeleteModal = (batchId, data) => {
    setRawData(data)
    setBatchIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteBatch = async () => {
    try {
      const deleteBatchResponse = await adminService.deleteInstitute(
        batchIdToDelete
      );
      if (deleteBatchResponse.data.success === true) {
        const logData = {body:  `Institute ${rawData.name} deleted`, url: 'institute/delete' }
        const createLog = await adminService.addLog(logData);
        if (createLog.data.success === true)
        {
          message.success(deleteBatchResponse.data.data);
        }
      
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      setRawData(null)
      fetch();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  // const cityFilters = cityList.map((city) => ({
  //   text: city,
  //   value: city,
  // }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "City",
      dataIndex: "city",
      width: "15%",
      render: (city) => <>{city.join(", ")}</>,
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
      render: (category) =>
      {
        if (category && category.length > 0)
        {
          const examNames = category.join(", ");
          return <>{examNames}</>;
        } else
        {
          return <>No Category available</>;
        }
      },
    },
    {
      title: "Exam",
      dataIndex: "examId",
      width: "10%",
      render: (examIds) => {
        if (examIds && examIds.length > 0) {
          const examNames = examIds.map((exam) => exam.name).join(", ");
          return <>{examNames}</>;
        } else {
          return <>No exam available</>;
        }
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "20%",
      render: (image) => (
        <img style={{ width: "120px" }} src={image} alt={"instituteImage"} />
      ),
    },
    {
      title: "BranchLink",
      dataIndex: "instituteUrl",
      render: (ele) =>
      {
        return <Link to="/admin/institute">{ele}</Link>
      }
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
   
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? (
            <>
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editInstitute/${record}`}>Edit</Link>
              </Button>
            </>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button
                onClick={() => handleVisibility(data)}
                type="success"
                style={{ marginRight: 2 }}
              >
                {data.hide === "no" ? "Hide" : "Unhide"}
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editInstitute/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleVisibility = async (data) => {
    const instituteStatus = data.hide === "yes" ? "Visible" : "Hidden"
    const obj = {
      id: data._id,
      hide: data.hide === "yes" ? "no" : "yes",
    };

    const updateInstitue = await adminService.handleInstituteVisibility(obj);
    if (updateInstitue.status === 200) {
      const logData = {body:  `Institute ${data.name} status changed to ${instituteStatus}`, url: 'institute/status' }
      const createLog = await adminService.addLog(logData);
      if (createLog.data.success === true)
      {
        message.success("Log created")
      }
      message.success("Visiblity Updated");
      fetch();
    } else {
      message.error("An Error Occured");
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.city &&
          item.city[0].toLowerCase().includes(searchTerm.toLowerCase()))
    );
    if (filteredData.length === 0) {
      message.info("No Results Found");
    }
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <InstituteManagementWrapper>
        <InstituteManagementHeader>
          <h2>Institute Management</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <InstituteManagementCreateButton type="primary">
              <Link to="/admin/createInstitute">Create</Link>
            </InstituteManagementCreateButton>
          )}
        </InstituteManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
          <BatchButton>
            <BatchManagementCreateButton className="bg-success" type="light">
              <Link to="/admin/status" style={{ color: 'white' }}>Batch Status</Link>
            </BatchManagementCreateButton>
          </BatchButton>
          {/* <InstituteSearch /> */}
        </SearchWrapper>
        <InstituteManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Delete Institute"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this Institute?</p>
        </Modal>
      </InstituteManagementWrapper>
    </>
  );
}

export default InstituteManagement;
