import React from 'react';
// import PageHeader from './global-components/page-header';
import Register from './section-components/register';
// import myProf from "./media/banner/brdcrumb.jpg"
import { Helmet } from 'react-helmet';
import Layout from './global-components/Layout';
const RegisterV1 = () =>
{
    const textValue = "Register"
    return <div>
        <Helmet>
            <title>
                Access Your Personalized Learning Dashboard and Educational Resources
            </title>
            <meta
                name="description"
                content="Login to your account and gain access to your personalized learning dashboard, 
                where you can track your progress, access educational resources, and engage with interactive 
                learning materials. Sign in now to make the most of your learning experience and embark on 
                your path to academic excellence"
            />
            <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
        </Helmet>
        <Layout>
            {/* <PageHeader image={myProf} /> */}
            <Register text={textValue} />
        </Layout>
     
    </div>
}

export default RegisterV1

