import { Form, Modal, message } from 'antd';
import moment from 'moment'
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ThanksPage from '../global-components/ThanksPage';
import Benifits from '../global-components/Benifits';
import { BiChevronRight, BiChevronUp } from 'react-icons/bi';
import axios from 'axios';
import adminService from '../../common/adminService';
import userService from '../../common/userService';
import logoBmbc from "../media/banner/logo.png";
const getLocalData = () =>
{

    let userActive = localStorage.getItem("id");

    if (userActive)
    {
        return localStorage.getItem("id")
    } else
    {
        return [];
    }
}
const DescribeBatch = ({ data }) =>
{
    const testURL = process.env.REACT_APP_API_URL_LIVE;
    const [activeUser, setActiveUser] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenPage, setIsModalOpenPage] = useState(false);
    const [whyBmbcModal, setWhyBmbcModal] = useState(false)
    const [applyOffer, setApplyOffer] = useState();
    const [discountValue, setDiscountValue] = useState();
    const [reviewModal, setReviewModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [image, setImage] = useState("");
    const [qualification, setQualification] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [discountCode, setDiscountCode] = useState(null);
    const [alertMsg, setAlertMsg] = useState();
    const [branchNameLocal, setBranchNameLocal] = useState("")
    const userActiveId = localStorage.getItem("id");
    useEffect(() =>
    {
        const batchNum = localStorage.getItem("batchNumber")
        if (batchNum)
        {
            setBranchNameLocal(batchNum)
        }
        window.scrollTo(0, 0);
        getLocalData();
        if (userActiveId)
        {
            fetchUserData();
        }
        // eslint-disable-next-line
    }, []);

    // HANDLE RAZORPAY PAYEMNT

    const showModal = () =>
    {
        setCollapse3(true);
        const userId = localStorage.getItem("id");
        if (!userId)
        {
            localStorage.setItem("redirectUrl", window.location.pathname)
            message.error("Please Login First");
        } else
        {
            setIsModalOpen(true);
        }

    };

    const bmbcModalFunction = () =>
    {
        setWhyBmbcModal(true);
    }

    const handleCancelBMBCwHy = () =>
    {
        setWhyBmbcModal(false)
    }

    const handleCancelThankyou = () =>
    {

    }

    const loadScript = (src) =>
    {
        setIsModalOpen(false);

        return new Promise((resolve) =>
        {
            const script = document.createElement('script')
            script.src = src

            script.onload = () =>
            {
                resolve(true)
            }
            script.onerror = () =>
            {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    };

    const handleApplyChange = (applyValue) =>
    {
        const originalAmount = parseFloat(data.amount.toString().replace(/,/g, ''));

        if (applyValue.includes('%'))
        {
            const discountPercent = parseFloat(applyValue);
            const discountAmount = (originalAmount * discountPercent) / 100;
            const newActualAmount = originalAmount - discountAmount;

            setApplyOffer(newActualAmount);
            setDiscountValue(discountAmount);
        } else
        {
            const discountAmount = parseFloat(applyValue);
            const newActualAmount = originalAmount - discountAmount;
            setApplyOffer(newActualAmount);
            setDiscountValue(discountAmount);
        }
    };

    const displayRazorpay = async (batchDetails) =>
    {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res)
        {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }


        // creating a new order
        const amountToSend = applyOffer ? applyOffer : batchDetails.amount;
        const result = await axios.post(testURL + "transaction/order-create", {
            amount: amountToSend * 100
        });

        if (result.data.success)
        {
            const order_id_data = result.data.data.id;

            const options = {
                // rzp_test_u7kqE98f4F6ciQ
                // rzp_live_7ck6XrPcnXgzmw
                key: "rzp_live_7ck6XrPcnXgzmw",
                amount: amountToSend * 100,
                currency: "INR",
                name: "Book My Best Class",
                description: "Book My Best Class",
                image: logoBmbc,
                order_id: order_id_data,
                handler: async function (response)
                {
                    const data = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                    };
                    try
                    {
                        const result = await axios.post(testURL + "transaction/payment-create", data);
                        message.success(result.data.msg);
                        const paymentId = response.razorpay_payment_id;
                        if (paymentId && result)
                        {
                            try
                            {
                                const userId = localStorage.getItem("id");
                                const add = await adminService.addPayment(batchDetails, paymentId, userId, discountValue, amountToSend, discountCode);
                                const transactionIdNew = add.data.data._id
                                if (add.data.success === true)
                                {
                                    message.success("Payment succesfully");
                                } else
                                {
                                    message.error("Your Payment recive we will update soon")
                                }
                                const send = await userService.sendInvoice(transactionIdNew);
                                if (send.data.success === true)
                                {
                                    message.success("Check your email to get more details")
                                }
                                const subscribe = "yes"
                                const { photo, phone, address, city, email, name, password, qualification } = activeUser;
                                const id = userId
                                const userUpdate = await axios.put(testURL + "user/update", {
                                    id,
                                    photo,
                                    phone,
                                    address,
                                    city,
                                    email,
                                    name,
                                    password,
                                    qualification,
                                    subscribe
                                })
                                if (userUpdate.data.success === true)
                                {
                                    setIsModalOpenPage(true);
                                    message.success("Purchase done")
                                }
                            } catch (error)
                            {
                                message.error("Something went wrong")
                            }

                        }
                    } catch (error)
                    {
                        message.error("Sorry payment failed try again")
                    }
                },
                prefill: {
                    name: activeUser?.name,
                    email: activeUser?.email,
                },
                theme: {
                    color: "#f0151f",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } else
        {
            alert("Server error. Are you online?");
            return;
        }
    }

    const handleCheckoutDetails = () =>
    {
        setReviewModal(true);
        setCollapse1(true);
        setCollapse3(false);
    }

    const fetchUserData = async () =>
    {
        setIsLoading(true);

        try
        {
            const userId = localStorage.getItem("id");
            const rawData = await userService.getUserDetails(userId);
            const userData = rawData.data.data
            setActiveUser(userData);;
            setName(userData.name);
            setEmail(userData.email);
            setImage(userData.photo);
            setCity(userData.city);
            setQualification(userData.qualification);
            setPhone(userData.phone);
            setAddress(userData.address);
            setIsLoading(false);
        } catch (error)
        {
            console.error(error);
            setIsLoading(false);
        }
    };

    const handleUpdateProfile = async (e) =>
    {
        e.preventDefault();
        const id = localStorage.getItem("id")
        if (email !== null && phone !== "" && name !== "")
        {
            try
            {

                const res = await axios.put(testURL + "user/update", {
                    id,
                    name,
                    email,
                    phone,
                    qualification,
                    address,
                    city,
                    image
                })
                if (res.data.success === true)
                {
                    setAlertMsg("Update Successfully");

                    setTimeout(() =>
                    {
                        setAlertMsg("");
                        setCollapse1(false)
                        setCollapse2(true)
                    }, 3000);
                }

            } catch (error)
            {

            }
        }
    }

    // const handleRegisterClick = () =>
    // {
    //   sessionStorage.setItem("redirectUrl", window.location.pathname);
    // };

    // OFFER FUNCTION START
    const [collapse1, setCollapse1] = useState(false);
    const [collapse2, setCollapse2] = useState(false);
    const [collapse3, setCollapse3] = useState(false);
    const [appliedOffers, setAppliedOffers] = useState({});

    const handleCloseProfile = () =>
    {
        setCollapse1(false);
        setCollapse2(true);
    }

    const handleCloseBatch = () =>
    {
        setCollapse2(false)
        setCollapse3(true);
    }

    const handleCloseBilling = () =>
    {
        setCollapse3(false);
        setCollapse1(true);
    }

    const handleOpenProfile = () =>
    {
        setCollapse1(true);
        setCollapse2(false);
    }

    const handleOpenBatch = () =>
    {
        setCollapse2(true)
        setCollapse3(false);
    }

    const handleOpenBilling = () =>
    {
        setCollapse3(true);
        setCollapse1(false);
    }

    const formatDiscount = (discount) =>
    {
        if (discount?.includes("%"))
        {
            return discount;
        } else
        {
            const discountValue = parseFloat(discount);
            return `Rs ${discountValue.toLocaleString("en-IN")} /-`;
        }
    };

    let BmbcCoupen = "200";

    const handleCoupenOffer = (offerId, coupenAmount) =>
    {
        setDiscountCode(offerId)
        const updatedAppliedOffers = { ...appliedOffers, [offerId]: true };
        setAppliedOffers(updatedAppliedOffers);
        const amount = coupenAmount !== undefined ? coupenAmount : 0;
        handleApplyChange(amount);
    };

    return (
        <>
            <div className='batch_short_details'>
                <div className='batch_details_left'>
                    <div className='top'>
                        <div className='top_left'>
                            <img src={data?.instituteId?.image} alt="baych_logo" />
                        </div>
                        <div className='top_right'>
                            <h2>{data?.instituteId?.name}</h2>
                            <h3 className="align-items-start">Facilities : </h3>
                            <ol>
                                {data.facilities?.map((facility, index) => (
                                    <li key={index}><p>{facility.name}</p></li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <h4>BMBC Quality Management Team Rating:<p>
                        {
                            data?.bmbcRating
                                ? data?.bmbcRating % 1 !== 0
                                    ? data?.bmbcRating.toFixed(2)
                                    : data?.bmbcRating.toFixed(1)
                                : "0.00"
                        }
                    </p></h4>
                    <div className='center'>
                        <ul>
                            <li>
                                <p>Number :</p>
                                <p>{data.batchName}</p>
                            </li>
                            <li>
                                <p>Category :</p>
                                <p>{data.category}</p>
                            </li>
                            <li>
                                <p>Price : </p>
                                <p> {data.amount.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                }).split('.')[0]} <span className="incl_gst">incl. GST</span></p>
                            </li>
                            {/* <li>
                                <p>Start Date :</p>
                                <p>{moment(data.startDate).format("DD.MM.YYYY")}</p>
                            </li> */}
                        </ul>
                        <ul>
                            <li>
                                <p>Exam :</p>
                                <p>{data?.examId?.name}</p>
                            </li>
                            {/* <li>
                                <p>Timing :</p>
                                <p>{data.timings}</p>
                            </li> */}
                            <li>
                                <p>Language :</p>
                                <p>{data?.languages?.name}</p>
                            </li>
                            {/* <li>
                                <p>End Date :</p>
                                <p>{moment(data.endDate).format("DD.MM.YYYY")}</p>
                            </li> */}

                        </ul>
                    </div>
                    <p className="mb-0 inst_info_txt">{data.instituteId?.info} </p>
                </div>
                <div className='batch_details_right'>
                    <div className="batch_right_column_wrap">
                        <div className="book_btn_text2">
                            <button className="pay_now_btn me-1" onClick={showModal}>
                                Book Now
                            </button>
                            <Modal
                                className="thankYouPage"
                                visible={isModalOpenPage}
                                onCancel={handleCancelThankyou}
                                footer={false}
                            >
                                <ThanksPage/>
                            </Modal>
                            <button className="pay_now_btn bg_blue ms-1" onClick={bmbcModalFunction}>
                                Why BMBC
                            </button>
                            <Modal
                                className="thankYouPage"
                                visible={whyBmbcModal}
                                onCancel={handleCancelBMBCwHy}
                                footer={false}
                            >
                                <Benifits />
                            </Modal>
                            {/* WHY BMBC ENDS */}
                        </div>
                        {
                            reviewModal &&
                            <div className="checkout_details_box">
                                <Form onSubmit={handleUpdateProfile} className="login-form">
                                    <div className="d-flex justify-content-between">
                                        <h2 className="mb-0 pb-0">Review Profile :</h2>
                                        {
                                            collapse1 ?
                                                <BiChevronUp className="close_bill_icon" onClick={handleCloseProfile} />
                                                :
                                                <BiChevronRight className="close_bill_icon" onClick={handleOpenProfile} />

                                        }
                                    </div>

                                    {
                                        collapse1 &&
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input value={name} className="form-control" onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input value={phone} className="form-control" onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Contact" />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex align-items-center">
                                                <button
                                                    htmltype="submit"
                                                    loading={isLoading}
                                                    className="theme_btn_main mt-2 me-auto"
                                                >
                                                    Update
                                                </button>
                                                {
                                                    alertMsg && <p className="ps-2 alert_msg_otp mb-0">{alertMsg}</p>
                                                }
                                            </div>
                                        </div>
                                    }

                                </Form>
                                <div className="review_details_form">
                                    <div className="d-flex justify-content-between">
                                        <h2 className="mb-0 pb-0">Review Batch Detail :</h2>
                                        {
                                            collapse2 ?
                                                <BiChevronUp className="close_bill_icon" onClick={handleCloseBatch} />
                                                :
                                                <BiChevronRight className="close_bill_icon" onClick={handleOpenBatch} />
                                        }
                                    </div>
                                    {
                                        collapse2 &&
                                        <div className="review_details_form_card">
                                            <ul>
                                                <li>
                                                    <h1>Exam : <p>{data.examId?.name}</p> </h1>

                                                </li>
                                                <li>
                                                    <h1>Category : <p>{data.category}</p> </h1>
                                                </li>
                                                {
                                                    branchNameLocal &&
                                                    <li>
                                                        <h1 className="mb-0">Branch : <p>{branchNameLocal}</p></h1>
                                                    </li>
                                                }

                                                <li>
                                                    <h1>Name :<p>{data?.planName?.title}</p></h1>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <h1>Number :<p>{data.batchName}</p></h1>
                                                </li>

                                                <li>
                                                    <h1>Insitute Name :<p>{data.instituteId?.name}</p></h1>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            isModalOpen &&
                            <div className="review_page_wrap">

                                <div className="d-flex justify-content-between">
                                    <h2 className="mb-0 pb-0">Review Billing Details :</h2>
                                    {
                                        collapse3 ?
                                            <BiChevronUp className="close_bill_icon" onClick={handleCloseBilling} />
                                            :
                                            <BiChevronRight className="close_bill_icon" onClick={handleOpenBilling} />

                                    }
                                </div>
                                {
                                    collapse3 &&
                                    <>
                                        <div className="batch_details_checkout">
                                            <div className="chkout_batch_img">
                                                <img src={data?.instituteId?.image} alt="batchImage" />
                                                <span>
                                                    <p>{data?.instituteId?.name}</p>
                                                    <p>{data?.category}</p>
                                                    <p>{data.batchName}</p>
                                                    <p>From : {moment(data.startDate).format("DD.MM.YYYY")}</p>
                                                    <p>To :  {moment(data.endDate).format("DD.MM.YYYY")}</p>

                                                </span>
                                            </div>
                                            <div className="chkout_price">
                                                <span>
                                                    <p>Price</p>
                                                    <p>GST & Other Taxes</p>
                                                    <p>Sub-Total</p>
                                                    <p>Discount</p>
                                                    <h5 className="pt-3">Total </h5>
                                                </span>
                                                <span>
                                                    <p>
                                                        {data?.actualAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        })}
                                                    </p>
                                                    <p>
                                                        {data?.gstAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        })}
                                                    </p>
                                                    <p>
                                                        {data.amount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        })}
                                                    </p>
                                                    {
                                                        discountValue ? <p className="dicount_price_text">
                                                            {discountValue?.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            })}
                                                        </p>
                                                            :
                                                            <p>00.00</p>

                                                    }
                                                    <hr />
                                                    {applyOffer ?
                                                        <h5>
                                                            {applyOffer.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            })}
                                                        </h5> :

                                                        <h5>
                                                            {data.amount.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            })}
                                                        </h5>
                                                    }

                                                </span>
                                            </div>
                                        </div>
                                        {reviewModal &&
                                            <div className="d-flex justify-content-end">
                                                <button className="theme_btn_main" type="button" onClick={() => displayRazorpay(data)}>Make Payment</button>
                                            </div>
                                        }
                                        {
                                            !reviewModal &&
                                            <div className="d-flex justify-content-end">
                                                <button className="theme_btn_main" type="button" onClick={handleCheckoutDetails}>CheckOut</button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        }
                        <div className="offer_wrap_section">
                            <div className="heading_wrap">
                                <h2>Offers</h2>
                                <span className="circle1"></span>
                            </div>
                            <div className="offer_tabs">
                                {/* <Card batchDetails={batchDetails} onApplyChange={handleApplyChange} isModalOpen={isModalOpen} /> */}
                                <div className="coupon-card">
                                    <div className='logo_wrap'>
                                        <div>
                                            <img src={data?.instituteId?.image} className="logo" alt='coupon' />
                                        </div>
                                        <div>
                                            <h3>You have an offer from BMBC</h3>
                                            <p>200Rs. off only for one time</p>
                                            {isModalOpen && (
                                                <div className="coupon-row">

                                                    {!appliedOffers["BMBC01"] ? (
                                                        <>
                                                            <span id="cpnCode">BMBC01</span>
                                                            <span id="cpnBtn" onClick={() => handleCoupenOffer("BMBC01", BmbcCoupen)}>Apply Code</span>

                                                        </>
                                                    ) : (
                                                        <span id="aplBtn">Applied</span>
                                                    )}
                                                </div>
                                            )}

                                            <Link to="/term-condition">Terms & Conditions</Link>
                                        </div>
                                    </div>
                                </div>
                                {
                                    data.offers.map((offer) => (
                                        <div className="coupon-card" key={offer._id}>
                                            <div className='logo_wrap'>
                                                <img src={data?.instituteId?.image} className="logo" alt='coupon' />
                                                <div>
                                                    <h3>{formatDiscount(offer.discountPercent)} Off</h3>
                                                    <h3>{offer.offerName}</h3>
                                                    <p>Offer end on {moment(offer.endDate).format("DD.MM.YYYY")}</p>
                                                    {isModalOpen && (
                                                        <div className="coupon-row">

                                                            {!appliedOffers[offer.code] ? (
                                                                <>

                                                                    <span id="cpnCode">{offer.code}</span>
                                                                    <span id="cpnBtn" onClick={() => handleCoupenOffer(offer.code, formatDiscount(offer.discountPercent))}>Apply Code</span>
                                                                </>
                                                            ) : (
                                                                <span id="aplBtn">Applied</span>
                                                            )}
                                                        </div>
                                                    )}

                                                    <Link to="/term-condition">Terms & Conditions</Link>

                                                </div>
                                            </div>

                                        </div>
                                    ))
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DescribeBatch