import React from 'react';
import { BiSad } from "react-icons/bi";

const ErrorPage = ({value}) =>
{
    return <div className='no_data_found'>
        <div>
            <span>
                <BiSad />
            </span>
          
            <p>
               {value}
            </p>
        </div>

    </div>
}

export default ErrorPage

