import React, { useState, useEffect } from "react";
import { Button, Table, message, Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import SearchComponent from "../search";
import moment from "moment";

const ExamManagementWrapper = styled.div`
  padding: 20px;
`;

const ExamManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ExamManagementCreateButton = styled(Button)`
  float: right;
`;

const ExamManagementTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

// const ExamManagementColumnAction = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const ExamManagementEditButton = styled(Button)`
//   margin-right: 8px;
// `;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function ExamManagement() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.getAllExam().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      setLoading(false);
      setData(apiResponse.data.data);
      console.log(apiResponse.data.data);
      setPagination(paginationObj);
    });
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [batchIdToDelete, setBatchIdToDelete] = useState(null);

  const showDeleteModal = (batchId, data) => {
    setRawData(data)
    setBatchIdToDelete(batchId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteBatch = async () => {
    try {
      const deleteBatchResponse = await adminService.deleteExam(
        batchIdToDelete
      );
      if (deleteBatchResponse.data.success === true) {
        const logData = {body:  `Exam ${rawData.name} deleted`, url: 'exam/delete' }
        const createLog = await adminService.addLog(logData)
        if (createLog.data.success === true)
        {
          message.success(deleteBatchResponse.data.data);
        };
      
      } else {
        message.warn("An error occurred. Please try again");
      }
      setIsDeleteModalOpen(false);
      fetch();
    } catch (error) {
      message.warn("An error occurred. Please try again");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record, data) => (
        <>
          {adminType === "view" ? null : adminType === "edit" ? ( // Render no button
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editExam/${record}`}>Edit</Link>
            </Button>
          ) : adminType === "delete" ? (
            <>
              <Button
                onClick={() => showDeleteModal(record, data)}
                type="danger"
                style={{ marginRight: 2 }}
              >
                Delete
              </Button>
              <Button type="primary" style={{ marginRight: 2 }}>
                <Link to={`/admin/editExam/${record}`}>Edit</Link>
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleSearch = (searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if(filteredData.length === 0){message.warn('No Data Found')}
    setFilteredData(filteredData);
  };

  return (
    <>
      <AuthGuard />
      <TopNav />
      <ExamManagementWrapper>
        <ExamManagementHeader>
          <h2>Exam Management</h2>
          {(adminType === "delete" || adminType === "edit") && (
            <ExamManagementCreateButton type="primary">
              <Link to="/admin/createExam">Create</Link>
            </ExamManagementCreateButton>
          )}
        </ExamManagementHeader>
        <SearchWrapper>
          <SearchComponent data={data} onSearch={handleSearch} />
        </SearchWrapper>
        <ExamManagementTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={filteredData.length > 0 ? filteredData : data}
          pagination={
            filteredData.length > 0 ? filteredData.length : data.length
          }
          loading={loading}
          onChange={() => handleTableChange}
        />
        <Modal
          title="Delete Exam"
          visible={isDeleteModalOpen}
          onOk={handleDeleteBatch}
          onCancel={handleCancelDelete}
          okText="Delete"
          okType="danger"
          cancelText="Cancel"
          centered
          maskClosable={false}
          width={400}
        >
          <p>Are you sure you want to delete this Exam?</p>
        </Modal>
      </ExamManagementWrapper>
    </>
  );
}

export default ExamManagement;
