import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import userService from "../../common/userService";
import "../assets/css/batchDetail.css";
import moment from "moment";
import axios from "axios";
import { message } from "antd";
import Spinner from "../global-components/Spinner";
import { FaStar, FaRegStar } from 'react-icons/fa';
import logoBmbc from "../media/banner/logo.png";
import logoUser from "../media/banner/avtarbmbc.jpg";
import { Modal, Form } from 'antd';
import adminService from "../../common/adminService";
import ThanksPage from "../global-components/ThanksPage";
import Benifits from "../global-components/Benifits";
import "../assets/css/coupen.css";
import Register from "./register";
import { BiChevronUp, BiChevronRight } from "react-icons/bi";

const getLocalData = () =>
{
  let userActive = localStorage.getItem("id");
  if (userActive)
  {
    return localStorage.getItem("id")
  } else
  {
    return [];
  }
}

const BatchDetails = ({ onData }) =>
{
  const testURL = process.env.REACT_APP_API_URL_LIVE;
  const [batchReview, setBatchReview] = useState([]);
  const [batchDetails, setBatchDetails] = useState(null);
  const [intId, setIntId] = useState();
  const [ratingComment, setRatingComment] = useState('');
  const [ratingCount, setRatingCount] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [userData] = useState(getLocalData());
  const [activeUser, setActiveUser] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenPage, setIsModalOpenPage] = useState(false);
  const [whyBmbcModal, setWhyBmbcModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false);
  const [applyOffer, setApplyOffer] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [viewMore, setViewMore] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [qualification, setQualification] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [discountCode, setDiscountCode] = useState(null);
  const [alertMsg, setAlertMsg] = useState()
  const userActiveId = localStorage.getItem("id");
  const history = useHistory();
  const textValue = "Register";

  const getBatchDetail = async (id) =>
  {
    setLoading(true);
    const rawData = await userService.getBatchById(id);
    const data = rawData.data.data;

    setBatchDetails(data);
    setIntId(data.instituteId._id);
    const name = data.instituteId.name;
    const description = data.instituteId.info;
    const category = data.category;
    const city = data.city;
    const logo = data.image;
    onData(name, description, category, city, logo);
    setLoading(false);
  };

  useEffect(() =>
  {
    if (!userActiveId)
    {
      sessionStorage.setItem("redirectUrl", window.location.pathname);
      setRegisterModal(true);
    }
    window.scrollTo(0, 0);
    getBatchDetail(id);
    getLocalData();
    if (userActiveId)
    {
      fetchUserData();
    }
    // eslint-disable-next-line
  }, []);

  const handleStarClick = (rating) =>
  {
    setRatingCount(rating.toString());
  };

  const handlePostReview = async (e) =>
  {
    e.preventDefault();

    const userId = localStorage.getItem('id');
    if (!userId)
    {
      localStorage.setItem("redirectUrl", window.location.pathname)
      history.push('/login')
      message.error('Login to give a review');
    } else if (ratingComment === '' || ratingCount === '')
    {
      message.warn('Please fill in all fields');
    } else
    {
      const obj = { rating: ratingCount, comment: ratingComment, userActiveId: userData };

      const add = await userService.addInstitueReview(intId, obj);
      if (add.data.success === true)
      {
        message.success('Rating Added');
        handleReviews();

      } else
      {
        message.error('An error occurred. Please try again later');
      }
    }
  };

  const handleReviews = async () =>
  {
    try
    {
      const res = await axios.get(testURL + `instituteReview/instituteId/${intId}`);
      const reviewData = res.data.data;
      setBatchReview(reviewData);
    } catch (error)
    {
      console.log(error);
    }
    setLoading(false)
  }

  // HANDLE RAZORPAY PAYEMNT

  const showModal = () =>
  {
    setCollapse3(true);
    const userId = localStorage.getItem("id");
    if (!userId)
    {
      localStorage.setItem("redirectUrl", window.location.pathname)
      message.error("Please Login First");
    } else
    {
      setIsModalOpen(true);
    }

  };

  const bmbcModalFunction = () =>
  {
    setWhyBmbcModal(true);
  }

  const handleCancelBMBCwHy = () =>
  {
    setWhyBmbcModal(false)
  }

  const handleCancelThankyou = () =>
  {

  }

  const loadScript = (src) =>
  {
    setLoading(true);
    setIsModalOpen(true);
    setTimeout(() =>
    {
      setLoading(false);

    }, 1000);

    return new Promise((resolve) =>
    {
      const script = document.createElement('script')
      script.src = src

      script.onload = () =>
      {
        resolve(true)
      }
      script.onerror = () =>
      {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  };

  const handleApplyChange = (applyValue) =>
  {
    const originalAmount = parseFloat(batchDetails.amount.toString().replace(/,/g, ''));

    if (applyValue.includes('%'))
    {
      const discountPercent = parseFloat(applyValue);
      const discountAmount = (originalAmount * discountPercent) / 100;
      const newActualAmount = originalAmount - discountAmount;

      setApplyOffer(newActualAmount);
      setDiscountValue(discountAmount);
    } else
    {
      const discountAmount = parseFloat(applyValue);
      const newActualAmount = originalAmount - discountAmount;
      setApplyOffer(newActualAmount);
      setDiscountValue(discountAmount);
    }
  };

  const [error, setError] = useState("");
  const permissionIds = [
    "6461de19cd10f83e9358827b",
    "64be659d4dcfa238cc4b2b14",
    "64e473a04f25d7076635288a",
    "64e487ff4f25d707663528b9",
    "64be65bb4dcfa238cc4b2b16",
    "6463245acd10f83e9358828e"
  ]
  const displayRazorpay = async (batchDetails) =>
  {
    if (permissionIds.includes(batchDetails.examId._id) && (!batchDetails.branch || batchDetails.branch === "Select Branch"))
    {
      setError("Please choose branch in review batch detail for continue purchase");
      setTimeout(() =>
      {
        setError("")
      }, 2000);
    } else
    {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res)
      {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      // creating a new order
      const amountToSend = applyOffer ? applyOffer : batchDetails.amount;
      const result = await axios.post(testURL + "transaction/order-create", {
        amount: amountToSend * 100
      });

      if (result.data.success)
      {
        const order_id_data = result.data.data.id;
        setCollapse1(false);
        setCollapse2(false);
        setCollapse3(false);
        const options = {
          key: "rzp_live_7ck6XrPcnXgzmw",
          amount: amountToSend * 100,
          currency: "INR",
          name: "Book My Best Class",
          description: "Book My Best Class",
          image: logoBmbc,
          order_id: order_id_data,
          handler: async function (response)
          {
            const data = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            try
            {
              const result = await axios.post(testURL + "transaction/payment-create", data);

              const paymentId = response.razorpay_payment_id;
              if (paymentId && result)
              {
                try
                {
                  const userId = localStorage.getItem("id");
                  const add = await adminService.addPayment(batchDetails, paymentId, userId, discountValue, amountToSend, discountCode);
                  console.log(add);
                  console.log(add.data.data);
                  const transactionIdNew = add.data.data._id
                  console.log(transactionIdNew);
                  if (add.data.success === true)
                  {
                    message.success("Payment successfully");
                  } else
                  {
                    message.error("Your Payment recive we will update soon")
                  }
                  const send = await userService.sendInvoice(transactionIdNew);
                  console.log(send);
                  if (send.data.success === true)
                  {
                    message.success("Check your email to get more details")
                  }
                  const subscribe = "yes"
                  const { photo, phone, address, city, email, name, password, qualification } = activeUser;
                  const id = userId
                  const userUpdate = await axios.put(testURL + "user/update", {
                    id,
                    photo,
                    phone,
                    address,
                    city,
                    email,
                    name,
                    password,
                    qualification,
                    subscribe
                  })
                  console.log(userUpdate);
                  if (userUpdate.data.success === true)
                  {
                    setIsModalOpenPage(true);
                    message.success("Purchase done")
                  }
                } catch (error)
                {
                  message.error("Something went wrong")
                }

              }
            } catch (error)
            {
              message.error("Sorry payment failed try again")
            }
          },
          prefill: {
            name: activeUser?.name,
            email: activeUser?.email,
          },
          theme: {
            color: "#f0151f",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else
      {
        alert("Server error. Are you online?");
        return;
      }
    }
  }

  const handleCheckoutDetails = () =>
  {
    setReviewModal(true);
    setCollapse1(false);
    setCollapse3(true);
  }

  const fetchUserData = async () =>
  {
    setIsLoading(true);

    try
    {
      const userId = localStorage.getItem("id");
      const rawData = await userService.getUserDetails(userId);
      const userData = rawData.data.data
      setActiveUser(userData);;
      setName(userData.name);
      setEmail(userData.email);
      setImage(userData.photo);
      setCity(userData.city);
      setQualification(userData.qualification);
      setPhone(userData.phone);
      setAddress(userData.address);
      setIsLoading(false);
    } catch (error)
    {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleUpdateProfile = async (e) =>
  {
    e.preventDefault();
    const id = localStorage.getItem("id")
    if (email !== null && phone !== "" && name !== "")
    {
      try
      {

        const res = await axios.put(testURL + "user/update", {
          id,
          name,
          email,
          phone,
          qualification,
          address,
          city,
          image
        })
        if (res.data.success === true)
        {
          setAlertMsg("Update Successfully");

          setTimeout(() =>
          {
            setAlertMsg("");
            setCollapse1(false)
            setCollapse2(true)
          }, 3000);
        }

      } catch (error)
      {

      }
    }
  }

  const handleSuccessClose = () =>
  {
    setRegisterModal(false);
  };
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [appliedOffers, setAppliedOffers] = useState({});

  const handleCloseProfile = () =>
  {
    setCollapse1(false);
    setCollapse2(true);
  }

  const handleCloseBatch = () =>
  {
    setCollapse2(false)
    setCollapse3(true);
  }

  const handleCloseBilling = () =>
  {
    setCollapse3(false);
    setCollapse1(true);
  }

  const handleOpenProfile = () =>
  {
    setCollapse1(true);
    setCollapse2(false);
  }

  const handleOpenBatch = () =>
  {
    setCollapse2(true)
    setCollapse3(false);
  }

  const handleOpenBilling = () =>
  {
    setCollapse3(true);
    setCollapse1(false);
  }

  const formatDiscount = (discount) =>
  {
    if (discount?.includes("%"))
    {
      return discount;
    } else
    {
      const discountValue = parseFloat(discount);
      return `Rs ${discountValue.toLocaleString("en-IN")} /-`;
    }
  };

  let BmbcCoupen = "200";

  const handleCoupenOffer = (offerId, coupenAmount) =>
  {
    setDiscountCode(offerId)
    const updatedAppliedOffers = { ...appliedOffers, [offerId]: true };
    setAppliedOffers(updatedAppliedOffers);
    const amount = coupenAmount !== undefined ? coupenAmount : 0;
    handleApplyChange(amount);
  };

  const handleInput = (e) =>
  {
    const branchValue = e.target.value
    batchDetails.branch = branchValue;
    setCollapse3(true);
  }



  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        {
          loading ?
            <Spinner />
            :
            (
              batchDetails && (
                <>
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <div className="batch_detail_box">
                        <h2 className="batch_header">Batch Details</h2>
                        <div className="batch_detail_box_head">
                          <div className="row">
                            <div className="col-12">
                              <h1>
                                Institute Name : <p>{batchDetails.instituteId?.name}</p>
                              </h1>
                              <h1>Exam :<p>{batchDetails?.examId?.name}</p></h1>
                              <h1>Category : <p>{batchDetails.category}</p> </h1>
                              <h1>Number :<p>{batchDetails.batchName}</p></h1>
                              {
                                batchDetails?.examId.name === "GATE" &&
                                <h1 className="mb-0">Branch : {batchDetails?.branchName?.map((idx, index) => (
                                  <p key={index}>{idx.name}   {index < batchDetails.branchName.length - 1 && <span>, </span>}</p>
                                ))}
                                </h1>
                              }

                              <h1>Type :<p className="planName">{batchDetails?.planName?.title}</p></h1>
                              <div className="batch_facilities">
                                <h1 className="align-items-start">Facilities : </h1>
                                <ol>
                                  {batchDetails.facilities?.map((facility, index) => (
                                    <li key={index}><p>{facility.name}</p></li>
                                  ))}
                                </ol>
                                <h1>BMBC Quality Management Team Rating:
                                  <p>
                                    {
                                      batchDetails?.bmbcRating ?
                                        batchDetails?.bmbcRating.toFixed(2)
                                        : "0.00"
                                    }
                                  </p>
                                </h1>

                              </div>
                              <h1>Faculties :<p>{batchDetails?.faculties}</p></h1>
                              <h1 className="mb-0">Price : <p> {batchDetails.amount.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).split('.')[0]} <span className="incl_gst">incl. GST</span></p></h1>
                              {batchDetails.category !== "Online" && <h1 className="mb-0">City : <p>{batchDetails?.city}</p></h1>}
                            </div>
                          </div>
                        </div>

                        {/* <div className="date_wrap">
                          <h6>Start Date: <p style={{ color: "#f0151f" }}>{moment(batchDetails.startDate).format("DD.MM.YYYY")}</p></h6>
                          <h6 className="ms-3">End Date: <p style={{ color: "#f0151f" }}>{moment(batchDetails.endDate).format("DD.MM.YYYY")}</p></h6>
                        </div> */}
                        <h2>Institute Description :</h2>
                        {
                          !viewMore &&
                          <>
                            <p className="mb-0 inst_info_txt">{batchDetails.instituteId?.info.slice(0, 100)}</p>
                            <button type="button" className="info_read_more_btn" onClick={() => setViewMore(true)}>read more..</button>
                          </>
                        }
                        {
                          viewMore && <>
                            <p className="mb-0 inst_info_txt">{batchDetails.instituteId?.info}</p>
                            <button type="button" className="info_read_more_btn" onClick={() => setViewMore(false)}>read less..</button>
                          </>
                        }
                        {/* <div className="batch_timings desk_v">
                          <h4 className="title_head mb-0">Class Timings</h4>
                          <ul>
                            <li>{batchDetails.timings}</li>
                          </ul>
                        </div> */}

                        <div className="book_btn_text_mob">
                          <button type="primary" className="btn pay_now_btn me-1" onClick={showModal}>
                            Book Now
                          </button>
                          <Modal
                              className="thankYouPage"
                              visible={isModalOpenPage}
                              onCancel={handleCancelThankyou}
                              footer={false}
                          >
                              <ThanksPage />
                          </Modal>
                          <button type="primary" className="pay_now_btn blue_btn ms-1" onClick={bmbcModalFunction}>
                            Why BMBC
                          </button>
                        </div>

                        <div className="comment_lv_fm cmnt_desk_view">
                          <form action="#">
                            <h4>Add a Review</h4>
                            <div className="mb-15">
                              <div className="add-a-review">
                                <h6>Your Rating:</h6>
                                <div className="product-ratting">
                                  <ul>
                                    {[1, 2, 3, 4, 5].map((rating) => (
                                      <li key={rating}>
                                        <button
                                          type="button"
                                          className={`star-icon${rating <= parseInt(ratingCount) ? ' active' : ''}`}
                                          onClick={() => handleStarClick(rating)}
                                        >
                                          <FaStar />
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="leave_cmnt_input">
                              <input type="text" className="form-control" value={ratingComment} onChange={(e) => setRatingComment(e.target.value)} placeholder="Type your comments...." />
                              <button
                                className="sign_up"
                                type="submit"
                                onClick={(e) => handlePostReview(e)}
                              >
                                Post
                              </button>
                            </div>
                          </form>

                          <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 mt-30 ltn__product-details-review-inner mobile_sidedesk">
                            <div className="d-flex justify-content-between align-items-start">
                              <div>
                                <h4 className="title_head mb-0">
                                  Student Ratings
                                </h4>
                                <div className="product-ratting mt-0">
                                  <ul className="ps-3">
                                    {Array.from({ length: 5 }).map((_, index) => (
                                      <li key={index} className="me-2">
                                        <span>
                                          {index < batchDetails?.instituteId?.rating ? <FaStar /> : <FaRegStar />}
                                        </span>
                                      </li>
                                    ))}
                                    <li><p>({batchDetails.instituteId?.rating.toFixed(1) + "0"})</p></li>
                                  </ul>
                                </div>
                              </div>
                              <button type="button" className="btn btn-light btn-outline-secondary" onClick={handleReviews}>See Reviews ({batchDetails?.instituteId?.ratingCount - 1})</button>
                            </div>

                            <hr className="d-block" />
                            <div className="row gy-4 mt-2 mb-4 reviews_col_row">
                              {
                                batchReview.length > 0 &&
                                batchReview.map((review, id) => (
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={id}>
                                    <div className="review_card">
                                      <div className="heading_rev">
                                        <img src={review.userId?.photo ? review.userId?.photo : logoUser} alt="hjghdhds" />
                                        <div className="name">
                                          <span className="rating_icon">
                                            <h4>
                                              {review?.userId?.name}

                                            </h4>
                                            <h5>
                                              {
                                                review?.userId?.city
                                              }
                                            </h5>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="cmt">
                                        {
                                          review?.message
                                        }
                                      </p>
                                      <span className="rating_star_number">
                                        ({review?.rating}) <FaStar />
                                      </span>
                                    </div>
                                  </div>
                                ))
                              }
                              {/* <p>{batchReview.length > 0 ? "" : "No reviews yet"}</p> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="app-container">
                          <div className="gradient-background">
                          </div>
                          <div className="gallery-container">
                            <div className="gallery-scrollable">
                            </div>
                          </div>
                        </div> */}
     
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="batch_right_column_wrap">
                        <div className="book_btn_text2">
                          <button className="pay_now_btn me-1" onClick={showModal}>
                            Book Now
                          </button>
                          <Modal
                            className="thankYouPage"
                            visible={isModalOpenPage}
                            onCancel={handleCancelThankyou}
                            footer={false}
                          >
                            <ThanksPage/>
                          </Modal>
                          <button className="pay_now_btn bg_blue ms-1" onClick={bmbcModalFunction}>
                            Why BMBC
                          </button>
                          <Modal
                            className="thankYouPage"
                            visible={whyBmbcModal}
                            onCancel={handleCancelBMBCwHy}
                            footer={false}
                          >
                            <Benifits />
                          </Modal>
                          {/* WHY BMBC ENDS */}
                        </div>
                        {
                          reviewModal &&
                          <div className="checkout_details_box">
                            <Form onSubmit={handleUpdateProfile} className="login-form">
                              <div className="d-flex justify-content-between">
                                <h2 className="mb-0 pb-0">Review Profile :</h2>
                                {
                                  collapse1 ?
                                    <BiChevronUp className="close_bill_icon" onClick={handleCloseProfile} />
                                    :
                                    <BiChevronRight className="close_bill_icon" onClick={handleOpenProfile} />

                                }
                              </div>

                              {
                                collapse1 &&
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <input value={name} className="form-control" onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <input value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <input value={phone} className="form-control" onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Contact" />
                                    </div>
                                  </div>
                                  <div className="col-12 d-flex align-items-center">
                                    <button
                                      htmltype="submit"
                                      loading={isLoading.toString()}
                                      className="theme_btn_main mt-2 me-auto"
                                    >
                                      Update
                                    </button>
                                    {
                                      alertMsg && <p className="ps-2 alert_msg_otp mb-0">{alertMsg}</p>
                                    }
                                  </div>
                                </div>
                              }

                            </Form>
                            <div className="review_details_form">
                              <div className="d-flex justify-content-between">
                                <h2 className="mb-0 pb-0">Review Batch Detail :</h2>
                                {
                                  collapse2 ?
                                    <BiChevronUp className="close_bill_icon" onClick={handleCloseBatch} />
                                    :
                                    <BiChevronRight className="close_bill_icon" onClick={handleOpenBatch} />
                                }
                              </div>
                              {
                                collapse2 &&
                                <div className="review_details_form_card">
                                  <ul>
                                    <li>
                                      <h1>Exam : <p>{batchDetails.examId?.name}</p> </h1>

                                    </li>
                                    <li>
                                      <h1>Category : <p>{batchDetails.category}</p> </h1>
                                    </li>
                                    {permissionIds.includes(batchDetails.examId?._id) &&
                                      <li>
                                        <h1 className="mb-0">Branch :
                                          <select onChange={handleInput}>
                                            <option key={0} value={null}>Select Branch</option>
                                            {batchDetails?.branchName &&
                                              batchDetails?.branchName.map((i, id) => (
                                                <option key={id} value={i._id}>
                                                  {i.name}
                                                </option>
                                              ))}
                                          </select>

                                        </h1>
                                      </li>
                                    }

                                    <li>
                                      <h1>Name :<p>{batchDetails?.planName?.title}</p></h1>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li>
                                      <h1>Number :<p>{batchDetails.batchName}</p></h1>
                                    </li>

                                    <li>
                                      <h1>Insitute Name :<p>{batchDetails.instituteId?.name}</p></h1>
                                    </li>
                                  </ul>
                                </div>
                              }
                            </div>
                          </div>
                        }
                        {
                          isModalOpen &&
                          <div className="review_page_wrap">

                            <div className="d-flex justify-content-between">
                              <h2 className="mb-0 pb-0">Review Billing Details :</h2>
                              {
                                collapse3 ?
                                  <BiChevronUp className="close_bill_icon" onClick={handleCloseBilling} />
                                  :
                                  <BiChevronRight className="close_bill_icon" onClick={handleOpenBilling} />

                              }
                            </div>
                            {
                              collapse3 &&
                              <>
                                <div className="batch_details_checkout">
                                  <div className="chkout_batch_img">
                                    <img src={batchDetails?.instituteId?.image} alt="batchImage" />
                                    <span>
                                      <p>{batchDetails?.instituteId?.name}</p>
                                      <p>{batchDetails?.category}</p>
                                      <p>{batchDetails.batchName}</p>
                                      <p>From : {moment(batchDetails.startDate).format("DD.MM.YYYY")}</p>
                                      <p>To :  {moment(batchDetails.endDate).format("DD.MM.YYYY")}</p>

                                    </span>
                                  </div>
                                  <div className="chkout_price">
                                    <span>
                                      <p>Price</p>
                                      <p>GST & Other Taxes</p>
                                      <p>Sub-Total</p>
                                      <p>Discount</p>
                                      <h5 className="pt-3">Total </h5>
                                    </span>
                                    <span>
                                      <p>
                                        {batchDetails?.actualAmount.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        })}
                                      </p>
                                      <p>
                                        {batchDetails?.gstAmount.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        })}
                                      </p>
                                      <p>
                                        {batchDetails.amount.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        })}
                                      </p>
                                      {
                                        discountValue ? <p className="dicount_price_text">
                                          {discountValue?.toLocaleString("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          })}
                                        </p>
                                          :
                                          <p>00.00</p>

                                      }
                                      <hr />
                                      {applyOffer ?
                                        <h5>
                                          {applyOffer.toLocaleString("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          })}
                                        </h5> :

                                        <h5>
                                          {batchDetails.amount.toLocaleString("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          })}
                                        </h5>
                                      }

                                    </span>
                                  </div>
                                </div>
                                {reviewModal &&
                                  <>
                                    <div className="d-flex justify-content-end">
                                      <button className="theme_btn_main" type="button" onClick={() => displayRazorpay(batchDetails)}>Make Payment</button>
                                    </div>
                                    {
                                      error &&
                                      <p className="text-danger" style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        {error}
                                      </p>
                                    }
                                  </>
                                }
                                {
                                  !reviewModal &&
                                  <div className="d-flex justify-content-end">
                                    <button className="theme_btn_main" type="button" onClick={handleCheckoutDetails}>CheckOut</button>
                                  </div>
                                }
                              </>



                            }


                          </div>
                        }
                        <div className="offer_wrap_section">
                          <div className="heading_wrap">
                            <h2>Offers</h2>
                            <span className="circle1"></span>
                          </div>
                          <div className="offer_tabs">
                            {/* <Card batchDetails={batchDetails} onApplyChange={handleApplyChange} isModalOpen={isModalOpen} /> */}
                            <div className="coupon-card">
                              <div className='logo_wrap'>
                                <div>
                                  <img src={batchDetails?.instituteId?.image} className="logo" alt='coupon' />
                                </div>
                                <div>
                                  <h3>You have an offer from BMBC</h3>
                                  <p>200Rs. off only for one time</p>
                                  {isModalOpen && (
                                    <div className="coupon-row">

                                      {!appliedOffers["BMBC01"] ? (
                                        <>
                                          <span id="cpnCode">BMBC01</span>
                                          <span id="cpnBtn" onClick={() => handleCoupenOffer("BMBC01", BmbcCoupen)}>Apply Code</span>

                                        </>
                                      ) : (
                                        <span id="aplBtn">Applied</span>
                                      )}
                                    </div>
                                  )}

                                  <Link to="/term-condition">Terms & Conditions</Link>
                                </div>
                              </div>
                            </div>
                            {
                              batchDetails.offers.map((offer) => (
                                <div className="coupon-card" key={offer._id}>
                                  <div className='logo_wrap'>
                                    <img src={batchDetails?.instituteId?.image} className="logo" alt='coupon' />
                                    <div>
                                      <h3>{formatDiscount(offer.discountPercent)} Off</h3>
                                      <h3>{offer.offerName}</h3>
                                      <p>Offer end on {moment(offer.endDate).format("DD.MM.YYYY")}</p>
                                      {isModalOpen && (
                                        <div className="coupon-row">

                                          {!appliedOffers[offer.code] ? (
                                            <>

                                              <span id="cpnCode">{offer.code}</span>
                                              <span id="cpnBtn" onClick={() => handleCoupenOffer(offer.code, formatDiscount(offer.discountPercent))}>Apply Code</span>
                                            </>
                                          ) : (
                                            <span id="aplBtn">Applied</span>
                                          )}
                                        </div>
                                      )}

                                      <Link to="/term-condition">Terms & Conditions</Link>

                                    </div>
                                  </div>

                                </div>
                              ))
                            }

                          </div>
                        </div>
                        <div className="comment_lv_fm cmnt_mobile_view">
                          <form action="#">
                            <h4>Add a Review</h4>
                            <div className="mb-15">
                              <div className="add-a-review">
                                <h6>Your Rating:</h6>
                                <div className="product-ratting">
                                  <ul>
                                    {[1, 2, 3, 4, 5].map((rating) => (
                                      <li key={rating}>
                                        <button
                                          type="button"
                                          className={`star-icon${rating <= parseInt(ratingCount) ? ' active' : ''}`}
                                          onClick={() => handleStarClick(rating)}
                                        >
                                          <FaStar />
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="leave_cmnt_input">
                              <input type="text" className="form-control" value={ratingComment} onChange={(e) => setRatingComment(e.target.value)} placeholder="Type comments...." />
                              <button
                                className="sign_up"
                                type="submit"
                                onClick={(e) => handlePostReview(e)}
                              >
                                Post
                              </button>
                            </div>
                          </form>

                          <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 mt-30 ltn__product-details-review-inner mobile_sidedesk">
                            <div className="d-flex justify-content-between align-items-start">
                              <div>
                                <h4 className="title_head mb-0">
                                  Student Ratings
                                </h4>

                                <div className="product-ratting mt-0">
                                  <ul className="ps-3">
                                    {Array.from({ length: 5 }).map((_, index) => (
                                      <li key={index} className="me-2">
                                        <span>
                                          {index < batchDetails?.instituteId?.rating ? <FaStar /> : <FaRegStar />}
                                        </span>
                                      </li>
                                    ))}
                                    <li><p>({batchDetails?.instituteId?.rating.toFixed(1) + "0"})</p></li>
                                  </ul>
                                </div>
                              </div>
                              <button type="button" className="btn btn-light btn-outline-secondary" onClick={handleReviews}>See Reviews ({batchDetails?.instituteId?.ratingCount - 1})</button>
                            </div>

                            <hr className="d-block" />
                            <div className="row gy-4 mt-2 mb-4 reviews_col_row">
                              {
                                batchReview.length > 0 &&
                                batchReview.map((review, id) => (
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={id}>
                                    <div className="review_card">
                                      <div className="heading_rev">
                                        <img src={review.userId?.photo ? review.userId?.photo : logoUser} alt="hjghdhds" />
                                        <div className="name">
                                          <span className="rating_icon">
                                            <h4>
                                              {review?.userId?.name}

                                            </h4>
                                            <h5>
                                              {
                                                review?.userId?.city
                                              }
                                            </h5>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="cmt">
                                        {
                                          review?.message
                                        }
                                      </p>
                                      <span className="rating_star_number">
                                        ({review?.rating}) <FaStar />
                                      </span>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
        }
      </div>
      <Modal
        visible={registerModal}
        onCancel={null}
        footer={null}
        className="login_det_form"
        centered
        bodyStyle={{ overflow: "hidden" }}
      >
        <Register
          text={textValue}
          onRegisterClose={handleSuccessClose}
        />

      </Modal>
    </div>
  );

};

export default BatchDetails;
