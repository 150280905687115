import React from 'react';
import ContactInfo from './section-components/contact-info';
import ContactUsForm from './section-components/ContactUsForm';
// import ContactForm from './section-components/contact-form';
// import contactImg from "./media/banner/contactban.jpg";
// import Pageheader from './global-components/page-header';
import { Helmet } from 'react-helmet';
import logoBmbc from "./media/banner/logo.png"
import Layout from './global-components/Layout';
const ContactV1 = () =>
{
    return <div>
        <Helmet>
            <title>
                Reach Out for Personalized Assistance and Support
            </title>
            <meta
                name="description"
                content="Contact us for personalized assistance and support on our platform. Whether you have questions, 
                feedback, or require any kind of assistance,our responsive team is here to help. Reach out to us through 
                the contact form or the provided contact details, and we'll ensure a prompt response to address your needs"
            />
            <meta name="keywords" content="find coaching centre, find coaching classes, find online coaching classes, find offline coaching classes, get coaching classes, top coaching centers" />
            <meta property="og:title" content="Reach Out for Personalized Assistance and Support" />
            <meta property="og:description" content="Contact us for personalized assistance and support on our platform. Whether you have questions, 
                feedback, or require any kind of assistance,our responsive team is here to help. Reach out to us through 
                the contact form or the provided contact details, and we'll ensure a prompt response to address your needs" />
            <meta property="og:image" content={logoBmbc} />
            <link rel="canonical" href="https://bookmybestclass.com/contact" />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            {/* <Pageheader subheader="Contact" image={contactImg} /> */}
            <div className='container'>
                <div className='padding_area'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <ContactInfo />
                        </div>
                        <div className='col-lg-6'>

                            <ContactUsForm />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    </div>
}

export default ContactV1

