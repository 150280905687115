import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import adminService from "../../../common/adminService";
import TopNav from "../topnavComponent";
import AuthGuard from "../authGuards";
import moment from "moment";

const RatingWrapper = styled.div`
  padding: 20px;
`;

const RatingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const RatingTable = styled(Table)`
  background-color: #fff;
  border-radius: 4px;
`;

function InstituteRating() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [institueList, setInstitueList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [adminType, setAdminType] = useState("");
  // const [adminRole, setAdminRole] = useState("");

  useEffect(() => {
    // const role = sessionStorage.getItem("adminRole");
    const type = sessionStorage.getItem("adminType");
    // setAdminRole(role);
    setAdminType(type);
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    adminService.listInstitueRating().then((apiResponse) => {
      const paginationObj = { ...pagination };
      paginationObj.total = apiResponse.data.data.length;
      const data = apiResponse.data.data;
      // const uniqueInstituteNames = [...new Set(data.map((ele) => ele.instituteId?.name))].filter(Boolean);
      // setInstitueList(uniqueInstituteNames)
      setLoading(false);
      setData(data);
      setPagination(paginationObj);
    });
  };

  // const deleteRating = async (id) => {
  //   const deleteRating = await adminService.deleteInstituteRating(id);
  //   if (deleteRating.data.success == true) {
  //     message.success(deleteRating.data.data);
  //   } else {
  //     message.warn("An error occured please try again");
  //   }
  //   fetch();
  // };

  const columns = [
    {
      title: "Institue Name",
      dataIndex: "instituteId.name",
      // filters: institueList,
      // filteredValue: filteredInfo?.["instituteId.name"] || null,
      // onFilter: (value, record) => record.instituteId?.name === value,
      // sortOrder: sortedInfo.columnKey === "instituteId.name" && sortedInfo.order,
      // ellipsis: true,
    },
    // {
    //   title: "User",
    //   dataIndex: "userId",
    // },
    {
      title: "Rating",
      dataIndex: "rating",
    },
    {
      title: "Review",
      dataIndex: "message",
      width: "50%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (idx) => {
        return <span>{`${moment(idx).format("MMMM Do, YYYY")}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (record) => (
        <>
          {adminType === "edit" || adminType === "delete" ? (
            <Button type="primary" style={{ marginRight: 2 }}>
              <Link to={`/admin/editInstituteRating/${record}`}>Edit</Link>
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <AuthGuard />
      <TopNav />
      <RatingWrapper>
        <RatingHeader>
          <h2>Institute Ratings</h2>
        </RatingHeader>
        <RatingTable
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </RatingWrapper>
    </>
  );
}

export default InstituteRating;
