import React from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import apiService from "../../common/apiService";
import
{
    FaStar,
    FaRegStar, FaStarHalfAlt
} from "react-icons/fa";
import ErrorPage from "../404";
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { BatchContext } from '../../redux/batchContext';
const InstituteSearch = ({ examName }) =>
{
    //   const apiURL = process.env.REACT_APP_API_URL;
    const testURL = process.env.REACT_APP_API_URL_LIVE
    const [modeName, setModeName] = useState("");
    const [cityName, setCityName] = useState("");
    const [examLocalId, setExamLocalId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showMoreInst, setShowMoreInst] = useState(false);
    const {
        instituteList,
        setInstituteList,
        originalInstituteList,
        setOriginalInstituteList,
        // examName
    } = useContext(BatchContext);

    useEffect(() =>
    {
        const examFromLocalStorage = localStorage.getItem('exam');
        const modeFromLocalStorage = localStorage.getItem('mode');
        const cityFromLocalStorage = localStorage.getItem('city');
        setCityName(cityFromLocalStorage)
        setModeName(modeFromLocalStorage);
        setExamLocalId(examFromLocalStorage);
        sessionStorage.removeItem("checkIds");
        // eslint-disable-next-line 
    }, []);

    useEffect(() =>
    {
        if (modeName !== null && examLocalId !== null)
        {
            getAllInstitutes("");
            handleCancelSearch();
        }
        // eslint-disable-next-line
    }, []);

    const getAllInstitutes = async () =>
    {
        const cityValue = localStorage.getItem('city');
        const examValue = localStorage.getItem('exam');
        const modeValue = localStorage.getItem('mode');
        let url = testURL + `institute/list`;
        if (examValue)
        {
            url += `?examId=${examValue}`;
        }
        // Modify the condition here
        if (modeValue === "Offline")
        {
            if (cityValue !== "all" && cityValue !== null)
            {
                url += `&city=${cityValue}`;
            }
        }

        const rawData = await apiService.get(url);
        const data = rawData.data.data;
        setOriginalInstituteList(data);
        const filterInstitute = [...data].sort((a, b) => b.rating - a.rating);
        console.log(filterInstitute);
        setInstituteList(filterInstitute);
    };

    const StarRating = ({ rating }) =>
    {
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating - fullStars >= 0.5;

        return (
            <>
                {Array.from({ length: 5 }).map((_, index) => (
                    <span key={index}>
                        {index < fullStars ? (
                            <FaStar />
                        ) : index === fullStars && hasHalfStar ? (
                            <FaStarHalfAlt />
                        ) : (
                            <FaRegStar />
                        )}
                    </span>
                ))}
            </>
        );
    };


    // SLIDER SETTING FUNCTION

    const CustomPrevArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsLeft />
            </div>
        );
    };

    const CustomNextArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsRight />
            </div>
        );
    };

    const InstituteSliderAll = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: instituteList.length > 5 ? 6 : instituteList.length,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1091,
                settings: {
                    slidesToShow: instituteList.length > 4 ? 5 : instituteList.length,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: instituteList.length < 2 ? 1 : 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: instituteList.length < 2 ? 1 : 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 491,
                settings: {
                    slidesToShow: instituteList.length < 2 ? 1 : 2,
                    slidesToScroll: 1
                }
            },
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    }

    const handleSearchInstitute = (e) =>
    {
        const inputValue = e.target.value;
        setSearchValue(inputValue);

        // Filter the institute list when the user starts typing
        const filteredName = originalInstituteList.filter((item) =>
            item.name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        setInstituteList(filteredName);
    };

    const handleCancelSearch = () =>
    {
        setSearchValue('');
        setInstituteList(originalInstituteList);
    };

    const handleKeyDown = (e) =>
    {
        if (e.keyCode === 8)
        {
            if (searchValue === '')
            {
                handleCancelSearch();
            }
        }
    };
    let instituteSliderWidth = "100%";
    let instituteSliderWidthMobile = "100%";

    if (instituteList.length < 5)
    {
        instituteSliderWidth = `${25 * instituteList.length}%`;
    }

    if (window.innerWidth < 768)
    {
        instituteSliderWidthMobile = `${50 * instituteList.length}%`;
    }

    return (
        <div className="batch_ins_list" >
            <div className="container batch_reviw">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area mb-3 ltn__section-title-2--- text-center">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h1 className="inst_title mb-0 mt-0">
                                    Top Institutes {examName && <span style={{ color: 'red' }}>for {examName}</span>}
                                    {modeName === "Offline" && ' in'} {modeName === "Offline" && <span style={{ color: 'red' }}>{cityName}</span>}

                                </h1>
                                {
                                    !showMoreInst ?
                                        <button className="btn show_more_btn  pe-0" onClick={() => setShowMoreInst(true)}>See All ({instituteList.length})<i className="fa-solid fa-arrow-right"></i></button>
                                        :
                                        <button className="btn show_more_btn  pe-0" onClick={() => setShowMoreInst(false)}>See Less<i className="fa-solid fa-arrow-right"></i></button>
                                }
                            </div>
                            {
                                !showMoreInst ?
                                    <button className="btn show_more_btn_mob_view pe-0" onClick={() => setShowMoreInst(true)}>See All ({instituteList.length})<i className="fa-solid fa-arrow-right"></i></button>
                                    :
                                    <button className="btn show_more_btn_mob_view pe-0" onClick={() => setShowMoreInst(false)}>See Less<i className="fa-solid fa-arrow-right"></i></button>
                            }

                            <div className="search_inst_section">
                                <div className="input-group">
                                    <input type="text" className="form-control" name="searchValue" value={searchValue} onKeyDown={handleKeyDown} onChange={handleSearchInstitute} placeholder="Search Insitute" aria-label="Search Insitute" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-2 gx-lg-2 slick-arrow-1 int_list_it go-top">
                    {
                        instituteList.length > 0 ?
                            (showMoreInst ?
                                instituteList?.map((ele) => (
                                    <div className='col-lg-2 col-md-4 col-sm-6 col-12  inst_car_all' key={ele._id}>
                                        <div className="ltn__search-by-place-item institute_list">
                                            <div className="search-by-place-img">
                                                <Link to={`/institute-detail/${ele._id}`} className="int_banner">
                                                    <img
                                                        src={ele.image}
                                                        alt="#"
                                                        loading="lazy"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="search-by-place-info">
                                                <div className="institute_tit">
                                                    <Link to={`/institute-detail/${ele._id}`}>
                                                        <h4>{ele.name?.slice(0, 15) + ".."}</h4>
                                                    </Link>
                                                </div>
                                                <div className="institute_tit_mob">
                                                    <Link to={`/institute-detail/${ele._id}`}>
                                                        <h4>{ele.name?.slice(0, 10) + ".."}</h4>
                                                    </Link>
                                                </div>
                                                <div className="search-by-place-btn">
                                                    <Link to={`/institute-detail/${ele._id}`}>
                                                        View Details <i className="flaticon-right-arrow" />
                                                    </Link>
                                                    <div className="ratings mr-2 mb-1">
                                                        {/* <StarRating rating={ele.rating} /> */}
                                                        <FaStar />
                                                        <span>{String(ele.rating).slice(0, 3) + ""}</span>
                                                    </div>
                                                </div>
                                                <div className='mob_inst_ratings'>
                                                    <StarRating rating={ele.rating} />
                                                    <span>{String(ele.rating.toFixed(1) + "0")}</span>
                                                </div>
                                                <Link to={`/institute-detail/${ele?._id}`} className="btn btn-sm view_more_batch_btn more_batch inst_view_more mt-2" type="button">View <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className="slider-container" style={{ width: window.innerWidth >= 768 ? instituteSliderWidth : instituteSliderWidthMobile }}>
                                    <Slider {...InstituteSliderAll}>
                                        {instituteList?.slice(0, 12).map((ele) => (
                                            <div className='inst_car_all' key={ele._id}>
                                                <div className="ltn__search-by-place-item institute_list">
                                                    <div className="search-by-place-img">
                                                        <Link to={`/institute-detail/${ele._id}`} className="int_banner">
                                                            <img
                                                                src={ele.image}
                                                                alt="#"
                                                                loading="lazy"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="search-by-place-info">
                                                        <div className="institute_tit">
                                                            <Link to={`/institute-detail/${ele._id}`}>
                                                                <h4>{ele.name?.slice(0, 15) + ".."}</h4>
                                                            </Link>
                                                        </div>
                                                        <div className="institute_tit_mob">
                                                            <Link to={`/institute-detail/${ele._id}`}>
                                                                <h4>{ele.name?.slice(0, 15) + ".."}</h4>
                                                            </Link>
                                                        </div>
                                                        <div className="search-by-place-btn">
                                                            <Link to={`/institute-detail/${ele._id}`}>
                                                                View Details <i className="flaticon-right-arrow" />
                                                            </Link>
                                                            <div className="ratings mr-2 mb-1">
                                                                {/* <StarRating rating={ele.rating} /> */}
                                                                <FaStar />
                                                                <span>{String(ele.rating).slice(0, 3) + ""}</span>
                                                            </div>
                                                        </div>
                                                        <div className='mob_inst_ratings'>
                                                            <StarRating rating={ele.rating} />
                                                            <span>{String(ele.rating).slice(0, 3) + ""}</span>
                                                        </div>
                                                        <Link to={`/institute-detail/${ele?._id}`} className="btn btn-sm view_more_batch_btn more_batch inst_view_more  mt-2" type="button">View <i className="fa-solid fa-arrow-right"></i></Link>
                                                    </div>
                                                    {
                                                        ele?.instituteKeyword?.keyword &&
                                                        <p className="institute_badge">
                                                            {ele?.instituteKeyword?.keyword?.name}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            )
                            :
                            <ErrorPage value="There are no institute for current filter. New Institute will be updated soon, please check regularly." />

                    }
                </div>
            </div>
        </div>
    )
}

export default InstituteSearch