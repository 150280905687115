import React, { useState, useEffect, useContext } from "react";
import
{
    Link,
} from "react-router-dom";
import moment from "moment";
import "../assets/css/searchForm.css";
import "../assets/css/batch.css";
import Slider from "react-slick";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { Spin } from "antd";
import ErrorPage from "../404";
import { BatchContext } from "../../redux/batchContext";
import "../assets/css/newSearch.css";
import "../assets/css/compareCard.css";
import { Suspense } from "react";
import BatchCard from "../reusable/BatchCard";
const TrendingBatches = ({ examName }) =>
{
    const [showMore, setShowMore] = useState(false);

    const {
        // examName,
        modeName,
        cityName,
        setExamLocalId,
        setCityName,
        setModeName,
        loading,
        examLocalId,
        trendingList,
        trendingFilterList,
        setTrendingList
    } = useContext(BatchContext);

    useEffect(() =>
    {
        const cityFromLocalStorage = localStorage.getItem('city');
        const examFromLocalStorage = localStorage.getItem('exam');
        const modeFromLocalStorage = localStorage.getItem('mode');
        setCityName(cityFromLocalStorage);
        setModeName(modeFromLocalStorage);
        setExamLocalId(examFromLocalStorage);
        if (modeName !== null && examLocalId !== null)
        {
            // getAllBatches()
            handleClick("all");
        }

        // eslint-disable-next-line
    }, [modeName, examLocalId]);

    // const history = useHistory();

    const handleClick = (filterString) =>
    {
        let filteredBatches;
        if (filterString === "all")
        {
            filteredBatches = trendingFilterList;
        } else if (filterString === "upcoming")
        {
            filteredBatches = trendingFilterList.filter((batch) => batch.status === "Upcoming");
        } else if (filterString === "ongoing")
        {
            filteredBatches = trendingFilterList.filter((batch) => batch.status === "Ongoing");
        } else
        {
            filteredBatches = trendingFilterList;
        }
        setTrendingList(filteredBatches);

    };
    // SLIDER SETTING FUNCTION

    const CustomPrevArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsLeft />
            </div>
        );
    };

    const CustomNextArrow = (props) =>
    {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block', lineHeight: '16px' }} onClick={onClick}>
                <BiChevronsRight />
            </div>
        );
    };

    const BatchSliderAll = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: trendingList?.length > 3 ? 4 : trendingList?.length,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1091,
                settings: {
                    slidesToShow: trendingList?.length > 3 ? 4 : trendingList?.length,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: trendingList.length < 2 ? 1 : 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    }
    let BatchSliderWidth = "100%";
    let BatchSliderWidthMobile = "100%";

    if (trendingList.length < 4)
    {
        BatchSliderWidth = `${25 * trendingList.length}%`;
    }
    if (trendingList.length < 2 && window.innerWidth < 768)
    {
        BatchSliderWidthMobile = `${50 * trendingList.length}%`;
    }
    if (trendingList.length < 2 && window.innerWidth < 430)
    {
        BatchSliderWidthMobile = `100%`;
    }

    const permissionIds = [
        "6461de19cd10f83e9358827b",
        "64be659d4dcfa238cc4b2b14",
        "64e473a04f25d7076635288a",
        "64e487ff4f25d707663528b9",
        "64be65bb4dcfa238cc4b2b16",
        "6463245acd10f83e9358828e"
    ]

    return (
        <>
            {
                trendingFilterList.length > 0 &&
                <div className="ltn__product-slider-area batch_ins_list ltn__product-gutter">
                    <div className="container batch_reviw">
                        <div className="section-title-area mb-3 ltn__section-title-2--- text-center">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="inst_title mb-0 mt-0">
                                    Trending {modeName && modeName} Classes {examName && <span style={{ color: 'red' }}> {examName}</span>} {
                                        modeName === "Offline" && <>
                                            {examName && cityName && '-'} <span style={{ color: 'red' }}>{cityName}</span></>
                                    }
                                </h1>
                                {
                                    !showMore ?
                                        <button className="btn pe-0  me-0 show_more_btn" onClick={() => setShowMore(true)}>See All ({trendingList.length})</button>
                                        :
                                        <button className="btn pe-0  me-0 show_more_btn" onClick={() => setShowMore(false)}>See Less</button>
                                }

                            </div>
                            {
                                !showMore ?
                                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMore(true)}>See All ({trendingList.length})<i className="fa-solid fa-arrow-right"></i></button>
                                    :
                                    <button className="btn pe-0  me-0 show_more_btn_mob_view" onClick={() => setShowMore(false)}>See Less<i className="fa-solid fa-arrow-right"></i></button>
                            }

                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade active" id="liton_product_list">
                                <div className="ltn__product-tab-content-inner tabs_content_btch ltn__product-list-view">
                                    <div className="row g-2">
                                        {
                                            loading ?
                                                <div className="d-flex justify-content-center">
                                                    <Spin />
                                                </div>
                                                :
                                                (
                                                    trendingList?.length > 0 ? (showMore ?
                                                        <Suspense fallback={'Loading ...'}>
                                                            <BatchCard
                                                                batchList={trendingList}
                                                                // hidden={hidden}
                                                                showMore={showMore}
                                                            // onView={hanleViewAllBatches}
                                                            // checkedId={checkedId}
                                                            // onCompare={handleCompareInput}
                                                            />
                                                        </Suspense>
                                                        :
                                                        <div className="slider-container" style={{ width: window.innerWidth >= 768 ? BatchSliderWidth : BatchSliderWidthMobile }}>
                                                            <Slider {...BatchSliderAll}>
                                                                {
                                                                    trendingList &&
                                                                    trendingList.map((ele) => (
                                                                        <div className="px-2" key={ele._id}>
                                                                            <div className="batch_card_details affordable_batch_card">
                                                                                <div className="left_box">
                                                                                    {ele?.batchKeyword &&
                                                                                        ele?.keywordStatus !== "Ended" && (
                                                                                            <h5 className="keyword_tags">
                                                                                                <p className="badge_btn">
                                                                                                    {ele.status}
                                                                                                </p>
                                                                                                <span>{ele.batchKeyword?.keyword?.name}</span>

                                                                                            </h5>
                                                                                        )
                                                                                    }
                                                                                    <img className="img-fluid img-responsive rounded product-image" loading="lazy" src={ele?.instituteId?.image} alt={ele.instituteId.image} />
                                                                                    {
                                                                                        ele.planName?.title && (
                                                                                            <h5 className="marquee_tags">
                                                                                                {/* <p>Batch Type</p> */}
                                                                                                <span>{ele.planName?.title}</span>
                                                                                            </h5>

                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className="center_box">
                                                                                    <div className="center_details">
                                                                                        <h5 className="my-0">
                                                                                            <span>
                                                                                                {ele.examId?.name}
                                                                                            </span>
                                                                                        </h5>
                                                                                        <h4>
                                                                                            <span>({ele.instituteId?.rating.toFixed(1) + "0"})</span><FaStar />
                                                                                        </h4>
                                                                                        <span className="mob_category">{ele?.category}</span>
                                                                                    </div>
                                                                                    <h5 className="desk_inst">
                                                                                        <span>Institute :</span>
                                                                                        <span>{ele.instituteId?.name?.slice(0, 15) + ".."}</span>
                                                                                    </h5>
                                                                                    <h5 className="mob_inst">
                                                                                        <span>{ele.instituteId?.name?.slice(0, 15) + ".."}</span>
                                                                                    </h5>
                                                                                    <h5 className="mob_batch">
                                                                                        <span>Batch No:</span>
                                                                                        <span>{ele?.batchName}</span>
                                                                                    </h5>
                                                                                    {permissionIds.includes(ele.examId._id) &&
                                                                                        <h5 className="mob_batch">
                                                                                            <span>Branch :</span>
                                                                                            <Link to={`/batch-detail/${ele._id}`}>
                                                                                                {ele.branchName?.length > 0 && (
                                                                                                    <>
                                                                                                        {ele.branchName.slice(0, 3).map((branch, index) =>
                                                                                                        {
                                                                                                            const truncatedName = branch.name;
                                                                                                            return (
                                                                                                                <React.Fragment key={index}>
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            cursor: "pointer",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {truncatedName}
                                                                                                                    </span>
                                                                                                                    {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                                                                                   
                                                                                                                </React.Fragment>
                                                                                                            );
                                                                                                        })}
                                                                                                        {ele?.branchName.length > 4 &&
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                            >
                                                                                                                ...
                                                                                                            </span>
                                                                                                        }
                                                                                                    </>
                                                                                                )}
                                                                                            </Link>
                                                                                        </h5>
                                                                                    }
                                                                                    <h5 className="mob_batch">
                                                                                        <span>Language :</span>
                                                                                        <span style={{ color: "#2292ca" }}>
                                                                                            {ele?.languages?.name ? ele?.languages?.name : "English"}
                                                                                        </span>
                                                                                    </h5>
                                                                                    {ele.category !== 'Online' &&
                                                                                        <h5 className="mob_batch">
                                                                                            {ele.category !== 'Online' && <span>City :</span>}
                                                                                            {ele.category !== 'Online' && <span>{ele.city}</span>}
                                                                                        </h5>
                                                                                    }

                                                                                    <h3 className="mob_price">
                                                                                        <span>Price :</span>
                                                                                        <span style={{ color: "#2292ca" }}>
                                                                                            {ele.amount.toLocaleString("en-IN", {
                                                                                                style: "currency",
                                                                                                currency: "INR",
                                                                                            }).split('.')[0]}
                                                                                            <span className="incl_gst">incl. GST</span>
                                                                                        </span>
                                                                                    </h3>
                                                                                    <h5 className="desk_btch_number">
                                                                                        <span>Batch No :</span>
                                                                                        <span>
                                                                                            {ele.batchName}
                                                                                        </span>
                                                                                    </h5>
                                                                                    {/* <h5 className="desk_strt_dt">
                                                                                        <span>Start Date :</span><span>{moment(ele.startDate).format("DD.MM.YYYY")}</span>
                                                                                    </h5> */}
                                                                                </div>
                                                                                <div className="right_box">
                                                                                    <h5>
                                                                                        <span>Price :</span>
                                                                                        <span style={{ color: "#2292ca" }}>
                                                                                            {ele.amount.toLocaleString("en-IN", {
                                                                                                style: "currency",
                                                                                                currency: "INR",
                                                                                            }).split('.')[0]}
                                                                                            <span className="incl_gst">incl. GST</span>
                                                                                        </span>
                                                                                    </h5>
                                                                                    {permissionIds.includes(ele.examId._id) && <h5>
                                                                                        <span>Branch :</span>
                                                                                        <Link to={`/batch-detail/${ele._id}`}>
                                                                                            {ele.branchName?.length > 0 && (
                                                                                                <>
                                                                                                    {ele.branchName.slice(0, 3).map((branch, index) =>
                                                                                                    {
                                                                                                        const truncatedName = branch.name;
                                                                                                        return (
                                                                                                            <React.Fragment key={index}>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        cursor: "pointer",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {truncatedName}
                                                                                                                </span>
                                                                                                                {index < ele.branchName.slice(0, 3).length - 1 && <span>, </span>}
                                                                                                              
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    })}
                                                                                                    {ele?.branchName.length > 4 &&
                                                                                                        <span
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                            }}
                                                                                                        >
                                                                                                            ...
                                                                                                        </span>
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </Link>
                                                                                    </h5>

                                                                                    }
                                                                                    <h5>
                                                                                        <span>Language :</span>
                                                                                        <span style={{ color: "#2292ca" }}>
                                                                                            {ele?.languages?.name ? ele?.languages?.name : "English"}
                                                                                        </span>
                                                                                    </h5>
                                                                                    <h5>
                                                                                        <span>{ele?.category}</span>
                                                                                        {
                                                                                            ele?.category === "Offline" && <p className="city">{ele?.city}</p>
                                                                                        }
                                                                                    </h5>
                                                                                </div>
                                                                                <div className="see_btn_wrap">
                                                                                    <Link to={`/batch-detail/${ele._id}`} className="btn btn-outline-primary view_al_btch w-100" type="button">View Details</Link>
                                                                                </div>
                                                                                {
                                                                                    ((ele?.batchKeyword && ele?.keywordStatus === "Ended") ||
                                                                                        (!ele?.batchKeyword && ele?.keywordStatus === "Active")) &&
                                                                                    (
                                                                                        <p className="badge_btn2">
                                                                                            {ele.status}
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                }
                                                            </Slider>
                                                        </div>

                                                    )
                                                        :
                                                        <ErrorPage value="There are no batches for current filter. New Batches will be updated soon, please check regularly." />
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }


        </>

    )
}

export default TrendingBatches