import React from 'react';
import BatchDetails from './section-components/BatchDetails';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Layout from './global-components/Layout';
const BatchDetail = () =>
{
    const [instituteName, setInstituteName] = useState("");
    const [instituteDescription, setInstituteDescription] = useState("");
    const [categoryList, setCategoryList] = useState("");
    const [city, setCity] = useState("");
    const [logoImg, setLogoImg] = useState("")

    // Function to receive the data from the child component
    const handleData = (name, description, category, city, logo) =>
    {
        setInstituteName(name);
        setInstituteDescription(description);
        setCategoryList(category);
        setCity(city);
        setLogoImg(logo)
    };
    const handleLoading = () =>
    {
        
    }
    return <div>
        <Helmet>
            <title>
                {`${instituteDescription.slice(0, 20) + "."}, ${instituteName}`}
            </title>
            <meta
                name="description"
                content={instituteDescription.slice(0, 60)}
            />
            <meta name="keywords" content={`${categoryList}, ${city}`} />
            <meta property="og:title" content={`${instituteDescription.slice(0, 20) + "."}, ${instituteName}`} />
            <meta property="og:description" content={instituteDescription.slice(0, 60)} />
            <meta property="og:image" content={logoImg} />
            <link rel="canonical" href="https://bookmybestclass.com/batch-detail" />
            <meta property="og:url" content="https://bookmybestclass.com"></meta>
            <meta property="og:site_name" content="bookmybestclass"></meta>
        </Helmet>
        <Layout>
            <BatchDetails onData={handleData} handleLoading={handleLoading} />
        </Layout>
     
    </div>
}
export default BatchDetail

